import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LinkOff from '@material-ui/icons/LinkOff';
import Save from '@material-ui/icons/Save';
import AutoRenew from '@material-ui/icons/Autorenew';
import Flare from '@material-ui/icons/Flare';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '../../../Components/General/Text';
import {
  BrancherDispatch,
  SaveDrafting,
  SavePairingData,
  SaveSuggestedPairs,
  UtilGetPairs,
  UtilGetTargetUserPairs,
  UtilSaveDraftedPairs,
} from '../../../store/actions';
import {
  AcceptButton,
  ActionButton,
  ActionNegativeOutlinedButton,
  ActionOutlinedButton,
} from '../../../Components/InputFields/BrancherButton';
import { IStoreTypes } from '../../../store/storeTypes';
import { SuggestedPair } from './SuggestedPair';
import { EPairingTypes, IDraftedPairs } from '../../../store/reducers/PairingReducer';
import { ApprovedPair } from './ApprovedPair';
import { RouteLeavingGuard } from '../../../Components/Routing/RouteLeavingGuard';
import { Colors } from '../../../consts/colors';
import {
  mainNavWidth,
  matchmakingMainNavBarHeight,
  matchmakingMobileNavBarHeight,
  mobileMainNavHeight,
} from '../../../consts/DimensionConsts';
import { ProgramPositions } from '../../../consts/ProgramPositionOptions';
import { BrancherFullPageLoader } from '../../../Components/General/BrancherFullPageLoader';
import { UserPairingCard } from './UserPairingCard';
import { BrancherDivider } from '../../../Components/General/BrancherDivider';
import { getUniqueDraftedUsers } from './matchMakingHelpers';
import { TargetUserPairsDialog } from './TargetUserPairsDialog';
import { EMatchingStrategies } from '../../Matching/PairingEngine';

export enum EDraftingPairTypes {
  SUGGESTED = 'suggested',
  AVAILABLE_POSITIONS = 'availablePositions',
}

const useStyles = makeStyles({
  overridePairingCardMargins: {
    marginBottom: 15,
  },
  inComplete: {
    opacity: 0.1,
    margin: '150px auto 0px',
    position: 'absolute',
    zIndex: 0,
  },
  zIndexed: {
    zIndex: 10,
    marginTop: 150,
  },
  bottomNav: {
    height: (props: { mobile: boolean }) =>
      props.mobile ? matchmakingMobileNavBarHeight : matchmakingMainNavBarHeight,
    background: Colors.backgroundLightPurple,
    bottom: (props: { mobile: boolean }) => (props.mobile ? mobileMainNavHeight : 0),
    top: 'auto',
    display: 'flex',
    alignItems: 'center',
    zIndex: 1000,
    width: (props: { mobile: boolean }) => (props.mobile ? '100%' : `calc(100% - ${mainNavWidth})`),
    boxShadow: `0px -3px 6px ${Colors.cardBorder}`,
  },
  matchmaking: {
    marginBottom: (props: { mobile: boolean }) =>
      props.mobile
        ? `calc(${matchmakingMobileNavBarHeight + mobileMainNavHeight} + 30px)`
        : `calc(${matchmakingMainNavBarHeight} + 30px)`,
  },
});

interface IMatchmaking {
  showActionsNav?: boolean;
  setIsMatching?: () => void;
}

export const Matchmaking: React.FC<IMatchmaking> = ({ setIsMatching, showActionsNav }) => {
  const [savingDraftPairs, setSavingDraftPairs] = React.useState<boolean>(false);
  const [hasSavedDraftingData, setHasSavedDraftingData] = React.useState<boolean>(true);
  const [targetUser, setTargetUser] = React.useState<string>();
  const [targetUserRoleId, setTargetUserRoleId] = React.useState<string>();
  const [targetUserDialog, setTargetUserDialog] = React.useState<boolean>(false);
  const [gettingTargetUserPairs, setGettingTargetUserPairs] = React.useState<boolean>(false);
  const [targetUserPairs, setTargetUserPairs] = React.useState<IDraftedPairs[]>();
  const [runningAlgorithm, setRunningAlgorithm] = React.useState<boolean>(false);
  // Would be good to have a re-look at simplifying this, syncing state across 3 objects is fiddly
  const [draftingPairType, setDraftingPairType] = React.useState<EDraftingPairTypes>();
  const [draftingActioningPosition, setDraftingActioningPosition] = React.useState<ProgramPositions>();
  const [matchingStrategy, setMatchingStrategy] = React.useState<EMatchingStrategies>(EMatchingStrategies.MAXIMIZE_COMPATIBILITY);
  const draftPairs = useSelector((state: IStoreTypes) => state?.pairing?.draftPairs);
  const pairings = useSelector((state: IStoreTypes) => state?.pairing?.paired);
  const unpaired = useSelector((state: IStoreTypes) => state?.pairing?.unpaired);
  const dispatch = useDispatch();
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const styles = useStyles({ mobile });

  const saveDraftedPairs = (updatingPairs?: boolean, cb?: () => void) => {
    if (draftPairs?.length > 0 || updatingPairs) {
      setSavingDraftPairs(true);
      BrancherDispatch(
        dispatch,
        UtilSaveDraftedPairs(() => {
          setHasSavedDraftingData(true);
          setSavingDraftPairs(false);
          cb?.();
        }),
      );
    }
  };

  const runAlgorithm = () => {
    setRunningAlgorithm(true);
    saveDraftedPairs(true, () => {
      BrancherDispatch(
        dispatch,
        UtilGetPairs({ persist: true, matchingStrategy }, () => {
          setRunningAlgorithm(false);
        }),
      );
    });
  };

  const autoMatch = () => {
    setHasSavedDraftingData(false);
    let intermediateDraftPairs = draftPairs;
    let intermediateSuggestedPairs = pairings;
    pairings.map((pair) => {
      if (
        pair.menteeApprovedPairs !== pair.menteePairAmount &&
        pair.mentorApprovedPairs !== pair.mentorPairAmount
      ) {
        intermediateDraftPairs = [...intermediateDraftPairs, pair].map((p) => {
          let newPairData = p;
          if (p.mentee === pair.mentee) {
            newPairData = {
              ...newPairData,
              pairingType: EPairingTypes.BRANCHER_RECOMMENDED,
              menteeApprovedPairs:
                intermediateDraftPairs.filter((f) => f.mentee === pair.mentee).length + 1,
            };
          }
          if (p.mentor === pair.mentor) {
            newPairData = {
              ...newPairData,
              pairingType: EPairingTypes.BRANCHER_RECOMMENDED,
              mentorApprovedPairs:
                intermediateDraftPairs.filter((f) => f.mentor === pair.mentor).length + 1,
            };
          }
          return newPairData;
        });
        const newPairings = [];
        intermediateSuggestedPairs.forEach((p) => {
          if (!(p.mentee === pair.mentee && p.mentor === pair.mentor)) {
            newPairings.push(p);
          }
        });
        intermediateSuggestedPairs = newPairings.map((np) => {
          let newPairData = np;
          if (np.mentee === pair.mentee) {
            newPairData = {
              ...newPairData,
              pairingType: EPairingTypes.BRANCHER_RECOMMENDED,
              menteeApprovedPairs:
                intermediateDraftPairs.filter((f) => f.mentee === pair.mentee).length + 1,
            };
          }
          if (np.mentor === pair.mentor) {
            newPairData = {
              ...newPairData,
              pairingType: EPairingTypes.BRANCHER_RECOMMENDED,
              mentorApprovedPairs:
                intermediateDraftPairs.filter((f) => f.mentor === pair.mentor).length + 1,
            };
          }
          return newPairData;
        });
      }
    });
    dispatch(SaveDrafting(intermediateDraftPairs));
    dispatch(SaveSuggestedPairs(intermediateSuggestedPairs));
  };

  const getTargetUserPossiblePairs = (
    user: string,
    position: ProgramPositions,
    name: string,
    overrideAlgorithm: boolean,
    draftingType?: EDraftingPairTypes,
  ) => {
    setTargetUser(name);
    setTargetUserRoleId(user);
    setGettingTargetUserPairs(true);
    setDraftingPairType(draftingType); // We can later add analytics to this to understand how often they are using certain pairing features
    setDraftingActioningPosition(position); // We can later add analytics to this to understand how often they are using certain pairing features
    BrancherDispatch(
      dispatch,
      UtilGetTargetUserPairs(
        user,
        position,
        overrideAlgorithm,
        true,
        (targetUserMatches: IDraftedPairs[]) => {
          setTargetUserPairs(targetUserMatches);
          setGettingTargetUserPairs(false);
          setTargetUserDialog(true);
        },
      ),
    );
  };

  const closeTargetUserDialog = () => {
    setTargetUserDialog(false);
    setTargetUser('');
    setTargetUserPairs([]);
  };

  const breakAllPairs = () => {
    const uniqueMentees = [];
    const uniqueMentors = [];
    const adjustedDraftPairings = draftPairs.map((p) => {
      if (!uniqueMentees.includes(p.mentee)) {
        uniqueMentees.push(p.mentee);
      }
      if (!uniqueMentors.includes(p.mentor)) {
        uniqueMentors.push(p.mentor);
      }
      return {
        ...p,
        menteeApprovedPairs: 0,
        mentorApprovedPairs: 0,
      };
    });
    const adjustedPairings = [];
    pairings.forEach((suggestedPair) => {
      let suggestedPairData = suggestedPair;
      if (uniqueMentees.includes(suggestedPair.mentee)) {
        suggestedPairData = { ...suggestedPairData, menteeApprovedPairs: 0 };
      }
      if (uniqueMentors.includes(suggestedPair.mentor)) {
        suggestedPairData = { ...suggestedPairData, mentorApprovedPairs: 0 };
      }
      adjustedPairings.push(suggestedPairData);
    });
    dispatch(SaveDrafting([]));
    dispatch(SaveSuggestedPairs([...adjustedDraftPairings, ...adjustedPairings]));
    saveDraftedPairs();
  };

  const draftPair = (pair: IDraftedPairs, pairingType: EPairingTypes) => {
    if (
      pair.menteeApprovedPairs !== pair.menteePairAmount &&
      pair.mentorApprovedPairs !== pair.mentorPairAmount
    ) {
      setHasSavedDraftingData(false);
      const adjustedDraftPairings = [...draftPairs, pair].map((p) => {
        let newPairData = p;
        if (p.mentee === pair.mentee) {
          newPairData = {
            ...newPairData,
            pairingType,
            menteeApprovedPairs: newPairData.menteeApprovedPairs + 1,
          };
        }
        if (p.mentor === pair.mentor) {
          newPairData = {
            ...newPairData,
            pairingType,
            mentorApprovedPairs: newPairData.mentorApprovedPairs + 1,
          };
        }
        return newPairData;
      });
      dispatch(SaveDrafting(adjustedDraftPairings));
      const newPairings = [];
      pairings.forEach((p) => {
        if (!(p.mentee === pair.mentee && p.mentor === pair.mentor)) {
          newPairings.push(p);
        }
      });
      const adjustedPairings = newPairings.map((p) => {
        let newPairData = p;
        if (p.mentee === pair.mentee) {
          newPairData = {
            ...newPairData,
            pairingType,
            menteeApprovedPairs: newPairData.menteeApprovedPairs + 1,
          };
        }
        if (p.mentor === pair.mentor) {
          newPairData = {
            ...newPairData,
            pairingType,
            mentorApprovedPairs: newPairData.mentorApprovedPairs + 1,
          };
        }
        return newPairData;
      });
      const filteredMaxedOutPairs = adjustedPairings.filter(
        (p) =>
          p.mentorApprovedPairs !== p.mentorPairAmount &&
          p.menteeApprovedPairs !== p.menteePairAmount,
      );
      dispatch(SaveSuggestedPairs(filteredMaxedOutPairs));
      if (targetUser) {
        closeTargetUserDialog();
      }
      // This is really fiddly - in order to update the available positions list
      if (draftingPairType === EDraftingPairTypes.AVAILABLE_POSITIONS) {
        const roleId =
          draftingActioningPosition === ProgramPositions.mentor ? 'mentorRoleId' : 'menteeRoleId';
        const pairingUserRole =
          draftingActioningPosition === ProgramPositions.mentor ? pair.mentor : pair.mentee;
        const newUnpairedData = unpaired[draftingActioningPosition].filter(
          (user) =>
            !(
              user[roleId] === pairingUserRole &&
              pair[`${draftingActioningPosition}ApprovedPairs`] + 1 ===
                pair[`${draftingActioningPosition}PairAmount`]
            ),
        );

        // Filters out the other user role in the role above removed an unpaired user on the other side
        const mentorIsRoleDraftingPosition = draftingActioningPosition === ProgramPositions.mentor;
        const otherRoleDraftingPosition = mentorIsRoleDraftingPosition
          ? ProgramPositions.mentee
          : ProgramPositions.mentor;
        const otherRoleId = mentorIsRoleDraftingPosition ? 'menteeRoleId' : 'mentorRoleId';
        const otherPairingUserRole = mentorIsRoleDraftingPosition ? pair.mentee : pair.mentor;
        const otherUserRoleUnpairedData = unpaired[otherRoleDraftingPosition].filter(
          (user) =>
            !(
              user[otherRoleId] === otherPairingUserRole &&
              pair[`${otherRoleDraftingPosition}ApprovedPairs`] + 1 ===
                pair[`${otherRoleDraftingPosition}PairAmount`]
            ),
        );
        const fullUnpairedData = {
          unpaired: {
            [otherRoleDraftingPosition]: otherUserRoleUnpairedData,
            [draftingActioningPosition]: newUnpairedData,
          },
        };
        dispatch(SavePairingData(fullUnpairedData));
      }
    }
  };

  const breakPair = (pair: IDraftedPairs) => {
    setHasSavedDraftingData(false);
    const newDraftPairings = draftPairs.filter((p) => p !== pair);
    const adjustedDraftPairings = newDraftPairings?.map((p) => {
      let newPairData = p;
      if (p.mentee === pair.mentee) {
        newPairData = { ...newPairData, menteeApprovedPairs: newPairData.menteeApprovedPairs - 1 };
      }
      if (p.mentor === pair.mentor) {
        newPairData = { ...newPairData, mentorApprovedPairs: newPairData.mentorApprovedPairs - 1 };
      }
      return newPairData;
    });
    dispatch(SaveDrafting(adjustedDraftPairings));
    const adjustedPairings = [...pairings, pair].map((p) => {
      let newPairData = p;
      if (p.mentee === pair.mentee) {
        newPairData = { ...newPairData, menteeApprovedPairs: newPairData.menteeApprovedPairs - 1 };
      }
      if (p.mentor === pair.mentor) {
        newPairData = { ...newPairData, mentorApprovedPairs: newPairData.mentorApprovedPairs - 1 };
      }
      return newPairData;
    });
    dispatch(SaveSuggestedPairs(adjustedPairings));
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      item
      xs={12}
      className={styles.matchmaking}
    >
      <BrancherFullPageLoader loading={gettingTargetUserPairs} />
      <RouteLeavingGuard when={!hasSavedDraftingData} />
      <TargetUserPairsDialog
        setClose={closeTargetUserDialog}
        open={targetUserDialog}
        targetUser={targetUser}
        targetUserPairs={targetUserPairs}
        draftPair={draftPair}
        getUserTargetPairs={() => {
          setTargetUserDialog(false);
          getTargetUserPossiblePairs(
            targetUserRoleId,
            draftingActioningPosition,
            targetUser,
            true,
            draftingPairType,
          );
        }}
      />

      <Grid item xs={12} container direction="column">
        {draftPairs?.length > 0 && (
          <>
            <Grid item>
              <Text variant="md" color="purple" marginBottom={20} fontWeight={700} display="inline">
                Paired Users
              </Text>
              <Text variant="xs" color="purple" display="inline" marginLeft={8}>
                {getUniqueDraftedUsers(draftPairs)}
              </Text>
            </Grid>
            <Grid item container justifyContent="space-between">
              <Text
                variant="sm"
                color="purple"
                fontWeight={600}
                marginRight={20}
                marginTop={15}
                marginBottom={15}
              >
                Mentee
              </Text>
              <Text variant="sm" color="purple" fontWeight={600} marginTop={15} marginBottom={15}>
                Overall Match Percentage
              </Text>
              <Text
                variant="sm"
                color="purple"
                fontWeight={600}
                marginLeft={20}
                marginTop={15}
                marginBottom={15}
              >
                Mentor
              </Text>
            </Grid>
            <Grid item container spacing={2}>
              {draftPairs?.map((pair, i) => (
                <ApprovedPair pair={pair} breakPair={breakPair} key={i} />
              ))}
            </Grid>
            {(pairings?.length > 0 || unpaired?.mentee?.length > 0 || unpaired?.mentor?.length) && (
              <Grid item>
                <BrancherDivider marginTop={40} marginBottom={40} />
              </Grid>
            )}
          </>
        )}

        <>
          <Grid container item xs={12} spacing={2}>
            {pairings?.length > 0 && (
              <Grid item>
                <ActionButton startIcon={<Flare color="secondary" />} onClick={autoMatch}>
                  Auto-match
                </ActionButton>
              </Grid>
            )}
            {(unpaired?.[ProgramPositions.mentee]?.length > 0 ||
              unpaired?.[ProgramPositions.mentor]?.length > 0 ||
              pairings?.length > 0) && (
              <Grid item>
                <ActionButton
                  startIcon={<AutoRenew color="secondary" />}
                  onClick={runAlgorithm}
                  loading={runningAlgorithm}
                >
                  Re-run algorithm
                </ActionButton>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <BrancherDivider marginTop={40} />
          </Grid>
        </>

        {pairings?.length > 0 && (
          <>
            <Grid item>
              <Text variant="md" color="purple" marginBottom={20} marginTop={30} fontWeight={700}>
                Suggested Pairs
              </Text>
            </Grid>
            <Grid item container justifyContent="space-between">
              <Text variant="sm" color="purple" fontWeight={600} marginRight={20} marginBottom={10}>
                Mentee
              </Text>
              <Text variant="sm" color="purple" fontWeight={600} marginLeft={20} marginBottom={10}>
                Mentor
              </Text>
            </Grid>
            <Grid item container spacing={2}>
              {pairings?.map((pair, i) => (
                <SuggestedPair
                  draftPair={draftPair}
                  pair={pair}
                  key={i}
                  targetUser={getTargetUserPossiblePairs}
                  draftingType={EDraftingPairTypes.SUGGESTED}
                />
              ))}
            </Grid>
          </>
        )}
      </Grid>

      {(unpaired?.mentee?.length > 0 || unpaired?.mentor?.length > 0) && (
        <Grid item xs={12} container>
          <Grid item>
            <Text variant="md" color="purple" marginBottom={20} fontWeight={700} marginTop={40}>
              Unpaired
            </Text>
          </Grid>
          <Grid item container justifyContent="space-between">
            <Text variant="sm" color="purple" fontWeight={600} marginRight={20} marginBottom={10}>
              Mentee
            </Text>
            <Text variant="sm" color="purple" fontWeight={600} marginLeft={20} marginBottom={10}>
              Mentor
            </Text>
          </Grid>
          <Grid item container justifyContent="space-between">
            <Grid item xs={5}>
              <Grid container direction="column">
                {unpaired?.mentee?.map((mentee, i) => (
                  <UserPairingCard
                    key={i}
                    xs={12}
                    className={styles.overridePairingCardMargins}
                    user={mentee.menteeRoleId}
                    name={`${mentee.firstName} ${mentee.lastName}`}
                    position={ProgramPositions.mentee}
                    targetUser={getTargetUserPossiblePairs}
                    draftingType={EDraftingPairTypes.AVAILABLE_POSITIONS}
                    pairAmount={mentee?.pairAmount}
                    approvedPairs={mentee?.approvedPairs}
                  />
                ))}
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Grid container direction="column">
                {unpaired?.mentor?.map((mentor, i) => (
                  <UserPairingCard
                    key={i}
                    xs={12}
                    className={styles.overridePairingCardMargins}
                    user={mentor.mentorRoleId}
                    name={`${mentor.firstName} ${mentor.lastName}`}
                    position={ProgramPositions.mentor}
                    targetUser={getTargetUserPossiblePairs}
                    draftingType={EDraftingPairTypes.AVAILABLE_POSITIONS}
                    pairAmount={mentor?.pairAmount}
                    approvedPairs={mentor?.approvedPairs}
                  />
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {showActionsNav && (
        <Box position="fixed" className={styles.bottomNav}>
          <Grid container justifyContent="center">
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              item
              xs={11}
              md={10}
              lg={9}
            >
              <Grid item>
                <ActionNegativeOutlinedButton
                  startIcon={<LinkOff color="error" />}
                  onClick={breakAllPairs}
                  loading={savingDraftPairs}
                >
                  Break all
                </ActionNegativeOutlinedButton>
              </Grid>
              <Grid item xs={8}>
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <ActionOutlinedButton
                      startIcon={<Save />}
                      onClick={() => saveDraftedPairs()}
                      loading={savingDraftPairs}
                    >
                      Save
                    </ActionOutlinedButton>
                  </Grid>
                  <Grid item>
                    <AcceptButton
                      onClick={() => {
                        saveDraftedPairs();
                        setIsMatching();
                      }}
                    >
                      Complete
                    </AcceptButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </Grid>
  );
};
