export enum ESessionAttributes {
  SIGNUP_PROGRAM_ID = 'pId',
  USER_EMAIL = 'uemail',
  PREVIOUS_PROGRAM_ID = 'prevPId',
  HAS_SAML = 'hasSaml',
}

export const getSessionAttribute = (attributeName: ESessionAttributes): string =>
  sessionStorage.getItem(attributeName);

export const setSessionAttribute = (attributeName: ESessionAttributes, attributeValue: string) =>
  sessionStorage.setItem(attributeName, attributeValue);

export const getLocalStorageAttribute = (attributeName: ESessionAttributes): string =>
  localStorage.getItem(attributeName);

export const setLocalStorageAttribute = (
  attributeName: ESessionAttributes,
  attributeValue: string,
) => localStorage.setItem(attributeName, attributeValue);
