import { BrancherAuthRequest } from './utils/BrancherDispatch';
import {
  CLEAN_DASHBOARD_DATA,
  ETrainingModules,
  IBaseDashboardInfo,
  IDashboardInfo,
  IDashboardTrainingInfo,
  IGlobalDashboardHealth,
  IGroupsHealthData,
  IMatchSatisfactionData,
  IMeetingHealthData,
  IProgressActivityHealthData,
  SAVE_BASE_DASHBOARD_DATA,
  SAVE_DASHBOARD_DATA,
  SAVE_DASHBOARD_TRAINING_DATA,
} from '../reducers/DashboardReducer';
import { IAPIResponseObject } from './actionTypes/apiTypes';

export const SaveDashboardBaseData = (dashboardData: IBaseDashboardInfo) => {
  return {
    type: SAVE_BASE_DASHBOARD_DATA,
    payload: dashboardData,
  };
};

export const SaveDashboardTrainingData = (dashboardData: IDashboardTrainingInfo) => {
  return {
    type: SAVE_DASHBOARD_TRAINING_DATA,
    payload: dashboardData,
  };
};

export const SaveDashboardData = (dashboardData: IDashboardInfo) => {
  return {
    type: SAVE_DASHBOARD_DATA,
    payload: dashboardData,
  };
};

export const CleanPairingData = () => {
  return {
    type: CLEAN_DASHBOARD_DATA,
  };
};

// This gets all the program health info
export const UtilGetDashboardData = (
  refresh: boolean,
  filters: { [x in string]: any } = {},
  cb: (a: any) => void,
) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/dashboard',
        params: JSON.stringify({
          programId,
          refresh,
          filters,
        }),
      },
      getState(),
    )
      .then((response: { data: { data: IBaseDashboardInfo } }) => {
        dispatch(SaveDashboardBaseData(response.data.data));
        cb(response.data.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

// This gets the program health meeting info
export const UtilGetDashboardMeetingData = (
  filters: { [x in string]: any } = {},
  cb: (a: any) => void,
) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/dashboardmeetings',
        params: JSON.stringify({
          programId,
          filters,
        }),
      },
      getState(),
    )
      .then((response: { data: { data: IMeetingHealthData } }) => {
        dispatch(SaveDashboardBaseData({ meetings: response.data.data }));
        cb(response.data.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

// This gets the program health goals info
export const UtilGetDashboardGroupsData = (
  filters: { [x in string]: any } = {},
  cb: (a: any) => void,
) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/dashboardgroups',
        params: JSON.stringify({
          programId,
          filters,
        }),
      },
      getState(),
    )
      .then((response: { data: { data: IGroupsHealthData } }) => {
        dispatch(SaveDashboardBaseData({ groups: response.data.data }));
        cb(response.data.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

// This gets the program health goals info
export const UtilGetDashboardProgressActivitiesData = (
  filters: { [x in string]: any } = {},
  cb: (a: any) => void,
) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/dashboardprogressactivity',
        params: JSON.stringify({
          programId,
          filters,
        }),
      },
      getState(),
    )
      .then((response: { data: { data: IProgressActivityHealthData } }) => {
        dispatch(SaveDashboardBaseData({ progressActivities: response.data.data }));
        cb(response.data.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

// This gets all the program training health info
export const UtilGetDashboardTrainingData = (cb: (a: any) => void) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/dashboard/training',
        params: JSON.stringify({
          programId,
        }),
      },
      getState(),
    )
      .then((response: { data: { data: IDashboardTrainingInfo } }) => {
        dispatch(SaveDashboardTrainingData(response.data.data));
        cb(response.data.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

export interface IGetTrainingModuleData {
  trainingModuleColumns: any;
  trainingModuleData: any;
  trainingModuleDates: { open: string; close: string };
  surveyOneNotMetModuleData?: any;
  surveyOneNotMetModuleColumns?: any;
  customModuleColumns?: any;
}

// This gets the data for a specific training module
export const UtilGetTrainingModuleData = (module: ETrainingModules, cb: (a: any) => void) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/trainingreport',
        params: JSON.stringify({
          programId,
          module,
        }),
      },
      getState(),
    )
      .then((response: { data: { data: IGetTrainingModuleData } }) => {
        cb(response.data.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

interface IUtilGetProgramMatchSatisfaction extends IAPIResponseObject {
  data: IMatchSatisfactionData;
}

export const UtilGetProgramMatchingSatisfaction = (
  cb: (a: IUtilGetProgramMatchSatisfaction) => void,
) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/matchingsatisfaction',
        params: JSON.stringify({
          programId,
        }),
      },
      getState(),
    )
      .then((response: { data: IUtilGetProgramMatchSatisfaction }) => {
        cb(response.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

export interface IMeetingFrequency {
  onTrack: number;
  toWatch: number;
  tooEarly: number;
}

export interface IMeetingSurveyHealth {
  stronglyDissatisfied: number;
  dissatisfied: number;
  neutral: number;
  satisfied: number;
  stronglySatisfied: number;
}

export interface IMeetingSatisfactionFrequencyHealth {
  satisfaction: IMeetingSurveyHealth;
  frequency: IMeetingFrequency;
}

interface IUtilGetProgramMeetingSatisfactionFrequencyHealth extends IAPIResponseObject {
  data: IMeetingSatisfactionFrequencyHealth;
}

export const UtilGetProgramMeetingSatisfactionFrequencyHealth = (
  refresh: boolean,
  cb: (a: IUtilGetProgramMeetingSatisfactionFrequencyHealth) => void,
) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/programmeetingsurveyhealth',
        params: JSON.stringify({
          programId,
          refresh,
        }),
      },
      getState(),
    )
      .then((response: { data: IUtilGetProgramMeetingSatisfactionFrequencyHealth }) => {
        dispatch(SaveDashboardBaseData({ meetingSatisfactionFrequencyHealth: response.data.data }));
        cb(response.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

interface IUtilGetGlobalDashboardData extends IAPIResponseObject {
  data: IGlobalDashboardHealth;
}

// This gets the global dashboard health info
export const UtilGetGlobalDashboardData = (cb: (a: IGlobalDashboardHealth) => void) => {
  return (dispatch: any, getState: any) => {
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/globaldashboard',
        params: JSON.stringify({}),
      },
      getState(),
    )
      .then((response: { data: IUtilGetGlobalDashboardData }) => {
        dispatch(SaveDashboardBaseData({ globalDashboardHealth: response.data.data }));
        cb(response.data.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

// This update the base been billed attribute for that particular billing period
export const UtilSetHasBeenBilled = (
  billingPeriodId: string,
  programId: string,
  billedStatus: boolean,
  cb: (a: IGlobalDashboardHealth) => void,
) => {
  return (dispatch: any, getState: any) => {
    BrancherAuthRequest(
      {
        method: 'put',
        url: 'v2/billingstatus',
        data: {
          billingPeriodId,
          programId,
          billedStatus,
        },
      },
      getState(),
    )
      .then((response: { data: IUtilGetGlobalDashboardData }) => {
        dispatch(SaveDashboardBaseData({ globalDashboardHealth: response.data.data }));
        cb(response.data.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};
