import { createTheme } from '@material-ui/core/styles';
import { Colors } from '../consts/colors';
import { IBrancherThemePalette } from './BrancherTheme';
import { MobileFontSizes, TabletDesktopFontSizes } from '../consts/FontSizes';

const breakpoints = createTheme().breakpoints;

export const MuiStepLabel = (theme: IBrancherThemePalette) => ({
  label: {
    [breakpoints.down('md')]: {
      fontSize: MobileFontSizes.xxs,
    },
    [breakpoints.up('md')]: {
      fontSize: TabletDesktopFontSizes.xs,
    },
    color: Colors.black,
    fontWeight: 600,
    '&.MuiStepLabel-active': {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
  },
});
