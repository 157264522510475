import * as React from 'react';
import RadioGroup, { RadioGroupProps } from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { BrancherRadioBase, IBrancherRadioBaseProps } from './BrancherRadioBase';

interface IBrancherRadioProps extends Omit<RadioGroupProps, 'onChange'> {
  options: IBrancherRadioBaseProps[];
  label: string;
  onChange: (v: any) => void;
  'aria-label': string;
  disabled?: boolean;
}

export const BrancherRadio = (props: IBrancherRadioProps) => {
  const { value, onChange, label, options, name, 'aria-label': ariaLabel, disabled } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange((event.target as HTMLInputElement).value);
  };

  return (
    <FormControl component="fieldset" disabled={disabled}>
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup aria-label={ariaLabel} name={name} value={value} onChange={handleChange}>
        {options.map((r, i) => (
          <BrancherRadioBase value={r.value} label={r.label} key={`${r.value}-${i}`} />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
