import * as React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';
import LinkOff from '@material-ui/icons/LinkOff';
import Link from '@material-ui/icons/Link';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { EPairingTypes, IDraftedPairs, IPairReason } from '../../../store/reducers/PairingReducer';
import { Colors } from '../../../consts/colors';
import { Text } from '../../../Components/General/Text';
import { BrancherDivider } from '../../../Components/General/BrancherDivider';
import { ActionButton, ActionNegativeButton } from '../../../Components/InputFields/BrancherButton';
import { BrancherGridCard } from '../../../Components/General/BrancherGridCard';

interface IApprovedPair {
  pair: IDraftedPairs;
  breakPair?: (pair: IDraftedPairs) => void;
  draftPair?: (pair: IDraftedPairs, pairingType: EPairingTypes) => void;
  overrideAlgorithm?: boolean;
}

const useStyles = makeStyles({
  paper: {
    marginBottom: 15,
  },
  pairingReasons: {
    background: Colors.backgroundLightPurple,
    borderRadius: 15,
    width: '100%',
    padding: 40,
  },
  customPairingReason: {
    background: Colors.white,
    borderRadius: 10,
    margin: 15,
    paddingTop: 15,
    paddingBottom: 15,
  },
  userCircleApproved: {
    color: Colors.green,
  },
  progress: {
    minWidth: '100%',
    maxWidth: 70,
    '&.MuiLinearProgress-root': {
      height: 5,
    },
  },
});

export const ApprovedPair: React.FC<IApprovedPair> = ({
  pair,
  breakPair,
  draftPair,
  overrideAlgorithm,
}) => {
  const {
    mentorName,
    menteeName,
    mentorPairAmount,
    menteePairAmount,
    percentageMatch,
    mentorApprovedPairs,
    menteeApprovedPairs,
    pairingReasons,
    personalityScoreTotal,
    valuesScoreTotal,
  } = pair;
  const [openPairingInfo, setOpenPairingInfo] = React.useState<boolean>(false);
  const styles = useStyles();

  const togglePairingInfo = () => {
    setOpenPairingInfo(!openPairingInfo);
  };

  return (
    <>
      <BrancherGridCard
        container
        item
        alignItems="center"
        className={styles.paper}
        hoverCursor
        onClick={togglePairingInfo}
      >
        <Grid item xs={4} container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Text variant="sm" marginLeft={20} fontWeight={700} color="secondaryGrey">
              {menteeName}
            </Text>
          </Grid>
          <Box mr={2}>
            <Grid item>
              {menteePairAmount - menteeApprovedPairs > 0 && (
                <>
                  {[...Array(menteePairAmount - menteeApprovedPairs)].map((a, i) => (
                    <AccountCircle color="disabled" key={i} />
                  ))}
                </>
              )}
              {menteeApprovedPairs > 0 && (
                <>
                  {[...Array(menteeApprovedPairs)].map((a, i) => (
                    <AccountCircle className={styles.userCircleApproved} key={i} />
                  ))}
                </>
              )}
            </Grid>
          </Box>
        </Grid>
        <Grid item container alignItems="center" xs={4} justifyContent="space-between">
          <Grid item>
            <Text variant="xs" fontWeight={500} color="purple">
              {Math.round(percentageMatch)}%
            </Text>
          </Grid>
          <Grid item xs={7}>
            <LinearProgress
              value={Number(percentageMatch)}
              valueBuffer={100}
              color="primary"
              variant="determinate"
              className={styles.progress}
            />
          </Grid>
          <Grid item>{openPairingInfo ? <ExpandLess /> : <ExpandMore />}</Grid>
        </Grid>
        <Grid item xs={4} container alignItems="center" justifyContent="space-between">
          <Box ml={2}>
            <Grid item>
              {mentorApprovedPairs > 0 && (
                <>
                  {[...Array(mentorApprovedPairs)].map((a, i) => (
                    <AccountCircle className={styles.userCircleApproved} key={i} />
                  ))}
                </>
              )}
              {mentorPairAmount - mentorApprovedPairs > 0 && (
                <>
                  {[...Array(mentorPairAmount - mentorApprovedPairs)].map((a, i) => (
                    <AccountCircle color="disabled" key={i} />
                  ))}
                </>
              )}
            </Grid>
          </Box>
          <Grid item>
            <Text variant="sm" marginRight={20} fontWeight={700} color="secondaryGrey">
              {mentorName}
            </Text>
          </Grid>
        </Grid>
      </BrancherGridCard>
      <Collapse in={openPairingInfo} className={styles.pairingReasons} mountOnEnter unmountOnExit>
        <Grid container spacing={4} justifyContent="center">
          {pairingReasons.custom.map((customReason, i) => (
            <CustomPairingReason {...customReason} key={i} />
          ))}
        </Grid>
        <Grid item xs={12}>
          <BrancherDivider marginBottom={30} marginTop={30} />
        </Grid>
        <Grid container spacing={4} justifyContent="center">
          <CustomPairingReason
            percentage={personalityScoreTotal}
            readableName="Personality Compatibility"
          />
          <CustomPairingReason percentage={valuesScoreTotal} readableName="Values Compatibility" />
        </Grid>
        <Grid item xs={12}>
          <BrancherDivider marginBottom={30} marginTop={30} />
        </Grid>
        {breakPair && (
          <Grid item container justifyContent="center">
            <ActionNegativeButton
              startIcon={<LinkOff color="secondary" />}
              onClick={() => {
                breakPair(pair);
                togglePairingInfo();
              }}
            >
              Break this pair
            </ActionNegativeButton>
          </Grid>
        )}
        {draftPair && (
          <Grid item container justifyContent="center">
            <ActionButton
              startIcon={<Link color="secondary" />}
              onClick={() => {
                draftPair(
                  pair,
                  overrideAlgorithm
                    ? EPairingTypes.NO_ALGORITHM
                    : EPairingTypes.TARGET_USER_RECOMMENDED,
                );
                togglePairingInfo();
              }}
            >
              Create pair
            </ActionButton>
          </Grid>
        )}
      </Collapse>
    </>
  );
};

interface ICustomPairingReason extends Pick<IPairReason, 'readableName' | 'percentage'> {}

const CustomPairingReason: React.FC<ICustomPairingReason> = (props) => {
  const { percentage, readableName } = props;
  const simplerPercentage: number = Number(percentage) > 100 ? 100 : Math.round(Number(percentage));
  const styles = useStyles();
  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      item
      xs={4}
      md={3}
      className={styles.customPairingReason}
    >
      <Grid item>
        <Text variant="xxs" align="center">
          {readableName}:
        </Text>
      </Grid>
      <Grid item container alignItems="center" justifyContent="center" spacing={3}>
        <CircularProgress
          size={16}
          thickness={6}
          variant="determinate"
          color="secondary"
          value={simplerPercentage}
        />
        <Text variant="xs" marginLeft={10} color="purple" fontWeight={700}>
          {simplerPercentage}%
        </Text>
      </Grid>
    </Grid>
  );
};
