import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../Components/General/Text';
import { MeetingSatisfactionHealth } from './MeetingSatisfactionHealth';
import { MeetingFrequencyHealth } from './MeetingFrequencyHealth';

export const HealthCheck: React.FC = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Text variant="lg" marginTop={40} marginBottom={20} color="purple" fontWeight={700}>
          Health Check
        </Text>
      </Grid>
      <Grid container item alignItems="center" xs={12} justifyContent="space-between">
        <MeetingFrequencyHealth />
        <MeetingSatisfactionHealth />
      </Grid>
    </Grid>
  );
};
