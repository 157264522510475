import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { IActiveUsersData } from '../../store/reducers/DashboardReducer';
import { Text } from '../../Components/General/Text';
import { BrancherGridCard } from '../../Components/General/BrancherGridCard';
import { BrancherList } from '../../Components/General/BrancherList';

const useStyles = makeStyles({
  dashboardCard: {
    maxHeight: (props: { desktop: boolean }) => (props.desktop ? 500 : 600),
    height: (props: { desktop: boolean }) => (props.desktop ? 500 : 600),
    maxWidth: 420,
    padding: 25,
  },
  popover: {
    '& .MuiPopover-paper': {
      padding: 12,
      borderRadius: 20,
    },
  },
  infoSubscriptionButton: {
    display: 'inline',
  },
});

interface ISubscription {
  activeUsers: IActiveUsersData;
}

export const SubscriptionHealth: React.FC<ISubscription> = (props) => {
  const desktop = useMediaQuery(useTheme().breakpoints.up('xl'));
  const { activeUsers } = props;
  const styles = useStyles({ desktop });
  const [popoverAnchor, setPopoverAnchor] = React.useState(null);

  const controlValidator = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!Boolean(popoverAnchor)) {
      setPopoverAnchor(event.currentTarget);
      event.currentTarget.focus();
    }
    event.stopPropagation();
    event.preventDefault();
  };

  const closeValidator = () => {
    if (Boolean(popoverAnchor)) {
      setPopoverAnchor(null);
    }
  };

  const open = Boolean(popoverAnchor);

  const remainingSpotsNotice = (): string => {
    return activeUsers?.userPaidCapLeft >= 0
      ? `You have ${activeUsers?.userPaidCapLeft} additional users available in your subscription.`
      : `You are ${Math.abs(activeUsers?.userPaidCapLeft)} users over your current subscription.`;
  };

  return (
    <Grid container item onClick={closeValidator}>
      <Grid container item alignItems="center">
        <BrancherGridCard
          container
          item
          direction="column"
          spacing={3}
          className={styles.dashboardCard}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item container justifyContent="center">
            <Text variant="lg" color="purple" fontWeight={700}>
              Subscription
            </Text>
          </Grid>
          <Popover
            open={open}
            className={styles.popover}
            id="active-users-more-info"
            anchorEl={popoverAnchor}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Grid container>
              <Grid item>
                <Text variant="sm">A unique user is a user that:</Text>
                <BrancherList
                  listItems={[
                    'Has created an account',
                    'Has completed an application form',
                    'Is not deactivated/rejected from the program',
                  ]}
                />
              </Grid>
            </Grid>
          </Popover>

          <Grid item container alignItems="center" justifyContent="center">
            <Text variant="lg" display="inline" color="purple" fontWeight={700} marginRight={-10}>
              {activeUsers?.uniqueUsersAmount}{' '}
            </Text>
            <IconButton className={styles.infoSubscriptionButton} onClick={controlValidator}>
              <InfoOutlined />
            </IconButton>
            <Text
              variant="lg"
              display="inline"
              color="tertiaryGrey"
              align="center"
              fontWeight={600}
            >
              (out of {activeUsers?.userCap} users)
            </Text>
          </Grid>
          <Grid item container alignItems="center" justifyContent="center">
            <Text variant="sm" fontWeight={600} color="darkGrey" align="center">
              {remainingSpotsNotice()}
            </Text>
          </Grid>
        </BrancherGridCard>
      </Grid>
    </Grid>
  );
};
