import * as React from 'react';
import { isValid, parse } from 'date-fns';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { dateTimeExtendedFormat, dateTimeFormat, formatDateTime } from '../../helpers/DateHelpers';

interface IDateTimePicker {
  value: Date | string;
  updateValue: (value: Date | string) => void;
  id: string;
  name: string;
  label?: string;
  disabled?: boolean;
}

export const DateTimePicker: React.FC<IDateTimePicker> = ({
  value,
  updateValue,
  label,
  disabled,
  id,
  name,
}) => {
  const handleDate = (date: Date) => {
    if (isValid(date)) {
      const formattedISOString = formatDateTime(date);
      const fullISODateString = parse(formattedISOString, dateTimeExtendedFormat, new Date());
      updateValue(fullISODateString);
    }
  };

  return (
    <KeyboardDateTimePicker
      clearable
      disabled={disabled}
      id={id}
      aria-describedby={name}
      value={value}
      placeholder={formatDateTime()}
      onChange={handleDate}
      format={dateTimeFormat}
      label={label}
      fullWidth
      KeyboardButtonProps={{ 'aria-label': `${name}-action` }}
    />
  );
};
