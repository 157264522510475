import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Email from '@material-ui/icons/Email';
import LinkedIn from '@material-ui/icons/LinkedIn';
import PhoneEnabled from '@material-ui/icons/PhoneEnabled';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { Page } from '../../Components/General/Page';
import {
  BrancherDispatch,
  UtilGetAllUserInfo,
  UtilGetUserByRoleId,
  UtilUpdateUserNotes,
} from '../../store/actions';
import { Text } from '../../Components/General/Text';
import { IOldPairs, IPairsInfo } from '../../store/reducers/PairingReducer';
import { ProfilePicture } from './ProfilePicture';
import { BrancherLinkText } from '../../Components/General/BrancherLinkText';
import { BrancherDivider } from '../../Components/General/BrancherDivider';
import { ActionButton, BackButton, SaveButton } from '../../Components/InputFields/BrancherButton';
import { ProgramPositions } from '../../consts/ProgramPositionOptions';
import { UserDeactivation } from './UserDeactivation';
import { GenerateAllUserInfoReport } from '../Reports/GenerateAllUserInfoReport';
import { RouteMaster } from '../../Components/Routing';
import { RemoveUserApplicationDialog } from './RemoveUserApplicationDialog';
import { UserApproval } from './UserApproval';
import { IStoreTypes } from '../../store/storeTypes';
import { Subscriptions, UpdatableProgramInfo } from '../../store/reducers/ProgramReducer';
import { IUserRoleIndexedMentoringRequest } from '../../store/reducers/DashboardReducer';
import { IGroup } from '../Groups/Groups';
import { UserMentoringRequests } from './UserMentoringRequests';
import { UserMentoringPartners } from './UserMentoringPartners';
import { UserRoleGroups } from './UserRoleGroups';
import { BrancherTextField } from '../../Components/InputFields/BrancherTextField';
import { IUserInfo } from '../../store/reducers/UserInfoReducer';
import { dateDefaultFormat } from '../../helpers/DateHelpers';

interface IBaseUserInfo
  extends Pick<
    IUserInfo,
    'username' | 'email' | 'lastLoggedIn' | 'notes' | 'notesUpdated' | 'phoneNumber'
  > {
  userId: string;
  name: string;
  deactivated: string;
  approved?: string;
  reviewed?: string;
}

export interface IUserRoleInfo {
  roleId: string;
  name: string;
  position: ProgramPositions;
  mentoringPartners?: IPairsInfo[];
  previousMentoringPartners?: IOldPairs[];
  jobTitle?: string;
  organisation?: string;
  linkedIn?: string;
  image?: string;
  location?: string;
  completedProfile?: string;
  trainingReportData?: any;
  trainingReportColumns?: { readableName: string; attributeName: string }[];
  receivedMentoringRequests?: IUserRoleIndexedMentoringRequest[];
  sentMentoringRequests?: IUserRoleIndexedMentoringRequest[];
  userGroups?: IGroup[];
}

interface IAllUserInfo {
  hasMultipleProfiles?: boolean;
  baseUserData: IBaseUserInfo;
  baseUserDataColumns?: { readableName: string; attributeName: string }[];
  profileDataColumns?: { readableName: string; attributeName: string }[];
  menteeData?: IUserRoleInfo;
  mentorData?: IUserRoleInfo;
}

const useStyles = makeStyles({
  profileImage: {
    marginRight: 15,
  },
});

export const User: React.FC = () => {
  const [hasUserData, setHasUserData] = React.useState<boolean>(false);
  const requiresApproval = useSelector(
    (state: IStoreTypes) => state.program?.[UpdatableProgramInfo.REQUIRES_APPLICATION_APPROVAL],
  );
  const hasGroups = useSelector((state: IStoreTypes) =>
    state.program.subscription?.includes(Subscriptions.GROUPS),
  );
  const userId = new URLSearchParams(useLocation().search).get('u');
  const roleId = new URLSearchParams(useLocation().search).get('r');
  const locationHistory = useHistory();
  const [savingNewUserNotes, setSavingNewUserNotes] = React.useState<boolean>(false);
  const [userData, setUserData] = React.useState<IAllUserInfo>();
  const [newUserNotes, setNewUserNotes] = React.useState<string>('');
  const [selectedProfileData, setSelectedProfileData] = React.useState<IUserRoleInfo>();
  const [selectedProfile, setSelectedProfile] = React.useState<ProgramPositions>(null);
  const dispatch = useDispatch();
  const styles = useStyles();

  const isMentee = selectedProfile === ProgramPositions.mentee;
  const otherPosition = isMentee ? ProgramPositions.mentor : ProgramPositions.mentee;

  const useHasActiveMentoringRelationships =
    userData?.menteeData?.mentoringPartners?.length > 0 ||
    userData?.mentorData?.mentoringPartners?.length > 0;

  React.useEffect(() => {
    if (!userData) {
      controlRetrieveUserData();
    }
  }, []);

  const saveNewUserNotes = () => {
    setSavingNewUserNotes(true);
    BrancherDispatch(
      dispatch,
      UtilUpdateUserNotes(userData.baseUserData.username, newUserNotes, (notesRes) => {
        if (notesRes.success) {
          setUserData({
            ...userData,
            baseUserData: {
              ...userData.baseUserData,
              notes: notesRes?.data?.notes,
              notesUpdated: notesRes?.data?.notesUpdated,
            },
          });
        }
        setSavingNewUserNotes(false);
      }),
    );
  };

  const controlRetrieveUserData = () => {
    if (userId) {
      getUserData();
    } else if (roleId) {
      getUserRoleData();
    }
  };

  const getUserData = () => {
    BrancherDispatch(
      dispatch,
      UtilGetAllUserInfo(userId, (user) => {
        controlUpdatedUserInfo(user);
      }),
    );
  };

  const controlUpdatedUserInfo = (user) => {
    if (!!roleId) {
      if (user?.menteeData?.hasFormData && user.menteeData.roleId === roleId) {
        setSelectedProfileData(user?.menteeData);
        setSelectedProfile(ProgramPositions.mentee);
      } else if (user?.mentorData?.hasFormData && user.mentorData.roleId === roleId) {
        setSelectedProfileData(user?.mentorData);
        setSelectedProfile(ProgramPositions.mentor);
      }
    } else {
      if (user?.menteeData?.hasFormData) {
        setSelectedProfileData(user?.menteeData);
        setSelectedProfile(ProgramPositions.mentee);
      } else if (user?.mentorData?.hasFormData) {
        setSelectedProfileData(user?.mentorData);
        setSelectedProfile(ProgramPositions.mentor);
      }
    }
    setUserData(user);
    setNewUserNotes(user.baseUserData?.notes);
    setHasUserData(true);
  };

  const getUserRoleData = () => {
    BrancherDispatch(
      dispatch,
      UtilGetUserByRoleId(roleId, (user) => {
        controlUpdatedUserInfo(user);
      }),
    );
  };

  const toggleProfile = () => {
    setSelectedProfileData(isMentee ? userData?.mentorData : userData?.menteeData);
    setSelectedProfile(otherPosition);
  };

  const cleanedRoleData = (roleData: IUserRoleInfo) => {
    const { trainingReportData, trainingReportColumns, roleId, mentoringPartners, ...other } =
      roleData;
    return other;
  };

  const menteeData = userData?.menteeData
    ? [{ ...cleanedRoleData(userData?.menteeData), ...userData?.menteeData?.trainingReportData }]
    : [];
  const mentorData = userData?.mentorData
    ? [{ ...cleanedRoleData(userData?.mentorData), ...userData?.mentorData?.trainingReportData }]
    : [];
  const menteeDataColumns = userData?.menteeData?.trainingReportColumns
    ? [...userData?.profileDataColumns, ...userData?.menteeData?.trainingReportColumns]
    : userData?.profileDataColumns;
  const mentorDataColumns = userData?.mentorData?.trainingReportColumns
    ? [...userData?.profileDataColumns, ...userData?.mentorData?.trainingReportColumns]
    : userData?.profileDataColumns;

  const removeUserCallback = () => {
    window.location.assign(`${RouteMaster.user.path}?u=${userData?.baseUserData?.userId}`);
  };

  return (
    <Page loading={!hasUserData && !userData}>
      <Grid container>
        <Grid container justifyContent="center">
          <Grid item xs={10}>
            <Box mt={5} mb={5}>
              <BackButton onClick={() => locationHistory.goBack()} />
            </Box>
          </Grid>
          <Grid container justifyContent="center" item xs={10}>
            <Grid container alignItems="center">
              <Grid item xs={4}>
                <Grid container alignItems="center">
                  <Grid item className={styles.profileImage}>
                    <ProfilePicture src={selectedProfileData?.image} />
                  </Grid>
                  <Grid item xs={9} container direction="column">
                    <Grid item>
                      <Text variant="lg" color="purple" fontWeight={600}>
                        {userData?.baseUserData?.name}
                      </Text>
                    </Grid>
                    <Grid item>
                      <Text variant="xs" marginTop={4} marginBottom={4}>
                        {selectedProfileData?.jobTitle}
                      </Text>
                    </Grid>
                    <Grid item container alignItems="center">
                      <Text variant="xs" color="tertiaryGrey" display="inline">
                        {selectedProfileData?.organisation}
                      </Text>
                    </Grid>
                    <Grid item>
                      <Text variant="xs" marginTop={4} marginBottom={8}>
                        {selectedProfileData?.location}
                      </Text>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={7} md={5} container direction="column" spacing={1}>
                <Grid item container alignItems="center">
                  <Grid item xs={2} md={1}>
                    <Email />
                  </Grid>
                  <Grid item xs={10}>
                    <Text variant="sm" fontWeight={500} color="secondaryGrey">
                      {userData?.baseUserData?.email}
                    </Text>
                  </Grid>
                </Grid>
                <Grid item container alignItems="center">
                  <Grid item xs={2} md={1}>
                    <PhoneEnabled />
                  </Grid>
                  <Grid item xs={10}>
                    <Text variant="sm" fontWeight={500} color="secondaryGrey">
                      {userData?.baseUserData?.phoneNumber}
                    </Text>
                  </Grid>
                </Grid>
                <Grid item container alignItems="center">
                  <Grid item xs={2} md={1}>
                    <LinkedIn />
                  </Grid>
                  <Grid item xs={10}>
                    <BrancherLinkText
                      target="_blank"
                      variant="sm"
                      color="secondaryGrey"
                      wordBreak
                      fontWeight={500}
                      href={selectedProfileData?.linkedIn}
                    >
                      {selectedProfileData?.linkedIn?.split('/')[4]}
                    </BrancherLinkText>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container spacing={2} alignItems="center">
                {selectedProfileData?.roleId && (
                  <Grid item xs={6} md={4}>
                    <RemoveUserApplicationDialog
                      userApplication={selectedProfileData}
                      actionCallback={removeUserCallback}
                    />
                  </Grid>
                )}
                {userData?.hasMultipleProfiles && (
                  <Grid item xs={6} md={4}>
                    <ActionButton onClick={toggleProfile} size="small">
                      Switch to {otherPosition} profile
                    </ActionButton>
                  </Grid>
                )}
                <Grid item xs={6} md={4}>
                  <GenerateAllUserInfoReport
                    baseData={[userData?.baseUserData]}
                    baseDataColumns={userData?.baseUserDataColumns}
                    menteeData={menteeData}
                    menteeDataColumns={menteeDataColumns}
                    mentorData={mentorData}
                    mentorDataColumns={mentorDataColumns}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <BrancherDivider marginTop={40} marginBottom={30} />
              </Grid>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item container spacing={2} xs={10} md={6}>
                  <Grid item xs={12}>
                    <Text variant="sm" fontWeight={600} color="purple" display="inline">
                      Last active{' '}
                    </Text>
                    <Text variant="sm" fontWeight={500} display="inline" color="secondaryGrey">
                      {userData?.baseUserData?.lastLoggedIn}
                    </Text>
                  </Grid>
                  <Grid item xs={12}>
                    <Text variant="sm" fontWeight={600} color="purple" display="inline">
                      Completed Profile{' '}
                    </Text>
                    <Text variant="sm" fontWeight={500} display="inline" color="secondaryGrey">
                      {selectedProfileData?.completedProfile}
                    </Text>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={10}
                  md={6}
                  spacing={2}
                  direction="column"
                  alignItems="center"
                >
                  {requiresApproval && userData?.baseUserData?.deactivated === 'No' && (
                    <Grid item>
                      <UserApproval
                        name={userData?.baseUserData?.name}
                        userId={userData?.baseUserData?.userId}
                        approved={userData?.baseUserData?.approved === 'Yes'}
                        reviewed={userData?.baseUserData?.reviewed === 'Yes'}
                        actionApprovalCallback={controlRetrieveUserData}
                      />
                    </Grid>
                  )}
                  <Grid item>
                    <UserDeactivation
                      name={userData?.baseUserData?.name}
                      userId={userData?.baseUserData?.userId}
                      disableDeactivation={useHasActiveMentoringRelationships}
                      deactivated={userData?.baseUserData?.deactivated === 'Yes'}
                      actionActivationCallback={controlRetrieveUserData}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={10} container spacing={2}>
                  <Grid item xs={7}>
                    <Text variant="xs" fontWeight={600} marginTop={10}>
                      Notes about {userData?.baseUserData?.name}
                    </Text>
                    <BrancherTextField
                      fullWidth
                      multiline
                      rows={6}
                      value={newUserNotes}
                      updateValue={setNewUserNotes}
                      placeholder="These are notes that only you can see..."
                    />
                  </Grid>
                  <Grid item xs={3} container alignItems="flex-end">
                    <Grid container item>
                      {userData?.baseUserData?.notesUpdated && (
                        <Grid item xs={12}>
                          <Box mb={1}>
                            <Text variant="xs" fontWeight={600} color="purple" display="inline">
                              Last updated:{' '}
                            </Text>
                            <Text
                              variant="xs"
                              fontWeight={500}
                              display="inline"
                              color="secondaryGrey"
                            >
                              {format(userData?.baseUserData?.notesUpdated, dateDefaultFormat)}
                            </Text>
                          </Box>
                        </Grid>
                      )}
                      <Box mb={1}>
                        <SaveButton
                          onClick={saveNewUserNotes}
                          fullWidth
                          disabled={savingNewUserNotes}
                        >
                          Save notes
                        </SaveButton>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <BrancherDivider marginTop={40} marginBottom={30} />
              </Grid>
              {hasUserData && selectedProfile !== null && (
                <Grid container spacing={2} justifyContent="space-between">
                  <UserMentoringPartners
                    userRoleName={userData?.baseUserData?.name}
                    roleId={selectedProfileData?.roleId}
                    mentoringPartners={selectedProfileData?.mentoringPartners}
                    previousMentoringPartners={selectedProfileData?.previousMentoringPartners}
                    getUserData={getUserData}
                    selectedProfile={selectedProfile}
                  />

                  <UserMentoringRequests
                    isMentee={isMentee}
                    receivedMentoringRequests={selectedProfileData?.receivedMentoringRequests}
                    sentMentoringRequests={selectedProfileData?.sentMentoringRequests}
                  />

                  {hasGroups && <UserRoleGroups userGroups={selectedProfileData?.userGroups} />}

                  <Grid item xs={12}>
                    <BrancherDivider marginTop={30} marginBottom={30} />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};
