import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { USER_LOGOUT, UserInfoReducer } from './reducers/UserInfoReducer';
import { CompaniesReducer } from './reducers/CompaniesReducer';
import { ProgramReducer } from './reducers/ProgramReducer';
import { PairingReducer } from './reducers/PairingReducer';
import { DashboardReducer } from './reducers/DashboardReducer';
import { ReportReducer } from './reducers/ReportReducer';
import { SurveysReducer } from './reducers/SurveysReducer';

const appReducer = combineReducers({
  user: UserInfoReducer,
  companies: CompaniesReducer,
  program: ProgramReducer,
  pairing: PairingReducer,
  dashboard: DashboardReducer,
  report: ReportReducer,
  surveys: SurveysReducer,
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

const composeMiddleware = compose;

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Make sure we don't include logging in production
let middleware = [thunk];
if (process.env.NODE_ENV !== 'production') {
  middleware = [...middleware, logger];
}

const enhancer = composeMiddleware(applyMiddleware(...middleware));

const configureStore = () => {
  const store = createStore(persistedReducer, enhancer);
  const persistor = persistStore(store);
  return { store, persistor };
};

export default configureStore;
