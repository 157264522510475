import * as React from 'react';
import Grid, { GridProps } from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { Colors } from '../../consts/colors';

const useStyles = makeStyles({
  card: {
    boxShadow: `0px 5px 15px ${Colors.cardBorder}`,
    borderRadius: 10,
    paddingTop: 12,
    paddingBottom: 12,
    '&:hover': {
      cursor: (props: { hoverCursor }) => (props.hoverCursor ? 'pointer' : 'initial'),
    },
  },
});

export interface IBrancherGridCard extends GridProps {
  hoverCursor?: boolean;
}

export const BrancherGridCard: React.FC<IBrancherGridCard> = ({
  className,
  hoverCursor,
  ...other
}) => {
  const styles = useStyles({ hoverCursor });
  return <Grid {...other} className={cx(styles.card, className)} />;
};
