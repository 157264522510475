import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Accessible from '@material-ui/icons/Accessible';
import EventBusy from '@material-ui/icons/EventBusy';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { parse } from 'date-fns';
import { IStoreTypes } from '../../../store/storeTypes';
import { Colors } from '../../../consts/colors';
import { Text } from '../../../Components/General/Text';
import { GetIllustrationLink } from '../../../helpers/LinkHelpers';
import { DateTimePicker } from '../../../Components/InputFields/DateTimePicker';
import { BrancherInputLabel } from '../../../Components/InputFields/BrancherInputLabel';
import { RejectButton, UpdateButton } from '../../../Components/InputFields/BrancherButton';
import {
  UtilUpdateFormConfigurationAttribute,
  UtilUpdateProgramAttribute,
  BrancherDispatch,
} from '../../../store/actions';
import {
  UpdatableFormConfiguration,
  UpdatableProgramInfo,
} from '../../../store/reducers/ProgramReducer';
import { formatDateTime } from '../../../helpers/DateHelpers';
import { BrancherDivider } from '../../../Components/General/BrancherDivider';
import { ConfirmDialog } from '../../../Components/General/ConfirmDialog';
import { RouteMaster } from '../../../Components/Routing';
import { CopyProgramSignupLink } from '../../Dashboard/CopyProgramSignupLink';

const useStyles = makeStyles({
  section: {
    backgroundColor: Colors.backgroundLightPurple,
    borderRadius: 15,
  },
  progress: {
    marginTop: 20,
    minWidth: '100%',
    maxWidth: (props: { notCompleted?: boolean }) => (props.notCompleted ? 700 : 800),
  },
});

interface ITrackingFormSubmissions {
  hasAdhocPairing?: boolean;
}

export const TrackingFormSubmissions: React.FC<ITrackingFormSubmissions> = ({
  hasAdhocPairing,
}) => {
  const program = useSelector((state: IStoreTypes) => state.program);
  const [savingDate, setSavingDate] = React.useState<boolean>(false);
  const [closingForm, setClosingForm] = React.useState<boolean>(false);
  const [redirectDashboard, setRedirectDashboard] = React.useState<boolean>(false);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [closeFormDate, setCloseFormDate] = React.useState<Date | string>(
    parse(program?.formConfiguration?.applicationClosingDate, "dd/MM/yyyy'T'HH:mm", new Date()),
  );
  const styles = useStyles({});
  const dispatch = useDispatch();

  const updateCloseFormDate = () => {
    setSavingDate(true);
    BrancherDispatch(
      dispatch,
      UtilUpdateFormConfigurationAttribute(
        UpdatableFormConfiguration.APPLICATION_CLOSING_DATE,
        formatDateTime(closeFormDate),
        () => {
          setSavingDate(false);
        },
      ),
    );
  };

  const updateCloseForm = () => {
    setClosingForm(true);
    if (hasAdhocPairing) {
      BrancherDispatch(
        dispatch,
        UtilUpdateProgramAttribute(UpdatableProgramInfo.LAUNCHED_PROGRAM, true, () => {
          setClosingForm(false);
          setRedirectDashboard(true);
        }),
      );
    } else {
      BrancherDispatch(
        dispatch,
        UtilUpdateProgramAttribute(UpdatableProgramInfo.ACTIVE, false, () => {
          setClosingForm(false);
        }),
      );
    }
  };

  return (
    <Grid container justifyContent="space-between" spacing={4}>
      {redirectDashboard && <Redirect to={RouteMaster.dashboard.path} />}
      <ConfirmDialog
        confirmAction={updateCloseForm}
        denyAction={() => setOpenDialog(false)}
        setClose={() => setOpenDialog(false)}
        open={openDialog}
        denyButtonText="Not yet"
        confirmButtonText="Close now"
        title="Are you sure you want to close the application form?"
        labelledBy="close-application-form-confirmation"
      />
      <Grid item container xs={6} className={styles.section} spacing={2}>
        <Grid item container>
          <Grid item>
            <Text variant="md" display="inline" fontWeight={600}>
              {program.totalSubmittedUsers} Submissions
            </Text>
            <Text variant="md" display="inline" color="darkGrey" fontWeight={600}>
              {' '}
              / {program.totalUsers} Users
            </Text>
          </Grid>
          <Grid item xs={12}>
            <LinearProgress
              value={(program.totalSubmittedUsers / program.totalUsers) * 100}
              valueBuffer={100}
              color="primary"
              variant="determinate"
              className={styles.progress}
            />
          </Grid>
        </Grid>
        <Grid item container justifyContent="space-between">
          <Grid item xs={5} container direction="column" spacing={1}>
            <Grid item container alignItems="center">
              <img
                src={GetIllustrationLink('approachMentoring.png')}
                width={40}
                height={40}
                alt="mentees-submissions"
              />
              <Text variant="sm" display="inline" fontWeight={600} marginLeft={15}>
                Mentees
              </Text>
            </Grid>
            <Grid item>
              <Text variant="md" display="inline" fontWeight={600}>
                {program?.submittedMenteeAmount}
              </Text>
              <Text variant="md" display="inline" color="darkGrey" fontWeight={600}>
                {' '}
                / {program?.menteeAmount}
              </Text>
            </Grid>
            <Grid item>
              <LinearProgress
                value={(program.submittedMenteeAmount / program.menteeAmount) * 100}
                valueBuffer={100}
                color="primary"
                variant="determinate"
                className={styles.progress}
              />
            </Grid>
          </Grid>
          <Grid item xs={5} container direction="column" spacing={1}>
            <Grid item container alignItems="center">
              <img
                src={GetIllustrationLink('approachMentoring.png')}
                width={40}
                height={40}
                alt="mentors-submissions"
              />
              <Text variant="sm" display="inline" fontWeight={600} marginLeft={15}>
                Mentors
              </Text>
            </Grid>
            <Grid item>
              <Text variant="md" display="inline" fontWeight={600}>
                {program?.submittedMentorAmount}
              </Text>
              <Text variant="md" display="inline" color="darkGrey" fontWeight={600}>
                {' '}
                / {program?.mentorAmount}
              </Text>
            </Grid>
            <Grid item>
              <LinearProgress
                value={(program.submittedMentorAmount / program.mentorAmount) * 100}
                valueBuffer={100}
                color="primary"
                variant="determinate"
                className={styles.progress}
              />
            </Grid>
          </Grid>
          {!!program?.accessibilityRequirements && (
            <Grid container item justifyContent="center">
              <Box
                display="inline-flex"
                mt={4}
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                <Accessible />
                <Text display="inline" variant="xs" color="purple" textDecoration="underline">
                  {program?.accessibilityRequirements} users have accessibility requirements
                </Text>
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item container xs={6} spacing={2}>
        <Grid item container justifyContent="center">
          <Text variant="sm" fontWeight={700} color="purple">
            Dates
          </Text>
        </Grid>
        <BrancherDivider />
        <Grid item xs={12} container>
          <Grid container item justifyContent="space-between" alignItems="center">
            <Grid item xs={7} md={3}>
              <BrancherInputLabel variant="sm" fontWeight={700} for="closeFormDate">
                Close Form
              </BrancherInputLabel>
            </Grid>
            <Grid item xs={11} md={8}>
              <DateTimePicker
                value={closeFormDate}
                updateValue={setCloseFormDate}
                id="closeFormDate"
                name="close-form-date"
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2} justifyContent="flex-end">
            <Grid item>
              <UpdateButton onClick={updateCloseFormDate} loading={savingDate} />
            </Grid>
            <Grid item>
              <RejectButton
                onClick={() => setOpenDialog(true)}
                loading={closingForm}
                startIcon={<EventBusy color="secondary" />}
              >
                Close now
              </RejectButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container justifyContent="flex-end">
          <CopyProgramSignupLink />
        </Grid>
      </Grid>
    </Grid>
  );
};
