import * as React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { Colors } from '../../../consts/colors';
import { Text } from '../../../Components/General/Text';
import { ProgramPositions } from '../../../consts/ProgramPositionOptions';
import { BrancherGridCard, IBrancherGridCard } from '../../../Components/General/BrancherGridCard';
import { ISuggestedPair } from './SuggestedPair';

interface IUserPairingCard extends IBrancherGridCard {
  user: string; // roleId
  name: string;
  position: ProgramPositions;
  targetUser: ISuggestedPair['targetUser'];
  draftingType: ISuggestedPair['draftingType'];
  pairAmount: number;
  approvedPairs: number;
}

const useStyles = makeStyles({
  userCircleApproved: {
    color: Colors.green,
  },
});

export const UserPairingCard: React.FC<IUserPairingCard> = (props) => {
  const { user, name, position, targetUser, approvedPairs, pairAmount, draftingType, ...other } = props;
  const styles = useStyles();

  return (
    <BrancherGridCard
      item
      hoverCursor
      xs={5}
      container
      alignItems="center"
      justifyContent="space-between"
      direction={position === ProgramPositions.mentor ? 'row-reverse' : 'row'}
      onClick={() => {
        targetUser && targetUser(user, position, name, false, draftingType);
      }}
      {...other}
    >
      <Grid item>
        <Text variant="sm" marginLeft={20} marginRight={20} fontWeight={700} color="secondaryGrey">
          {name}
        </Text>
      </Grid>
      <Box mr={2} ml={2}>
        <Grid item>
          {approvedPairs > 0 && (
            <>
              {[...Array(approvedPairs)].map((a, i) => (
                <AccountCircle className={styles.userCircleApproved} key={i} />
              ))}
            </>
          )}
          {(pairAmount - approvedPairs) > 0 && (
            <>
              {[...Array(pairAmount - approvedPairs)].map((a, i) => (
                <AccountCircle color="disabled" key={i} />
              ))}
            </>
          )}
        </Grid>
      </Box>
    </BrancherGridCard>
  );
};
