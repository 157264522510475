import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import Popover from '@material-ui/core/Popover';
import { useSelector } from 'react-redux';
import { BrancherGridCard } from '../../Components/General/BrancherGridCard';
import { Text } from '../../Components/General/Text';
import { EmojiRate } from '../../Components/General/EmojiRate';
import { IStoreTypes } from '../../store/storeTypes';
import { BrancherLinkText } from '../../Components/General/BrancherLinkText';
import { RouteMaster } from '../../Components/Routing';

const useStyles = makeStyles({
  dashboardCard: {
    minWidth: 420,
    padding: 25,
    minHeight: 220,
    height: 'fit-content',
  },
  popover: {
    '& .MuiPopover-paper': {
      padding: 12,
      borderRadius: 20,
    },
  },
  infoSubscriptionButton: {
    display: 'inline',
  },
});

export const MeetingSatisfactionHealth: React.FC = () => {
  const styles = useStyles({});
  const satisfaction = useSelector(
    (state: IStoreTypes) => state.dashboard?.meetingSatisfactionFrequencyHealth?.satisfaction,
  );
  const [popoverAnchor, setPopoverAnchor] = React.useState(null);

  const controlValidator = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!Boolean(popoverAnchor)) {
      setPopoverAnchor(event.currentTarget);
      event.currentTarget.focus();
    }
    event.stopPropagation();
    event.preventDefault();
  };

  const closeValidator = () => {
    if (Boolean(popoverAnchor)) {
      setPopoverAnchor(null);
    }
  };
  const open = Boolean(popoverAnchor);

  return !!satisfaction ? (
    <BrancherGridCard
      container
      item
      md={6}
      className={styles.dashboardCard}
      alignItems="center"
      justifyContent="center"
      onClick={closeValidator}
    >
      <Grid item xs={12} container justifyContent="center" alignItems="center">
        <Text variant="lg" display="inline" color="purple" fontWeight={700}>
          Meeting Satisfaction
        </Text>
        <IconButton className={styles.infoSubscriptionButton} onClick={controlValidator}>
          <InfoOutlined />
        </IconButton>
      </Grid>
      <Popover
        open={open}
        className={styles.popover}
        id="meeting-frequency-more-info"
        anchorEl={popoverAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Grid container spacing={2} direction="column">
          <Grid item container alignItems="center" direction="column" spacing={2}>
            <Grid item>
              <Text variant="sm" fontWeight={500}>
                These percentages are calculated by averaging the 'average satisfaction rating' of
                each mentee and mentor
              </Text>
            </Grid>
            <Grid item>
              <Text variant="sm" fontWeight={500}>
                Click the satisfaction percentage below to get a breakdown of each in the pairs
                section
              </Text>
            </Grid>
          </Grid>
        </Grid>
      </Popover>
      <Grid container item spacing={5} alignItems="center" justifyContent="center">
        <MeetingSatisfactionEmoji
          rating={1}
          satisfactionAmount={satisfaction?.stronglyDissatisfied}
        />
        <MeetingSatisfactionEmoji rating={2} satisfactionAmount={satisfaction?.dissatisfied} />
        <MeetingSatisfactionEmoji rating={3} satisfactionAmount={satisfaction?.neutral} />
        <MeetingSatisfactionEmoji rating={4} satisfactionAmount={satisfaction?.satisfied} />
        <MeetingSatisfactionEmoji rating={5} satisfactionAmount={satisfaction?.stronglySatisfied} />
      </Grid>
    </BrancherGridCard>
  ) : (
    <CircularProgress color="secondary" size={64} />
  );
};

interface IMeetingSatisfactionEmoji {
  rating: number;
  satisfactionAmount: number;
}

const MeetingSatisfactionEmoji: React.FC<IMeetingSatisfactionEmoji> = ({
  rating,
  satisfactionAmount,
}) => {
  return (
    <Grid item>
      <EmojiRate variant="xl" display="inline" rating={rating} showRawRating={false} />
      {satisfactionAmount > 0 ? (
        <BrancherLinkText
          href={`${RouteMaster.pairs.path}?ms=${rating}`}
          underline="always"
          variant="md"
          display="inline"
          marginLeft={10}
          fontWeight={600}
        >
          {satisfactionAmount}%
        </BrancherLinkText>
      ) : (
        <Text variant="md" display="inline" marginLeft={10} fontWeight={600}>
          {satisfactionAmount}%
        </Text>
      )}
    </Grid>
  );
};
