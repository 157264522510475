import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { SaveButton } from '../../Components/InputFields/BrancherButton';
import { Text } from '../../Components/General/Text';
import { BrancherFileUpload } from '../../Components/InputFields/BrancherFileUpload';
import { UtilUploadApplicationApprovalList, BrancherDispatch } from '../../store/actions/';
import { validateEmail } from '../../utils/validators/validateEmail';
import { BrancherLinkText } from '../../Components/General/BrancherLinkText';
import { GetSharedResourceLink } from '../../helpers/LinkHelpers';
import { BrancherList } from '../../Components/General/BrancherList';
import { BrancherCheckbox } from '../../Components/InputFields/BrancherCheckbox';
import { ConfirmDialog } from '../../Components/General/ConfirmDialog';

const useStyles = makeStyles({
  popover: {
    '& .MuiPopover-paper': {
      padding: 12,
      borderRadius: 20,
    },
  },
  infoButton: {
    display: 'inline',
  },
});

export const UserApplicationApprovalsUpload: React.FC = () => {
  const [CSVList, setCSVList] = React.useState<string[]>([]);
  const [invalidUsernames, setInvalidUsernames] = React.useState<string[]>([]);
  const [approvalErrorMessage, setApprovalErrorMessage] = React.useState<string>('');
  const [updatingProgram, setUpdatingProgram] = React.useState<boolean>(false);
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState<boolean>(false);
  const [sendNotification, setSendNotification] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const styles = useStyles();
  const [popoverAnchor, setPopoverAnchor] = React.useState(null);

  const cleanUsernames = (username: string): string => {
    return username.replace('\r\n', '');
  };

  const controlSavingCSVList = (usernameList: string) => {
    let validatedUsernames: string[] = [];
    let invalidNames: string[] = [];
    const initialNameList = Array.from(new Set(usernameList.split(',')));
    initialNameList
      ?.map((m) => cleanUsernames(m))
      ?.forEach((f) => {
        if (validateEmail(f)) {
          validatedUsernames.push(f);
        } else {
          invalidNames.push(f);
        }
      });
    setApprovalErrorMessage('');
    setInvalidUsernames(invalidNames);
    setCSVList(validatedUsernames);
  };

  const uploadCSVList = () => {
    setUpdatingProgram(true);
    BrancherDispatch(
      dispatch,
      UtilUploadApplicationApprovalList(CSVList, sendNotification, (res) => {
        setCSVList([]);
        setInvalidUsernames([]);
        setUpdatingProgram(false);
        if (!!res?.message) {
          setApprovalErrorMessage(res.message);
        }
      }),
    );
  };

  const controlValidator = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!Boolean(popoverAnchor)) {
      setPopoverAnchor(event.currentTarget);
      event.currentTarget.focus();
    }
    event.stopPropagation();
    event.preventDefault();
  };

  const closeValidator = () => {
    if (Boolean(popoverAnchor)) {
      setPopoverAnchor(null);
    }
  };
  const open = Boolean(popoverAnchor);

  return (
    <Grid container justifyContent="center" spacing={4} onClick={closeValidator}>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Text variant="sm" color="purple" fontWeight={600} marginBottom={10}>
            Upload users to approve CSV file (emails)
          </Text>
          <Text variant="xs" fontWeight={400} marginBottom={10} display="inline">
            This is a list of (comma separated) emails of users you want approved for this program
          </Text>
          <IconButton className={styles.infoButton} onClick={controlValidator}>
            <InfoOutlined />
          </IconButton>
          <ConfirmDialog
            loading={updatingProgram}
            setClose={() => setOpenConfirmDialog(false)}
            labelledBy="user-approval"
            open={openConfirmDialog}
            title="Approve users"
            confirmAction={uploadCSVList}
            confirmButtonText="Yes, approve"
            denyAction={() => setOpenConfirmDialog(false)}
            denyButtonText="Not yet"
          >
            <Grid container justify="center" alignItems="center" direction="column">
              <Grid item>
                <Text variant="md" fontWeight={600} marginTop={50}>
                  Are you sure you want to approve {CSVList.length} users for this program?
                </Text>
              </Grid>

              <Grid item>
                <Box display="flex" mb={5} mt={3} justifyContent="center">
                  <BrancherCheckbox
                    name="sendNotification"
                    value={sendNotification}
                    updateValue={setSendNotification}
                    label="Send an email notification to notify users"
                  />
                </Box>
              </Grid>

              {updatingProgram && (
                <Grid item>
                  <Text variant="md" fontWeight={600} marginTop={30} marginBottom={30}>
                    Approving {CSVList.length} users! &#58130; This will take a while, so you can close this dialog after 30 seconds if it doesn't automatically close!
                  </Text>
                </Grid>
              )}
            </Grid>
          </ConfirmDialog>
          <Popover
            open={open}
            className={styles.popover}
            id="active-users-more-info"
            anchorEl={popoverAnchor}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Grid container>
              <Grid item>
                <BrancherList
                  listItems={[
                    'Email addresses must be in a single row format (e.g. cell 1A, 1B, 1C)',
                    'No commas or semi-colons after the emails',
                    'Tip: in excel, you can select a paste option to "transpose" the emails from a single column into a single row',
                  ]}
                />
              </Grid>
            </Grid>
          </Popover>

          <BrancherLinkText
            marginBottom={30}
            variant="sm"
            download
            href={GetSharedResourceLink('user_approval_list.csv')}
          >
            Example approvals CSV file
          </BrancherLinkText>
          <Box marginBottom={3}>
            <BrancherFileUpload
              isCSV
              value={CSVList.join(',')}
              name="csv-upload-list"
              updateValue={(c: string) => controlSavingCSVList(c)}
            />
          </Box>
          {CSVList?.length > 0 && (
            <Text variant="sm" fontWeight={500}>
              {CSVList.length} users will be approved.
            </Text>
          )}
          {invalidUsernames?.length > 0 && (
            <Text variant="sm" marginTop={15} color="red" fontWeight={500}>
              {invalidUsernames.join(', ')} are not valid usernames.
            </Text>
          )}
        </Grid>
      </Grid>
      {!!approvalErrorMessage && (
        <Grid item xs={12}>
          <Text variant="sm" fontWeight={600} marginBottom={30}>
            {approvalErrorMessage}
          </Text>
        </Grid>
      )}
      <Grid item xs={12}>
        <SaveButton
          onClick={() => setOpenConfirmDialog(true)}
          loading={updatingProgram}
          disabled={updatingProgram || !(CSVList?.length > 0)}
        >
          Upload user approval list
        </SaveButton>
      </Grid>
    </Grid>
  );
};
