import * as React from 'react';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Redirect, Route, RouteProps, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { IRouteWithSubRoutesProps, RouteMaster } from './RouteConfig';
import { IStoreTypes } from '../../store/storeTypes';
import { Error404 } from './Error404';
import { HeaderNav } from '../HeaderNav/HeaderNav';
import { mainNavWidth } from '../../consts/DimensionConsts';
import { AllPositions } from '../../consts/ProgramPositionOptions';
import { SignUserOut } from '../../store/actions';

export function RouteWithSubRoutes(route: IRouteWithSubRoutesProps) {
  return (
    <PrivateRoute path={route.path}>
      <route.component routes={route.routes} />
    </PrivateRoute>
  );
}

const publicRoutes: string[] = [
  RouteMaster.splash.path,
  RouteMaster.forgotPassword.path,
  RouteMaster.verifyForgotPassword.path,
  RouteMaster.login.path,
  RouteMaster.signup.path,
  RouteMaster.confirmRegistration.path,
];

const nonNavRoutes = [...publicRoutes];

const authRoutes: string[] = [
  RouteMaster.home.path,
  RouteMaster.program.path,
  RouteMaster.dashboard.path,
  RouteMaster.trainingDashboard.path,
  RouteMaster.programUsers.path,
  RouteMaster.user.path,
  RouteMaster.pairs.path,
  RouteMaster.pair.path,
  RouteMaster.matching.path,
  RouteMaster.timeline.path,
  RouteMaster.settings.path,
  RouteMaster.supportRequests.path,
  RouteMaster.mentoringRequests.path,
  RouteMaster.surveys.path,
  RouteMaster.survey.path,
  RouteMaster.group.path,
  RouteMaster.groups.path,
  RouteMaster.leaderboard.path,
  RouteMaster.resources.path,
];

const brancherAdminRoutes: string[] = [
  RouteMaster.superUser.path,
  RouteMaster.superUserDashboard.path,
];

const allRoutes = [...publicRoutes, ...authRoutes];

const PrivateRoute = (props: RouteProps) => {
  const loggedIn = useSelector((state: IStoreTypes) => state.user.loggedIn);
  const validToken =
    useSelector((state: IStoreTypes) => state.user?.tokenExp) > new Date().getTime() / 1000;
  const expiredToken = useSelector((state: IStoreTypes) => state.user.expiredToken);
  const isBrancherAdmin = useSelector((state: IStoreTypes) =>
    state.user?.positions?.includes(AllPositions.BRANCHER_ADMIN),
  );
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const postAuthRoutes: string[] = isBrancherAdmin
    ? [...allRoutes, ...brancherAdminRoutes]
    : allRoutes;

  React.useEffect(() => {
    // TODO: check this doesnt blow up
    if (!nonNavRoutes.includes(path.toString()) && expiredToken) {
      dispatch(SignUserOut());
    } else {
      window.scrollTo({ behavior: 'smooth', top: 0 });
    }
  }, [pathname]);

  const { children, path, ...other } = props;

  return (
    <Route
      {...other}
      render={() =>
        (validToken && loggedIn) || publicRoutes.includes(path.toString()) ? (
          !postAuthRoutes.includes(path.toString()) ? (
            <Error404 />
          ) : !nonNavRoutes.includes(path.toString()) ? (
            expiredToken ? (
              <Redirect to={RouteMaster.login.path} />
            ) : (
              <Box
                marginBottom={mobile ? 12 : 0}
                marginLeft={mobile ? 0 : mainNavWidth}
                width="-webkit-fill-available"
              >
                <HeaderNav />
                {children}
              </Box>
            )
          ) : (
            children
          )
        ) : (
          <Redirect to={RouteMaster.login.path} />
        )
      }
    />
  );
};
