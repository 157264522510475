import * as React from 'react';
import Switch, { SwitchProps } from '@material-ui/core/Switch';

interface ISwitchProps extends SwitchProps {
  updateValue: (a: boolean) => void;
  value: boolean;
  ariaLabel: string;
}

export const BrancherSwitch = (props: ISwitchProps) => {
  const { updateValue, ariaLabel, ...other } = props;
  return (
    <Switch
      color="primary"
      {...other}
      inputProps={{
        'aria-label': ariaLabel,
      }}
      checked={props.value}
      onChange={(e) => updateValue(e.target.checked)}
    />
  );
};
