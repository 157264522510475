import * as React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useDropzone } from 'react-dropzone';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Text } from '../General/Text';
import { BrancherInputLabel } from './BrancherInputLabel';
import { CreateButton } from './BrancherButton';

interface IBrancherFileUpload {
  value: string | string[];
  name: string;
  updateValue: (e: string) => void;
  isCSV?: boolean;
  required?: boolean;
  ratio?: number;
}

const useStyles = makeStyles((theme) => ({
  img: {
    width: '100%',
  },
  imgBox: {
    display: 'flex',
    alignSelf: 'flex-end',
    padding: '22px 14px',
    borderRadius: 10,
    width: 180,
    height: 150,
    border: `3px solid ${theme.palette.primary.main}`,
    boxShadow: '0px 5px 8px -3px rgba(0,0,0,0.14)',
  },
  acceptedFile: {
    maxWidth: 180,
    maxHeight: 150,
    borderRadius: 5,
  },
}));

export const BrancherFileUpload = (props: IBrancherFileUpload) => {
  const [uploadedFile, setUploadedFile] = React.useState<string>('');
  const [fileSizeError, setFileSizeError] = React.useState<boolean>(false);
  const [fileTypeError, setFileTypeError] = React.useState<boolean>(false);
  const [croppedImage, setCroppedImage] = React.useState<string>('');
  const [filename, setFilename] = React.useState<string>('');
  const { updateValue, name, ratio, isCSV, value } = props;
  const styles = useStyles();

  React.useEffect(() => {
    if (value?.length === 0 && uploadedFile) {
      setFilename('');
      setUploadedFile('');
    }
  }, [value]);

  const acceptedFileTypes = isCSV
    ? [
        'text/plain',
        '.csv',
        'text/csv',
        'application/vnd.ms-excel',
        'application/csv',
        'text/x-csv',
        'application/x-csv',
        'text/comma-separated-values',
        'text/x-comma-separated-values',
      ]
    : ['image/jpeg', 'image/jpg', 'image/png'];

  const onDropAccepted = (files: any[]) => {
    const file = files[0];
    const reader = new FileReader();
    setFilename(file?.name);
    reader.onload = (event) => {
      const actualFileData = event.target.result.toString();
      setUploadedFile(actualFileData);
      updateValue(actualFileData);
      if (!isCSV) {
        setCroppedImage(actualFileData);
      }
      if (fileSizeError) {
        setFileSizeError(true);
      }
      if (fileTypeError) {
        setFileTypeError(true);
      }
    };
    if (!isCSV) {
      reader.readAsDataURL(file);
      setIsCropping(true);
    } else {
      reader.readAsText(file);
    }
  };

  const onDropRejected = (files: Array<{ errors: Array<{ code: string }> }>) => {
    files[0].errors.forEach((err) => {
      if (err.code === 'file-invalid-type') {
        setFileTypeError(true);
      } else if (err.code === 'file-too-large') {
        setFileSizeError(true);
      }
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: acceptedFileTypes,
    onDropAccepted,
    onDropRejected,
    maxSize: 1024000,
  });

  const cropperRef = React.useRef<HTMLImageElement>(null);
  const [isCropping, setIsCropping] = React.useState<boolean>(false);

  const onCrop = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    setCroppedImage(cropper.getCroppedCanvas().toDataURL());
  };

  const saveCrop = () => {
    setUploadedFile(croppedImage);
    updateValue(croppedImage);
    setIsCropping(false);
  };

  return (
    <>
      {isCropping && (
        <Grid container justifyContent="center">
          <Cropper
            style={{ height: 250, width: '100%' }}
            src={uploadedFile}
            crop={onCrop}
            aspectRatio={ratio ?? 1}
            ref={cropperRef}
          />
          <Box mt={3} mb={3}>
            <CreateButton onClick={saveCrop} aria-label="finish-crop">
              Finish cropping
            </CreateButton>
          </Box>
        </Grid>
      )}
      <div {...getRootProps()}>
        <input {...getInputProps()} multiple={false} />
        {isDragActive ? (
          <BrancherInputLabel variant="lg" for="label">
            Drop file here...
          </BrancherInputLabel>
        ) : (
          <Grid container alignItems="center">
            {uploadedFile && !isCropping && (
              <Box display="inline" marginRight={3}>
                {!isCSV ? (
                  <img src={uploadedFile} className={styles.acceptedFile} alt={name} />
                ) : (
                  <Text variant="xs">{filename}</Text>
                )}
              </Box>
            )}
            <Box className={styles.imgBox} display="inline-block" marginLeft={uploadedFile && 4}>
              <img
                src="https://media.brancher.com.au/shared/upload.svg"
                alt="upload"
                className={styles.img}
              />
            </Box>
          </Grid>
        )}
        {fileSizeError && (
          <Text variant="sm" color="red" key="file-size-error">
            File size must be under 1MB
          </Text>
        )}
        {fileTypeError && (
          <Text variant="sm" color="red" key="file-type-error">
            File type must be a {!isCSV ? 'JPG, JPEG or PNG' : 'csv'}
          </Text>
        )}
      </div>
    </>
  );
};
