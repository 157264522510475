import * as React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { Colors } from '../../consts/colors';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Close from '@material-ui/icons/Close';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Apps from '@material-ui/icons/Apps';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { getFontSize } from '../../utils/TextUtils';
import { WindowSizeVariants } from '../../types/WindowSizeTypes';
import { Edit, Save } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import Check from '@material-ui/icons/Check';

interface IBrancherButtonStyles {
  fontSize?: number;
  mobile?: boolean;
  disabled?: boolean;
  small?: boolean;
}

const useStyles = makeStyles((theme) => ({
  startIcon: {
    color: theme.palette.primary.main,
  },
  endIcon: {
    color: Colors.white,
  },
  negativeEndIcon: {
    color: (props: IBrancherButtonStyles) => (props.disabled ? Colors.white : Colors.red),
    '& .MuiSvgIcon-root': {
      color: Colors.white,
    },
  },
  negative: {
    borderColor: Colors.red,
    color: Colors.red,
    fontWeight: 600,
  },
  appsIcon: {
    color: Colors.white,
  },
  navSearch: {
    borderRadius: '8px !important',
    fontSize: 13,
    fontWeight: 600,
    marginTop: 20,
    width: 'fit-content',
  },
  navSearchLabel: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  letterSearch: {
    background: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  letterSearchLabel: {
    fontSize: 18,
    color: Colors.white,
  },
  label: {
    fontSize: (props: IBrancherButtonStyles) => props.fontSize,
  },
  action: {
    height: (props: IBrancherButtonStyles) => (props.mobile ? 42 : 64),
    padding: (props: IBrancherButtonStyles) => (props.mobile ? 12 : 18),
    background: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  actionLabel: {
    fontSize: (props: IBrancherButtonStyles) => (props.mobile ? 14 : 20),
    color: Colors.white,
  },
  actionOutlined: {
    border: `2px solid ${theme.palette.primary.main}`,
    height: (props: IBrancherButtonStyles) => (props.mobile ? 42 : 64),
    padding: (props: IBrancherButtonStyles) => (props.mobile ? 12 : 18),
    background: Colors.white,
    '&:hover': {
      background: Colors.white,
    },
  },
  actionOutlinedLabel: {
    fontSize: (props: IBrancherButtonStyles) => props?.fontSize ?? (props.mobile ? 14 : 20),
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  smallAction: {
    border: `2px solid ${theme.palette.primary.main}`,
    height: (props: IBrancherButtonStyles) => (props.mobile ? 32 : 44),
    padding: 4,
    background: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  smallActionOutlined: {
    border: `2px solid ${theme.palette.primary.main}`,
    height: (props: IBrancherButtonStyles) => (props.mobile ? 32 : 44),
    padding: 4,
    background: Colors.white,
    '&:hover': {
      background: Colors.white,
    },
  },
  negativeAction: {
    border: `2px solid ${Colors.red}`,
    height: (props: IBrancherButtonStyles) => (props.mobile ? 42 : 64),
    padding: (props: IBrancherButtonStyles) => (props.mobile ? 12 : 18),
    background: Colors.red,
    '&:hover': {
      border: `2px solid ${Colors.red}`,
      background: Colors.red,
    },
  },
  negativeActionLabel: {
    fontSize: (props: IBrancherButtonStyles) => (props.mobile ? 14 : 20),
    color: Colors.white,
  },
  negativeOutlinedAction: {
    border: `2px solid ${Colors.red}`,
    height: (props: IBrancherButtonStyles) => (props.mobile ? 42 : 64),
    padding: (props: IBrancherButtonStyles) => (props.mobile ? 12 : 18),
    background: Colors.white,
    '&:hover': {
      border: `2px solid ${Colors.red}`,
      background: Colors.white,
    },
  },
  negativeOutlinedActionLabel: {
    fontSize: (props: IBrancherButtonStyles) => (props.mobile ? 14 : 20),
    color: Colors.red,
    fontWeight: 600,
  },
  smallNegativeOutlinedAction: {
    border: `2px solid ${Colors.red}`,
    height: (props: IBrancherButtonStyles) => (props.mobile ? 32 : 44),
    padding: 4,
    background: Colors.white,
    '&:hover': {
      border: `2px solid ${Colors.red}`,
      background: Colors.white,
    },
  },

  positiveOutlinedAction: {
    border: `2px solid ${Colors.green}`,
    height: (props: IBrancherButtonStyles) => (props.mobile ? 42 : 64),
    padding: (props: IBrancherButtonStyles) => (props.mobile ? 12 : 18),
    background: Colors.white,
    '&:hover': {
      border: `2px solid ${Colors.green}`,
      background: Colors.white,
    },
    fontSize: (props: { fontSize: number }) => props.fontSize,
  },
  positiveOutlinedActionLabel: {
    fontSize: (props: IBrancherButtonStyles) => (props.mobile ? 14 : 20),
    color: Colors.green,
    fontWeight: 600,
  },
  smallPositiveOutlinedAction: {
    border: `2px solid ${Colors.green}`,
    height: (props: IBrancherButtonStyles) => (props.mobile ? 32 : 44),
    padding: 4,
    background: Colors.white,
    '&:hover': {
      border: `2px solid ${Colors.green}`,
      background: Colors.white,
    },
  },

  positiveTextAction: {
    height: (props: IBrancherButtonStyles) => (props.mobile ? 42 : 64),
    padding: (props: IBrancherButtonStyles) => (props.mobile ? 12 : 18),
    background: Colors.transparent,
    '&:hover': {
      background: Colors.cardBorder,
    },
    fontSize: (props: { fontSize: number }) => props.fontSize,
  },
  positiveTextActionLabel: {
    fontSize: (props: IBrancherButtonStyles) => props.fontSize ?? (props.mobile ? 14 : 20),
    color: Colors.green,
  },

  negativeTextAction: {
    height: (props: IBrancherButtonStyles) => (props.mobile ? 42 : 64),
    padding: 4,
    background: Colors.transparent,
    '&:hover': {
      background: Colors.cardBorder,
    },
  },
  negativeTextActionLabel: {
    fontSize: (props: IBrancherButtonStyles) => props.fontSize ?? (props.mobile ? 14 : 20),
    color: Colors.red,
  },

  primaryTextAction: {
    padding: 2,
    background: Colors.white,
  },
  primaryTextActionLabel: {
    fontSize: (props: IBrancherButtonStyles) => (props.mobile ? 10 : 16),
    color: Colors.purple,
  },
}));

export interface IBrancherButton extends ButtonProps {
  loading?: boolean;
  children?: React.ReactNode;
  fontSize?: number;
}

export const BrancherButton = (props: IBrancherButton) => {
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const size = mobile ? WindowSizeVariants.mobile : WindowSizeVariants.tabletDesktop;
  const fontSize = props?.fontSize ?? props.size === 'small' ? 18 : getFontSize(size, 'md');
  const styles = useStyles({ ...props, disabled: props.loading || props.disabled, fontSize });
  const { children, loading, ...other } = props;
  return (
    <Button
      variant="outlined"
      size="large"
      classes={{ label: styles.label }}
      disabled={loading}
      {...other}
    >
      {loading ? <CircularProgress className={styles.endIcon} size={48} /> : children}
    </Button>
  );
};

export const NegativeButton = (props: IBrancherButton) => {
  const styles = useStyles({});
  const { children = '', ...other } = props;
  return (
    <BrancherButton
      className={styles.negative}
      endIcon={<Close className={styles.negativeEndIcon} />}
      {...other}
    >
      {children}
    </BrancherButton>
  );
};

export const LogoutButton = (props: IBrancherButton) => {
  return (
    <BrancherButton
      color="secondary"
      variant="text"
      size="small"
      aria-label="logout"
      endIcon={<ExitToApp color="secondary" />}
      {...props}
    >
      Log out
    </BrancherButton>
  );
};

export const NextButton = (props: IBrancherButton) => {
  const styles = useStyles({});
  const { children, ...other } = props;
  return (
    <BrancherButton
      color="primary"
      endIcon={<ArrowForward className={styles.endIcon} />}
      {...other}
    >
      {children}
    </BrancherButton>
  );
};

export const EditButton = (props: IBrancherButton) => {
  const styles = useStyles({});
  const { children, ...other } = props;
  return (
    <BrancherButton
      variant="text"
      color="primary"
      startIcon={<Edit className={styles.startIcon} />}
      {...other}
    >
      Edit
    </BrancherButton>
  );
};

export const PrevButton = (props: IBrancherButton) => {
  const styles = useStyles({});
  const { children, ...other } = props;
  return (
    <BrancherButton
      color="secondary"
      startIcon={<ArrowBack className={styles.startIcon} />}
      {...other}
    >
      {children}
    </BrancherButton>
  );
};

export const SearchNavigationButton = (props: IBrancherButton) => {
  const styles = useStyles({});
  const { children, ...other } = props;
  return (
    <Button
      variant="outlined"
      color="primary"
      startIcon={<Apps className={styles.appsIcon} />}
      classes={{ root: styles.navSearch, label: styles.navSearchLabel }}
      {...other}
    >
      {children}
    </Button>
  );
};

export const SearchLetterButton = (props: IBrancherButton) => {
  const styles = useStyles({});
  const { children, ...other } = props;
  return (
    <Button
      variant="outlined"
      classes={{ root: styles.letterSearch, label: styles.letterSearchLabel }}
      {...other}
    >
      {children}
    </Button>
  );
};

export const ActionButton = (props: IBrancherButton) => {
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const { children, loading, fontSize, ...other } = props;
  const isSmall = other.size === 'small';
  const styles = useStyles({ mobile, small: isSmall, fontSize });
  return (
    <Button
      variant="contained"
      aria-label={other?.['aria-label']}
      disabled={loading}
      classes={{ root: isSmall ? styles.smallAction : styles.action, label: styles.actionLabel }}
      {...other}
    >
      {loading ? <CircularProgress className={styles.startIcon} size={48} /> : children}
    </Button>
  );
};

export const ResetButton = (props: IBrancherButton) => {
  const { children, loading, ...other } = props;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const styles = useStyles({ mobile, small: other?.size === 'small' });
  return (
    <NegativeButton aria-label={other?.['aria-label'] ?? 'reset'} disabled={loading} {...other}>
      {loading ? <CircularProgress className={styles.startIcon} size={48} /> : children ?? 'Reset'}
    </NegativeButton>
  );
};

export const ActionOutlinedButton = (props: IBrancherButton) => {
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const { children, ...other } = props;
  const styles = useStyles({ mobile });
  const isSmall = other.size === 'small';
  return (
    <ActionButton
      variant="outlined"
      classes={{
        root: isSmall ? styles.smallActionOutlined : styles.actionOutlined,
        label: styles.actionOutlinedLabel,
      }}
      {...other}
    >
      {children}
    </ActionButton>
  );
};

export const ActionNegativeTextButton = (props: IBrancherButton) => {
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const styles = useStyles({ mobile });
  const { children, loading, ...other } = props;
  return (
    <Button
      color="secondary"
      variant="text"
      aria-label={other?.['aria-label']}
      disabled={loading}
      classes={{ root: styles.negativeTextAction, label: styles.negativeTextActionLabel }}
      {...other}
    >
      {loading ? <CircularProgress className={styles.endIcon} size={48} /> : children}
    </Button>
  );
};

export const ActionPositiveTextButton = (props: IBrancherButton) => {
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const styles = useStyles({ mobile });
  const { children, loading, ...other } = props;
  return (
    <Button
      variant="text"
      aria-label={other?.['aria-label']}
      disabled={loading}
      classes={{ root: styles.positiveTextAction, label: styles.positiveTextActionLabel }}
      {...other}
    >
      {loading ? <CircularProgress className={styles.endIcon} size={48} /> : children}
    </Button>
  );
};

export const ActionTextButton = (props: IBrancherButton) => {
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const styles = useStyles({ mobile });
  const { children, loading, ...other } = props;
  return (
    <Button
      variant="text"
      aria-label={other?.['aria-label']}
      disabled={loading}
      classes={{ root: styles.primaryTextAction, label: styles.primaryTextActionLabel }}
      {...other}
    >
      {loading ? <CircularProgress className={styles.endIcon} size={48} /> : children}
    </Button>
  );
};

export const ActionPositiveOutlinedButton = (props: IBrancherButton) => {
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const styles = useStyles({ mobile });
  const { children, loading, ...other } = props;
  const isSmall = other.size === 'small';
  return (
    <ActionOutlinedButton
      disabled={loading}
      classes={{
        root: isSmall ? styles.smallPositiveOutlinedAction : styles.positiveOutlinedAction,
        label: styles.positiveOutlinedActionLabel,
      }}
      {...other}
    >
      {loading ? <CircularProgress className={styles.endIcon} size={48} /> : children}
    </ActionOutlinedButton>
  );
};

export const ActionNegativeButton = (props: IBrancherButton) => {
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const styles = useStyles({ mobile });
  const { children, loading, ...other } = props;
  return (
    <Button
      color="secondary"
      disabled={loading}
      classes={{ root: styles.negativeAction, label: styles.negativeActionLabel }}
      {...other}
    >
      {loading ? <CircularProgress className={styles.endIcon} size={48} /> : children}
    </Button>
  );
};

export const ActionNegativeOutlinedButton = (props: IBrancherButton) => {
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const styles = useStyles({ mobile });
  const { children, loading, ...other } = props;
  const isSmall = other.size === 'small';
  return (
    <ActionOutlinedButton
      disabled={loading}
      classes={{
        root: isSmall ? styles.smallNegativeOutlinedAction : styles.negativeOutlinedAction,
        label: styles.negativeOutlinedActionLabel,
      }}
      {...other}
    >
      {loading ? <CircularProgress className={styles.endIcon} size={48} /> : children}
    </ActionOutlinedButton>
  );
};

interface ICreateDeleteButton extends ButtonProps {
  loading?: boolean;
}

export const UpdateButton = (props: ICreateDeleteButton) => {
  const { children, loading, ...other } = props;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const styles = useStyles({ mobile });
  return (
    <BrancherButton
      color="secondary"
      aria-label={other?.['aria-label'] ?? 'update'}
      startIcon={<Edit className={styles.startIcon} />}
      disabled={loading}
      {...other}
    >
      {loading ? <CircularProgress className={styles.startIcon} size={48} /> : children ?? 'Edit'}
    </BrancherButton>
  );
};

export const RejectButton = (props: IBrancherButton) => {
  const { children, ...other } = props;
  return (
    <ActionNegativeButton aria-label="reject" startIcon={<Close color="secondary" />} {...other}>
      {children ?? 'Reject'}
    </ActionNegativeButton>
  );
};

export const SaveButton = (props: IBrancherButton) => {
  const { children, ...other } = props;
  return (
    <ActionButton
      color="secondary"
      aria-label={other?.['aria-label'] ?? 'save'}
      startIcon={<Save color="secondary" />}
      {...other}
    >
      {children ?? 'Save'}
    </ActionButton>
  );
};

export const AcceptButton = (props: IBrancherButton) => {
  const { children, ...other } = props;
  return (
    <ActionButton
      variant="contained"
      aria-label="accept"
      startIcon={<Check color="secondary" />}
      {...other}
    >
      {children ?? 'Accept'}
    </ActionButton>
  );
};

export const NotYetButton = (props: IBrancherButton) => {
  const { children, ...other } = props;
  return (
    <ActionOutlinedButton aria-label="not-yet" startIcon={<Close />} {...other}>
      {children ?? 'Not yet'}
    </ActionOutlinedButton>
  );
};

export const BackButton = (props: IBrancherButton) => {
  const { children, ...other } = props;
  return (
    <ActionOutlinedButton aria-label="back" startIcon={<ArrowBack />} {...other}>
      {children ?? 'Back'}
    </ActionOutlinedButton>
  );
};

export const ForwardButton = (props: IBrancherButton) => {
  const { children, ...other } = props;
  return (
    <ActionButton aria-label="forward" endIcon={<ArrowForward color="secondary" />} {...other}>
      {children ?? 'Forward'}
    </ActionButton>
  );
};

export const CreateButton = (props: IBrancherButton) => {
  const { children, ...other } = props;
  return (
    <ActionOutlinedButton aria-label="forward" startIcon={<Save />} {...other}>
      {children ?? 'Forward'}
    </ActionOutlinedButton>
  );
};
