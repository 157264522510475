import * as React from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../consts/colors';
import { FormHelperText } from '@material-ui/core';

interface IBaseFieldTypes {
  value: string | string[];
  name: string;
  label?: React.ReactNode;
  updateValue: (e: string | string[]) => void;
  required?: boolean;
  fullWidth?: boolean;
}

export interface IFormSelectType {
  value: number | string;
  label: string;
}

const useStyles = makeStyles({
  formControl: {
    marginTop: (props: { decreaseMargins: boolean }) => (props.decreaseMargins ? 10 : 20),
    marginBottom: (props: { decreaseMargins: boolean }) => (props.decreaseMargins ? 10 : 20),
    minWidth: '100%',
    '&& .MuiPopover-paper': {
      padding: 0,
    },
    '& .MuiInputLabel-root': {
      color: Colors.white,
      marginLeft: 25,
      zIndex: 20,
      lineHeight: '2px',
    },
    '& .MuiInputLabel-shrink': {
      top: -15,
      color: Colors.black,
      fontWeight: 600,
      marginLeft: 0,
      fontSize: '18px',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover': {
        border: 'none',
      },
    },
  },
});

interface IBrancherSelect extends IBaseFieldTypes {
  options?: IFormSelectType[];
  inputLabel?: string;
  id?: string;
  helpText?: React.ReactNode;
  decreaseMargins?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  disablePortal?: boolean;
}

export const BrancherSelect = (props: IBrancherSelect) => {
  const {
    inputLabel,
    name,
    options,
    value = '',
    updateValue,
    fullWidth,
    id,
    decreaseMargins,
    helpText,
    disabled,
    disablePortal = true,
    multiple = false,
  } = props;
  const styles = useStyles({ decreaseMargins });
  return (
    <FormControl
      className={styles.formControl}
      key={name}
      fullWidth={fullWidth}
      disabled={disabled}
    >
      <InputLabel id={inputLabel}>{inputLabel}</InputLabel>
      <Select
        // labelId={inputLabel}
        id={inputLabel}
        value={value}
        variant="outlined"
        onChange={(e: any) => updateValue(e.target.value)}
        name={name}
        multiple={multiple}
        renderValue={(selected: string[] | string) => {
          if (multiple && typeof selected === 'object') {
            return selected
              ?.map((val) => {
                return options.find((o) => o?.value === val)?.label;
              })
              .join(', ');
          } else {
            return options.find((o) => o?.value === selected)?.label;
          }
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          disablePortal,
          getContentAnchorEl: null,
          disableScrollLock: true,
        }}
      >
        {options.map((c: IFormSelectType) => (
          <MenuItem value={c.value} key={c.value}>
            {multiple ? (
              <>
                <Checkbox checked={value?.includes(c.value as string)} />
                <ListItemText primary={c.label} />
              </>
            ) : (
              c.label
            )}
          </MenuItem>
        ))}
      </Select>
      {helpText && <FormHelperText>{helpText}</FormHelperText>}
    </FormControl>
  );
};
