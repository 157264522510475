import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../consts/colors';

const useStyles = makeStyles({
  fullPage: {
    minHeight: `100vh`,
    marginTop: (props: { marginTop: number; marginBottom: number; background: string }) =>
      props.marginTop,
    marginBottom: (props: { marginTop: number; marginBottom: number; background: string }) =>
      props.marginBottom,
    background: (props: { marginTop: number; marginBottom: number; background: string }) =>
      props.background ?? Colors.white,
  },
});

interface IPage {
  loading?: boolean;
  marginTop?: number;
  marginBottom?: number;
  background?: string;
}

export const Page: React.FC<IPage> = (props) => {
  const { loading, marginTop, marginBottom, background, children } = props;
  const styles = useStyles({ marginTop, marginBottom, background });

  return (
    <Grid container item xs={12} className={styles.fullPage}>
      {loading ? (
        <Grid container item xs={12} justifyContent="center" alignItems="center">
          <CircularProgress color="secondary" size={64} />
        </Grid>
      ) : (
        <Grid item xs={12}>
          {children}
        </Grid>
      )}
    </Grid>
  );
};
