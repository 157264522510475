import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Page } from '../../Components/General/Page';
import { BrancherDispatch, UtilGetProgramCurrentPairs } from '../../store/actions';
import { Colors } from '../../consts/colors';
import { Text } from '../../Components/General/Text';
import { EMeetingFrequencyHealth, IAllProgramPairsData } from '../../store/actions/PairingActions';
import { BrancherLinkText } from '../../Components/General/BrancherLinkText';
import { RouteMaster } from '../../Components/Routing';
import { BrancherButton } from '../../Components/InputFields/BrancherButton';
import { EmojiRate } from '../../Components/General/EmojiRate';

enum EPairsTableFilterType {
  ON_TRACK = 'onTrack',
  TO_WATCH = 'toWatch',
  TOO_EARLY = 'tooEarly',
  NO_MEETINGS = 'noMeetings',
}

interface ICustomToolbar {
  clearFilters: () => void;
  setFilter: (filterType: EPairsTableFilterType) => void;
  selectedFilter: EPairsTableFilterType;
}

const CustomToolbar: React.FC<ICustomToolbar> = ({ clearFilters, setFilter, selectedFilter }) => {
  return (
    <GridToolbarContainer>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={3} sm={2}>
          <GridToolbarDensitySelector />
        </Grid>
        <Grid
          item
          xs={8}
          sm={10}
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={3}
        >
          <Grid item>
            <BrancherButton
              color="secondary"
              variant="outlined"
              onClick={clearFilters}
              size="small"
            >
              Clear Filters
            </BrancherButton>
          </Grid>
          <Grid item>
            <BrancherButton
              color={selectedFilter === EPairsTableFilterType.ON_TRACK ? 'primary' : 'secondary'}
              variant={selectedFilter === EPairsTableFilterType.ON_TRACK ? 'contained' : 'outlined'}
              onClick={() => setFilter(EPairsTableFilterType.ON_TRACK)}
              size="small"
            >
              On Track
            </BrancherButton>
          </Grid>
          <Grid item>
            <BrancherButton
              color={selectedFilter === EPairsTableFilterType.TO_WATCH ? 'primary' : 'secondary'}
              variant={selectedFilter === EPairsTableFilterType.TO_WATCH ? 'contained' : 'outlined'}
              onClick={() => setFilter(EPairsTableFilterType.TO_WATCH)}
              size="small"
            >
              To Watch
            </BrancherButton>
          </Grid>
          <Grid item>
            <BrancherButton
              color={selectedFilter === EPairsTableFilterType.TOO_EARLY ? 'primary' : 'secondary'}
              variant={
                selectedFilter === EPairsTableFilterType.TOO_EARLY ? 'contained' : 'outlined'
              }
              onClick={() => setFilter(EPairsTableFilterType.TOO_EARLY)}
              size="small"
            >
              Too Early to Tell
            </BrancherButton>
          </Grid>
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
};

const useStyles = makeStyles({
  dataGrid: {
    width: '100%',
    minWidth: '100%',
    height: '100vh',
    '& .MuiSelect-select': {
      boxShadow: 'none !important',
      '&:focus': {
        color: `${Colors.black} !important`,
        backgroundColor: `${Colors.transparent} !important`,
      },
    },
  },
});

export const Pairs = () => {
  const [gettingPairs, setGettingPairs] = React.useState<boolean>(false);
  const [programPairs, setProgramPairs] = React.useState<IAllProgramPairsData[]>([]);
  const history = useHistory();
  const meetingSatisfactionParam = new URLSearchParams(useLocation().search)?.get('ms');
  const meetingFrequencyParam = new URLSearchParams(useLocation().search)?.get('mf');
  const showOnTrack = meetingFrequencyParam === EMeetingFrequencyHealth.ON_TRACK;
  const showToWatch = meetingFrequencyParam === EMeetingFrequencyHealth.TO_WATCH;
  const showTooEarly = meetingFrequencyParam === EMeetingFrequencyHealth.TOO_EARLY;
  const [useFilter, setUseFilter] = React.useState<boolean>(
    showOnTrack || showTooEarly || showToWatch,
  );
  const [filter, setFilter] = React.useState<any>(null);
  const [selectedFilterType, setSelectedFilterType] = React.useState<EPairsTableFilterType>(null);
  const dispatch = useDispatch();
  const styles = useStyles();

  const createMeetingSatisfactionFilterState = (pairs: IAllProgramPairsData[]) => {
    const satisfactionFilterParam = Number(meetingSatisfactionParam);
    const isNeutral = satisfactionFilterParam === 3;
    const filteredBySatisfactionPairs = pairs.filter((f) =>
      isNeutral
        ? Number(f.averageMenteeRating) === 3.0 || Number(f.averageMentorRating) === 3.0
        : (Number(f.averageMenteeRating) >= satisfactionFilterParam - 0.9 &&
            Number(f.averageMenteeRating) <= satisfactionFilterParam) ||
          (Number(f.averageMentorRating) >= satisfactionFilterParam - 0.9 &&
            Number(f.averageMentorRating) <= satisfactionFilterParam),
    );
    setProgramPairs(filteredBySatisfactionPairs?.length > 0 ? filteredBySatisfactionPairs : []);
  };

  const createMeetingFrequencyFilterState = (filterType: EPairsTableFilterType) => {
    return {
      items: [
        {
          columnField: 'meetingFrequencyHealth',
          operatorValue: 'equals',
          value: filterType,
        },
      ],
    };
  };

  const controlSetFilter = (filterType: EPairsTableFilterType) => {
    setUseFilter(true);
    setSelectedFilterType(filterType);
    if (filterType === EPairsTableFilterType.ON_TRACK) {
      setFilter(createMeetingFrequencyFilterState(filterType));
    } else if (filterType === EPairsTableFilterType.TO_WATCH) {
      setFilter(createMeetingFrequencyFilterState(filterType));
    } else if (filterType === EPairsTableFilterType.TOO_EARLY) {
      setFilter(createMeetingFrequencyFilterState(filterType));
    } else if (filterType === EPairsTableFilterType.NO_MEETINGS) {
      setFilter({
        items: [
          {
            columnField: 'lastMeetingDate',
            operatorValue: 'equals',
            value: '-',
          },
        ],
      });
    }
  };

  const controlClearFilters = () => {
    history.replace({
      search: '',
    });
    window.location.reload();
  };

  const columns: GridColDef[] = [
    {
      field: 'menteeName',
      headerName: 'Mentee Name',
      width: 180,
      sortable: true,
      renderCell: (grid) => (
        <BrancherLinkText
          variant="xxs"
          href={`${RouteMaster.user.path}?r=${grid.row.mentee}`}
          underline="always"
        >
          {grid.value}
        </BrancherLinkText>
      ),
    },
    {
      field: 'mentorName',
      headerName: 'Mentor Name',
      width: 180,
      sortable: true,
      renderCell: (grid) => (
        <BrancherLinkText
          variant="xxs"
          href={`${RouteMaster.user.path}?r=${grid.row.mentor}`}
          underline="always"
        >
          {grid.value}
        </BrancherLinkText>
      ),
    },
    {
      field: 'relationshipLength',
      headerName: 'Relationship Length',
      width: 220,
      sortable: true,
    },
    {
      field: 'totalMeetings',
      headerName: 'Meetings Amount',
      width: 200,
      sortable: true,
    },
    {
      field: 'meetingFrequencyHealth',
      headerName: 'Meeting Health',
      width: 180,
      sortable: true,
      renderCell: (grid) => (
        <Text variant="xxs">
          {grid.value === EMeetingFrequencyHealth.ON_TRACK
            ? 'On Track'
            : grid.value === EMeetingFrequencyHealth.TO_WATCH
            ? 'To Watch'
            : 'Too Early to Tell'}
        </Text>
      ),
    },
    {
      field: 'lastMeetingDate',
      headerName: 'Last Meeting',
      width: 180,
      sortable: true,
    },
    {
      field: 'nextMeetingDate',
      headerName: 'Next Meeting',
      width: 180,
      sortable: true,
    },
    {
      field: 'averageMeetingRating',
      headerName: 'Average Meeting Meeting',
      width: 220,
      sortable: true,
      renderCell: (grid) => <EmojiRate variant="xxs" rating={grid.value.toString()} />,
    },
    {
      field: 'averageMenteeRating',
      headerName: 'Mentee Meeting Average Rating',
      width: 220,
      sortable: true,
      renderCell: (grid) => <EmojiRate variant="xxs" rating={grid.value.toString()} />,
    },
    {
      field: 'averageMentorRating',
      headerName: 'Mentor Meeting Average Rating',
      width: 220,
      sortable: true,
      renderCell: (grid) => <EmojiRate variant="xxs" rating={grid.value.toString()} />,
    },
    {
      field: 'id',
      headerName: 'Actions',
      width: 200,
      sortable: true,
      renderCell: (grid) => (
        <BrancherLinkText
          variant="xxs"
          href={`${RouteMaster.pair.path}?mentee=${grid.row.mentee}&mentor=${grid.row.mentor}`}
          underline="always"
        >
          View Pair
        </BrancherLinkText>
      ),
    },
  ];

  React.useEffect(() => {
    setGettingPairs(true);
    BrancherDispatch(
      dispatch,
      UtilGetProgramCurrentPairs((allPairs) => {
        if (allPairs.success) {
          setProgramPairs(allPairs?.data?.length > 0 ? allPairs.data : []);
        }
        if (useFilter) {
          const filter = showToWatch
            ? EPairsTableFilterType.TO_WATCH
            : showTooEarly
            ? EPairsTableFilterType.TOO_EARLY
            : EPairsTableFilterType.ON_TRACK;
          controlSetFilter(filter);
        }
        if (!!meetingSatisfactionParam) {
          createMeetingSatisfactionFilterState(allPairs.data);
        }
        setGettingPairs(false);
      }),
    );
  }, []);

  return (
    <Page loading={gettingPairs}>
      <Grid container justifyContent="center">
        <Grid container item xs={12}>
          <Box className={styles.dataGrid}>
            {useFilter && programPairs?.length > 0 ? (
              <DataGrid
                rows={programPairs}
                columns={columns}
                pageSize={30}
                disableSelectionOnClick
                components={{
                  Toolbar: () => (
                    <CustomToolbar
                      clearFilters={controlClearFilters}
                      setFilter={controlSetFilter}
                      selectedFilter={selectedFilterType}
                    />
                  ),
                }}
                filterModel={{ ...filter }}
              />
            ) : (
              <DataGrid
                rows={programPairs}
                columns={columns}
                pageSize={30}
                disableSelectionOnClick
                components={{
                  Toolbar: () => (
                    <CustomToolbar
                      clearFilters={controlClearFilters}
                      setFilter={controlSetFilter}
                      selectedFilter={selectedFilterType}
                    />
                  ),
                }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Page>
  );
};
