import { Colors } from '../consts/colors';
import { IBrancherThemePalette } from './BrancherTheme';

export const MuiSwitch = (theme: IBrancherThemePalette) => ({
  switchBase: {
    color: Colors.lightPurple,
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  colorPrimary: {
    '&.Mui-checked': {
      color: theme.palette.primary.main,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
});
