import * as React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { BrancherDivider } from '../../Components/General/BrancherDivider';
import { Text } from '../../Components/General/Text';
import { IUserRoleInfo } from './User';
import { BrancherLinkText } from '../../Components/General/BrancherLinkText';
import { GetRoute } from '../../Components/Routing';

interface IUserGroups extends Pick<IUserRoleInfo, 'userGroups'> {}

export const UserRoleGroups: React.FC<IUserGroups> = ({ userGroups }) => {
  return (
    <>
      {userGroups?.length > 0 && (
        <Grid item xs={12}>
          <Grid item xs={12}>
            <BrancherDivider marginTop={30} marginBottom={30} />
          </Grid>
          <Grid item xs={12}>
            <Box mb={1}>
              <Text variant="sm" fontWeight={600} color="purple" display="inline">
                Groups{' '}
              </Text>
              <Text variant="xs" fontWeight={500} display="inline">
                ({userGroups?.length ?? 0})
              </Text>
            </Box>
          </Grid>

          <Grid container spacing={2}>
            {userGroups?.map((ug) => (
              <Grid
                container
                item
                key={ug.groupId}
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
              >
                <Grid item xs={10}>
                  <Text variant="sm" fontWeight={500} display="inline" color="purple">
                    Group:{' '}
                  </Text>
                  <BrancherLinkText
                    href={`${GetRoute('group').path}?g=${ug.groupId}`}
                    variant="sm"
                    fontWeight={500}
                    display="inline"
                    underline="always"
                    color="secondaryGrey"
                  >
                    {ug.title}
                  </BrancherLinkText>
                </Grid>
                <Grid item xs={4}>
                  <Text variant="sm" fontWeight={500} display="inline" color="purple">
                    Group Leader:{' '}
                  </Text>
                  <Text variant="sm" fontWeight={500} display="inline">
                    {ug.ownerName}
                  </Text>
                </Grid>
                <Grid item xs={3}>
                  <Text variant="sm" fontWeight={500} display="inline" color="purple">
                    Mentees:{' '}
                  </Text>
                  <Text variant="sm" fontWeight={500} display="inline">
                    {ug.mentees.length}
                  </Text>
                </Grid>
                <Grid item xs={3}>
                  <Text
                    variant="sm"
                    fontWeight={500}
                    display="inline"
                    marginLeft={30}
                    color="purple"
                  >
                    Mentors:{' '}
                  </Text>
                  <Text variant="sm" fontWeight={500} display="inline">
                    {ug.mentors.length}
                  </Text>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </>
  );
};
