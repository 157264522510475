import { Colors } from '../consts/colors';
import { IBrancherThemePalette } from './BrancherTheme';

export const MuiTextField = (theme: IBrancherThemePalette) => ({
  root: {
    marginTop: 10,
    marginBottom: 10,
    lineHeight: 1.2,
    '& .MuiInputLabel-root': {
      color: Colors.lightGrey,
      fontSize: 22,
    },
    '& .MuiInput-root': {
      '&.Mui-error': {
        background: Colors.lightRed,
      },
    },
    '& .MuiInputBase-input': {
      color: theme.palette.primary.main,
      fontSize: 22,
      fontWeight: 600,
      '&::placeholder': {
        color: Colors.placeholderGrey,
        fontWeight: 500,
      },
      '&:-webkit-autofill': {
        color: `${theme.palette.primary.main} !important`,
        backgroundColor: `${Colors.transparent} !important`,
        '-webkit-box-shadow': `0 0 0 50px ${Colors.white} inset`,
        '-webkit-text-fill-color': theme.palette.primary.main,
      },
      '&:-webkit-autofill:focus': {
        color: `${theme.palette.primary.main} !important`,
        backgroundColor: `${Colors.transparent} !important`,
        '-webkit-box-shadow': `0 0 0 50px ${Colors.white} inset`,
        '-webkit-text-fill-color': theme.palette.primary.main,
      },
    },
    '& .MuiFormLabel-root': {
      color: Colors.lightGrey,
      fontSize: 21, // TODO: Why does 22px not work?!
      '&.MuiInputLabel-shrink': {
        color: Colors.black,
        fontWeight: 600,
      },
    },
    '&.Mui-focused': {
      color: theme.palette.primary.main,
    },
    '& .MuiInput-underline': {
      '&:before': {
        borderBottomColor: theme.palette.primary.main,
        opacity: 0.42,
      },
      '&:after': {
        borderBottomColor: theme.palette.primary.main,
      },
      '&:hover': {
        '&:not(.Mui-disabled)': {
          '&:before': {
            borderBottomColor: theme.palette.primary.main,
          },
        },
      },
    },
  },
});
