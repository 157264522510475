import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Update from '@material-ui/icons/Update';
import { useDispatch } from 'react-redux';
import { BrancherDispatch, UtilUpdateCompany } from '../../store/actions';
import { BrancherTextField } from '../../Components/InputFields/BrancherTextField';
import { ActionButton, BrancherButton } from '../../Components/InputFields/BrancherButton';
import { Text } from '../../Components/General/Text';
import { BrancherSwitch } from '../../Components/InputFields/BrancherSwitch';
import { BrancherFileUpload } from '../../Components/InputFields/BrancherFileUpload';
import { UpdatableCompanyInfo } from '../../store/reducers/CompaniesReducer';
import { ICompanyInfo } from '../../store/reducers/UserInfoReducer';
import { GetLinkBase } from '../../helpers/LinkHelpers';
import Add from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import { DeleteForever } from '@material-ui/icons';

const useStyles = makeStyles({
  acceptedFile: {
    maxWidth: 150,
    maxHeight: 150,
    borderRadius: 5,
  },
});

interface IUpdateCompany {
  companyInfo: ICompanyInfo;
}

export const UpdateCompany: React.FC<IUpdateCompany> = ({ companyInfo }) => {
  const [companyName, setCompanyName] = React.useState<string>(
    companyInfo?.[UpdatableCompanyInfo.COMPANY_NAME],
  );
  const [updatingCompany, setUpdatingCompany] = React.useState<boolean>(false);
  const [googleSSO, setGoogleSSO] = React.useState<boolean>(
    companyInfo?.[UpdatableCompanyInfo.GOOGLE_SSO],
  );
  const [samlSignOn, setSamlSignOn] = React.useState<boolean>(
    companyInfo?.[UpdatableCompanyInfo.SAML_SSO],
  );
  const [passwordAuth, setPasswordAuth] = React.useState<boolean>(
    companyInfo?.[UpdatableCompanyInfo.PASSWORD_AUTH],
  );
  const [whiteLabel, setWhiteLabel] = React.useState<boolean>(
    companyInfo?.[UpdatableCompanyInfo.WHITE_LABEL],
  );
  const [disabled, setDisabled] = React.useState<boolean>(
    companyInfo?.[UpdatableCompanyInfo.DISABLED],
  );
  const [logo, setLogo] = React.useState<string>(companyInfo?.[UpdatableCompanyInfo.CUSTOM_LOGO]);
  const [domainEmail, setDomainEmail] = React.useState<string>('');
  const [domainEmails, setDomainEmails] = React.useState<string[]>(
    companyInfo?.[UpdatableCompanyInfo.DOMAIN_EMAIL] ?? [],
  );
  const styles = useStyles();
  const dispatch = useDispatch();

  const UpdateCompany = () => {
    setUpdatingCompany(true);
    BrancherDispatch(
      dispatch,
      UtilUpdateCompany(
        companyName,
        googleSSO,
        samlSignOn,
        passwordAuth,
        whiteLabel,
        logo,
        companyInfo?.[UpdatableCompanyInfo.CUSTOM_LOGO] !== logo,
        domainEmails,
        disabled,
        () => {
          setUpdatingCompany(false);
        },
      ),
    );
  };

  const controlRemoveCompanyDomain = (index: number) => {
    setDomainEmails(domainEmails.filter((m, i) => i !== index));
  };

  const controlAddCompanyDomain = () => {
    if (domainEmail !== '') {
      setDomainEmails([...domainEmails, domainEmail]);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={600}>
          Update Company
        </Text>
      </Grid>
      <Grid item xs={12}>
        <BrancherTextField
          value={companyName}
          updateValue={setCompanyName}
          placeholder="New company name"
          label="Company Name"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} container>
        {domainEmails?.length > 0 &&
          domainEmails?.map((domain, domainIndex) => (
            <Grid item xs={10}>
              <Text variant="sm" display="inline" marginRight={30}>
                {domain}
              </Text>
              <IconButton onClick={() => controlRemoveCompanyDomain(domainIndex)}>
                <DeleteForever color="error" />
              </IconButton>
            </Grid>
          ))}
        <Grid item>
          <AddDomainItem
            value={domainEmail}
            setValue={setDomainEmail}
            addValue={controlAddCompanyDomain}
            label={`Domain ${domainEmails.length + 1}`}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} justifyContent="space-between" alignItems="center">
        <Text variant="sm" fontWeight={600}>
          Google sign on?
        </Text>
        <BrancherSwitch value={googleSSO} updateValue={setGoogleSSO} ariaLabel="googleSSO" />
      </Grid>
      <Grid item container xs={12} justifyContent="space-between" alignItems="center">
        <Text variant="sm" fontWeight={600}>
          SAML sign on?
        </Text>
        <BrancherSwitch value={samlSignOn} updateValue={setSamlSignOn} ariaLabel="samlSignOn" />
      </Grid>
      <Grid item container xs={12} justifyContent="space-between" alignItems="center">
        <Text variant="sm" fontWeight={600}>
          Password Authentication?
        </Text>
        <BrancherSwitch
          value={passwordAuth}
          updateValue={setPasswordAuth}
          ariaLabel="passwordAuth"
        />
      </Grid>
      <Grid item container xs={12} justifyContent="space-between" alignItems="center">
        <Text variant="sm" fontWeight={600}>
          White labelled?
        </Text>
        <BrancherSwitch value={whiteLabel} updateValue={setWhiteLabel} ariaLabel="whiteLabel" />
      </Grid>
      <Grid item container xs={12} justifyContent="space-between" alignItems="center">
        <Text variant="sm" fontWeight={600}>
          Disabled?
        </Text>
        <BrancherSwitch value={disabled} updateValue={setDisabled} ariaLabel="disabled" />
      </Grid>
      {whiteLabel && (
        <Grid item container xs={12} justifyContent="space-between" alignItems="center">
          <Text variant="sm" fontWeight={600}>
            Custom Logo?
          </Text>
          {companyInfo?.[UpdatableCompanyInfo.CUSTOM_LOGO] && (
            <Box display="inline" marginRight={3}>
              <Grid container alignItems="center">
                <Text variant="sm" fontWeight={600}>
                  Current:
                </Text>
                <img
                  src={`${GetLinkBase()}${companyInfo?.[UpdatableCompanyInfo.CUSTOM_LOGO]}`}
                  alt={`${companyName}-logo`}
                  className={styles.acceptedFile}
                />
              </Grid>
            </Box>
          )}
          <BrancherFileUpload
            value={logo}
            name={`${companyName}-logo`}
            updateValue={setLogo}
            ratio={1.2}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <ActionButton
          startIcon={<Update color="secondary" />}
          loading={updatingCompany}
          onClick={UpdateCompany}
          disabled={!companyName}
        >
          Update Company
        </ActionButton>
      </Grid>
    </Grid>
  );
};

interface IAddDomainItem {
  setValue: (value: string) => void;
  addValue: (domain: string) => void;
  value: string;
  label: string;
}

export const AddDomainItem: React.FC<IAddDomainItem> = ({ setValue, value, addValue, label }) => {
  return (
    <Grid item xs={12} container alignItems="center" justifyContent="space-between">
      <Grid item xs={8}>
        <BrancherTextField
          fullWidth
          value={value}
          placeholder="company.com"
          updateValue={setValue}
          name="domainItem"
          id="domainItem"
          label={label}
        />
      </Grid>
      <Grid item xs={3} container spacing={1}>
        <BrancherButton size="small" startIcon={<Add />} onClick={() => addValue(value)}>
          Add domain
        </BrancherButton>
      </Grid>
    </Grid>
  );
};
