import * as React from 'react';
import Box from '@material-ui/core/Box';
import FileCopy from '@material-ui/icons/FileCopy';
import Grid from '@material-ui/core/Grid';
import { QRCodeCanvas } from 'qrcode.react';
import { useDispatch } from 'react-redux';
import { BrancherDialog } from '../../Components/General/BrancherDialog';
import { BrancherSnackbar } from '../../Components/General/BrancherSnackbar';
import { Text } from '../../Components/General/Text';
import {
  AcceptButton,
  BrancherButton,
  NextButton,
} from '../../Components/InputFields/BrancherButton';
import { BrancherTextField } from '../../Components/InputFields/BrancherTextField';
import { UtilMFASessionVerification, UtilMFASetupVerification } from '../../store/actions';
import { EMFAChallengeNames, IUtilLoginUserResponse } from '../../store/actions/UserInfoActions';
import { BrancherList } from '../../Components/General/BrancherList';

interface IMFAVerify {
  accessToken: string;
  challengeName: EMFAChallengeNames;
  setResume: (login?: boolean, waiting?: boolean) => void;
  username: string;
  programId: string;
  session?: string;
  secretCode?: string;
  setSessionInformation?: (session: IUtilLoginUserResponse['data']) => void;
}

// TODO: fix up this type to work with challengeName = SOFTWARE_TOKEN_MFA and session being mandatory

export const MFAVerify: React.FC<IMFAVerify> = (props) => {
  const {
    accessToken,
    secretCode,
    challengeName,
    setResume,
    username,
    session,
    setSessionInformation,
    programId,
  } = props;
  const [open, setOpen] = React.useState<boolean>(true);
  const [sendingVerification, setSendingVerification] = React.useState<boolean>(false);
  const [setupStep, setSetupStep] = React.useState<EMFAChallengeNames>(challengeName);
  const [verifyCode, setVerifyCode] = React.useState<string>('');
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [snackbarOpen, setSnackbar] = React.useState<boolean>(false);
  const dispatch = useDispatch();

  const isMFASetupFlow = challengeName === EMFAChallengeNames.MFA_SETUP;

  const sendVerifyChallenge = () => {
    setSendingVerification(true);
    setErrorMessage('');
    if (isMFASetupFlow) {
      dispatch(
        UtilMFASetupVerification(verifyCode, accessToken, (res) => {
          setSendingVerification(false);
          if (res.success) {
            setOpen(false);
            setResume(true);
          } else {
            setErrorMessage(res.message);
          }
        }),
      );
    } else {
      dispatch(
        UtilMFASessionVerification(verifyCode, session, username, programId, (res) => {
          setSendingVerification(false);
          if (res.success) {
            setOpen(false);
            setResume();
            setSessionInformation(res.data);
          } else {
            setErrorMessage(res.message);
          }
        }),
      );
    }
  };

  const controlCloseDialog = () => {
    setResume(false, false);
    setOpen(false);
  };

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(secretCode);
    setSnackbar(true);
  };

  return (
    <BrancherDialog
      setClose={controlCloseDialog}
      title={setupStep === EMFAChallengeNames.MFA_SETUP ? 'MFA Setup' : 'MFA Authentication'}
      labelledBy="mfa authentication"
      open={open}
    >
      <BrancherSnackbar
        controlClose={setSnackbar}
        open={snackbarOpen}
        message="Authenticator link copied!"
      />
      {setupStep === EMFAChallengeNames.MFA_SETUP ? (
        <>
          <Grid container justifyContent="center">
            <Grid item>
              <BrancherList
                listItems={[
                  <Text variant="sm" fontWeight={600} marginBottom={10}>
                    Open your Authenticator app.
                    <br />
                    (If you don’t have this, install an authenticator app such as Google
                    Authenticator or Microsoft Authenticator)
                  </Text>,
                  'In the app, select add an account',
                  'Scan QR code below',
                ]}
              />
            </Grid>
          </Grid>
          <Grid item container justifyContent="center">
            <QRCodeCanvas
              value={`otpauth://totp/${username}?secret=${secretCode}&issuer=Brancher-TOTP-MFA`}
              size={128}
              width={128}
              height={128}
            />
          </Grid>
          <Grid container justifyContent="center" alignItems="center" direction="column">
            <Text variant="sm" color="secondaryGrey" marginBottom={10} marginTop={30}>
              If you cannot scan the QR code, enter this setup key:
            </Text>
            <BrancherButton
              size="small"
              variant="text"
              onClick={copyToClipboard}
              startIcon={<FileCopy />}
            >
              {secretCode}
            </BrancherButton>
          </Grid>
          <Box mt={4}>
            <Grid item container justifyContent="flex-end" xs={12}>
              <Grid item>
                <NextButton onClick={() => setSetupStep(EMFAChallengeNames.SOFTWARE_TOKEN_MFA)}>
                  Verify MFA Setup
                </NextButton>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        <>
          <Grid container justifyContent="center">
            <Grid item>
              <Text variant="sm" color="secondaryGrey" marginBottom={25}>
                Please enter the MFA code from your authenticator app
                {isMFASetupFlow && ' to verify MFA setup'}
              </Text>
            </Grid>
          </Grid>
          <Grid item>
            <BrancherTextField
              value={verifyCode}
              updateValue={setVerifyCode}
              label="Verification Code"
              placeholder="000000"
              fullWidth
            />
          </Grid>
          {errorMessage && (
            <Grid item container justifyContent="center">
              <Grid item>
                <Text
                  align="center"
                  variant="sm"
                  color="red"
                  fontWeight={500}
                  marginTop={20}
                  marginBottom={5}
                >
                  {errorMessage}
                </Text>
                {!isMFASetupFlow && (
                  <Text align="center" variant="xs" fontWeight={600} marginBottom={15}>
                    If this is your first time logging in after setting up MFA, wait until a new
                    time based code appears in your Authenticator app, and input that above.
                  </Text>
                )}
              </Grid>
            </Grid>
          )}
          <Grid item container justifyContent="flex-end" xs={12}>
            <Grid item>
              <AcceptButton
                disabled={sendingVerification || !(verifyCode?.length === 6)}
                onClick={sendVerifyChallenge}
              >
                Yes
              </AcceptButton>
            </Grid>
          </Grid>
        </>
      )}
    </BrancherDialog>
  );
};
