import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '../../../Components/General/Text';
import { BrancherToggleButton } from '../../../Components/InputFields/ToggleButton/BrancherToggleButton';
import { IStoreTypes } from '../../../store/storeTypes';
import { ProgramPositionOptions, ProgramPositions } from '../../../consts/ProgramPositionOptions';
import { SaveApplicationFormData } from '../../../store/actions/ProgramActions';
import { EApplicationFormSections } from '../../../store/reducers/ProgramReducer';

const useStyles = makeStyles({
  marginTop: {
    marginTop: 50,
  },
});

export const ChooseProgramPosition = () => {
  const position = useSelector(
    (state: IStoreTypes) => state.program?.applicationForm?.qualifying?.position,
  );
  const customisedRoleLabels = useSelector(
    (state: IStoreTypes) => state?.program?.applicationForm?.customisedRoleLabels,
  );
  const dispatch = useDispatch();
  const styles = useStyles();

  const CustomisedLabelOptions =
    customisedRoleLabels === undefined
      ? ProgramPositionOptions
      : [
          { value: ProgramPositions.mentee, label: customisedRoleLabels[0] },
          { value: ProgramPositions.mentor, label: customisedRoleLabels[1] },
        ];

  return (
    <Grid container justifyContent="center" className={styles.marginTop}>
      <Grid container direction="column" justifyContent="center" item xs={10}>
        <Grid item>
          <Text variant="xl" align="center" marginBottom={10}>
            What program position are you applying for?
          </Text>
        </Grid>
        <Grid container item justifyContent="center">
          <Text variant="sm" display="inline">
            Choose{' '}
          </Text>
          <Text variant="sm" display="inline" fontWeight={500}>
            '{CustomisedLabelOptions[0].label}'
          </Text>
          <Text variant="sm" display="inline">
            {' '}
            if you want to get support, advice and guidance.
          </Text>
        </Grid>
        <Grid container item justifyContent="center">
          <Text variant="sm" display="inline">
            {' '}
            Choose{' '}
          </Text>
          <Text variant="sm" display="inline" fontWeight={500}>
            '{CustomisedLabelOptions[1].label}'
          </Text>
          <Text variant="sm" display="inline" marginBottom={10}>
            {' '}
            if you want to give support, advice and guidance.
          </Text>
        </Grid>
        <Grid item>
          <BrancherToggleButton
            name="position"
            value={position}
            halfWidth
            exclusive
            updateValue={(a: string) =>
              dispatch(
                SaveApplicationFormData(EApplicationFormSections.QUALIFYING, { position: a }),
              )
            }
            options={CustomisedLabelOptions}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
