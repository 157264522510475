import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch } from 'react-redux';
import Add from '@material-ui/icons/Add';
import { BrancherDispatch, UtilAddUserToGroup, UtilGetAllProgramUsers } from '../../store/actions';
import { BrancherDialog } from '../../Components/General/BrancherDialog';
import { BrancherButton, SaveButton } from '../../Components/InputFields/BrancherButton';
import {
  BrancherAutoComplete,
  IBrancherAutoCompleteOption,
} from '../../Components/InputFields/BrancherAutoComplete';
import { IGroupMember } from './Groups';
import { ProgramPositions } from '../../consts/ProgramPositionOptions';
import { IExtendedGroupData } from './Group';
import { BrancherCheckbox } from '../../Components/InputFields/BrancherCheckbox';

interface IAddMentorsToGroup {
  updateGroupData: (group: IExtendedGroupData) => void;
  currentMentors: IGroupMember[];
  groupId: string;
}

export const AddMentorToGroup: React.FC<IAddMentorsToGroup> = ({
  updateGroupData,
  groupId,
  currentMentors = [],
}) => {
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [editingGroup, setEditingGroup] = React.useState<boolean>(false);
  const [openEditingGroup, setOpenEditingGroup] = React.useState<boolean>(false);
  const [gettingUsers, setGettingUsers] = React.useState<boolean>(false);
  const [isOwner, setIsOwner] = React.useState<boolean>(false);
  const [mentorList, setMentorList] = React.useState<IBrancherAutoCompleteOption[]>([]);
  const [mentor, setMentor] = React.useState<IBrancherAutoCompleteOption>(null);
  const dispatch = useDispatch();

  React.useEffect(() => {
    setGettingUsers(true);
    BrancherDispatch(
      dispatch,
      UtilGetAllProgramUsers((userData) => {
        if (userData.success) {
          let allMentors = [];
          userData.data.forEach((user) => {
            if (user.hasMentorPosition) {
              if (!currentMentors.find((cm) => cm.roleId === user.mentorRoleId)) {
                allMentors.push({
                  value: user.mentorRoleId,
                  label: `${user.firstName} ${user.lastName}`,
                });
              }
            }
          });
          setMentorList(allMentors);
        }
        setGettingUsers(false);
      }),
    );
  }, [currentMentors]);

  const convertToGroupMember = (): IGroupMember => {
    return { roleId: mentor.value, name: mentor.label, joined: new Date().getTime() };
  };

  const editGroup = () => {
    setEditingGroup(true);
    BrancherDispatch(
      dispatch,
      UtilAddUserToGroup(
        convertToGroupMember(),
        ProgramPositions.mentor,
        groupId,
        isOwner,
        (groupRes) => {
          setEditingGroup(false);
          if (groupRes.success) {
            updateGroupData(groupRes.data);
          }
          setMentor(null);
          setMentorList([]);
          setOpenEditingGroup(false);
        },
      ),
    );
  };

  return (
    <>
      <BrancherDialog
        keepMounted={false}
        contentWidth
        open={openEditingGroup}
        setClose={() => setOpenEditingGroup(false)}
        labelledBy="edit-group"
        title="Add mentor to group"
        fullScreen={mobile}
      >
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={10}>
            <BrancherAutoComplete
              fullWidth
              value={mentor}
              updateValue={(a: IBrancherAutoCompleteOption) => setMentor(a)}
              label="Add a mentor"
              name="mentor"
              options={mentorList}
            />
          </Grid>
          <Grid item xs={10}>
            <BrancherCheckbox updateValue={setIsOwner} value={isOwner} label="Is group owner" />
          </Grid>
          <Grid item container justifyContent="flex-end">
            <SaveButton onClick={editGroup} loading={editingGroup}>
              Save
            </SaveButton>
          </Grid>
        </Grid>
      </BrancherDialog>
      <Grid item xs={12}>
        <BrancherButton
          startIcon={<Add color="secondary" />}
          size="small"
          color="primary"
          onClick={() => setOpenEditingGroup(true)}
          disabled={gettingUsers}
          id="add-mentor-to-group"
        >
          Add mentor to group
        </BrancherButton>
      </Grid>
    </>
  );
};
