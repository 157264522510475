import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch } from 'react-redux';
import {
  BrancherDispatch,
  UtilCreateProgramGroup,
  UtilGetAllProgramUsers,
} from '../../store/actions';
import { BrancherDialog } from '../../Components/General/BrancherDialog';
import { BrancherTextField } from '../../Components/InputFields/BrancherTextField';
import { BrancherButton, CreateButton } from '../../Components/InputFields/BrancherButton';
import {
  BrancherAutoComplete,
  IBrancherAutoCompleteOption,
} from '../../Components/InputFields/BrancherAutoComplete';
import { IGroup, IGroupMember } from './Groups';
import { RouteMaster } from '../../Components/Routing';

interface ICreateGroup {
  setNewGroup: (group: IGroup) => void;
}

export const CreateGroup: React.FC<ICreateGroup> = ({ setNewGroup }) => {
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [creatingGroup, setCreatingGroup] = React.useState<boolean>(false);
  const [openCreatingGroup, setOpenCreatingGroup] = React.useState<boolean>(false);
  const [gettingUsers, setGettingUsers] = React.useState<boolean>(false);
  const [owner, setOwner] = React.useState<IBrancherAutoCompleteOption>();
  const [ownerList, setOwnerList] = React.useState<IBrancherAutoCompleteOption[]>([]);
  const [mentorList, setMentorList] = React.useState<IBrancherAutoCompleteOption[]>([]);
  const [menteeList, setMenteeList] = React.useState<IBrancherAutoCompleteOption[]>([]);
  const [mentors, setMentors] = React.useState<IBrancherAutoCompleteOption[]>([]);
  const [mentees, setMentees] = React.useState<IBrancherAutoCompleteOption[]>([]);
  const [title, setTitle] = React.useState<string>("[Owner]'s mentoring group");
  const dispatch = useDispatch();

  React.useEffect(() => {
    setGettingUsers(true);
    BrancherDispatch(
      dispatch,
      UtilGetAllProgramUsers((userData) => {
        if (userData.success) {
          let allMentees = [];
          let allMentors = [];
          let allOwners = [];
          userData.data.forEach((user) => {
            if (user.hasMenteePosition) {
              allMentees.push({
                value: user.menteeRoleId,
                label: `${user.firstName} ${user.lastName}`,
              });
            }
            if (user.hasMentorPosition) {
              allMentors.push({
                value: user.mentorRoleId,
                label: `${user.firstName} ${user.lastName}`,
              });
              allOwners.push({ value: user.id, label: `${user.firstName} ${user.lastName}` });
            }
          });
          setMenteeList(allMentees);
          setMentorList(allMentors);
          setOwnerList(allOwners);
        }
        setGettingUsers(false);
      }),
    );
  }, []);

  const controlAddMentors = (mentors: IBrancherAutoCompleteOption[]) => {
    if (!mentors.find((owner) => owner.label === owner.label)) {
      setOwner(null);
    }
    setMentors(mentors);
  };

  const convertToGroupMember = (fieldOptions: IBrancherAutoCompleteOption[]): IGroupMember[] => {
    return fieldOptions.map((option) => ({
      roleId: option.value,
      name: option.label,
      joined: new Date().getTime(),
    }));
  };

  const controlAddOwner = (mentor: IBrancherAutoCompleteOption) => {
    const isSelected = mentor?.label;
    const concatenatedOwnerNames = isSelected
      ? mentor?.label?.concat("'s mentoring group")
      : "[Owner]'s mentoring group";
    if (isSelected) {
      const adjustedValueForRoleId = mentorList.find((me) => me.label === mentor.label);
      setMentors([...mentors, adjustedValueForRoleId]);
    }
    setTitle(concatenatedOwnerNames);
    setOwner(mentor);
  };

  const createGroup = () => {
    setCreatingGroup(true);
    BrancherDispatch(
      dispatch,
      UtilCreateProgramGroup(
        {
          title,
          created: new Date().getTime(),
          mentees: convertToGroupMember(mentees),
          mentors: convertToGroupMember(mentors),
          owner: owner.value,
          ownerName: owner.label,
        },
        (groupRes) => {
          setCreatingGroup(false);
          if (groupRes.success) {
            setNewGroup(groupRes.data);
            window.location.assign(`${RouteMaster.group.path}?g=${groupRes.data.groupId}`);
          }
          setOpenCreatingGroup(false);
        },
      ),
    );
  };

  return (
    <>
      <BrancherDialog
        contentWidth
        open={openCreatingGroup}
        setClose={() => setOpenCreatingGroup(false)}
        labelledBy="create-group"
        title="Create Group"
        fullScreen={mobile}
      >
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={10} md={8}>
            <BrancherTextField
              value={title}
              updateValue={setTitle}
              label="Group name"
              name="group-title"
              fullWidth
            />
          </Grid>
          <Grid item xs={10} md={8}>
            <BrancherAutoComplete
              value={owner}
              updateValue={(a: IBrancherAutoCompleteOption) => controlAddOwner(a)}
              label="Assign a group owner"
              name="owner"
              options={ownerList}
            />
          </Grid>
          <Grid item xs={10} md={8}>
            <BrancherAutoComplete
              value={mentors}
              updateValue={(a: IBrancherAutoCompleteOption[]) => controlAddMentors(a)}
              label="Assign mentors"
              name="mentors"
              multiple
              options={mentorList}
            />
          </Grid>
          <Grid item xs={10} md={8}>
            <BrancherAutoComplete
              helpText="You can add mentees after group creation"
              value={mentees}
              updateValue={(a: IBrancherAutoCompleteOption[]) => setMentees(a)}
              label="Assign mentees"
              name="mentees"
              multiple
              options={menteeList}
            />
          </Grid>
          <Grid item container justifyContent="flex-end">
            <CreateButton onClick={createGroup} loading={creatingGroup} disabled={!owner}>
              Create group
            </CreateButton>
          </Grid>
        </Grid>
      </BrancherDialog>
      <Grid item xs={6} md={4}>
        <BrancherButton
          color="primary"
          onClick={() => setOpenCreatingGroup(true)}
          disabled={gettingUsers}
          id="create-group"
        >
          Create new group
        </BrancherButton>
      </Grid>
    </>
  );
};
