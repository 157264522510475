import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { differenceInMonths, format, isPast, parse } from 'date-fns';
import { Text } from '../../Components/General/Text';
import { IProgramPair } from '../../store/actions/PairingActions';
import { EProfileAttributes } from '../../types/ProfileTypes';
import { BrancherDivider } from '../../Components/General/BrancherDivider';
import { Colors } from '../../consts/colors';
import { IMeetingAction, IMeetingAgenda, IMeetingData } from '../../types/MeetingTypes';
import { BrancherDialog } from '../../Components/General/BrancherDialog';
import { BrancherButton } from '../../Components/InputFields/BrancherButton';
import { EmojiRate } from '../../Components/General/EmojiRate';

const useStyles = makeStyles({
  activityCard: {
    padding: 15,
    border: `2px solid ${Colors.purple}`,
    bgcolor: Colors.backgroundGrey,
    borderRadius: 16,
    marginBottom: 30,
  },
});

export const PairMeetingDetails: React.FC<IProgramPair> = ({
  totalActionsCompleted,
  totalTalkingPointsCompleted,
  meetingDetails,
  averageMeetingRating,
  nextMeetingDate,
  lastMeetingDate,
  menteeProfile,
  mentorProfile,
  allCompletedActions,
  allCompletedTalkingPoints,
  pairingStartDate,
  averageMentorRating,
  averageMenteeRating,
  // menteePairAmount, menteeApprovedPairs, mentorPairAmount, mentorApprovedPairs,
}) => {
  const [openMeetingDetails, setOpenMeetingDetails] = React.useState<boolean>(false);
  const menteeRoleId = menteeProfile.roleId;
  const menteeName = menteeProfile.profileData[EProfileAttributes.FIRST_NAME];
  const mentorRoleId = mentorProfile.roleId;
  const mentorName = mentorProfile.profileData[EProfileAttributes.FIRST_NAME];
  const styles = useStyles();
  const pastMeetings = meetingDetails.filter((m) => isPast(new Date(m.datetimeEnd)));

  const lastMeetingDateHealth: number =
    lastMeetingDate !== '-'
      ? differenceInMonths(new Date(parse(lastMeetingDate, 'dd/MM/yyyy', new Date())), new Date())
      : 5;

  return (
    <Grid container className={styles.activityCard} alignItems="center">
      <Grid container item spacing={2} alignItems="center">
        <Grid item xs={6}>
          <Text variant="sm" color="purple" display="inline" fontWeight={500}>
            Last meeting date:{' '}
          </Text>
          <Text variant="sm" fontWeight={500} display="inline">
            {lastMeetingDate}
          </Text>
          {lastMeetingDate !== '-' && lastMeetingDateHealth >= 0 && (
            <EmojiRate
              variant="sm"
              fontWeight={500}
              rating={lastMeetingDateHealth > 5 ? 0 : 5 - lastMeetingDateHealth}
              showRawRating={false}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <Text variant="sm" color="purple" display="inline" fontWeight={500}>
            Next meeting date:{' '}
          </Text>
          <Text variant="sm" fontWeight={500} display="inline">
            {nextMeetingDate}
          </Text>
        </Grid>
        {totalActionsCompleted > 0 && (
          <Grid item xs={6}>
            <Text variant="sm" color="purple" display="inline" fontWeight={500}>
              Meeting actions completed:{' '}
            </Text>
            <Text variant="sm" display="inline" fontWeight={500}>
              {totalActionsCompleted}
            </Text>
          </Grid>
        )}
        {totalTalkingPointsCompleted > 0 && (
          <Grid item xs={6}>
            <Text variant="sm" color="purple" display="inline" fontWeight={500}>
              Talking points completed:{' '}
            </Text>
            <Text variant="sm" display="inline" fontWeight={500}>
              {totalTalkingPointsCompleted}
            </Text>
          </Grid>
        )}
        {averageMenteeRating !== '-' ? (
          <Grid item xs={6}>
            <Text variant="sm" color="purple" display="inline" fontWeight={500}>
              {menteeName}'s average rating:{' '}
            </Text>
            <EmojiRate
              variant="sm"
              fontWeight={500}
              display="inline"
              rating={averageMenteeRating}
            />
          </Grid>
        ) : (
          <Grid item xs={6}>
            <Text variant="sm" fontWeight={500}>
              {menteeName} hasn't provided any meeting survey responses yet
            </Text>
          </Grid>
        )}

        {averageMentorRating !== '-' ? (
          <Grid item xs={6}>
            <Text variant="sm" color="purple" display="inline" fontWeight={500}>
              {mentorName}'s average rating:{' '}
            </Text>
            <EmojiRate
              variant="sm"
              fontWeight={500}
              display="inline"
              rating={averageMentorRating}
            />
          </Grid>
        ) : (
          <Grid item xs={6}>
            <Text variant="sm" fontWeight={500}>
              {mentorName} hasn't provided any meeting survey responses yet
            </Text>
          </Grid>
        )}

        {averageMeetingRating !== '-' ? (
          <Grid item xs={6}>
            <Text variant="sm" color="purple" display="inline" fontWeight={500}>
              Average meeting rating:{' '}
            </Text>
            <EmojiRate
              variant="sm"
              fontWeight={500}
              display="inline"
              rating={averageMeetingRating}
            />
          </Grid>
        ) : (
          <Grid item xs={10} lg={4}>
            <Text variant="sm" fontWeight={500}>
              {menteeName} and {mentorName} haven't provided any meeting survey responses yet
            </Text>
          </Grid>
        )}

        <Grid item xs={6} container justifyContent="flex-end">
          <BrancherButton
            onClick={() => setOpenMeetingDetails(true)}
            size="small"
            color="secondary"
          >
            All meeting details
          </BrancherButton>
        </Grid>
      </Grid>

      <BrancherDialog
        setClose={() => setOpenMeetingDetails(false)}
        labelledBy="Past meeting details"
        title="Previous Meetings"
        open={openMeetingDetails}
      >
        <PastMeetingDetails
          pastMeetings={pastMeetings}
          allCompletedActions={allCompletedActions}
          allCompletedTalkingPoints={allCompletedTalkingPoints}
          menteeName={menteeName}
          menteeRoleId={menteeRoleId}
          mentorName={mentorName}
          mentorRoleId={mentorRoleId}
        />
      </BrancherDialog>
    </Grid>
  );
};

interface IPastMeetingDetails {
  pastMeetings: IMeetingData[];
  allCompletedActions: IMeetingAction[];
  allCompletedTalkingPoints: IMeetingAgenda[];
  menteeName: string;
  menteeRoleId: string;
  mentorName: string;
  mentorRoleId: string;
}

const PastMeetingDetails: React.FC<IPastMeetingDetails> = ({
  pastMeetings,
  menteeRoleId,
  mentorRoleId,
  menteeName,
  mentorName,
  allCompletedActions,
  allCompletedTalkingPoints,
}) => {
  return (
    <Grid container item spacing={3}>
      {pastMeetings.length > 0 ? (
        pastMeetings.map((meeting) => (
          <Grid container item spacing={1} key={meeting.meetingId}>
            <Grid item xs={12}>
              <Text variant="sm" color="purple" display="inline" fontWeight={500}>
                Meeting title:{' '}
              </Text>
              <Text variant="sm" display="inline">
                {meeting.title}
              </Text>
            </Grid>
            <Grid item xs={6}>
              <Text variant="sm" color="purple" display="inline" fontWeight={500}>
                Meeting Date:{' '}
              </Text>
              <Text variant="sm" display="inline">
                {format(new Date(meeting.datetimeStart), 'dd/MM/yyyy')}
              </Text>
            </Grid>
            <Grid item xs={3}>
              <Text variant="sm" color="purple" display="inline" fontWeight={500}>
                {menteeName}'s rating:{' '}
              </Text>
              <Text variant="sm" display="inline">
                {meeting?.ratings?.[menteeRoleId] ?? 'No ratings yet'}
              </Text>
            </Grid>
            <Grid item xs={3}>
              <Text variant="sm" color="purple" display="inline" fontWeight={500}>
                {mentorName}'s rating:{' '}
              </Text>
              <Text variant="sm" display="inline">
                {meeting?.ratings?.[mentorRoleId] ?? 'No ratings yet'}
              </Text>
            </Grid>
            {meeting?.agendas?.length > 0 && (
              <Grid item xs={3}>
                <Text variant="sm" color="purple" display="inline" fontWeight={500}>
                  Talking points completed:{' '}
                </Text>
                <Text variant="sm" display="inline">
                  {
                    allCompletedTalkingPoints?.filter(
                      (a) => a.completedMeeting === meeting.meetingId,
                    ).length
                  }
                </Text>
              </Grid>
            )}
            {meeting?.actions?.length > 0 && (
              <Grid item xs={3}>
                <Text variant="sm" color="purple" display="inline" fontWeight={500}>
                  Actions completed:{' '}
                </Text>
                <Text variant="sm" display="inline">
                  {
                    allCompletedActions?.filter((a) => a.completedMeeting === meeting.meetingId)
                      .length
                  }
                </Text>
              </Grid>
            )}
            <BrancherDivider marginTop={16} />
          </Grid>
        ))
      ) : (
        <Text variant="sm" color="purple" display="inline" fontWeight={500}>
          {menteeName} and {mentorName} haven't had their first meeting yet.
        </Text>
      )}
    </Grid>
  );
};
