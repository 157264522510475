import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Add from '@material-ui/icons/Add';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '../../Components/General/Text';
import { BrancherDispatch, SaveProgramData, UtilGetAllCompanyPrograms } from '../../store/actions';
import { IStoreTypes } from '../../store/storeTypes';
import { Colors } from '../../consts/colors';
import { RouteMaster } from '../../Components/Routing';
import { Page } from '../../Components/General/Page';
import { AllPositions } from '../../consts/ProgramPositionOptions';
import { CreateProgram } from '../SuperUser/CreateProgram';
import { ProgramTile } from '../../Components/General/ProgramTile';
import { UpdateCompany } from '../SuperUser/UpdateCompany';
import { ISimpleProgramHealthData } from '../../store/reducers/CompaniesReducer';

const useStyles = makeStyles((theme) => ({
  createProgramCard: {
    padding: `15px 8px`,
    borderRadius: 12,
    width: '100%',
    minWidth: 140,
    border: `1px solid ${Colors.blue}`,
    boxShadow: `0px 5px 15px ${Colors.cardBorder}`,
    minHeight: 150,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&:hover': {
      border: `2px solid ${theme.palette.primary.main}`,
      cursor: 'pointer',
      '& .MuiTypography-root': {
        fontWeight: 600,
      },
    },
  },
  createProgramSection: {
    marginTop: 30,
    marginBottom: 30,
    padding: 10,
    borderRadius: 12,
    boxShadow: `0px 5px 15px ${Colors.cardBorder}`,
    border: `1px solid ${Colors.backgroundGrey}`,
  },
}));

export const Admin = () => {
  const [retrievingPrograms, setRetrievingPrograms] = React.useState<boolean>(false);
  const [redirectToProgram, setRedirectToProgram] = React.useState<boolean>(false);
  const [redirectToDashboard, setRedirectToDashboard] = React.useState<boolean>(false);
  const [openCreateProgram, setOpenCreateProgram] = React.useState<boolean>(false);
  const [openUpdateCompany, setOpenUpdateCompany] = React.useState<boolean>(false);
  const programs = useSelector((state: IStoreTypes) => state?.companies?.programs);
  const loggedInUserCompanyName = useSelector((state: IStoreTypes) => state.user.companyName);
  const selectedCompanyId = useSelector((state: IStoreTypes) => state.user?.selectedCompanyId);
  const selectedCompany = useSelector((state: IStoreTypes) => state.companies?.companyList)?.find(
    (a) => a.companyId === selectedCompanyId,
  );
  let selectedCompanyName = selectedCompany?.companyName ?? loggedInUserCompanyName;
  const companyName = !!selectedCompanyId ? selectedCompanyName : loggedInUserCompanyName;
  const dispatch = useDispatch();
  const styles = useStyles();
  const isBrancherAdmin = useSelector((state: IStoreTypes) => state.user.positions)?.includes(
    AllPositions.BRANCHER_ADMIN,
  );

  React.useEffect(() => {
    setRetrievingPrograms(true);
    BrancherDispatch(
      dispatch,
      UtilGetAllCompanyPrograms(() => {
        setRetrievingPrograms(false);
      }),
    );
  }, []);

  const selectProgram = (programInfo: ISimpleProgramHealthData) => {
    dispatch(SaveProgramData({ ...programInfo }));
    if (!programInfo.launchedProgram || !programInfo.active) {
      setRedirectToProgram(true);
    } else {
      setRedirectToDashboard(true);
    }
  };

  return (
    <Page loading={retrievingPrograms}>
      {redirectToProgram && <Redirect to={RouteMaster.program.path} />}
      {redirectToDashboard && <Redirect to={RouteMaster.dashboard.path} />}
      <Grid container justifyContent="center">
        <Grid container item xs={11}>
          <Grid item container xs={11}>
            <Text variant="xl" marginBottom={50} marginTop={50} color="purple">
              {companyName} Programs
            </Text>
          </Grid>
          <Grid container spacing={4} item>
            {programs?.length > 0 &&
              programs?.map((program) => (
                <Grid item xs={12} md={6} key={program.programId}>
                  <ProgramTile programInfo={program} selectProgram={selectProgram} />
                </Grid>
              ))}
          </Grid>

          {isBrancherAdmin && (
            <>
              <Grid container spacing={4}>
                <Grid item xs={6} md={4}>
                  <Box
                    className={styles.createProgramCard}
                    onClick={() => setOpenCreateProgram(!openCreateProgram)}
                  >
                    <Add fontSize="large" />
                    <Text variant="md" color="purple" fontWeight={600} marginLeft={20}>
                      Create program
                    </Text>
                  </Box>
                </Grid>
              </Grid>
              {/*<Grid container item xs={6} md={4}>*/}
              {/*  <MatchingSatisfactionHealth />*/}
              {/*</Grid>*/}
            </>
          )}

          {openCreateProgram && (
            <Grid item xs={10} className={styles.createProgramSection}>
              <CreateProgram closeProgramTile={() => setOpenCreateProgram(false)} />
            </Grid>
          )}
          {isBrancherAdmin && selectedCompany && (
            <Grid item xs={6} md={4}>
              <Box
                className={styles.createProgramCard}
                marginTop={4}
                onClick={() => setOpenUpdateCompany(!openUpdateCompany)}
              >
                <Add fontSize="large" />
                <Text variant="md" color="purple" fontWeight={600} marginLeft={20}>
                  Update Company
                </Text>
              </Box>
            </Grid>
          )}
          {openUpdateCompany && (
            <Grid item xs={10} className={styles.createProgramSection}>
              <UpdateCompany companyInfo={selectedCompany} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Page>
  );
};
