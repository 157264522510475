import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { QuestionHeader } from '../FormFieldTypes/QuestionHeader';
import { Text } from '../../../Components/General/Text';
import { BrancherList } from '../../../Components/General/BrancherList';

export const ValuesInfo = () => {
  return (
    <>
      <Grid item xs={12} md={10}>
        <QuestionHeader>
          Next, we will be asking you a series of questions to measure your values.
        </QuestionHeader>
      </Grid>
      <Grid item xs={12} md={10}>
        <Text variant="md" fontWeight={600} marginTop={30}>
          Tips:
        </Text>
        <BrancherList
          listItems={[
            "Answer honestly, even if you don't like the answer.",
            'There are no right or wrong answers.',
            'Describe your values as they are now, not as you wish to be in the future.',
            'Your spontaneous answer is usually the most accurate.',
          ]}
        />
      </Grid>
    </>
  );
};
