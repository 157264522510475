import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text, IText } from './Text';

interface IScaleText {
  scale: number;
  description: React.ReactNode;
  noDescription?: boolean;
  variant?: IText['variant'];
  color?: IText['color'];
  fontWeight?: IText['fontWeight'];
}

export const ScaleText = (props: IScaleText) => {
  const {
    scale,
    description,
    noDescription = false,
    variant = 'sm',
    color = 'purple',
    fontWeight = 600,
  } = props;
  return (
    <Grid container justifyContent="flex-start" alignItems="center">
      <Grid item xs="auto">
        <Text
          display="inline"
          fontWeight={fontWeight}
          variant={variant}
          color={color}
          marginRight={7}
          verticalAlign="top"
        >
          {scale}
        </Text>
        {!noDescription && (
          <Text display="inline" variant="sm" marginRight={7}>
            =
          </Text>
        )}
      </Grid>
      <Grid item xs={10}>
        <Text display="inline" variant="sm">
          {description}
        </Text>
      </Grid>
    </Grid>
  );
};
