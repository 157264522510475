import { MobileFontSizes, TabletDesktopFontSizes } from '../consts/FontSizes';
import { DesktopFontWeights, MobileFontWeights, TabletFontWeights } from '../consts/FontWeights';
import { WindowSizeValues, WindowSizeVariants } from '../types/WindowSizeTypes';
import { TextVariantValues } from '../types/TextTypes';

export function getFontSize(windowSize: WindowSizeValues, variant?: TextVariantValues): number {
  const sizesMapping =
    windowSize === WindowSizeVariants.mobile ? MobileFontSizes : TabletDesktopFontSizes;
  return sizesMapping[variant];
}

export function getFontWeight(windowSize: WindowSizeValues, variant?: TextVariantValues): number {
  const sizesMapping =
    windowSize === WindowSizeVariants.mobile
      ? MobileFontWeights
      : windowSize === WindowSizeVariants.tablet
      ? TabletFontWeights
      : DesktopFontWeights;
  return sizesMapping[variant];
}

export function addPlural(entityAmount: number): string {
  if (entityAmount === 0 || entityAmount > 1 || entityAmount < -1) {
    return 's';
  } else {
    return '';
  }
}

export function CamelCase(name: string): string {
  const lowerCaseName = name.toLowerCase();
  return lowerCaseName.replace(/\s+(.)/g, (match, group) => {
    return group.toUpperCase();
  });
}
