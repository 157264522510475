import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { IProgressActivityHealthData } from '../../store/reducers/DashboardReducer';
import { Text } from '../../Components/General/Text';
import { SegmentedControlGroup } from '../../Components/InputFields/BrancherSegmentedControl';
import { EChosenProfile, ReportingProfileOptions } from './Dashboard';
import { ProgressActivityLineChart } from '../Program/Charts/ProgressActivityLineChart';

interface IActivityHealth {
  activities: IProgressActivityHealthData;
}

export const ActivityHealth: React.FC<IActivityHealth> = (props) => {
  const [chosenProfile, setChosenProfile] = React.useState<EChosenProfile>(EChosenProfile.ALL);
  const { activities } = props;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Text variant="lg" color="purple" fontWeight={700}>
          Activity
        </Text>
      </Grid>
      <Grid item xs={12}>
        <SegmentedControlGroup
          value={chosenProfile}
          options={ReportingProfileOptions}
          updateValue={(v: EChosenProfile) => setChosenProfile(v)}
        />
      </Grid>
      <Grid container item alignItems="center">
        <Grid item xs={12}>
          <ProgressActivityLineChart
            activityGraphData={activities?.activityGraphData}
            chosenProfile={chosenProfile}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
