import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import FlightTakeoff from '@material-ui/icons/FlightTakeoff';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import {
  UtilCompletePairing,
  UtilUpdateProgramAttribute,
  BrancherDispatch,
} from '../../../store/actions';
import { ActionButton, BackButton } from '../../../Components/InputFields/BrancherButton';
import { BrancherDivider } from '../../../Components/General/BrancherDivider';
import { UpdatableProgramInfo } from '../../../store/reducers/ProgramReducer';
import { Text } from '../../../Components/General/Text';
import { RouteMaster } from '../../../Components/Routing';
import { BrancherCheckbox } from '../../../Components/InputFields/BrancherCheckbox';
import { IStoreTypes } from '../../../store/storeTypes';
import { ConfirmDialog } from '../../../Components/General/ConfirmDialog';

const useStyles = makeStyles({
  margins: {
    marginTop: 50,
  },
});

export const LaunchProgram = () => {
  const hasPlatformAccess = useSelector((state: IStoreTypes) => state.program.hasPlatformAccess);
  const [launchingProgram, setLaunchingProgram] = React.useState<boolean>(false);
  const [updatingProgramStatus, setUpdatingProgramStatus] = React.useState<boolean>(false);
  const [redirectDashboard, setRedirectDashboard] = React.useState<boolean>(false);
  const [sendPairedEmails, setSendPairedEmails] = React.useState<boolean>(false);
  const [sendUnpairedEmails, setSendUnpairedEmails] = React.useState<boolean>(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const styles = useStyles();

  const launchProgram = () => {
    setLaunchingProgram(true);
    BrancherDispatch(
      dispatch,
      UtilCompletePairing(sendPairedEmails, sendUnpairedEmails, () => {
        setLaunchingProgram(false);
        setOpenConfirmationDialog(false);
        setRedirectDashboard(true);
      }),
    );
  };

  const updateProgramStatus = () => {
    setUpdatingProgramStatus(true);
    BrancherDispatch(
      dispatch,
      UtilUpdateProgramAttribute(UpdatableProgramInfo.MATCHING_COMPLETE, false, () => {
        setUpdatingProgramStatus(false);
      }),
    );
  };

  return (
    <Grid container direction="column" justifyContent="center" item xs={12}>
      {redirectDashboard && (
        <Redirect to={!hasPlatformAccess ? RouteMaster.home.path : RouteMaster.dashboard.path} />
      )}
      <ConfirmDialog
        setClose={() => setOpenConfirmationDialog(false)}
        labelledBy="launch-program"
        open={openConfirmationDialog}
        title="Launch Program"
        confirmAction={launchProgram}
        confirmButtonText="Launch"
        denyAction={() => setOpenConfirmationDialog(false)}
        denyButtonText="Not yet"
      >
        <Box width="100%" mb={5}>
          <Text variant="md" fontWeight={600}>
            Are you sure you want to launch this program?
          </Text>
        </Box>
      </ConfirmDialog>
      <Grid item className={styles.margins}>
        <Text variant="sm" fontWeight={500}>
          Launch program and send notification emails
        </Text>
      </Grid>
      <BrancherDivider marginBottom={30} marginTop={20} />
      <Grid item container spacing={2}>
        <Grid item xs={12} sm={10} md={9}>
          <BrancherCheckbox
            updateValue={setSendPairedEmails}
            value={sendPairedEmails}
            label={
              <Text variant="xs" fontWeight={600}>
                Send launch email to all successful mentees and mentors
              </Text>
            }
          />
        </Grid>
        <Grid item xs={12} sm={10} md={9}>
          <BrancherCheckbox
            updateValue={setSendUnpairedEmails}
            value={sendUnpairedEmails}
            label={
              <Text variant="xs" fontWeight={600}>
                Send email to unpaired mentees and mentors
              </Text>
            }
          />
        </Grid>
      </Grid>
      <BrancherDivider marginTop={30} marginBottom={30} />
      <Grid container justifyContent="space-between">
        <Grid item>
          <BackButton disabled={updatingProgramStatus} onClick={updateProgramStatus}>
            Previous step
          </BackButton>
        </Grid>
        <Grid item>
          <ActionButton
            onClick={launchProgram}
            disabled={launchingProgram}
            startIcon={<FlightTakeoff color="secondary" />}
          >
            Launch Program
          </ActionButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
