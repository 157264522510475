import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useDispatch } from 'react-redux';
import { BrancherSelect } from '../../Components/InputFields/BrancherSelect';
import { ResetButton, UpdateButton } from '../../Components/InputFields/BrancherButton';
import { BrancherDispatch, UtilGetProgramFormConfiguration } from '../../store/actions';
import { EComponentType } from '../../store/reducers/ProgramReducer';
import { IGeneralFormConfig, ValueTypes } from '../ApplicationForm/General/GeneralFormConfig';
import { MakeOptions } from '../ApplicationForm/MakeOptions';

enum ESpecialFilterAttributes {
  IS_ADMIN_FILTERABLE = 'isAdminFilterable',
}

interface ISearchableDashboardFilters {
  updateSearchCriteria: (filters: { [x in string]: any }) => void;
  setHideFilter: () => void;
  updatingDashboard: boolean;
}

export const SearchableDashboardFilters: React.FC<ISearchableDashboardFilters> = (props) => {
  const { updateSearchCriteria, setHideFilter, updatingDashboard } = props;
  const [gettingFormConfiguration, setGettingFormConfiguration] = React.useState<boolean>(false);
  const [formConfiguration, setFormConfiguration] = React.useState<any[]>([]);
  const [filters, setFilters] = React.useState<any>({});
  const dispatch = useDispatch();

  const defaultConfiguration = (config: any[]) => {
    let defaultFilter = {};
    config?.forEach((ff) => {
      defaultFilter = { ...defaultFilter, [ff?.name]: [] };
    });
    return defaultFilter;
  };

  const cleanConfiguration = (formConfig: any[]) => {
    const questions = formConfig.map((fc) => {
      if (
        !!fc?.[ESpecialFilterAttributes.IS_ADMIN_FILTERABLE] &&
        !(fc?.options?.mentee || fc?.options?.mentor)
      ) {
        if (
          fc?.secondaryAttributeName ||
          fc?.tertiaryAttributeName ||
          fc?.quaternaryAttributeName ||
          fc?.conditionalOptions ||
          fc.componentType !== EComponentType.TOGGLE
        ) {
          return null;
        }
        return {
          name: fc.name,
          options: MakeOptions(fc.options),
          inputLabel: fc.readableName,
          componentType: fc.componentType,
        };
      } else {
        return null;
      }
    });
    const cleanedQuestions = questions.filter((f) => f !== null);
    if (cleanedQuestions?.length > 0) {
      setFilters(defaultConfiguration(cleanedQuestions));
      setFormConfiguration(cleanedQuestions);
    } else {
      setHideFilter();
    }
    setGettingFormConfiguration(false);
  };

  React.useEffect(() => {
    setGettingFormConfiguration(true);
    BrancherDispatch(
      dispatch,
      UtilGetProgramFormConfiguration(({ formConfig }) => {
        cleanConfiguration(formConfig?.general ?? []);
      }),
    );
  }, []);

  const updateFilters = (attributeName: string, attributeValue: Omit<ValueTypes, 'boolean'>) => {
    setFilters({ ...filters, [attributeName]: attributeValue });
  };

  const updateSearch = () => {
    let cleanedFilters = {};
    Object.keys(filters).forEach((f) => {
      if (filters[f] !== null && filters[f]?.length > 0) {
        cleanedFilters = { ...cleanedFilters, [f]: filters[f] };
      }
    });
    updateSearchCriteria(cleanedFilters);
  };

  return (
    <Grid container item xs={12}>
      {!gettingFormConfiguration && formConfiguration?.length > 0 && (
        <Grid container item justifyContent="center" spacing={1} alignItems="center">
          {filters &&
            formConfiguration?.map((fc, i) => {
              const { componentType, ...other } = fc;
              return (
                <SearchToggleFilter
                  key={i}
                  updateFilters={updateFilters}
                  componentProps={other}
                  value={filters?.[other.name]}
                />
              );
            })}
        </Grid>
      )}

      <Box mt={2} display="flex" width="100%">
        <Grid item justifyContent="flex-end" container xs="auto" spacing={1}>
          <Grid item>
            <ResetButton
              size="small"
              disabled={updatingDashboard}
              onClick={() => setFilters(defaultConfiguration(formConfiguration))}
            >
              Reset filters
            </ResetButton>
          </Grid>
          <Grid item>
            <UpdateButton size="small" onClick={updateSearch} disabled={updatingDashboard}>
              Update filters
            </UpdateButton>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

interface ISearchToggleFilter {
  value: string;
  updateFilters: (name: string, value: string | string[]) => void;
  componentProps: IGeneralFormConfig;
}

const SearchToggleFilter: React.FC<ISearchToggleFilter> = (props) => {
  const { updateFilters, value, componentProps } = props;
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <BrancherSelect
        {...componentProps}
        id={`${componentProps.name}_filter`}
        multiple
        decreaseMargins
        fullWidth
        value={value}
        updateValue={(val) => updateFilters(componentProps.name, val)}
      />
    </Grid>
  );
};
