import * as React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { IBrancherTextField } from './BrancherTextField';

export interface IBrancherAutoCompleteOption {
  value: string;
  label: string;
}

interface IBrancherAutoComplete extends Omit<IBrancherTextField, 'updateValue' | 'value'> {
  updateValue: (a: IBrancherAutoCompleteOption | IBrancherAutoCompleteOption[]) => void;
  options: IBrancherAutoCompleteOption[];
  value: IBrancherAutoCompleteOption | IBrancherAutoCompleteOption[];
  multiple?: boolean;
}

export const BrancherAutoComplete: React.FC<IBrancherAutoComplete> = (props) => {
  const { value, updateValue, options, id, multiple, helpText, ...other } = props;

  return (
    <Autocomplete
      multiple={multiple}
      id={id}
      options={options}
      onChange={(event, newValue: IBrancherAutoCompleteOption[]) => {
        updateValue(newValue);
      }}
      value={value}
      getOptionLabel={(option: IBrancherAutoCompleteOption) => option.label}
      defaultValue={[]}
      renderInput={(params) => (
        <TextField {...params} helperText={helpText} {...other} variant="standard" />
      )}
    />
  );
};
