import * as React from 'react';
import Workbook from 'react-excel-workbook';
import { BrancherButton } from '../../Components/InputFields/BrancherButton';

export const GenerateFinalSurveyReport = ({
  menteeData,
  mentorData,
  menteeDisplayData,
  mentorDisplayData,
  programName,
}) => (
  <Workbook
    filename={`${programName}_final_survey_report.xlsx`}
    element={
      <BrancherButton color="primary" variant="text">
        Download final survey report!
      </BrancherButton>
    }
  >
    <Workbook.Sheet data={menteeData} name="Mentee Report">
      {menteeDisplayData.map((d, l) => (
        <Workbook.Column label={d.readableName} value={d.attributeName} key={l} />
      ))}
    </Workbook.Sheet>
    <Workbook.Sheet data={mentorData} name="Mentor Report">
      {mentorDisplayData.map((d, l) => (
        <Workbook.Column label={d.readableName} value={d.attributeName} key={l} />
      ))}
    </Workbook.Sheet>
  </Workbook>
);
