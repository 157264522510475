import * as React from 'react';
import Workbook from 'react-excel-workbook';
import { BrancherButton } from '../../Components/InputFields/BrancherButton';
import { IReportColumn } from '../../store/reducers/ReportReducer';

interface IGenerateAllTrainingReport {
  menteeData: any[];
  menteeDisplayData: IReportColumn[];
  mentorData: any[];
  mentorDisplayData: IReportColumn[];
  programName: string;
}

export const GenerateAllTrainingReport: React.FC<IGenerateAllTrainingReport> = ({
  menteeData,
  menteeDisplayData,
  mentorData,
  mentorDisplayData,
  programName,
}) => (
  <Workbook
    filename={`${programName}_all_training_report.xlsx`}
    element={
      <BrancherButton color="primary" variant="text" size="small">
        Download training report!
      </BrancherButton>
    }
  >
    <Workbook.Sheet data={menteeData} name="Mentee Report">
      {menteeDisplayData.map((d, l) => (
        <Workbook.Column label={d.readableName} value={d.attributeName} key={l} />
      ))}
    </Workbook.Sheet>
    <Workbook.Sheet data={mentorData} name="Mentor Report">
      {mentorDisplayData.map((d, l) => (
        <Workbook.Column label={d.readableName} value={d.attributeName} key={l} />
      ))}
    </Workbook.Sheet>
  </Workbook>
);
