import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../consts/colors';
import { Admin } from './Admin';

const useStyles = makeStyles({
  home: {
    background: Colors.white,
    flexGrow: 1,
    minHeight: `calc(100vh)`,
  },
});

export const Home = () => {
  const classes = useStyles({});

  return (
    <Grid container className={classes.home}>
      <Grid item xs={12}>
        <Admin />
      </Grid>
    </Grid>
  );
};
