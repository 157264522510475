import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../Components/General/Text';
import { BooleanTexts } from '../ProgramUsers/ProgramUsers';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../consts/colors';

const useStyles = makeStyles({
  activityCard: {
    padding: 15,
    border: `2px solid ${Colors.purple}`,
    bgcolor: Colors.backgroundGrey,
    borderRadius: 16,
    marginBottom: 30,
  },
});

interface IPairSharedNotesAgreement {
  sharedNotesAmount: number;
  completedMentoringAgreement: boolean;
}

export const PairSharedNotesAgreement: React.FC<IPairSharedNotesAgreement> = ({
  sharedNotesAmount,
  completedMentoringAgreement,
}) => {
  const styles = useStyles();

  return (
    <Grid container alignItems="center" spacing={2} className={styles.activityCard}>
      <Grid item xs={6}>
        <Text variant="sm" color="purple" display="inline" fontWeight={500}>
          Amount of shared notes:{' '}
        </Text>
        <Text variant="sm" display="inline" fontWeight={500}>
          {sharedNotesAmount ?? '0'}
        </Text>
      </Grid>
      <Grid item xs={6}>
        <Text variant="sm" color="purple" display="inline" fontWeight={500}>
          Completed mentoring agreement:{' '}
        </Text>
        <Text variant="sm" display="inline" fontWeight={500}>
          {completedMentoringAgreement ? BooleanTexts.YES : BooleanTexts.NO}
        </Text>
      </Grid>
    </Grid>
  );
};
