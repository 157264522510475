import * as React from 'react';
import Workbook from 'react-excel-workbook';
import { BrancherButton } from '../../Components/InputFields/BrancherButton';

export const GenerateBaselineSurveyReport = ({
  metData,
  metDisplayData,
  notMetData,
  notMetDisplayData,
  programName,
}) => (
  <Workbook
    filename={`${programName}_baseline_survey_report.xlsx`}
    element={
      <BrancherButton color="primary" variant="text">
        Download baseline survey report!
      </BrancherButton>
    }
  >
    <Workbook.Sheet data={metData} name="Pairs Met Report">
      {metDisplayData.map((d, l) => (
        <Workbook.Column label={d.readableName} value={d.attributeName} key={l} />
      ))}
    </Workbook.Sheet>
    <Workbook.Sheet data={notMetData} name="Pairs Not Met Report">
      {notMetDisplayData.map((d, l) => (
        <Workbook.Column label={d.readableName} value={d.attributeName} key={l} />
      ))}
    </Workbook.Sheet>
  </Workbook>
);
