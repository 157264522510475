import * as React from 'react';
import Workbook from 'react-excel-workbook';
import { BrancherButton } from '../../Components/InputFields/BrancherButton';
import { IReportInfo } from '../../store/reducers/ReportReducer';

interface IGenerateMoDPairingReport extends IReportInfo {
  data: IReportInfo['pairingReportData'];
  displayData: IReportInfo['pairingReportColumns'];
  programName: string;
  wrappedUpPairsReportData?: IReportInfo['wrappedUpPairsReportData'];
  wrappedUpPairsReportColumns?: IReportInfo['wrappedUpPairsReportColumns'];
  groupsReportData?: IReportInfo['groupsReportData'];
  groupsReportColumns?: IReportInfo['groupsReportColumns'];
  mentoringRequestsReportData?: IReportInfo['mentoringRequestsReportData'];
  mentoringRequestsReportColumns?: IReportInfo['mentoringRequestsReportColumns'];
  inDraftPairingReportData?: IReportInfo['inDraftPairingReportData'];
  inDraftPairingReportColumns?: IReportInfo['inDraftPairingReportColumns'];
}

export const GenerateMoDPairingReport: React.FC<IGenerateMoDPairingReport> = ({
  data,
  displayData,
  programName,
  wrappedUpPairsReportColumns,
  wrappedUpPairsReportData,
  mentoringRequestsReportColumns,
  mentoringRequestsReportData,
  groupsReportData,
  groupsReportColumns,
  inDraftPairingReportData,
  inDraftPairingReportColumns
}) => {
  return (
    <Workbook
      filename={`${programName}_pairing_report.xlsx`}
      element={
        <BrancherButton color="primary" variant="text" size="small" id="download-pairing-report" />
      }
    >
      <Workbook.Sheet data={data} name="Current Pairs">
        {displayData.map((d, l) => (
          <Workbook.Column label={d.readableName} value={d.attributeName} key={l} />
        ))}
      </Workbook.Sheet>
      <Workbook.Sheet data={inDraftPairingReportData} name="In Draft Pairs">
        {inDraftPairingReportColumns.map((d, l) => (
          <Workbook.Column label={d.readableName} value={d.attributeName} key={l} />
        ))}
      </Workbook.Sheet>
      <Workbook.Sheet data={wrappedUpPairsReportData?.filter((a) => !!a)} name="Wrapped Up Pairs">
        {wrappedUpPairsReportColumns?.map((d, l) => (
          <Workbook.Column label={d.readableName} value={d.attributeName} key={l} />
        ))}
      </Workbook.Sheet>
      <Workbook.Sheet
        data={groupsReportData?.length > 0 ? groupsReportData.filter((a) => !!a) : []}
        name="Groups"
      >
        {groupsReportColumns?.map((d, l) => (
          <Workbook.Column label={d.readableName} value={d.attributeName} key={l} />
        ))}
      </Workbook.Sheet>
      <Workbook.Sheet
        data={mentoringRequestsReportData?.filter((a) => !!a)}
        name="Mentoring Requests"
      >
        {mentoringRequestsReportColumns?.map((d, l) => (
          <Workbook.Column label={d.readableName} value={d.attributeName} key={l} />
        ))}
      </Workbook.Sheet>
    </Workbook>
  );
};
