import * as React from 'react';
import Workbook from 'react-excel-workbook';
import { BrancherButton } from '../../Components/InputFields/BrancherButton';
import { IReportColumn } from '../../store/reducers/ReportReducer';

interface IGenerateProfileReport {
  data: any[];
  displayData: IReportColumn[];
}

export const GenerateProfileReport: React.FC<IGenerateProfileReport> = ({ data, displayData }) => (
  <Workbook
    filename="profile_report.xlsx"
    element={
      <BrancherButton color="primary" variant="text" size="small">
        Download profile report!
      </BrancherButton>
    }
  >
    <Workbook.Sheet data={data} name="Profiles">
      {displayData.map((d, l) => (
        <Workbook.Column label={d.readableName} value={d.attributeName} key={l} />
      ))}
    </Workbook.Sheet>
  </Workbook>
);
