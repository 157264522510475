import { IBrancherThemePalette } from './BrancherTheme';
import { Colors } from '../consts/colors';

export const MuiAppBar = (theme: IBrancherThemePalette) => ({
  root: {
    overflowY: 'auto',
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
    '&::-webkit-scrollbar': {
      width: 6,
    },
    '&::-webkit-scrollbar-track': {
      background: Colors.backgroundDarkerPurple,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main /* color of the scroll thumb */,
      borderRadius: 10 /* roundness of the scroll thumb */,
      border: `3px solid transparent` /* creates padding around scroll thumb */,
    },
  },
  colorPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
});
