import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Check from '@material-ui/icons/Check';
import Flare from '@material-ui/icons/Flare';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import {
  ActionOutlinedButton,
  BackButton,
  SaveButton,
} from '../../../Components/InputFields/BrancherButton';
import { QuestionList } from './QuestionList';
import { IStoreTypes } from '../../../store/storeTypes';
import {
  EComponentType,
  IAlgorithmType,
  IDefaultValue,
  IFormConfig,
  IGeneralQuestionsConfig,
  Subscriptions,
  UpdatableFormConfiguration,
  UpdatableProgramInfo,
} from '../../../store/reducers/ProgramReducer';
import { Colors } from '../../../consts/colors';
import { QuestionConfiguration } from './QuestionConfiguration';
import { EChosenProfile } from '../../Dashboard/Dashboard';
import {
  BrancherDispatch,
  UtilSaveFormConfiguration,
  UtilUpdateProgramAttribute,
} from '../../../store/actions';
import { BrancherDialog } from '../../../Components/General/BrancherDialog';
import { ApplicationForm } from '../../ApplicationForm/ApplicationForm';
import { SaveApplicationDefaultFormData } from '../../../store/actions/ProgramActions';
import { RouteMaster } from '../../../Components/Routing';

interface IFormConfigurationEngine {
  setIsFormConfiguring: () => void;
}

const useStyles = makeStyles((theme) => ({
  formEngineContainer: {
    maxHeight: 'calc(100vh - 180px)',
  },
  margins: {
    marginBottom: 30,
  },
  questionConfigurationSection: {
    overflow: 'auto',
    '--webkit-overflow-scrolling': 'touch',
    '--ms-overflow-style': 'none',
    maxHeight: 'calc(100vh - 320px)',
    minHeight: 'calc(100vh - 320px)',
    backgroundColor: Colors.backgroundLightPurple,
    borderRadius: 15,
    padding: 30,
    '&::-webkit-scrollbar': {
      width: 6,
    },
    '&::-webkit-scrollbar-track': {
      background: Colors.backgroundDarkPurple,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: 10,
      border: `3px solid transparent`,
    },
  },
  questionListSection: {
    overflow: 'auto',
    '--webkit-overflow-scrolling': 'touch',
    '--ms-overflow-style': 'none',
    maxHeight: 'calc(100vh - 320px)',
    minHeight: 'calc(100vh - 320px)',
    '&::-webkit-scrollbar': {
      width: 6,
    },
    '&::-webkit-scrollbar-track': {
      background: Colors.backgroundDarkPurple,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: 10,
      border: `3px solid transparent`,
    },
  },
}));

export const FormConfigurationEngine: React.FC<IFormConfigurationEngine> = (props) => {
  const program = useSelector((state: IStoreTypes) => state.program?.formConfiguration);
  const programSubscriptions = useSelector((state: IStoreTypes) => state.program.subscription);
  const hasAdhocPairing = programSubscriptions?.includes(Subscriptions.ADHOC_PAIRING);
  const [intermediateFormConfiguration, setIntermediateFormConfiguration] = React.useState<
    IFormConfig['formConfig']
  >(program?.[UpdatableFormConfiguration.FORM_CONFIG]);
  const [intermediateAlgorithmConfiguration, setIntermediateAlgorithmConfiguration] =
    React.useState<IFormConfig['algorithmConfig']>(
      program?.[UpdatableFormConfiguration.ALGORITHM_CONFIG],
    );
  const [intermediateDefaultValues, setIntermediateDefaultValues] = React.useState<
    IFormConfig['formConfigDefaultValues']
  >(program?.[UpdatableFormConfiguration.FORM_CONFIG_DEFAULT_VALUES]);
  const [selectedQuestion, setSelectedQuestion] = React.useState<string>('');
  const [savingForm, setSavingForm] = React.useState<boolean>(false);
  const [settingFormActive, setSettingFormActive] = React.useState<boolean>(false);
  const [viewApplicationForm, setViewApplicationForm] = React.useState<boolean>(false);
  const [redirectToDashboard, setRedirectToDashboard] = React.useState<boolean>(false);
  const { setIsFormConfiguring } = props;
  const dispatch = useDispatch();
  const styles = useStyles();

  const controlOpenApplicationForm = () => {
    dispatch(SaveApplicationDefaultFormData(intermediateDefaultValues));
    setViewApplicationForm(true);
  };

  const controlCloseApplicationForm = () => {
    dispatch(SaveApplicationDefaultFormData(intermediateDefaultValues));
    setViewApplicationForm(false);
  };

  const setFormActive = () => {
    setSettingFormActive(true);
    BrancherDispatch(
      dispatch,
      UtilUpdateProgramAttribute(UpdatableProgramInfo.ACTIVE, true, () => {
        if (hasAdhocPairing) {
          setRedirectToDashboard(true);
        } else {
          setSettingFormActive(false);
        }
      }),
    );
  };

  const saveFormConfiguration = () => {
    setSavingForm(true);
    BrancherDispatch(
      dispatch,
      UtilSaveFormConfiguration(
        intermediateFormConfiguration,
        intermediateAlgorithmConfiguration,
        intermediateDefaultValues,
        () => {
          setSavingForm(false);
        },
      ),
    );
  };

  const addNewQuestion = (newQuestionIndex: number) => {
    const startOfConfig = intermediateFormConfiguration.general.slice(0, newQuestionIndex);
    const endOfConfig = intermediateFormConfiguration.general.slice(newQuestionIndex);
    const repeatNewQuestionLength =
      intermediateFormConfiguration.general.filter((a) => a.name.indexOf('newQuestion') > -1)
        .length + 1;
    const newIntermediateFormConfig = {
      ...intermediateFormConfiguration,
      general: [
        ...startOfConfig,
        {
          componentType: EComponentType.TOGGLE,
          options: [],
          exclusive: true,
          name: `newQuestion-${repeatNewQuestionLength}`,
          isMentee: true,
          isMentor: true,
          readableName: `New Question ${repeatNewQuestionLength}`,
          question: 'Please enter your question',
        },
        ...endOfConfig,
      ],
    } as IFormConfig['formConfig'];
    setIntermediateFormConfiguration(newIntermediateFormConfig);
    setSelectedQuestion(`newQuestion-${repeatNewQuestionLength}`);
  };

  const updateQuestionConfig = (
    newQuestionConfig: IGeneralQuestionsConfig,
    prevQuestionName: string,
  ) => {
    let newIntermediateFormConfiguration = intermediateFormConfiguration.general.map((a) =>
      a.name === selectedQuestion || a.name === prevQuestionName ? newQuestionConfig : a,
    );
    if (
      newQuestionConfig.componentType === EComponentType.TOGGLE &&
      !!newQuestionConfig?.conditionalOptionsField
    ) {
      newIntermediateFormConfiguration = newIntermediateFormConfiguration.map((a) =>
        a.name === newQuestionConfig?.conditionalOptionsField
          ? { ...a, resetsOtherField: true, resetField: newQuestionConfig.name }
          : a,
      );
    }
    setIntermediateFormConfiguration({
      ...intermediateFormConfiguration,
      general: newIntermediateFormConfiguration,
    });
    setSelectedQuestion(newQuestionConfig.name);
  };

  const removeQuestionAlgorithm = (question: string) => {
    const newAlgorithmConfiguration = intermediateAlgorithmConfiguration.general.filter(
      (a) => a.attributeName !== question,
    );
    setIntermediateAlgorithmConfiguration({
      ...intermediateAlgorithmConfiguration,
      general: newAlgorithmConfiguration,
    });
  };

  const updateQuestionAlgorithmConfig = (
    newQuestionAlgorithmConfig: IAlgorithmType,
    prevQuestionName: string,
  ) => {
    const newIntermediateAlgorithmConfiguration =
      !prevQuestionName ||
      (newQuestionAlgorithmConfig.attributeName === prevQuestionName &&
        !intermediateAlgorithmConfiguration.general.find(
          (b) => b.attributeName === newQuestionAlgorithmConfig.attributeName,
        ))
        ? [...intermediateAlgorithmConfiguration.general, newQuestionAlgorithmConfig]
        : intermediateAlgorithmConfiguration.general.map((a) =>
            a.attributeName === selectedQuestion || a.attributeName === prevQuestionName
              ? newQuestionAlgorithmConfig
              : a,
          );
    setIntermediateAlgorithmConfiguration({
      ...intermediateAlgorithmConfiguration,
      general: newIntermediateAlgorithmConfiguration,
    });
  };

  const updateDefaultValues = (
    newDefaultValue: IDefaultValue,
    visibleTo: EChosenProfile,
    prevQuestionName: string,
  ) => {
    const newMenteeDefaultValues =
      !prevQuestionName ||
      (newDefaultValue.key === prevQuestionName &&
        !intermediateDefaultValues.general.mentee.find((b) => b.key === newDefaultValue.key))
        ? [...intermediateDefaultValues.general.mentee, newDefaultValue]
        : intermediateDefaultValues.general.mentee.map((a) => {
            if (
              (visibleTo === EChosenProfile.MENTEE || visibleTo === EChosenProfile.ALL) &&
              (a.key === newDefaultValue.key || a.key === prevQuestionName)
            ) {
              return newDefaultValue;
            } else {
              return a;
            }
          });
    const newMentorDefaultValues =
      !prevQuestionName ||
      (newDefaultValue.key === prevQuestionName &&
        !intermediateDefaultValues.general.mentor.find((b) => b.key === newDefaultValue.key))
        ? [...intermediateDefaultValues.general.mentor, newDefaultValue]
        : intermediateDefaultValues.general.mentor.map((a) => {
            if (
              (visibleTo === EChosenProfile.MENTOR || visibleTo === EChosenProfile.ALL) &&
              (a.key === newDefaultValue.key || a.key === prevQuestionName)
            ) {
              return newDefaultValue;
            } else {
              return a;
            }
          });
    setIntermediateDefaultValues({
      ...intermediateDefaultValues,
      general: {
        mentee: newMenteeDefaultValues,
        mentor: newMentorDefaultValues,
      },
    });
  };

  const removeQuestion = (questionName: string) => {
    setIntermediateFormConfiguration({
      ...intermediateFormConfiguration,
      general: intermediateFormConfiguration.general.filter((a) => a.name !== questionName),
    });
    setIntermediateAlgorithmConfiguration({
      ...intermediateAlgorithmConfiguration,
      general: intermediateAlgorithmConfiguration.general.filter(
        (a) => a.attributeName !== questionName,
      ),
    });
    setIntermediateDefaultValues({
      ...intermediateDefaultValues,
      general: {
        mentee: intermediateDefaultValues.general.mentee.filter((a) => a.key !== questionName),
        mentor: intermediateDefaultValues.general.mentor.filter((a) => a.key !== questionName),
      },
    });
  };

  return (
    <Grid container className={styles.formEngineContainer}>
      {redirectToDashboard && <Redirect to={RouteMaster.dashboard.path} />}
      <BrancherDialog
        setClose={controlCloseApplicationForm}
        labelledBy="view-application-form"
        open={viewApplicationForm}
      >
        <ApplicationForm closeDialog={controlCloseApplicationForm} />
      </BrancherDialog>
      <Grid item className={styles.margins} xs={12} justifyContent="space-between" container>
        <Grid item>
          <BackButton onClick={setIsFormConfiguring} />
        </Grid>
        <Grid item xs={6} spacing={2} container justifyContent="flex-end">
          <Grid item>
            <SaveButton onClick={saveFormConfiguration} disabled={savingForm} />
          </Grid>
          <Grid item>
            <ActionOutlinedButton onClick={controlOpenApplicationForm} startIcon={<Flare />}>
              Preview
            </ActionOutlinedButton>
          </Grid>
          <Grid item>
            <ActionOutlinedButton
              onClick={setFormActive}
              loading={settingFormActive}
              startIcon={<Check />}
            >
              Complete
            </ActionOutlinedButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} className={styles.questionListSection}>
        <QuestionList
          selectedQuestion={selectedQuestion}
          questions={intermediateFormConfiguration}
          setSelectedQuestion={setSelectedQuestion}
          addQuestion={addNewQuestion}
          deleteQuestion={removeQuestion}
        />
      </Grid>
      <Grid item xs={12} md={6} className={styles.questionConfigurationSection}>
        {!!intermediateFormConfiguration?.general?.find((a) => a.name === selectedQuestion) && (
          <QuestionConfiguration
            selectedQuestion={selectedQuestion}
            saveQuestion={updateQuestionConfig}
            removeQuestionAlgorithm={removeQuestionAlgorithm}
            saveQuestionAlgorithm={updateQuestionAlgorithmConfig}
            saveDefaultValue={updateDefaultValues}
            defaultValues={intermediateDefaultValues}
            questionConfig={intermediateFormConfiguration}
            questionAlgorithmConfig={intermediateAlgorithmConfiguration}
          />
        )}
      </Grid>
    </Grid>
  );
};
