import * as React from 'react';
import { format } from 'date-fns';
import { KeyboardDatePicker } from '@material-ui/pickers';

interface IDatePicker {
  value: Date;
  updateValue: (value: Date) => void;
  id: string;
  name: string;
  helperText?: string;
  label?: string;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
}

export const DatePicker: React.FC<IDatePicker> = ({
  value,
  updateValue,
  label,
  disabled,
  id,
  name,
  helperText,
  minDate,
  maxDate,
}) => {
  const handleDate = (date: Date) => {
    updateValue(date);
  };

  return (
    <KeyboardDatePicker
      clearable
      disabled={disabled}
      id={id}
      aria-describedby={name}
      value={value}
      placeholder={format(new Date(), 'dd/MM/yyyy')}
      onChange={handleDate}
      format="dd/MM/yyyy"
      helperText={helperText}
      label={label}
      KeyboardButtonProps={{ 'aria-label': `${name}-action` }}
      minDate={minDate}
      maxDate={maxDate}
    />
  );
};
