import { IReducerType } from './ReducerType';
import { IDragNDrop, ISlider, IText, IToggle } from './ProgramReducer';
import { ProgramPositions } from '../../consts/ProgramPositionOptions';

export const SAVE_ALL_SURVEYS_DATA = 'SAVE_ALL_SURVEYS_DATA';

export const SurveysReducer = (state = initialState, action: IReducerType) => {
  switch (action.type) {
    case SAVE_ALL_SURVEYS_DATA:
      return { ...state, surveys: action.payload };
    default:
      return state;
  }
};

interface ISurveyToggleQuestion extends IToggle {
  hasBranchingLogic?: boolean; // if set, the next 2 attributes should be set - this can probably be omitted tbh
  branchingOptionValue?: string; // the value selected that pushes to the branchingStep
  branchingStepName?: string; // the name of the step to jump to
}

export type SurveyConfigurationQuestion = ISurveyToggleQuestion | IText | ISlider | IDragNDrop;

export enum EUserActions {
  CHECK_IN_SURVEY = 'checkInSurvey',
  FINISHING_SURVEY = 'finishingSurvey',
  CUSTOM_SURVEY = 'customSurvey',
  POST_WRAP_UP_MENTEE_SURVEY = 'postWrapUpMenteeSurvey', // this is a client customised survey
  POST_WRAP_UP_MENTOR_SURVEY = 'postWrapUpMentorSurvey', // this is a client customised survey
}

export enum ESurveyStatus {
  DRAFT = 'draft',
  ACTIVE = 'active',
  CLOSED = 'closed',
}

export const SurveyTypeNames = {
  [EUserActions.CHECK_IN_SURVEY]: 'Check-in survey',
  [EUserActions.FINISHING_SURVEY]: 'Finishing-up survey',
  [EUserActions.CUSTOM_SURVEY]: 'Customised survey (Blank template)',
  [EUserActions.POST_WRAP_UP_MENTEE_SURVEY]: 'Post wrap-up mentee survey',
  [EUserActions.POST_WRAP_UP_MENTOR_SURVEY]: 'Post wrap-up mentor survey',
};

export enum UpdatableSurveyConfiguration {
  SURVEY_STATUS = 'surveyStatus',
  SURVEY_DESCRIPTION = 'surveyDescription',
  SURVEY_TITLE = 'surveyTitle',
  SURVEY_INTRO = 'surveyIntro',
  SURVEY_INITIAL_REMINDER_DATE = 'surveyInitialReminderDate',
  SURVEY_FINAL_REMINDER_DATE = 'surveyFinalReminderDate',
  SURVEY_DUE_DATE = 'surveyDueDate',
  SURVEY_DATE_SENT = 'surveyDateSent',
}

export interface ISurveyConfiguration {
  surveyId: string;
  programId: string;
  surveyType: EUserActions;
  configuration: SurveyConfigurationQuestion[];
  [UpdatableSurveyConfiguration.SURVEY_TITLE]: string;
  [UpdatableSurveyConfiguration.SURVEY_INTRO]?: string;
  [UpdatableSurveyConfiguration.SURVEY_DESCRIPTION]?: string;
  [UpdatableSurveyConfiguration.SURVEY_DATE_SENT]?: string;
  [UpdatableSurveyConfiguration.SURVEY_INITIAL_REMINDER_DATE]?: string;
  [UpdatableSurveyConfiguration.SURVEY_FINAL_REMINDER_DATE]?: string;
  [UpdatableSurveyConfiguration.SURVEY_DUE_DATE]?: string;
  [UpdatableSurveyConfiguration.SURVEY_STATUS]?: ESurveyStatus;
  surveySentMentees?: string[]; // menteeRoleIds
  surveySentMentors?: string[]; // mentorRoleIds
  surveySegments?: ProgramPositions[];
  updatable?: boolean; // this controls whether or not the survey can be updated due to being permanent
}

export interface ISurveysInfo {
  surveys: ISurveyConfiguration[];
}

const initialState: ISurveysInfo = {
  surveys: [],
};
