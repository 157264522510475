import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { SaveButton } from '../../Components/InputFields/BrancherButton';
import { EComponentType } from '../../store/reducers/ProgramReducer';
import { Colors } from '../../consts/colors';
import { SurveyConfigurationQuestion } from '../../store/reducers/SurveysReducer';
import { SurveyBuilderQuestionList } from './SurveyBuilderQuestionList';
import { SurveyBuilderQuestionConfiguration } from './SurveyBuilderQuestionConfiguration';

interface ISurveyBuilderEngine {
  configuration: SurveyConfigurationQuestion[];
  saveConfiguration: (configuration: SurveyConfigurationQuestion[]) => void;
  savingForm: boolean;
  surveyIntro: string;
}

const useStyles = makeStyles((theme) => ({
  formEngineContainer: {
    maxHeight: 'calc(100vh - 180px)',
  },
  margins: {
    marginBottom: 30,
  },
  questionConfigurationSection: {
    overflow: 'auto',
    '--webkit-overflow-scrolling': 'touch',
    '--ms-overflow-style': 'none',
    maxHeight: 'calc(100vh - 320px)',
    minHeight: 'calc(100vh - 320px)',
    backgroundColor: Colors.backgroundLightPurple,
    borderRadius: 15,
    padding: 30,
    '&::-webkit-scrollbar': {
      width: 6,
    },
    '&::-webkit-scrollbar-track': {
      background: Colors.backgroundDarkPurple,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: 10,
      border: `3px solid transparent`,
    },
  },
  questionListSection: {
    overflow: 'auto',
    '--webkit-overflow-scrolling': 'touch',
    '--ms-overflow-style': 'none',
    maxHeight: 'calc(100vh - 320px)',
    minHeight: 'calc(100vh - 320px)',
    '&::-webkit-scrollbar': {
      width: 6,
    },
    '&::-webkit-scrollbar-track': {
      background: Colors.backgroundDarkPurple,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: 10,
      border: `3px solid transparent`,
    },
  },
}));

export const BrancherOwnedQuestions: string[] = [
  'goalAchievement',
  'skill',
  'nps',
  'advocacy',
  'promotionAndPayRise',
  'mentoringSkills',
  'closingMessage',
];

export const NonDraggableQuestions: string[] = ['closingMessage'];

export const SurveyBuilderEngine: React.FC<ISurveyBuilderEngine> = ({
  configuration,
  saveConfiguration,
  savingForm,
  surveyIntro,
}) => {
  const [selectedQuestion, setSelectedQuestion] = React.useState<string>('');
  const [intermediateSurveyBuilder, setIntermediateSurveyBuilder] = React.useState<
    SurveyConfigurationQuestion[]
  >(configuration ?? []);
  const styles = useStyles();

  const controlSetSelectedQuestion = (questionName: string) => {
    if (!BrancherOwnedQuestions.includes(questionName)) {
      setSelectedQuestion(questionName);
    }
  };

  const addNewQuestion = (newQuestionIndex: number) => {
    const startOfConfig = intermediateSurveyBuilder.slice(0, newQuestionIndex) ?? [];
    const endOfConfig = intermediateSurveyBuilder.slice(newQuestionIndex) ?? [];
    const repeatNewQuestionLength =
      intermediateSurveyBuilder.filter((a) => a.name.indexOf('newQuestion') > -1).length + 1;
    const newIntermediateFormConfig = [
      ...startOfConfig,
      {
        componentType: EComponentType.TOGGLE,
        options: [],
        exclusive: true,
        name: `newQuestion-${repeatNewQuestionLength}`,
        readableName: `New Question ${repeatNewQuestionLength}`,
        question: 'Please enter your question',
      },
      ...endOfConfig,
    ];
    // @ts-ignore
    setIntermediateSurveyBuilder(newIntermediateFormConfig);
    controlSetSelectedQuestion(`newQuestion-${repeatNewQuestionLength}`);
  };

  const updateQuestionConfig = (
    newQuestionConfig: SurveyConfigurationQuestion,
    prevQuestionName?: string,
  ) => {
    let newIntermediateSurveyBuilder = intermediateSurveyBuilder.map((a) =>
      a.name === selectedQuestion || a.name === prevQuestionName ? newQuestionConfig : a,
    );
    if (
      newQuestionConfig.componentType === EComponentType.TOGGLE &&
      !!newQuestionConfig?.conditionalOptionsField
    ) {
      newIntermediateSurveyBuilder = newIntermediateSurveyBuilder.map((a) =>
        a.name === newQuestionConfig?.conditionalOptionsField
          ? { ...a, resetsOtherField: true, resetField: newQuestionConfig.name }
          : a,
      );
    }
    setIntermediateSurveyBuilder(newIntermediateSurveyBuilder);
    controlSetSelectedQuestion(newQuestionConfig.name);
  };

  const removeQuestion = (questionName: string) => {
    setIntermediateSurveyBuilder(intermediateSurveyBuilder.filter((a) => a.name !== questionName));
  };

  return (
    <Grid container className={styles.formEngineContainer}>
      <Grid item className={styles.margins} xs={12} justifyContent="space-between" container>
        <Grid item spacing={2} container justifyContent="flex-end">
          <Grid item>
            <SaveButton
              onClick={() => saveConfiguration(intermediateSurveyBuilder)}
              loading={savingForm}
            >
              Save all survey updates
            </SaveButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={7} className={styles.questionListSection}>
        <SurveyBuilderQuestionList
          selectedQuestion={selectedQuestion}
          questions={intermediateSurveyBuilder}
          setSelectedQuestion={controlSetSelectedQuestion}
          addQuestion={addNewQuestion}
          deleteQuestion={removeQuestion}
          saveConfiguration={setIntermediateSurveyBuilder}
          introduction={surveyIntro}
        />
      </Grid>
      <Grid item xs={12} md={5} className={styles.questionConfigurationSection}>
        {!!intermediateSurveyBuilder?.find((a) => a.name === selectedQuestion) && (
          <SurveyBuilderQuestionConfiguration
            selectedQuestion={selectedQuestion}
            saveQuestion={updateQuestionConfig}
            questionConfig={intermediateSurveyBuilder}
          />
        )}
      </Grid>
    </Grid>
  );
};
