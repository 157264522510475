import { Colors } from '../consts/colors';
import { IBrancherThemePalette } from './BrancherTheme';

export const MuiCircularProgress = (theme: IBrancherThemePalette) => ({
  root: {
    color: theme.palette.primary.main,
  },
  colorPrimary: {
    color: Colors.white,
  },
  colorSecondary: {
    color: theme.palette.primary.main,
  },
});
