import * as React from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { BrancherDispatch, UtilRemoveUserApplication } from '../../store/actions';
import {
  ActionNegativeOutlinedButton,
  ActionOutlinedButton,
} from '../../Components/InputFields/BrancherButton';
import { IUserRoleInfo } from './User';
import { BrancherDialog } from '../../Components/General/BrancherDialog';
import { Text } from '../../Components/General/Text';
import { BrancherList } from '../../Components/General/BrancherList';
import { ProgramPositions } from '../../consts/ProgramPositionOptions';

interface IRemoveUserApplication {
  userApplication: IUserRoleInfo;
  actionCallback: () => void;
}

export const RemoveUserApplicationDialog: React.FC<IRemoveUserApplication> = (props) => {
  const { userApplication, actionCallback } = props;
  const [removingUserProfile, setRemovingUserProfile] = React.useState<boolean>(false);
  const [openRemoveApplicationDialog, setOpenRemoveApplicationDialog] =
    React.useState<boolean>(false);
  const dispatch = useDispatch();

  const controlUserDeactivation = () => {
    setRemovingUserProfile(true);
    BrancherDispatch(
      dispatch,
      UtilRemoveUserApplication(userApplication.roleId, userApplication.position, (res) => {
        setRemovingUserProfile(false);
        setOpenRemoveApplicationDialog(false);
        actionCallback();
      }),
    );
  };

  const userProfileHasCurrentMentoringPartner = userApplication?.mentoringPartners?.length > 0;

  return (
    <Grid container>
      <Grid item container alignItems="center">
        <ActionNegativeOutlinedButton
          size="small"
          onClick={() => setOpenRemoveApplicationDialog(true)}
        >
          Remove {userApplication?.position} application
        </ActionNegativeOutlinedButton>
      </Grid>
      <BrancherDialog
        open={openRemoveApplicationDialog}
        setClose={() => setOpenRemoveApplicationDialog(false)}
        labelledBy={`Remove ${userApplication.name}'s ${userApplication.position} information`}
        title={`Remove ${userApplication.name}'s ${userApplication.position} information`}
        contentWidth={true}
      >
        {!userProfileHasCurrentMentoringPartner ? (
          <>
            <Grid container>
              <Grid item>
                <Text variant="sm" fontWeight={600}>
                  This will remove {userApplication.name}'s {userApplication.position}:
                </Text>
                <BrancherList
                  fontWeight={600}
                  listItems={[
                    'Application submission',
                    'Profile information',
                    'Private notes',
                    'Goals',
                  ]}
                />
              </Grid>
              <Grid item>
                <Text variant="md" fontWeight={700} marginTop={30}>
                  Are you sure you want to remove this information? It cannot be recovered once
                  deleted.
                </Text>
                <Text variant="sm" fontWeight={700} marginBottom={40} marginTop={10}>
                  This ONLY removes {userApplication.name}'s {userApplication.position} information.
                  <br />
                  This does not delete ALL the user's data. This does not remove other user
                  information that is not described above.
                </Text>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between" item>
              <ActionNegativeOutlinedButton
                onClick={() => setOpenRemoveApplicationDialog(false)}
                loading={removingUserProfile}
              >
                Don't remove
              </ActionNegativeOutlinedButton>
              <ActionOutlinedButton onClick={controlUserDeactivation} loading={removingUserProfile}>
                Remove profile
              </ActionOutlinedButton>
            </Grid>
          </>
        ) : (
          <Grid item>
            <Text variant="sm" fontWeight={600}>
              You can't remove {userApplication.name} {userApplication.position}'s information
              whilst they still have a{' '}
              {userApplication.position === ProgramPositions.mentor
                ? ProgramPositions.mentee
                : ProgramPositions.mentor}
            </Text>
          </Grid>
        )}
      </BrancherDialog>
    </Grid>
  );
};
