import * as React from 'react';
import Grid, { GridJustification } from '@material-ui/core/Grid';
import capitalize from '@material-ui/core/utils/capitalize';
import { makeStyles } from '@material-ui/core/styles';
import Email from '@material-ui/icons/Email';
import PhoneEnabled from '@material-ui/icons/PhoneEnabled';
import { IBaseProfileData } from '../../types/ProfileTypes';
import { ProgramPositions } from '../../consts/ProgramPositionOptions';
import { ProfilePicture } from '../User/ProfilePicture';
import { BrancherLinkText } from '../../Components/General/BrancherLinkText';
import { RouteMaster } from '../../Components/Routing';
import { Text } from '../../Components/General/Text';
import { BooleanTexts } from '../ProgramUsers/ProgramUsers';

const useStyles = makeStyles({
  profileImage: {
    marginRight: 15,
  },
});

interface IPairRoleProfile {
  profileData: IBaseProfileData;
  completedProfile: boolean;
  roleId: string;
  activityScore: number;
  position: ProgramPositions;
  justifyContent: GridJustification;
}

export const PairRoleProfile: React.FC<IPairRoleProfile> = ({
  profileData,
  completedProfile,
  roleId,
  activityScore,
  position,
  justifyContent,
}) => {
  const styles = useStyles({});

  return (
    <Grid item xs={12} md={6} container justifyContent={justifyContent}>
      <Grid container item alignItems="center" justifyContent={justifyContent}>
        <Grid item className={styles.profileImage} xs={2}>
          <ProfilePicture src={profileData?.image} />
        </Grid>
        <Grid item xs={9} container direction="column" spacing={2}>
          <Grid item>
            <BrancherLinkText
              underline="always"
              variant="sm"
              display="inline"
              fontWeight={600}
              href={`${RouteMaster.user.path}?r=${roleId}`}
            >
              {profileData?.firstName} {profileData?.lastName}
            </BrancherLinkText>
            <Text variant="xs" display="inline">
              {'   '}({capitalize(position)})
            </Text>
          </Grid>
          <Grid item container alignItems="center">
            <Grid item>
              <Email />
            </Grid>
            <Grid item xs={10}>
              <Text
                variant="sm"
                fontWeight={500}
                color="secondaryGrey"
                display="inline"
                wordWrap
                marginLeft={10}
              >
                {profileData?.contactEmail}
              </Text>
            </Grid>
          </Grid>
          <Grid item container alignItems="center">
            <Grid item>
              <PhoneEnabled />
            </Grid>
            <Grid item xs={10}>
              <Text
                variant="sm"
                fontWeight={500}
                color="secondaryGrey"
                display="inline"
                wordWrap
                marginLeft={10}
              >
                {profileData?.phoneNumber}
              </Text>
            </Grid>
          </Grid>
          <Grid item>
            <Text variant="sm" fontWeight={600} color="purple" display="inline">
              Job Title{' '}
            </Text>
            <Text variant="sm" fontWeight={500} display="inline" color="secondaryGrey">
              {profileData?.jobTitle}
            </Text>
          </Grid>
          <Grid item xs={12}>
            <Text variant="sm" fontWeight={600} color="purple" display="inline">
              Completed Profile{' '}
            </Text>
            <Text variant="sm" fontWeight={500} display="inline" color="secondaryGrey">
              {completedProfile ? BooleanTexts.YES : BooleanTexts.NO}
            </Text>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
