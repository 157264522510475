import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Add from '@material-ui/icons/Add';
import AddCircle from '@material-ui/icons/AddCircle';
import { useDispatch, useSelector } from 'react-redux';
import { add, addWeeks, format } from 'date-fns';
import { Text } from '../../Components/General/Text';
import { BrancherDispatch, UtilCreateSurvey } from '../../store/actions';
import { Colors } from '../../consts/colors';
import { IStoreTypes } from '../../store/storeTypes';
import { BrancherRadio } from '../../Components/InputFields/Radio/BrancherRadio';
import { BrancherSelect } from '../../Components/InputFields/BrancherSelect';
import { EBooleanOptions } from '../SuperUser/CreateProgram';
import { ActionButton } from '../../Components/InputFields/BrancherButton';
import { EUserActions, SurveyTypeNames } from '../../store/reducers/SurveysReducer';
import { BrancherTextField } from '../../Components/InputFields/BrancherTextField';
import { BrancherInputLabel } from '../../Components/InputFields/BrancherInputLabel';
import { DatePicker } from '../../Components/InputFields/DatePicker';
import { ProgramPositions } from '../../consts/ProgramPositionOptions';
import { BrancherCheckbox } from '../../Components/InputFields/BrancherCheckbox';

const useStyles = makeStyles((theme) => ({
  createSurveyCard: {
    padding: 15,
    borderRadius: 12,
    width: '100%',
    minWidth: 140,
    border: `1px solid ${Colors.blue}`,
    boxShadow: `0px 5px 15px ${Colors.cardBorder}`,
    minHeight: 150,
    height: 150,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
      cursor: 'pointer',
      '& .MuiTypography-root': {
        fontWeight: 600,
      },
    },
  },
  createSurveySection: {
    marginTop: 20,
    marginBottom: 30,
    padding: 10,
    borderRadius: 12,
    boxShadow: `0px 5px 15px ${Colors.cardBorder}`,
    border: `1px solid ${Colors.backgroundGrey}`,
  },
}));

export const CreateSurvey: React.FC = () => {
  const [duplicateSurveyId, setDuplicateSurveyId] = React.useState<string>('');
  const [creatingSurvey, setCreatingSurvey] = React.useState<boolean>(false);
  const [hasDuplicateSurvey, setHasDuplicateSurvey] = React.useState<EBooleanOptions>(
    EBooleanOptions.NO,
  );
  const [openCreateSurvey, setOpenCreateSurvey] = React.useState<boolean>(false);
  const [surveyTitle, setSurveyTitle] = React.useState<string>('');
  const [surveyType, setSurveyType] = React.useState<EUserActions>(EUserActions.CUSTOM_SURVEY);
  const [isMenteeSegment, setIsMenteeSegment] = React.useState<boolean>(false);
  const [isMentorSegment, setIsMentorSegment] = React.useState<boolean>(false);
  const [dueDate, setDueDate] = React.useState<Date>(addWeeks(new Date(), 3));
  const [surveyDescription, setSurveyDescription] = React.useState<string>('');
  const surveys = useSelector((state: IStoreTypes) => state.surveys.surveys);
  const styles = useStyles();

  const dispatch = useDispatch();

  const createSurvey = () => {
    setCreatingSurvey(true);
    let surveySegments = [];
    if (isMenteeSegment) surveySegments.push(ProgramPositions.mentee);
    if (isMentorSegment) surveySegments.push(ProgramPositions.mentor);
    BrancherDispatch(
      dispatch,
      UtilCreateSurvey(
        surveyTitle,
        surveyDescription,
        duplicateSurveyId,
        format(dueDate, 'dd/MM/yyyy'),
        surveyType,
        surveySegments,
        () => {
          setHasDuplicateSurvey(EBooleanOptions.NO);
          setDuplicateSurveyId('');
          setSurveyDescription('');
          setSurveyTitle('');
          setSurveyType(EUserActions.CUSTOM_SURVEY);
          setIsMenteeSegment(false);
          setIsMentorSegment(false);
          setDueDate(addWeeks(new Date(), 3));
          setOpenCreateSurvey(false);
          setCreatingSurvey(false);
        },
      ),
    );
  };

  const controlSetDuplicateSurveyId = (surveyId: string) => {
    const surveyToDuplicate = surveys.find((s) => s.surveyId === surveyId);
    setDuplicateSurveyId(surveyId);
    setSurveyDescription(surveyToDuplicate.surveyDescription);
    setSurveyTitle(surveyToDuplicate.surveyTitle);
  };

  const controlSetSurveyType = (surType: EUserActions) => {
    if (surType === EUserActions.CHECK_IN_SURVEY) {
      setSurveyTitle('Check in survey - How are you finding this program?');
    } else if (surType === EUserActions.FINISHING_SURVEY) {
      setSurveyTitle('Final survey - How did you find this program?');
    }
    setSurveyType(surType);
  };

  const validateSurveyData = (): boolean => {
    return !!surveyTitle;
  };

  return (
    <Grid container item xs={12}>
      <Box
        className={styles.createSurveyCard}
        marginTop={2}
        onClick={() => setOpenCreateSurvey(!openCreateSurvey)}
      >
        <Add fontSize="large" />
        <Text variant="md" color="purple" fontWeight={600} marginLeft={20}>
          Create Survey
        </Text>
      </Box>
      {openCreateSurvey && (
        <Grid item xs={12} className={styles.createSurveySection}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <BrancherTextField
                value={surveyTitle}
                updateValue={setSurveyTitle}
                placeholder="title..."
                label="Survey title/name"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <BrancherTextField
                value={surveyDescription}
                updateValue={setSurveyDescription}
                placeholder="description..."
                label="Survey description"
                fullWidth
              />
            </Grid>
            <Grid container alignItems="center" justifyContent="space-between" item xs={12}>
              <Grid item>
                <BrancherInputLabel variant="sm" fontWeight={700} color="purple" for="date">
                  Due date
                </BrancherInputLabel>
                <DatePicker
                  value={dueDate}
                  updateValue={setDueDate}
                  minDate={add(new Date(), { days: 2 })}
                  id="due-date"
                  name="due-date"
                />
              </Grid>
            </Grid>
            {surveys?.length > 0 && (
              <Grid item xs={12}>
                <BrancherRadio
                  aria-label="has-duplicate-survey"
                  value={hasDuplicateSurvey}
                  onChange={(a: EBooleanOptions) => {
                    setHasDuplicateSurvey(a);
                    if (hasDuplicateSurvey === EBooleanOptions.YES && a === EBooleanOptions.NO) {
                      setDuplicateSurveyId('');
                    }
                  }}
                  label="Do you want to duplicate a previous survey?"
                  options={[
                    { label: 'No', value: EBooleanOptions.NO },
                    { label: 'Yes', value: EBooleanOptions.YES },
                  ]}
                />
              </Grid>
            )}
            {surveys?.length > 0 && hasDuplicateSurvey === EBooleanOptions.YES ? (
              <Grid item xs={12}>
                <BrancherInputLabel variant="xs" fontWeight={700} color="purple" for="date">
                  Which survey would you like to duplicate?
                </BrancherInputLabel>
                <BrancherSelect
                  name="duplicateSurveyId"
                  value={duplicateSurveyId}
                  updateValue={(selectedSurveyId: string) =>
                    controlSetDuplicateSurveyId(selectedSurveyId)
                  }
                  label="Which survey would you like to duplicate?"
                  options={surveys.map((m) => ({
                    label: `${m.surveyTitle} - ${SurveyTypeNames[m.surveyType]}`,
                    value: m.surveyId,
                  }))}
                  fullWidth
                />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <BrancherInputLabel variant="xs" fontWeight={700} color="purple" for="date">
                  Which survey template would you like to use?
                </BrancherInputLabel>
                <BrancherSelect
                  name="surveyType"
                  value={surveyType}
                  updateValue={(sType: EUserActions) => controlSetSurveyType(sType)}
                  label="Survey type"
                  options={[
                    {
                      label: `${SurveyTypeNames[EUserActions.CUSTOM_SURVEY]}`,
                      value: EUserActions.CUSTOM_SURVEY,
                    },
                    {
                      label: `${SurveyTypeNames[EUserActions.CHECK_IN_SURVEY]}`,
                      value: EUserActions.CHECK_IN_SURVEY,
                    },
                    {
                      label: `${SurveyTypeNames[EUserActions.FINISHING_SURVEY]}`,
                      value: EUserActions.FINISHING_SURVEY,
                    },
                  ]}
                  fullWidth
                />
              </Grid>
            )}
            <Grid item xs={12} sm={10} md={9}>
              <BrancherCheckbox
                updateValue={setIsMenteeSegment}
                value={isMenteeSegment}
                label={
                  <Text variant="xs" fontWeight={600}>
                    Made for mentees?
                  </Text>
                }
              />
            </Grid>
            <Grid item xs={12} sm={10} md={9}>
              <BrancherCheckbox
                updateValue={setIsMentorSegment}
                value={isMentorSegment}
                label={
                  <Text variant="xs" fontWeight={600}>
                    Made for mentors?
                  </Text>
                }
              />
            </Grid>
            <Grid item xs={12}>
              <ActionButton
                startIcon={<AddCircle color="secondary" />}
                onClick={createSurvey}
                loading={creatingSurvey}
                disabled={!validateSurveyData()}
              >
                Create Survey
              </ActionButton>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
