import * as React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SupervisedUserCircle from '@material-ui/icons/SupervisedUserCircle';
import SvgIcon from '@material-ui/core/SvgIcon';
import Person from '@material-ui/icons/Person';
import Settings from '@material-ui/icons/Settings';
import Dashboard from '@material-ui/icons/Dashboard';
import Timeline from '@material-ui/icons/Timeline';
import LocalLibrary from '@material-ui/icons/LocalLibrary';
import MenuBook from '@material-ui/icons/MenuBook';
import LiveHelp from '@material-ui/icons/LiveHelp';
import EmojiEvents from '@material-ui/icons/EmojiEvents';
import GroupWork from '@material-ui/icons/GroupWork';
import GroupAdd from '@material-ui/icons/GroupAdd';
import People from '@material-ui/icons/People';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { Colors } from '../../consts/colors';
import { mainNavWidth, mobileMainNavHeight } from '../../consts/DimensionConsts';
import { RouteMaster } from '../Routing';
import { Text } from '../General/Text';
import { IStoreTypes } from '../../store/storeTypes';
import { HomeIcon } from './images/HomeIcon';
import { TrainingIcon } from './images/TrainingIcon';
import { LogoutButton } from '../InputFields/BrancherButton';
import { BrancherDispatch, UtilSignOut } from '../../store/actions';
import { AllPositions } from '../../consts/ProgramPositionOptions';
import { Subscriptions, UpdatableProgramInfo } from '../../store/reducers/ProgramReducer';
import { NotesIcon } from './images/NotesIcon';
import { GetLinkBase } from '../../helpers/LinkHelpers';

interface IHeaderNavStyles {
  mobile: boolean;
  navLinkAmount: number;
  hasLogo: boolean;
}

const useStyles = makeStyles({
  navBar: {
    height: (props: IHeaderNavStyles) => (props.mobile ? mobileMainNavHeight : '100vh'),
    width: (props: IHeaderNavStyles) => (props.mobile ? '100vw' : mainNavWidth),
    left: (props: IHeaderNavStyles) => (props.mobile ? 'auto' : 0),
    bottom: (props: IHeaderNavStyles) => (props.mobile ? 0 : 'auto'),
    top: (props: IHeaderNavStyles) => props.mobile && 'auto',
    padding: `0px !important`,
  },
  selected: {
    marginLeft: 10,
  },
  unselected: {
    marginLeft: 10,
  },
  navLinkSection: {
    minHeight: (props: IHeaderNavStyles) => (props.mobile ? 55 : 'auto'),
    height: (props: IHeaderNavStyles) => (props.mobile ? 55 : 'auto'),
    width: '100%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  mobileNavLinkSection: {
    minHeight: mobileMainNavHeight,
    maxHeight: mobileMainNavHeight,
    width: (props: IHeaderNavStyles) => `calc(100% / ${props.navLinkAmount})`,
  },
  navSectionSelected: {
    background: Colors.white,
    borderBottomLeftRadius: 50,
    borderTopLeftRadius: 50,
  },
  mobileNavSectionSelected: {
    background: Colors.white,
    minHeight: mobileMainNavHeight,
    maxHeight: mobileMainNavHeight,
  },
  mobileNavSectionUnselected: {
    minHeight: mobileMainNavHeight,
    maxHeight: mobileMainNavHeight,
  },
  navSection: {
    minHeight: 72,
    maxHeight: 72,
  },
  profilePic: {
    maxWidth: 100,
    minWidth: 100,
    maxHeight: 100,
    minHeight: 100,
    borderRadius: '50%',
  },
  profilePicBacking: {
    marginTop: 100,
    borderRadius: '50%',
    maxWidth: 106,
    minWidth: 106,
    maxHeight: 106,
    minHeight: 106,
    background: Colors.white,
  },
  navHeader: {
    marginBottom: 30,
  },
  navIcon: {
    fontSize: 30,
  },
  logo: {
    marginTop: 40,
    maxHeight: 90,
    width: 90,
    height: 'auto',
  },
});

interface INavConfig {
  isCurrentRoute: boolean;
  isVisible: boolean;
  route: string;
  icon: React.ReactNode;
  title: string;
}

export const HeaderNav = () => {
  const selectedRoute = useLocation().pathname;
  const firstName = useSelector((state: IStoreTypes) => state.user.firstName);
  const loggedIn = useSelector((state: IStoreTypes) => state.user.loggedIn);
  const customLogo = useSelector((state: IStoreTypes) => state.user?.customLogo);
  const companyName = useSelector((state: IStoreTypes) => state.user.companyName);
  const validToken =
    useSelector((state: IStoreTypes) => state.user?.tokenExp) > new Date().getTime() / 1000;
  const programInfo = useSelector((state: IStoreTypes) => state.program);
  const isBrancherAdmin = useSelector((state: IStoreTypes) =>
    state.user?.positions?.includes(AllPositions.BRANCHER_ADMIN),
  );
  const hasGroups = programInfo?.[UpdatableProgramInfo.SUBSCRIPTION]?.includes(
    Subscriptions.GROUPS,
  );
  const isAdhocPairing = programInfo?.[UpdatableProgramInfo.SUBSCRIPTION]?.includes(
    Subscriptions.ADHOC_PAIRING,
  );
  const hasTraining = programInfo?.[UpdatableProgramInfo.SUBSCRIPTION]?.includes(
    Subscriptions.TRAINING,
  );
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const dispatch = useDispatch();
  const hasLogo = !!customLogo;

  const {
    home,
    program,
    superUser,
    superUserDashboard,
    dashboard,
    programUsers,
    user,
    timeline,
    settings,
    groups,
    group,
    supportRequests,
    mentoringRequests,
    surveys,
    survey,
    trainingDashboard,
    leaderboard,
    matching,
    pairs,
    pair,
    resources,
  } = RouteMaster;

  const isGroupsPath: boolean = selectedRoute === groups.path || selectedRoute === group.path;
  const isUserPath: boolean = selectedRoute === programUsers.path || selectedRoute === user.path;
  const isDashboardPath: boolean =
    selectedRoute === dashboard.path || selectedRoute === mentoringRequests.path;
  const isSurveyPath: boolean = selectedRoute === surveys.path || selectedRoute === survey.path;
  const isPairsPath: boolean = selectedRoute === pairs.path || selectedRoute === pair.path;
  const isSuperUserPath: boolean =
    selectedRoute === superUser.path || selectedRoute === superUserDashboard.path;

  const navConfig: INavConfig[] = [
    {
      route: home.path,
      isCurrentRoute: selectedRoute === home.path,
      icon: <HomeIcon selected={selectedRoute === home.path} />,
      title: 'Home',
      isVisible: loggedIn && validToken,
    },
    {
      route: dashboard.path,
      isCurrentRoute: isDashboardPath,
      icon: <Dashboard color={isDashboardPath ? 'primary' : 'secondary'} />,
      title: 'Dashboard',
      isVisible: programInfo?.launchedProgram,
    },
    {
      route: program.path,
      isCurrentRoute: selectedRoute === program.path,
      icon: <LocalLibrary color={selectedRoute === program.path ? 'primary' : 'secondary'} />,
      title: 'Program',
      isVisible: !!programInfo?.programId && !programInfo?.launchedProgram,
    },
    {
      route: programUsers.path,
      isCurrentRoute: isUserPath,
      icon: <Person color={isUserPath ? 'primary' : 'secondary'} />,
      title: 'Users',
      isVisible: !!programInfo?.programId,
    },
    {
      route: pairs.path,
      isCurrentRoute: isPairsPath,
      icon: <People color={isPairsPath ? 'primary' : 'secondary'} />,
      title: 'Pairs',
      isVisible: !!programInfo?.programId && programInfo?.launchedProgram,
    },
    {
      route: groups.path,
      isCurrentRoute: isGroupsPath,
      icon: <GroupWork color={isGroupsPath ? 'primary' : 'secondary'} />,
      title: 'Groups',
      isVisible: !!programInfo?.programId && hasGroups,
    },
    {
      route: leaderboard.path,
      isCurrentRoute: selectedRoute === leaderboard.path,
      icon: <EmojiEvents color={selectedRoute === leaderboard.path ? 'primary' : 'secondary'} />,
      title: 'Leaderboard',
      isVisible: !!programInfo?.programId,
    },
    {
      route: supportRequests.path,
      isCurrentRoute: selectedRoute === supportRequests.path,
      icon: <LiveHelp color={selectedRoute === supportRequests.path ? 'primary' : 'secondary'} />,
      title: 'Support requests',
      isVisible: !!programInfo?.programId && !!programInfo?.[UpdatableProgramInfo.ACTIVE],
    },
    {
      route: matching.path,
      isCurrentRoute: selectedRoute === matching.path,
      icon: <GroupAdd color={selectedRoute === matching.path ? 'primary' : 'secondary'} />,
      title: 'Matching',
      isVisible: !!programInfo?.programId && !!programInfo?.[UpdatableProgramInfo.LAUNCHED_PROGRAM],
    },
    {
      route: surveys.path,
      isCurrentRoute: isSurveyPath,
      icon: <NotesIcon selected={isSurveyPath} />,
      title: 'Surveys',
      isVisible: !!programInfo?.programId && !!programInfo?.[UpdatableProgramInfo.ACTIVE],
    },
    {
      route: trainingDashboard.path,
      isCurrentRoute: selectedRoute === trainingDashboard.path,
      icon: <TrainingIcon selected={selectedRoute === trainingDashboard.path} />,
      title: 'Training',
      isVisible: !!programInfo?.programId && hasTraining,
    },
    {
      route: timeline.path,
      isCurrentRoute: selectedRoute === timeline.path,
      icon: <Timeline color={selectedRoute === timeline.path ? 'primary' : 'secondary'} />,
      title: 'Timeline',
      isVisible: !!programInfo?.programId && !isAdhocPairing,
    },
    {
      route: settings.path,
      isCurrentRoute: selectedRoute === settings.path,
      icon: <Settings color={selectedRoute === settings.path ? 'primary' : 'secondary'} />,
      title: 'Settings',
      isVisible: !!programInfo?.programId,
    },
    {
      route: resources.path,
      isCurrentRoute: selectedRoute === resources.path,
      icon: <MenuBook color={selectedRoute === resources.path ? 'primary' : 'secondary'} />,
      title: 'Resources',
      isVisible: !!programInfo?.programId,
    },
    {
      route: superUser.path,
      isCurrentRoute: isSuperUserPath,
      icon: <SupervisedUserCircle color={isSuperUserPath ? 'primary' : 'secondary'} />,
      title: 'Super User',
      isVisible: isBrancherAdmin,
    },
  ];
  const navLinkAmount = navConfig.filter((c) => c.isVisible).length;
  const styles = useStyles({ mobile, navLinkAmount, hasLogo });

  const config = [];
  if (!mobile && validToken) {
    navConfig.forEach((nav, index) => {
      if (nav.isVisible) {
        config.push(
          <Link to={nav.route} className={styles.navLinkSection} key={index}>
            <Grid item container xs={12} justifyContent="space-between">
              <Grid item container xs={1} />
              <Grid
                item
                container
                xs={11}
                justifyContent="center"
                alignItems="center"
                className={cx(styles.navSection, {
                  [styles.navSectionSelected]: nav.isCurrentRoute,
                })}
              >
                <Grid item xs={4}>
                  <SvgIcon
                    className={cx(
                      styles.navIcon,
                      nav.isCurrentRoute ? styles.selected : styles.unselected,
                    )}
                  >
                    {nav.icon}
                  </SvgIcon>
                </Grid>
                <Grid item xs={6}>
                  <Text
                    variant="xs"
                    fontWeight={nav.isCurrentRoute ? 600 : 500}
                    color={nav.isCurrentRoute ? 'purple' : 'white'}
                    noPreWrap
                  >
                    {nav.title}
                  </Text>
                </Grid>
              </Grid>
            </Grid>
          </Link>,
        );
      }
    });
  } else {
    navConfig.forEach((nav, index) => {
      if (nav.isVisible) {
        config.push(
          <Link to={nav.route} className={styles.mobileNavLinkSection} key={index}>
            <Grid
              item
              container
              xs={12}
              justifyContent="center"
              alignItems="center"
              className={cx(styles.mobileNavSectionUnselected, {
                [styles.mobileNavSectionSelected]: nav.isCurrentRoute,
              })}
            >
              <SvgIcon
                className={cx(
                  styles.navIcon,
                  nav.isCurrentRoute ? styles.selected : styles.unselected,
                )}
              >
                {nav.icon}
              </SvgIcon>
            </Grid>
          </Link>,
        );
      }
    });
  }

  return (
    <AppBar className={styles.navBar} position="fixed">
      <Grid container alignItems="center" item>
        {!mobile && (
          <Grid
            container
            item
            direction="column"
            alignItems="center"
            justifyContent="center"
            className={styles.navHeader}
          >
            {hasLogo && (
              <Grid item container justifyContent="center">
                <img
                  src={`${GetLinkBase()}${customLogo}`}
                  alt={`${companyName}-logo`}
                  className={styles.logo}
                />
              </Grid>
            )}
            <Grid item>
              <Text variant="xs" color="white" fontWeight={400} marginTop={40}>
                Welcome back,
              </Text>
              <Text variant="lg" fontWeight={600} color="white" align="center">
                {firstName}
              </Text>
            </Grid>
            <Grid item container xs={8} justifyContent="center">
              <LogoutButton onClick={() => BrancherDispatch(dispatch, UtilSignOut())} />
            </Grid>
          </Grid>
        )}
        <Grid
          container
          justifyContent="center"
          direction={mobile ? 'row' : 'column'}
          alignItems="center"
          item
        >
          {config}
          {mobile && (
            <Grid item container xs={11} justifyContent="flex-end">
              <LogoutButton onClick={() => BrancherDispatch(dispatch, UtilSignOut())} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </AppBar>
  );
};
