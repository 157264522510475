import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { Page } from '../../Components/General/Page';
import {
  BrancherDispatch,
  UtilGetProgramGroup,
  UtilRemoveUserFromGroup,
} from '../../store/actions';
import { Text } from '../../Components/General/Text';
import { BackButton } from '../../Components/InputFields/BrancherButton';
import { ProgramPositions } from '../../consts/ProgramPositionOptions';
import { IGroup, IGroupMember } from './Groups';
import { EProfileAttributes, IProfileData } from '../../types/ProfileTypes';
import { Profile } from '../Leaderboard/Profile';
import { ProfileCard } from '../Leaderboard/ProfileCard';
import { BrancherDialog } from '../../Components/General/BrancherDialog';
import { ConfirmDialog } from '../../Components/General/ConfirmDialog';
import { AddMenteeToGroup } from './AddMenteeToGroup';
import { AddMentorToGroup } from './AddMentorToGroup';
import { BrancherSnackbar } from '../../Components/General/BrancherSnackbar';

const useStyles = makeStyles({
  marginHeading: {
    marginBottom: 40,
    marginTop: 40,
  },
});

interface IExtendedGroupMember extends IGroupMember {
  profile: IProfileData;
}

export interface IExtendedGroupData extends Omit<IGroup, 'mentees' | 'mentors' | 'peers'> {
  mentees: IExtendedGroupMember[];
  mentors: IExtendedGroupMember[];
  peers?: IExtendedGroupMember[];
}

export const Group: React.FC = () => {
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [gettingGroup, setGettingGroup] = React.useState<boolean>(true);
  const [removingUserFromGroup, setRemovingUserFromGroup] = React.useState<boolean>(false);
  const [groupData, setGroupData] = React.useState<IExtendedGroupData>(null);
  const groupId = new URLSearchParams(useLocation().search).get('g');
  const locationHistory = useHistory();
  const dispatch = useDispatch();
  const [profileOpen, setProfileOpen] = React.useState<boolean>(false);
  const [confirmRemoveUserDialog, setConfirmRemoveUserDialog] = React.useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState<string>('');
  const [selectedUser, setSelectedUser] = React.useState<IProfileData | null>();
  const [userToRemove, setUserToRemove] = React.useState<
    IGroupMember & { position: ProgramPositions }
  >(null);
  const styles = useStyles();

  React.useEffect(() => {
    if (!!groupId) {
      getGroupData();
    }
  }, []);

  const getGroupData = () => {
    setGettingGroup(true);
    BrancherDispatch(
      dispatch,
      UtilGetProgramGroup(groupId, (groupRes) => {
        setGroupData(groupRes.data);
        setGettingGroup(false);
      }),
    );
  };

  const controlRemoveUser = (user: IGroupMember, position: ProgramPositions) => {
    if (groupData.ownerName === user.name) {
      setSnackbarMessage(
        ` You must assign a new owner of the group before removing ${user.name} from the group.`,
      );
      setSnackbarOpen(true);
    } else {
      setUserToRemove({ ...user, position });
      setConfirmRemoveUserDialog(true);
    }
  };

  const removeUserFromGroup = () => {
    setRemovingUserFromGroup(true);
    BrancherDispatch(
      dispatch,
      UtilRemoveUserFromGroup(
        { roleId: userToRemove.roleId, joined: userToRemove.joined, name: userToRemove.name },
        userToRemove.position,
        groupId,
        (updatedGroup) => {
          setGroupData(updatedGroup.data);
          setRemovingUserFromGroup(false);
          setCloseRemovePairDialog();
        },
      ),
    );
  };

  const addUserToGroup = (groupData: IExtendedGroupData) => {
    setGroupData(groupData);
  };

  const setCloseRemovePairDialog = () => {
    setUserToRemove(null);
    setConfirmRemoveUserDialog(false);
  };

  const controlDialog = (control: boolean) => {
    setProfileOpen(control);
    if (!control) {
      setSelectedUser(null);
    }
  };

  const controlSelectedUser = (user?: IProfileData) => {
    if (!profileOpen) {
      setSelectedUser(user);
      controlDialog(true);
    }
  };

  return (
    <Page loading={gettingGroup || !groupData?.title}>
      <BrancherSnackbar
        controlClose={() => setSnackbarOpen(false)}
        open={snackbarOpen}
        message={snackbarMessage}
      />
      {selectedUser && (
        <BrancherDialog
          open={profileOpen}
          setClose={() => controlDialog(false)}
          labelledBy={selectedUser[EProfileAttributes.FIRST_NAME]}
          fullScreen={mobile}
        >
          <Box right={0} position="absolute" width="10%" marginTop={2}>
            <CloseIcon onClick={() => controlDialog(false)} color="secondary" fontSize="large" />
          </Box>
          <DialogContent>
            <Profile profileData={selectedUser} profileType={selectedUser.profileType} />
          </DialogContent>
        </BrancherDialog>
      )}
      {userToRemove && (
        <ConfirmDialog
          confirmAction={removeUserFromGroup}
          denyAction={setCloseRemovePairDialog}
          setClose={setCloseRemovePairDialog}
          disableActions={removingUserFromGroup}
          open={confirmRemoveUserDialog}
          denyButtonText={`Don't remove ${userToRemove.name}`}
          confirmButtonText={`Remove ${userToRemove.name}`}
          title={`Are you sure you want to remove ${userToRemove.name} from ${groupData.title}?`}
          labelledBy="remove-user-from-group-dialog"
        />
      )}
      <Grid container>
        <Grid container justifyContent="center">
          <Grid item xs={10}>
            <Box mt={5} mb={5}>
              <BackButton onClick={() => locationHistory.goBack()} />
            </Box>
          </Grid>
          <Grid container justifyContent="center" item xs={10}>
            <Grid container alignItems="center">
              <Grid item xs={8} container>
                <Text variant="xl" color="purple" fontWeight={600} marginBottom={20}>
                  {groupData?.title}
                </Text>
              </Grid>
              {groupData?.created && (
                <Grid item xs={4} container justifyContent="flex-end">
                  <Text variant="xs" fontWeight={600}>
                    Created: {format(new Date(groupData?.created), 'dd/MM/yyyy')}
                  </Text>
                </Grid>
              )}
              <Grid container item justifyContent="flex-end">
                <Text variant="sm" display="inline" fontWeight={500}>
                  Group owner:{' '}
                </Text>
                <Text variant="sm" display="inline" fontWeight={600}>
                  {groupData?.ownerName}
                </Text>
              </Grid>

              <Grid container justifyContent="space-between">
                <Grid item xs={11} md={5} container direction="column">
                  <Grid
                    item
                    container
                    justifyContent="space-between"
                    className={styles.marginHeading}
                  >
                    <Grid item>
                      <Text variant="lg" fontWeight={600} display="inline">
                        Mentors
                      </Text>
                    </Grid>
                    <Grid item>
                      <AddMentorToGroup
                        updateGroupData={addUserToGroup}
                        groupId={groupId}
                        currentMentors={groupData?.mentors}
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    {groupData?.mentors?.map((mentor) => (
                      <Grid container alignItems="center" key={mentor.roleId}>
                        <Grid item xs={10}>
                          <ProfileCard
                            onSelectUser={() => controlSelectedUser(mentor.profile)}
                            activityScore={mentor?.profile?.activityScore}
                            firstName={mentor?.profile?.[EProfileAttributes.FIRST_NAME]}
                            lastName={mentor?.profile?.[EProfileAttributes.LAST_NAME]}
                            jobTitle={mentor?.profile?.[EProfileAttributes.JOB_TITLE]}
                            image={mentor?.profile?.[EProfileAttributes.IMAGE]}
                            profileType={ProgramPositions.mentor}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <IconButton
                            onClick={() =>
                              controlRemoveUser(
                                { roleId: mentor.roleId, joined: mentor.joined, name: mentor.name },
                                ProgramPositions.mentor,
                              )
                            }
                          >
                            <Delete color="error" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid item xs={11} md={5} container direction="column">
                  <Grid
                    item
                    container
                    justifyContent="space-between"
                    className={styles.marginHeading}
                  >
                    <Grid item>
                      <Text variant="lg" fontWeight={600} display="inline">
                        Mentees
                      </Text>
                    </Grid>
                    <Grid item>
                      <AddMenteeToGroup
                        updateGroupData={addUserToGroup}
                        groupId={groupId}
                        currentMentees={groupData?.mentees}
                        currentMentors={groupData?.mentors}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container>
                    {groupData?.mentees?.map((mentee) => (
                      <Grid container alignItems="center" key={mentee.roleId}>
                        <Grid item xs={10}>
                          <ProfileCard
                            onSelectUser={() => controlSelectedUser(mentee.profile)}
                            activityScore={mentee?.profile?.activityScore}
                            firstName={mentee?.profile?.[EProfileAttributes.FIRST_NAME]}
                            lastName={mentee?.profile?.[EProfileAttributes.LAST_NAME]}
                            jobTitle={mentee?.profile?.[EProfileAttributes.JOB_TITLE]}
                            image={mentee?.profile?.[EProfileAttributes.IMAGE]}
                            profileType={ProgramPositions.mentee}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <IconButton
                            onClick={() =>
                              controlRemoveUser(
                                { roleId: mentee.roleId, joined: mentee.joined, name: mentee.name },
                                ProgramPositions.mentee,
                              )
                            }
                          >
                            <Delete color="error" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};
