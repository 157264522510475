import { BrancherAuthRequest } from './utils/BrancherDispatch';
import { IReportColumn, IReportInfo, SAVE_REPORTING_DATA } from '../reducers/ReportReducer';
import { EGenerateReport } from '../reducers/ReportReducer';
import { IAPIResponseObject } from './actionTypes/apiTypes';

export const SaveReportData = (reportData: IReportInfo) => {
  return {
    type: SAVE_REPORTING_DATA,
    payload: reportData,
  };
};

// This generates the specified report
export const UtilGenerateReport = (reportType: EGenerateReport, cb: (a: any) => void) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/generatereport',
        params: JSON.stringify({
          programId,
          reportType,
        }),
      },
      getState(),
    ).then(async (response: any) => {
      await dispatch(SaveReportData(response.data.data));
      cb(response.data.data);
    });
  };
};

interface IUtilGenerateMasterPairingSatisfactionReport extends IAPIResponseObject {
  data: {
    pairingSatisfactionReportData: any[];
    pairingSatisfactionReportColumns: IReportColumn[];
  };
}

// This generates the master pairing satisfaction report
export const UtilGenerateMasterPairingSatisfactionReport = (
  cb: (a: IUtilGenerateMasterPairingSatisfactionReport['data']) => void,
) => {
  return (dispatch: any, getState: any) => {
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/mastermatchingsatisfaction',
        params: JSON.stringify({}),
      },
      getState(),
    ).then((response: any) => {
      cb(response.data.data);
    });
  };
};
