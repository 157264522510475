import * as React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Text,
} from 'recharts';

export interface IChartData {
  name: string;
  value?: number;
}

interface ISimpleLineChart {
  data: IChartData[];
  xValueLabel: string;
  yValueLabel: string;
  height?: number;
}

export const SimpleLineChart = (props: ISimpleLineChart) => {
  const { data, height, xValueLabel, yValueLabel } = props;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const chartRef = React.useRef(null);

  const modifiedDataLabel = data?.map((d) => ({
    [yValueLabel]: d.name,
    [xValueLabel]: d.value,
  }));

  return (
    <div ref={chartRef}>
      <ResponsiveContainer width="100%" height={height ? height : mobile ? 250 : 425}>
        <LineChart
          width={chartRef?.current?.clientWidth ?? 320}
          height={height ? height : mobile ? 300 : 425}
          data={modifiedDataLabel}
          margin={{
            right: 15,
            left: -15,
            top: 5,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={yValueLabel} />
          <YAxis
            allowDecimals={false}
            dataKey={xValueLabel}
            label={<CustomizedLabelB label={yValueLabel} />}
          />
          <Tooltip />
          {/*<Legend />*/}
          <Line
            dataKey={xValueLabel}
            stroke={theme.palette.primary.main}
            type="monotone"
            activeDot={{ r: 5 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export const CustomizedLabelB = ({ label }) => {
  return (
    <Text x={0} y={0} dx={20} dy={180} textAnchor="start" width={180} angle={270}>
      {label}
    </Text>
  );
};
