import {
  ICompaniesInfo,
  ICompany,
  ISimpleProgramHealthData,
  SAVE_COMPANIES_DATA,
  UpdatableCompanyInfo,
} from '../reducers/CompaniesReducer';
import { PlatformTypes } from '../../types/PlatformTypes';
import { BrancherAuthRequest } from './utils/BrancherDispatch';
import { Subscriptions } from '../reducers/ProgramReducer';
import { IAPIResponseObject } from './actionTypes/apiTypes';

export const SaveCompaniesData = (companyData: ICompaniesInfo) => {
  return {
    type: SAVE_COMPANIES_DATA,
    payload: companyData,
  };
};

// This retrieves a program list for a company
export const UtilGetAllCompanyPrograms = (cb: (a: ISimpleProgramHealthData) => void) => {
  return (dispatch: any, getState: any) => {
    const selectedCompanyId = getState().user?.selectedCompanyId;
    const companyId = !!selectedCompanyId ? selectedCompanyId : getState().user.companyId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/allcompanyprograms',
        params: JSON.stringify({
          companyId,
        }),
      },
      getState(),
    ).then(async (response: any) => {
      const programsData = response?.data?.data ?? [];
      if (response.data?.success) {
        dispatch(SaveCompaniesData({ programs: programsData }));
      }
      cb(programsData);
    });
  };
};

interface IGetAllCompanies extends IAPIResponseObject {
  activeProgramAmount: number;
}

// This is just for BrancherAdmin - special API to retrieve all companies
export const UtilGetAllCompanies = (cb: (a: IGetAllCompanies) => void) => {
  return (dispatch: any, getState: any) => {
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/companies',
        params: JSON.stringify({}),
      },
      getState(),
    ).then((response: any) => {
      dispatch(SaveCompaniesData({ companyList: response.data.data }));
      cb(response.data.data);
    });
  };
};

// This is just for BrancherAdmin - special API to create a company
export const UtilCreateCompany = (
  companyName: string,
  googleSSO: boolean,
  samlSSO: boolean,
  passwordAuth: boolean,
  whiteLabel: boolean,
  logo: string, // base64 encoded
  mfaEnabled: boolean,
  domainEmail: string = '',
  cb: (a: any) => void,
) => {
  return (dispatch: any, getState: any) => {
    const allCompanies = getState().companies.companyList;
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'v2/company',
        data: {
          companyName,
          mfaEnabled,
          [UpdatableCompanyInfo.GOOGLE_SSO]: googleSSO,
          [UpdatableCompanyInfo.SAML_SSO]: samlSSO,
          [UpdatableCompanyInfo.PASSWORD_AUTH]: passwordAuth,
          [UpdatableCompanyInfo.WHITE_LABEL]: whiteLabel,
          [UpdatableCompanyInfo.CUSTOM_LOGO]: logo,
          [UpdatableCompanyInfo.DOMAIN_EMAIL]: domainEmail,
        },
      },
      getState(),
    ).then((response: any) => {
      dispatch(SaveCompaniesData({ companyList: [...allCompanies, response.data.data] }));
      cb(response.data.data);
    });
  };
};

// This is just for BrancherAdmin - special API to update a company
export const UtilUpdateCompany = (
  companyName: string,
  googleSSO: boolean,
  samlSignOn: boolean,
  passwordAuth: boolean,
  whiteLabel: boolean,
  logo: string, // base64 encoded
  newLogo: boolean,
  domainEmail: string[],
  disabled: boolean,
  cb: (a: any) => void,
) => {
  return (dispatch: any, getState: any) => {
    const companyId = getState().user.selectedCompanyId;
    const allCompanies = getState().companies.companyList;
    BrancherAuthRequest(
      {
        method: 'put',
        url: 'v2/company',
        data: {
          companyName,
          companyId,
          [UpdatableCompanyInfo.GOOGLE_SSO]: googleSSO,
          [UpdatableCompanyInfo.SAML_SSO]: samlSignOn,
          [UpdatableCompanyInfo.PASSWORD_AUTH]: passwordAuth,
          [UpdatableCompanyInfo.WHITE_LABEL]: whiteLabel,
          [UpdatableCompanyInfo.CUSTOM_LOGO]: logo,
          [UpdatableCompanyInfo.DOMAIN_EMAIL]: domainEmail,
          [UpdatableCompanyInfo.DISABLED]: disabled,
          newLogo: newLogo,
        },
      },
      getState(),
    ).then((response: any) => {
      const updatedCompanies = allCompanies.map((a) =>
        a.companyId === companyId ? response.data.data : a,
      );
      dispatch(SaveCompaniesData({ companyList: updatedCompanies }));
      cb(response.data.data);
    });
  };
};

// This is just for BrancherAdmin - special API to create a program for a company
export const UtilCreateProgram = (
  programName: string,
  programType: PlatformTypes,
  subscription: Subscriptions[],
  programLengthMonths: number,
  supportEmail: string,
  hasPlatformAccess: boolean,
  duplicatedProgram: string,
  applicationAlwaysOpen: boolean,
  copyDuplicateProgramMentors: boolean,
  copyDuplicateProgramMentees: boolean,
  maximumUserCapHard: number,
  maximumUserCapSoft: number,
  subscriptionEndDate: string,
  cb: (a: any) => void,
) => {
  return (dispatch: any, getState: any) => {
    const companyId = getState().user.selectedCompanyId;
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'v2/program',
        data: {
          programName,
          programType,
          companyId,
          subscription,
          programLengthMonths,
          supportEmail,
          hasPlatformAccess,
          duplicatedProgram,
          applicationAlwaysOpen,
          copyDuplicateProgramMentors,
          copyDuplicateProgramMentees,
          maximumUserCapHard,
          maximumUserCapSoft,
          subscriptionEndDate,
        },
      },
      getState(),
    ).then((response: any) => {
      const allPrograms = getState().companies?.programs ?? [];
      const combinedPrograms =
        allPrograms?.length > 0 ? [...allPrograms, response.data.data] : [response.data.data];
      dispatch(SaveCompaniesData({ programs: combinedPrograms }));
      cb(response.data.data);
    });
  };
};

// This is just for BrancherAdmin - special API to update a company
export const UtilSaveCompany = (
  companyData: Omit<ICompany, 'companyId' | UpdatableCompanyInfo.PROGRAM_IDS>,
  cb: (a: any) => void,
) => {
  return (dispatch: any, getState: any) => {
    const allCompanies = getState().companies.companyList;
    const companyId = getState().user.selectedCompanyId;
    BrancherAuthRequest(
      {
        method: 'put',
        url: 'v2/company',
        data: {
          companyId,
          ...companyData,
        },
      },
      getState(),
    ).then((response: any) => {
      const updatedCompanyList = allCompanies.map((a) =>
        a.companyId === companyId ? response.data.data : a,
      );
      dispatch(SaveCompaniesData({ companyList: updatedCompanyList }));
      cb(response.data.data);
    });
  };
};
