import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {
  EAlgorithmType,
  EComponentType,
  IAlgorithmConfig,
  IAlgorithmType,
  IDefaultValue,
  IDefaultValueType,
  IFormConfigDefaultValues,
  IFormQuestionsConfiguration,
  IGeneralQuestionsConfig,
  IToggle,
} from '../../../store/reducers/ProgramReducer';
import { Text } from '../../../Components/General/Text';
import { SegmentedControlGroup } from '../../../Components/InputFields/BrancherSegmentedControl';
import { EChosenProfile, ReportingProfileOptions } from '../../Dashboard/Dashboard';
import { BrancherSwitch } from '../../../Components/InputFields/BrancherSwitch';
import { BrancherTextField } from '../../../Components/InputFields/BrancherTextField';
import { BrancherSelect } from '../../../Components/InputFields/BrancherSelect';
import { QuestionAnswerOptions } from './QuestionAnswerOptions';
import { SaveButton } from '../../../Components/InputFields/BrancherButton';
import { BrancherDivider } from '../../../Components/General/BrancherDivider';

interface IQuestionConfiguration {
  selectedQuestion: string;
  questionConfig: IFormQuestionsConfiguration;
  questionAlgorithmConfig: IAlgorithmConfig;
  defaultValues: IFormConfigDefaultValues;
  removeQuestionAlgorithm: (question: string) => void;
  saveQuestion: (newConfig: IGeneralQuestionsConfig, prevQuestionName: string) => void;
  saveQuestionAlgorithm: (newConfigAlgorithm: IAlgorithmType, prevQuestionName: string) => void;
  saveDefaultValue: (
    newDefaultValue: IDefaultValue,
    visibleTo: EChosenProfile,
    prevQuestionName: string,
  ) => void;
}

const useStyles = makeStyles({
  dependentQuestions: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

export const QuestionConfiguration: React.FC<IQuestionConfiguration> = (props) => {
  const {
    questionConfig,
    questionAlgorithmConfig,
    saveQuestion,
    saveQuestionAlgorithm,
    selectedQuestion,
    saveDefaultValue,
    defaultValues,
    removeQuestionAlgorithm,
  } = props;
  const questionActualAlgorithmConfig = questionAlgorithmConfig.general.find(
    (a) => a.attributeName === selectedQuestion,
  );
  const questionActualConfig = questionConfig.general.find((a) => a.name === selectedQuestion);
  const [visibleTo, setVisibleTo] = React.useState<EChosenProfile>(EChosenProfile.ALL);
  const questionActualDefaultValue =
    visibleTo === EChosenProfile.ALL || visibleTo === EChosenProfile.MENTEE
      ? defaultValues.general?.mentee.find((a) => a.key === selectedQuestion)
      : defaultValues.general?.mentor.find((a) => a.key === selectedQuestion);
  const [componentType, setComponentType] = React.useState<EComponentType>(
    questionActualConfig?.componentType,
  );
  const [hasAlgorithm, setHasAlgorithm] = React.useState<boolean>(false);
  const [defaultValue, setDefaultValue] = React.useState<IDefaultValueType>(null);
  const [mandatory, setMandatory] = React.useState<boolean>(false);
  const [multipleSelectionExclusive, setMultipleSelectionExclusive] = React.useState<boolean>();
  const [hasDependentQuestion, setHasDependentQuestion] = React.useState<boolean>(
    Boolean(questionActualConfig?.secondaryAttributeName),
  );
  const [dependentQuestion, setDependentQuestion] = React.useState<string>();
  const [algorithmType, setAlgorithmType] = React.useState<EAlgorithmType>();
  const [multipleSelectionAmount, setMultipleSelectionAmount] = React.useState<number>(1);
  const [algorithmWeight, setAlgorithmWeight] = React.useState<number>(1);
  const [reportingName, setReportingName] = React.useState<string>();
  const [question, setQuestion] = React.useState<string>();
  const [description, setDescription] = React.useState<string>();
  const [options, setOptions] = React.useState<IToggle['options']>();
  const [openOptionAccordionParentIndex, setOpenOptionAccordionParentIndex] =
    React.useState<number>();
  const [openOptionAccordionSecondaryIndex, setOpenOptionAccordionSecondaryIndex] =
    React.useState<number>();
  const [hasSecondaryDependentOption, setHasSecondaryDependentOption] = React.useState<boolean>();
  const styles = useStyles();

  React.useEffect(() => {
    const questionVisibleTo =
      questionActualConfig?.isMentor && questionActualConfig?.isMentee
        ? EChosenProfile.ALL
        : questionActualConfig?.isMentor
        ? EChosenProfile.MENTOR
        : EChosenProfile.MENTEE;
    setVisibleTo(questionVisibleTo ?? EChosenProfile.ALL);
    setReportingName(questionActualConfig?.readableName ?? '');
    setQuestion(questionActualConfig?.question ?? '');
    setComponentType(questionActualConfig?.componentType ?? EComponentType.TOGGLE);
    setDefaultValue(questionActualDefaultValue?.value ?? null);
    setDescription(questionActualConfig?.description ?? '');
    if (questionActualConfig?.componentType === EComponentType.TOGGLE) {
      setAlgorithmWeight(questionActualAlgorithmConfig?.weight ?? 1);
      setHasAlgorithm(!!questionActualAlgorithmConfig?.type);
      setMandatory(!!questionActualAlgorithmConfig?.mandatory);
      setDependentQuestion(questionActualConfig?.conditionalOptionsField);
      setHasDependentQuestion(!!questionActualConfig?.conditionalOptions);
      setHasSecondaryDependentOption(!!questionActualConfig?.conditionalOptionsSecondaryField);
      setOptions(questionActualConfig.options ?? []);
      setAlgorithmType(questionActualAlgorithmConfig?.type);
      setMultipleSelectionExclusive(
        !questionActualConfig?.exclusive && questionActualConfig?.maximumAmount > 1,
      );
      setMultipleSelectionAmount(
        !questionActualConfig?.exclusive ? questionActualConfig?.maximumAmount ?? 1 : 1,
      );
    }
  }, [selectedQuestion]);

  const CamelCase = (name: string): string => {
    const lowerCaseName = name.toLowerCase();
    return lowerCaseName.replace(/\s+(.)/g, (match, group) => {
      return group.toUpperCase();
    });
  };

  const saveQuestionConfig = () => {
    const actualQuestionUniqueName = CamelCase(reportingName);
    if (hasAlgorithm) {
      saveQuestionAlgorithm(
        {
          ...questionActualAlgorithmConfig,
          mandatory,
          type: algorithmType,
          weight: algorithmWeight,
          attributeName: actualQuestionUniqueName,
          readableName: reportingName,
        },
        actualQuestionUniqueName,
      );
    } else if (!!questionActualAlgorithmConfig) {
      removeQuestionAlgorithm(selectedQuestion);
    }
    const newCompleteQuestionConfig = (
      componentType === EComponentType.TOGGLE
        ? {
            ...questionActualConfig,
            mandatory,
            componentType,
            question,
            readableName: reportingName,
            description,
            name: actualQuestionUniqueName,
            isMentee: visibleTo === EChosenProfile.ALL || visibleTo === EChosenProfile.MENTEE,
            isMentor: visibleTo === EChosenProfile.ALL || visibleTo === EChosenProfile.MENTOR,
            options,
            conditionalOptions: hasDependentQuestion,
            conditionalOptionsField: dependentQuestion,
            conditionalOptionsSecondaryField: (
              questionConfig.general.find((d) => d.name === dependentQuestion) as IToggle
            )?.conditionalOptionsField,
            exclusive: !(componentType === EComponentType.TOGGLE && multipleSelectionExclusive),
            maximumAmount: componentType === EComponentType.TOGGLE && multipleSelectionAmount,
          }
        : componentType === EComponentType.TEXT && {
            ...questionActualConfig,
            mandatory,
            componentType,
            question,
            readableName: reportingName,
            description,
            name: actualQuestionUniqueName,
            isMentee: visibleTo === EChosenProfile.ALL || visibleTo === EChosenProfile.MENTEE,
            isMentor: visibleTo === EChosenProfile.ALL || visibleTo === EChosenProfile.MENTOR,
            fullWidth: true,
          }
    ) as IGeneralQuestionsConfig;
    saveQuestion(newCompleteQuestionConfig, actualQuestionUniqueName);
    saveDefaultValue(
      { key: actualQuestionUniqueName, value: defaultValue },
      visibleTo,
      actualQuestionUniqueName,
    );
  };

  const getDependentToggleQuestions = (): IToggle['options'] => {
    return (questionConfig.general.find((d) => d.name === dependentQuestion) as IToggle)?.options;
  };

  const getSecondaryParentDependentToggleQuestions = (): IToggle['options'] => {
    const topLevelQuestionOptions = (
      questionConfig.general.find((d) => d.name === dependentQuestion) as IToggle
    )?.conditionalOptionsField;
    return (questionConfig.general.find((d) => d.name === topLevelQuestionOptions) as IToggle)
      ?.options;
  };

  const setNewOptions = (selectedIndexOptions: string[]) => {
    if (openOptionAccordionSecondaryIndex !== null) {
      setHasSecondaryDependentOption(true);
      const opts = options ?? getDependentToggleQuestions();
      const MDOptions = opts?.map((sub, parent) =>
        parent !== openOptionAccordionParentIndex
          ? sub
          : sub.map((child, secondary) =>
              secondary === openOptionAccordionSecondaryIndex ? selectedIndexOptions : child ?? [],
            ),
      );
      setOptions(MDOptions);
    } else {
      setOptions(
        options?.map((a, i) => (i === openOptionAccordionParentIndex ? selectedIndexOptions : a)),
      );
    }
  };

  const createSecondaryOptions = (): IToggle['options'] => {
    return typeof options?.[openOptionAccordionParentIndex]?.[openOptionAccordionSecondaryIndex] ===
      'object'
      ? (options[openOptionAccordionParentIndex][openOptionAccordionSecondaryIndex] as any)
      : ([] as IToggle['options']);
  };

  return (
    <Grid container>
      <Grid item container xs={12} justifyContent="space-between" alignItems="center">
        <Text variant="sm" fontWeight={600}>
          Visible to
        </Text>
        <SegmentedControlGroup
          value={visibleTo}
          updateValue={(v: EChosenProfile) => setVisibleTo(v)}
          options={ReportingProfileOptions}
        />
      </Grid>

      <BrancherDivider />

      <Grid item container xs={12}>
        <Text variant="md" fontWeight={600} color="purple" marginTop={20} marginBottom={10}>
          Question & Description
        </Text>
      </Grid>
      <Grid item container xs={12}>
        <BrancherTextField
          value={reportingName}
          fullWidth
          updateValue={setReportingName}
          placeholder="Unique name used for reports"
          label="Reporting name"
        />
      </Grid>
      <Grid item container xs={12}>
        <BrancherTextField
          value={question}
          fullWidth
          updateValue={setQuestion}
          placeholder="What is your..."
          label="Question"
        />
      </Grid>
      <Grid item container xs={12}>
        <BrancherTextField
          value={description}
          fullWidth
          updateValue={setDescription}
          placeholder="Your..."
          label="Description"
        />
      </Grid>

      <BrancherDivider marginTop={40} />

      <Grid item container xs={12}>
        <Text variant="md" fontWeight={600} color="purple" marginTop={20} marginBottom={10}>
          Format
        </Text>
      </Grid>
      <Grid item container xs={12}>
        <BrancherSelect
          value={componentType}
          options={[
            { value: EComponentType.TOGGLE, label: 'Selection' },
            { value: EComponentType.TEXT, label: 'Text' },
          ]}
          fullWidth
          name="componentType"
          updateValue={(a: EComponentType) => setComponentType(a)}
          label="Question type"
        />
      </Grid>
      {componentType === EComponentType.TOGGLE && (
        <>
          <Grid item container xs={12} justifyContent="space-between" alignItems="center">
            <Text variant="sm" fontWeight={600}>
              Multiple selection
            </Text>
            <BrancherSwitch
              value={multipleSelectionExclusive}
              updateValue={(a: boolean) => {
                setMultipleSelectionExclusive(a);
                if (!a) {
                  setMultipleSelectionAmount(1);
                  setOptions([]);
                }
              }}
              ariaLabel="multiple-selection"
            />
          </Grid>
          {multipleSelectionExclusive && (
            <Grid item container xs={12}>
              <BrancherTextField
                value={multipleSelectionAmount?.toString()}
                fullWidth
                min={1}
                max={3}
                type="number"
                updateValue={(a: string) => setMultipleSelectionAmount(Number(a))}
                placeholder="Amount of options the user must select"
                label="Selection amount"
              />
            </Grid>
          )}

          <BrancherDivider marginTop={20} marginBottom={20} />

          <Grid item container xs={12} justifyContent="space-between" alignItems="center">
            <Text variant="sm" fontWeight={600}>
              Answers dependent on a previous question
            </Text>
            <BrancherSwitch
              value={hasDependentQuestion}
              updateValue={(a: boolean) => {
                setHasDependentQuestion(a);
                if (!a) {
                  setDependentQuestion('');
                }
              }}
              ariaLabel="hasDependentQuestion"
            />
          </Grid>

          {hasDependentQuestion && (
            <>
              <Grid item container xs={12}>
                <BrancherSelect
                  value={dependentQuestion}
                  options={questionConfig.general
                    .map((a) => ({
                      label: a.readableName,
                      value: a.name,
                    }))
                    .filter(
                      (b) =>
                        b.label !== questionActualConfig.readableName && b.label !== reportingName,
                    )}
                  fullWidth
                  name="dependentQuestion"
                  updateValue={(a: string) => {
                    setDependentQuestion(a);
                    const modifiedOptionsLength = (
                      questionConfig.general[Number(a)] as IToggle
                    )?.options?.map(() => []);
                    setOptions(modifiedOptionsLength);
                  }}
                  label="Dependent on question"
                />
              </Grid>
              <Grid item container xs={12} spacing={2}>
                {getDependentToggleQuestions()?.map((b, optionIndex) => {
                  if (typeof b === 'object' && b?.length > 0) {
                    return (
                      <>
                        <Grid
                          key={optionIndex}
                          item
                          xs={10}
                          onClick={() => {
                            openOptionAccordionParentIndex === optionIndex
                              ? setOpenOptionAccordionParentIndex(null)
                              : setOpenOptionAccordionParentIndex(optionIndex);
                          }}
                          className={styles.dependentQuestions}
                          alignItems="center"
                        >
                          <Grid item container alignItems="center">
                            <Text variant="sm" fontWeight={700} marginRight={10} display="inline">
                              {getSecondaryParentDependentToggleQuestions()[optionIndex]}
                            </Text>
                            {openOptionAccordionParentIndex === optionIndex ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </Grid>
                        </Grid>
                        <Grid item xs={10}>
                          <Collapse
                            in={openOptionAccordionParentIndex === optionIndex}
                            mountOnEnter
                            unmountOnExit
                          >
                            {b?.map((a, secondaryOptionIndex) => (
                              <>
                                <Grid
                                  item
                                  xs={10}
                                  onClick={() => {
                                    openOptionAccordionSecondaryIndex === secondaryOptionIndex
                                      ? setOpenOptionAccordionSecondaryIndex(null)
                                      : setOpenOptionAccordionSecondaryIndex(secondaryOptionIndex);
                                  }}
                                  className={styles.dependentQuestions}
                                  alignItems="center"
                                >
                                  <Grid item container alignItems="center">
                                    <Text
                                      variant="sm"
                                      fontWeight={500}
                                      marginRight={10}
                                      display="inline"
                                    >
                                      {a}
                                    </Text>
                                    {openOptionAccordionSecondaryIndex === secondaryOptionIndex ? (
                                      <ExpandLess />
                                    ) : (
                                      <ExpandMore />
                                    )}
                                  </Grid>
                                </Grid>
                                <Collapse
                                  in={openOptionAccordionSecondaryIndex === secondaryOptionIndex}
                                  mountOnEnter
                                  unmountOnExit
                                >
                                  <QuestionAnswerOptions
                                    options={createSecondaryOptions()}
                                    selectedOptionIndex={openOptionAccordionParentIndex}
                                    selectedSecondaryOptionIndex={openOptionAccordionSecondaryIndex}
                                    setOptions={(selectedIndexOptions: string[]) =>
                                      setNewOptions(selectedIndexOptions)
                                    }
                                    isDependentQuestion={hasDependentQuestion}
                                  />
                                </Collapse>
                              </>
                            ))}
                          </Collapse>
                        </Grid>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <Grid
                          item
                          xs={10}
                          onClick={() => {
                            openOptionAccordionParentIndex === optionIndex
                              ? setOpenOptionAccordionParentIndex(null)
                              : setOpenOptionAccordionParentIndex(optionIndex);
                          }}
                          className={styles.dependentQuestions}
                          alignItems="center"
                        >
                          <Grid item container alignItems="center">
                            <Text variant="sm" fontWeight={500} marginRight={10} display="inline">
                              {b}
                            </Text>
                            {openOptionAccordionParentIndex === optionIndex ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </Grid>
                        </Grid>
                        <Collapse
                          in={openOptionAccordionParentIndex === optionIndex}
                          mountOnEnter
                          unmountOnExit
                        >
                          <QuestionAnswerOptions
                            options={
                              (options?.[openOptionAccordionParentIndex] ??
                                []) as IToggle['options']
                            }
                            selectedOptionIndex={openOptionAccordionParentIndex}
                            setOptions={(selectedIndexOptions: string[]) =>
                              setNewOptions(selectedIndexOptions)
                            }
                            isDependentQuestion={hasDependentQuestion}
                          />
                        </Collapse>
                      </>
                    );
                  }
                })}
              </Grid>
            </>
          )}

          {!hasDependentQuestion && (
            <QuestionAnswerOptions
              options={options}
              setOptions={setOptions}
              defaultValue={defaultValue}
              setDefaultValue={setDefaultValue}
              isDependentQuestion={hasDependentQuestion}
            />
          )}
        </>
      )}

      <BrancherDivider marginTop={40} marginBottom={30} />

      {componentType === EComponentType.TOGGLE && !hasSecondaryDependentOption && (
        <>
          <Text variant="md" color="purple" fontWeight={600} marginBottom={15}>
            Algorithm configuration
          </Text>
          <Grid item container xs={12} justifyContent="space-between" alignItems="center">
            <Text variant="sm" fontWeight={600}>
              Has pairing criteria algorithm
            </Text>
            <BrancherSwitch
              value={hasAlgorithm}
              updateValue={setHasAlgorithm}
              ariaLabel="hasAlgorithm"
            />
          </Grid>
        </>
      )}

      {hasAlgorithm && (
        <>
          <Grid item container xs={12} justifyContent="space-between" alignItems="center">
            <Text variant="sm" fontWeight={600}>
              Deal breaker
            </Text>
            <BrancherSwitch value={mandatory} updateValue={setMandatory} ariaLabel="mandatory" />
          </Grid>

          <Grid item container xs={12}>
            <BrancherSelect
              value={algorithmType}
              options={[
                {
                  value: EAlgorithmType.MATCH_MULTIPLE,
                  label: 'Match as many answers as possible',
                },
                {
                  value: EAlgorithmType.MATCH_EITHER_SIDE,
                  label: 'Match answers one above or one below',
                },
                { value: EAlgorithmType.MATCH_SINGLE, label: 'Must have the same answer' },
                { value: EAlgorithmType.DIFFERENT_SINGLE, label: 'Must have a different answer' },
                {
                  value: EAlgorithmType.MORE_THAN_SINGLE,
                  label: 'Must have an answer down the list',
                },
              ]}
              fullWidth
              name="algorithmType"
              updateValue={(a: EAlgorithmType) => setAlgorithmType(a)}
              label="Algorithm type"
            />
          </Grid>

          <Grid item container xs={12}>
            <BrancherTextField
              value={algorithmWeight?.toString()}
              fullWidth
              min={1}
              max={5}
              type="number"
              updateValue={(a: string) => setAlgorithmWeight(Number(a))}
              placeholder="How important is this..."
              label="Weight (1 is normal, 4 is high)"
            />
          </Grid>
        </>
      )}

      <Grid container justifyContent="flex-end">
        <Box mt={4}>
          <SaveButton onClick={saveQuestionConfig} />
        </Box>
      </Grid>
    </Grid>
  );
};
