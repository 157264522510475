import { Colors } from '../consts/colors';

export const MuiToggleButtonGroup = () => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 40,
    marginTop: 20,
    backgroundColor: Colors.backgroundDarkGrey,
    '& .MuiToggleButtonGroup-grouped:not(:first-child)': {
      marginLeft: 0,
    },
  },
});
