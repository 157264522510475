import * as React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { useDispatch } from 'react-redux';
import { TextControl } from '../ApplicationForm/FormFieldTypes/TextControl';
import { BrancherDispatch, UtilGetSurveyConfiguration } from '../../store/actions';
import { NextButton, PrevButton } from '../../Components/InputFields/BrancherButton';
import { MakeOptions } from '../ApplicationForm/MakeOptions';
import { EComponentType } from '../../store/reducers/ProgramReducer';
import { SelectionControl } from '../ApplicationForm/FormFieldTypes/SelectionControl';
import { Text } from '../../Components/General/Text';
import { BrancherSlider } from '../../Components/InputFields/BrancherSlider';
import { hasDefinedValue } from '../../helpers/valueHelpers';
import { DraggableSection, IDraggableColumn } from '../../Components/General/DraggableSection';

interface ISurveyBuilder {
  surveyConfig: any[];
  surveyValues: any;
  saveSurveyValues: (
    fieldName: string,
    fieldValue: string | string[] | number | IDraggableColumn[],
  ) => void;
}

const SurveyBuilder = ({ surveyConfig, surveyValues, saveSurveyValues }: ISurveyBuilder): any[] => {
  return surveyConfig?.map((f) => {
    let field = {
      ...f,
      value: surveyValues?.[f.name] ?? f.value,
      updateValue: (a: any) => saveSurveyValues(f.name, a),
      question: f.question,
    };
    // For conditional text field
    if (f.conditionalTextField && surveyValues?.[f.name] === f.conditionalTextFieldValue) {
      field.textProps = {
        ...field.textProps,
        value: surveyValues[field.textProps.name],
        updateValue: (a: any) => saveSurveyValues(field.textProps.name, a),
      };
    } else {
      field.conditionalTextField = false;
    }
    // Most complex logic to pay attention to - stateTerritory and state transformations
    if (f.resetsOtherField) {
      field.updateValue = (a: any) => {
        saveSurveyValues(f.resetField, '');
        saveSurveyValues(f.name, a);
      };
    } // Most complex logic to pay attention to - stateTerritory and state transformations
    if (field.options) {
      const opts =
        f.conditionalOptions && surveyValues?.[f.conditionalOptionsField]
          ? f.options?.[surveyValues?.[f.conditionalOptionsField]]
          : f.options;
      field.options = MakeOptions(opts);
    }
    return field;
  });
};

interface ICustomSurvey {
  surveyId: string;
  closeDialog: () => void;
}

export const CustomSurvey: React.FC<ICustomSurvey> = (props) => {
  const { surveyId, closeDialog } = props;
  const [retrievedConfig, setRetrievedConfig] = React.useState<boolean>(false);
  const [stepNumber, setStepNumber] = React.useState<number>(1);
  const [surveyConfig, setSurveyConfig] = React.useState<any[]>([]);
  const [surveyValues, setSurveyValues] = React.useState<any>({});
  const [branchingStepPrev, setBranchingStepPrev] = React.useState<boolean>(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    BrancherDispatch(
      dispatch,
      UtilGetSurveyConfiguration(surveyId, (surveyData) => {
        if (surveyData?.success) {
          setSurveyConfig(surveyData.data.configuration);
        }
        setRetrievedConfig(true);
      }),
    );
  }, []);

  const saveSurveyValues = (attribute: string, value: string | number | IDraggableColumn[]) => {
    setSurveyValues({ ...surveyValues, [attribute]: value });
  };

  const validateValue = (): boolean => {
    const notMandatory = !!surveyConfig[stepNumber - 1]?.notMandatory;
    const currValue = surveyValues?.[surveyConfig[stepNumber - 1]?.name];
    return (
      notMandatory ||
      hasDefinedValue(
        currValue || surveyConfig[stepNumber - 1]?.componentType === EComponentType.INFORMATION,
      )
    );
  };

  const controlNextStep = () => {
    if (stepNumber === surveyConfig.length) {
      closeDialog();
    } else {
      const currValue = surveyValues?.[surveyConfig[stepNumber - 1]?.name];
      if (
        surveyConfig[stepNumber - 1]?.hasBranchingLogic &&
        surveyConfig[stepNumber - 1].branchingOptionValue === currValue
      ) {
        const branchingStepIndex = surveyConfig.findIndex(
          (s) => s.name === surveyConfig[stepNumber - 1].branchingStepName,
        );
        setBranchingStepPrev(true);
        setStepNumber(branchingStepIndex + 1);
      } else if (surveyConfig[stepNumber - 1]?.nextStepName) {
        const branchingStepIndex = surveyConfig.findIndex(
          (s) => s.name === surveyConfig[stepNumber - 1]?.nextStepName,
        );
        setBranchingStepPrev(true);
        setStepNumber(branchingStepIndex + 1);
      } else {
        setBranchingStepPrev(false);
        setStepNumber(stepNumber + 1);
      }
    }
  };

  // TODO: For branching step, how do we manage state of branched step so you can't go back after going forward?
  // Would have to search through the full config tree and find if a brancherStepName references this step and jump to it
  const controlPrevStep = () => {
    setStepNumber(stepNumber - 1);
  };

  return retrievedConfig ? (
    <Grid container alignItems="center" justifyContent="center">
      {stepNumber !== 1 && !branchingStepPrev && (
        <Grid item container xs={12} md={10} justifyContent="flex-start">
          <Grid item xs={5}>
            <Box marginBottom={4}>
              <PrevButton fullWidth onClick={controlPrevStep}>
                Previous
              </PrevButton>
            </Box>
          </Grid>
        </Grid>
      )}
      <Grid container justifyContent="center" item xs={12} md={11} lg={10}>
        {SurveyBuilder({ surveyConfig, surveyValues, saveSurveyValues })?.map((s, i) => {
          if (i + 1 === stepNumber) {
            const {
              readableName,
              notMandatory,
              componentType,
              conditionalTextField = false,
              conditionalTextFieldValue = '',
              ...other
            } = s;
            if (componentType === EComponentType.TOGGLE) {
              return (
                <SelectionControl
                  key={i}
                  conditionalTextField={conditionalTextField}
                  conditionalTextFieldValue={conditionalTextFieldValue}
                  {...other}
                />
              );
            } else if (componentType === EComponentType.TEXT) {
              return <TextControl key={i} {...other} />;
            } else if (componentType === EComponentType.SLIDER) {
              return <BrancherSlider key={i} {...other} />;
            } else if (componentType === EComponentType.DND) {
              return <DraggableSection key={i} {...other} />;
            } else if (componentType === EComponentType.INFORMATION) {
              return (
                <Text key={i} marginBottom={20} variant="md">
                  {other.question}
                </Text>
              );
            }
          }
        })}
      </Grid>
      <Grid container justifyContent="flex-end" alignItems="center" item xs={12} md={10}>
        <Grid item xs={5}>
          <Box mt={4} display="flex" width="100%">
            <NextButton fullWidth disabled={!validateValue()} onClick={controlNextStep}>
              {stepNumber === surveyConfig.length ? 'Submit survey' : 'Next'}
            </NextButton>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Grid container direction="column" justifyContent="center" alignItems="center" item xs={5}>
      <CircularProgress color="secondary" size={64} />
    </Grid>
  );
};
