import * as React from 'react';
import capitalize from '@material-ui/core/utils/capitalize';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Text } from '../../Components/General/Text';
import { BrancherDispatch, UtilGetAllProgramSurveys } from '../../store/actions';
import { Colors } from '../../consts/colors';
import { Page } from '../../Components/General/Page';
import { IStoreTypes } from '../../store/storeTypes';
import {
  ESurveyStatus,
  EUserActions,
  ISurveyConfiguration,
} from '../../store/reducers/SurveysReducer';
import { CreateSurvey } from './CreateSurvey';
import { RouteMaster } from '../../Components/Routing';
import { AllPositions } from '../../consts/ProgramPositionOptions';

export const Surveys: React.FC = () => {
  const [retrievingSurveys, setRetrievingSurveys] = React.useState<boolean>(false);
  const surveys = useSelector((state: IStoreTypes) => state.surveys.surveys);
  const isBrancherAdmin = useSelector((state: IStoreTypes) =>
    state.user?.positions?.includes(AllPositions.BRANCHER_ADMIN),
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    setRetrievingSurveys(true);
    BrancherDispatch(
      dispatch,
      UtilGetAllProgramSurveys(() => {
        setRetrievingSurveys(false);
      }),
    );
  }, []);

  return (
    <Page loading={retrievingSurveys}>
      <Grid container justifyContent="center">
        <Grid container item xs={11}>
          <Grid item>
            <Text variant="xl" marginBottom={30} marginTop={30} color="purple">
              Surveys:
            </Text>
          </Grid>
          <Grid container spacing={4} item justifyContent="space-between">
            {surveys?.length > 0 &&
              surveys?.map((survey) => <SurveyTile {...survey} key={survey.surveyId} />)}
            {isBrancherAdmin && (
              <Grid item xs={11} md={6} lg={4}>
                <CreateSurvey />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};

const useStyles = makeStyles(() => ({
  surveyCard: {
    padding: 15,
    borderRadius: 12,
    width: '100%',
    minWidth: 140,
    border: (props: { surveyStatusColor: string }) => `2px solid ${props.surveyStatusColor}`,
    boxShadow: `0px 5px 15px ${Colors.cardBorder}`,
    minHeight: 150,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&:hover': {
      borderWidth: 3,
      cursor: 'pointer',
      '& .MuiTypography-root': {
        fontWeight: 600,
      },
    },
  },
}));

const SurveyTile: React.FC<ISurveyConfiguration> = (props) => {
  const {
    surveyId,
    surveyTitle,
    surveyStatus,
    surveyDescription,
    surveyType,
    updatable,
    surveySegments,
    surveyDateSent = '',
  } = props;
  const surveyStatusType =
    surveyStatus === ESurveyStatus.DRAFT
      ? 'black'
      : surveyStatus === ESurveyStatus.CLOSED
      ? 'disabledGrey'
      : 'green';
  const surveyStatusColor = Colors[surveyStatusType];
  const styles = useStyles({ surveyStatusColor });

  const isPermanent = updatable !== undefined && !updatable && `This is a Brancher owned survey`;
  const shortSurveyTitle =
    surveyType === EUserActions.CUSTOM_SURVEY
      ? 'Customised survey'
      : surveyType === EUserActions.FINISHING_SURVEY
      ? 'Finishing-up survey'
      : surveyType === EUserActions.CHECK_IN_SURVEY
      ? 'Check-in survey'
      : surveyType === EUserActions.POST_WRAP_UP_MENTEE_SURVEY
      ? 'Mentee Wrap-up survey'
      : surveyType === EUserActions.POST_WRAP_UP_MENTOR_SURVEY
      ? 'Mentor Wrap-up survey'
      : surveyTitle;
  const readableDateSent = surveyDateSent ? ` - ${surveyDateSent} ` : '';

  const surveySegmentTitle =
    surveyType === EUserActions.POST_WRAP_UP_MENTEE_SURVEY
      ? 'Mentees'
      : surveyType === EUserActions.POST_WRAP_UP_MENTOR_SURVEY
      ? 'Mentors'
      : surveySegments?.length > 0
      ? `${capitalize(surveySegments?.join(' and '))}s`
      : 'All Users';

  return (
    <Grid item xs={11} md={6} lg={4}>
      <Link to={`${RouteMaster.survey.path}?s=${surveyId}`}>
        <Box className={styles.surveyCard} marginTop={4}>
          <Grid container direction="column" spacing={2} alignItems="center">
            <Text variant="md" fontWeight={500} align="center" color={surveyStatusType}>
              {shortSurveyTitle}
              {readableDateSent}
              {surveySegments && ` - For ${surveySegmentTitle}`}
            </Text>
            <Text
              variant="sm"
              color={surveyStatusType}
              fontWeight={500}
              marginTop={10}
              align="center"
            >
              {surveyDescription}
            </Text>
            {isPermanent && (
              <Text variant="xxs" color="black" align="center" marginTop={20}>
                {isPermanent}
              </Text>
            )}
          </Grid>
        </Box>
      </Link>
    </Grid>
  );
};
