import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { BrancherDialog, IBrancherDialog } from '../../Components/General/BrancherDialog';
import { GenerateTrainingModuleReport } from '../Reports/GenerateTrainingModuleReport';
import { GenerateBaselineSurveyReport } from '../Reports/GenerateBaselineSurveyReport';
import {
  EStructuredTrainingModules,
  ETrainingModules,
  ITrainingModule,
} from '../../store/reducers/DashboardReducer';
import { Text } from '../../Components/General/Text';
import { IStoreTypes } from '../../store/storeTypes';
import { GenerateFinalSurveyReport } from '../Reports/GenerateFinalSurveyReport';

interface IColumn {
  readableName: string;
  attributeName: string;
}

interface ITrainingModuleInfo {
  open: IBrancherDialog['open'];
  setClose: IBrancherDialog['setClose'];
  moduleReadableName: string;
  moduleDates: { open: string; close: string };
  module: ETrainingModules;
  moduleData: ITrainingModule[];
  moduleColumns?: IColumn[];
  customModuleColumns?: { menteeDisplayData: IColumn[]; mentorDisplayData: IColumn[] };
  surveyOneNotMetModuleData?: any; // TODO: This is doing too much, should abstract this out
  surveyOneNotMetColumns?: { readableName: string; attributeName: string }[];
}

export const TrainingModuleInfo: React.FC<ITrainingModuleInfo> = (props) => {
  const {
    setClose,
    open,
    moduleReadableName,
    moduleColumns,
    moduleData,
    moduleDates,
    module,
    surveyOneNotMetModuleData,
    surveyOneNotMetColumns,
    customModuleColumns,
  } = props;
  const programName = useSelector((state: IStoreTypes) => state.program.programName);
  return (
    <BrancherDialog
      setClose={setClose}
      labelledBy={moduleReadableName}
      title={moduleReadableName}
      open={open}
    >
      {module === EStructuredTrainingModules.SURVEY_ONE ? (
        <GenerateBaselineSurveyReport
          metData={moduleData}
          metDisplayData={moduleColumns}
          notMetData={surveyOneNotMetModuleData}
          notMetDisplayData={surveyOneNotMetColumns}
          programName={programName}
        />
      ) : module === EStructuredTrainingModules.SURVEY_TWO ? (
        <GenerateFinalSurveyReport
          menteeData={(moduleData as any)?.menteeData}
          mentorData={(moduleData as any)?.mentorData}
          menteeDisplayData={customModuleColumns?.menteeDisplayData}
          mentorDisplayData={customModuleColumns?.mentorDisplayData}
          programName={programName}
        />
      ) : (
        <GenerateTrainingModuleReport
          data={moduleData}
          displayData={moduleColumns}
          programName={programName}
          module={moduleReadableName}
        />
      )}
      {moduleDates?.open !== 'Invalid Date' && !!moduleDates?.open && (
        <Grid container direction="column" spacing={1} alignItems="center">
          <Grid item>
            <Text variant="sm">Module opens: {moduleDates?.open}</Text>
          </Grid>
          <Grid item>
            <Text variant="sm">Module closes: {moduleDates?.close}</Text>
          </Grid>
        </Grid>
      )}
    </BrancherDialog>
  );
};
