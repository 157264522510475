import * as React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import capitalize from '@material-ui/core/utils/capitalize';
import Info from '@material-ui/icons/Info';
import { differenceInDays, format, fromUnixTime } from 'date-fns';
import { IUserRoleInfo } from './User';
import { PairReasons } from './PairReasons';
import { BrancherLinkText } from '../../Components/General/BrancherLinkText';
import { RouteMaster } from '../../Components/Routing';
import {
  ActionButton,
  ActionNegativeOutlinedButton,
  BrancherButton,
} from '../../Components/InputFields/BrancherButton';
import { Text } from '../../Components/General/Text';
import { BrancherDivider } from '../../Components/General/BrancherDivider';
import {
  BrancherDispatch,
  UtilAddPair,
  UtilGetUserPairs,
  UtilRemovePair,
} from '../../store/actions';
import { IDraftedPairs } from '../../store/reducers/PairingReducer';
import { ConfirmDialog } from '../../Components/General/ConfirmDialog';
import { TargetUserPairsDialog } from '../Program/ProgramStepThree/TargetUserPairsDialog';
import { ProgramPositions } from '../../consts/ProgramPositionOptions';
import { useDispatch } from 'react-redux';

interface IUserMentoringPartners
  extends Pick<IUserRoleInfo, 'mentoringPartners' | 'previousMentoringPartners' | 'roleId'> {
  getUserData: () => void;
  userRoleName: string;
  selectedProfile: ProgramPositions;
}

export const UserMentoringPartners: React.FC<IUserMentoringPartners> = ({
  userRoleName,
  getUserData,
  mentoringPartners,
  previousMentoringPartners,
  selectedProfile,
  roleId,
}) => {
  const [gettingTargetUsersPairs, setGettingTargetUserPairs] = React.useState<boolean>(false);
  const [removingPair, setRemovingPair] = React.useState<boolean>(false);
  const [addingNewPair, setAddingNewPair] = React.useState<boolean>(false);
  const [targetedMentee, setTargetedMentee] = React.useState<string>();
  const [targetedMentor, setTargetedMentor] = React.useState<string>();
  const [targetUserDialog, setTargetUserDialog] = React.useState<boolean>(false);
  const [confirmRemovePairDialog, setConfirmRemovePairDialog] = React.useState<boolean>(false);
  const [targetUserName, setTargetUserName] = React.useState<string>();
  const [targetUserRoleId, setTargetUserRoleId] = React.useState<string>();
  const [targetUserPosition, setTargetUserPosition] = React.useState<ProgramPositions>();
  const [targetUserPairs, setTargetUserPairs] = React.useState<IDraftedPairs[]>();
  const dispatch = useDispatch();

  const isMentee = selectedProfile === ProgramPositions.mentee;
  const isMentor = selectedProfile === ProgramPositions.mentor;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleOpenPairingReasons = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePairingReasons = () => {
    setAnchorEl(null);
  };

  const setOpenRemovePairDialog = (menteeRoleId: string, mentorRoleId: string) => {
    setTargetedMentee(menteeRoleId);
    setTargetedMentor(mentorRoleId);
    setConfirmRemovePairDialog(true);
  };

  const removePair = () => {
    setRemovingPair(true);
    BrancherDispatch(
      dispatch,
      UtilRemovePair(targetedMentee, targetedMentor, () => {
        setCloseRemovePairDialog();
        getUserData();
        setRemovingPair(false);
      }),
    );
  };

  const addNewPair = (pair: IDraftedPairs) => {
    setAddingNewPair(true);
    BrancherDispatch(
      dispatch,
      UtilAddPair(pair, () => {
        getUserData();
        closeTargetUserDialog();
        setAddingNewPair(false);
      }),
    );
  };

  const getTargetUserPairs = (
    targetUserRoleId: string,
    targetUserPosition: ProgramPositions,
    overrideAlgorithm: boolean,
    name: string,
  ) => {
    setTargetUserName(name);
    setTargetUserRoleId(targetUserRoleId);
    setTargetUserPosition(targetUserPosition);
    setGettingTargetUserPairs(true);
    BrancherDispatch(
      dispatch,
      UtilGetUserPairs(
        targetUserRoleId,
        targetUserPosition,
        overrideAlgorithm,
        false,
        20,
        (targetUserMatches) => {
          setTargetUserPairs(targetUserMatches);
          setGettingTargetUserPairs(false);
          setTargetUserDialog(true);
        },
      ),
    );
  };

  const closeTargetUserDialog = () => {
    setTargetUserDialog(false);
    setTargetUserName('');
    setTargetUserRoleId('');
    setTargetUserPairs([]);
  };

  const setCloseRemovePairDialog = () => {
    setTargetedMentee('');
    setTargetedMentor('');
    setConfirmRemovePairDialog(false);
  };

  return (
    <>
      <ConfirmDialog
        confirmAction={removePair}
        denyAction={setCloseRemovePairDialog}
        setClose={setCloseRemovePairDialog}
        open={confirmRemovePairDialog}
        denyButtonText="Don't remove pair"
        confirmButtonText="Remove pair"
        title="Are you sure you want to remove this pair?"
        labelledBy="remove-pair-dialog"
      />
      <TargetUserPairsDialog
        targetUser={targetUserName}
        getUserTargetPairs={() => {
          setTargetUserDialog(false);
          getTargetUserPairs(targetUserRoleId, targetUserPosition, true, targetUserName);
        }}
        targetUserPairs={targetUserPairs}
        setClose={closeTargetUserDialog}
        open={targetUserDialog}
        draftPair={addNewPair}
      />
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Text variant="sm" fontWeight={600} color="purple" display="inline">
            {isMentee ? 'Mentors' : 'Mentees'}{' '}
          </Text>
          <Text variant="xs" fontWeight={500} display="inline" marginBottom={40}>
            ({mentoringPartners?.length ?? 0})
          </Text>
        </Grid>

        {mentoringPartners?.length > 0 && (
          <Grid item container spacing={2}>
            {mentoringPartners?.map((m, i) => (
              <Grid item container alignItems="center" xs={12} sm={6} key={i}>
                <Popover
                  id="pairing-reasons"
                  open={open}
                  onClose={handleClosePairingReasons}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  anchorEl={anchorEl}
                >
                  <PairReasons {...m.pairingReasons} />
                </Popover>
                <IconButton onClick={handleOpenPairingReasons}>
                  <Info />
                </IconButton>
                <BrancherLinkText
                  underline="always"
                  variant="sm"
                  fontWeight={500}
                  display="inline"
                  color="secondaryGrey"
                  marginRight={10}
                  href={`${RouteMaster.user.path}?r=${m.roleId}`}
                >
                  {m.name}
                </BrancherLinkText>
                <ActionNegativeOutlinedButton
                  size="small"
                  onClick={() =>
                    setOpenRemovePairDialog(
                      isMentor ? m.roleId : roleId,
                      isMentor ? roleId : m.roleId,
                    )
                  }
                  loading={removingPair}
                >
                  Unpair
                </ActionNegativeOutlinedButton>
                <Box ml={2}>
                  <BrancherButton
                    size="small"
                    color="secondary"
                    onClick={() =>
                      window.location.assign(
                        `${RouteMaster.pair.path}?mentee=${isMentor ? m.roleId : roleId}&mentor=${
                          isMentor ? roleId : m.roleId
                        }`,
                      )
                    }
                  >
                    Pair Details
                  </BrancherButton>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
        <Grid item>
          <Box mt={4}>
            <ActionButton
              onClick={() => getTargetUserPairs(roleId, selectedProfile, false, userRoleName)}
              loading={gettingTargetUsersPairs || addingNewPair}
            >
              Add{' '}
              {isMentee ? capitalize(ProgramPositions.mentor) : capitalize(ProgramPositions.mentee)}
            </ActionButton>
          </Box>
        </Grid>
      </Grid>

      {previousMentoringPartners?.length > 0 && (
        <>
          <Grid item xs={12}>
            <BrancherDivider marginTop={40} marginBottom={10} />
          </Grid>
          <Grid item xs={12}>
            <Text variant="sm" fontWeight={600} color="purple" display="inline">
              Previous {isMentee ? 'Mentors' : 'Mentees'}{' '}
            </Text>
            <Text variant="xs" fontWeight={500} display="inline" marginBottom={40}>
              ({previousMentoringPartners?.length ?? 0})
            </Text>
          </Grid>
          <Grid item container spacing={2}>
            {previousMentoringPartners?.map((m, i) => (
              <Grid item container alignItems="center" xs={12} sm={6} key={i} spacing={3}>
                <Grid item>
                  <BrancherButton
                    size="small"
                    color="secondary"
                    onClick={() =>
                      window.location.assign(
                        `${RouteMaster.user.path}?r=${isMentor ? m.mentee : m.mentor}`,
                      )
                    }
                  >
                    View {isMentor ? m.menteeName : m.mentorName}
                  </BrancherButton>
                </Grid>
                {m?.pairingStartDate && m?.pairingEndDate && (
                  <Grid item>
                    <Text variant="xs" display="inline">
                      {format(fromUnixTime(Number(m.pairingStartDate) / 1000), 'dd/MM/yyyy')} to{' '}
                      {format(fromUnixTime(Number(m.pairingEndDate) / 1000), 'dd/MM/yyyy')}{' '}
                    </Text>
                    <Text variant="xs" display="inline" fontWeight={500}>
                      (
                      {differenceInDays(
                        fromUnixTime(Number(m.pairingEndDate) / 1000),
                        fromUnixTime(Number(m.pairingStartDate) / 1000),
                      )}{' '}
                      days)
                    </Text>
                  </Grid>
                )}
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </>
  );
};
