import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { ChooseProgramPosition } from './ChooseProgramPosition';

export const QualifyingForm = () => {
  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item xs={12}>
        <ChooseProgramPosition />
      </Grid>
    </Grid>
  );
};
