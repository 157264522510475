import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Build from '@material-ui/icons/Build';
import MeetingRoom from '@material-ui/icons/MeetingRoom';
import { useDispatch } from 'react-redux';
import { Text } from '../../../Components/General/Text';
import { BrancherDispatch, UtilUpdateProgramAttribute } from '../../../store/actions';
import { UpdatableProgramInfo } from '../../../store/reducers/ProgramReducer';
import { ActionButton, ActionNegativeButton } from '../../../Components/InputFields/BrancherButton';
import { BrancherDivider } from '../../../Components/General/BrancherDivider';
import { FormConfigurationEngine } from './FormConfigurationEngine';

const useStyles = makeStyles({
  margins: {
    marginTop: 60,
    marginBottom: 30,
  },
});

export const FormConfigurationLanding = () => {
  const [updatingProgramStatus, setUpdatingProgramStatus] = React.useState<boolean>(false);
  const [isFormConfiguring, setIsFormConfiguring] = React.useState<boolean>(true);
  const dispatch = useDispatch();
  const styles = useStyles();

  const updateProgramStatus = () => {
    setUpdatingProgramStatus(true);
    BrancherDispatch(
      dispatch,
      UtilUpdateProgramAttribute(UpdatableProgramInfo.ACTIVE, true, () => {
        setUpdatingProgramStatus(false);
      }),
    );
  };

  return !isFormConfiguring ? (
    <>
      <Grid container justifyContent="center" alignItems="center" className={styles.margins}>
        <Grid item>
          <ActionButton
            startIcon={<Build color="secondary" />}
            aria-label="start-form-configuring"
            onClick={() => setIsFormConfiguring(true)}
          >
            Start form configuration
          </ActionButton>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item>
          <Text variant="md" display="inline">
            Set opening date and closing date of the form, you can edit them later.
          </Text>
        </Grid>
      </Grid>
      <BrancherDivider marginTop={120} marginBottom={60} />
      <Grid container justifyContent="flex-end">
        <Grid item>
          <ActionNegativeButton
            disabled={updatingProgramStatus}
            onClick={updateProgramStatus}
            startIcon={<MeetingRoom color="secondary" />}
          >
            Open now for submissions
          </ActionNegativeButton>
        </Grid>
      </Grid>
    </>
  ) : (
    <FormConfigurationEngine setIsFormConfiguring={() => setIsFormConfiguring(false)} />
  );
};
