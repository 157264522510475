import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Page } from '../../Components/General/Page';
import { PairingEngine } from './PairingEngine';

export const Matching: React.FC = () => {
  return (
    <Page marginTop={40}>
      <Grid container justifyContent="center" alignItems="center">
        <PairingEngine />
      </Grid>
    </Page>
  );
};
