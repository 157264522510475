import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import capitalize from '@material-ui/core/utils/capitalize';
import { format } from 'date-fns';
import { BrancherDivider } from '../../Components/General/BrancherDivider';
import { Text } from '../../Components/General/Text';
import { EMentoringRequestStatus } from '../../store/reducers/DashboardReducer';
import { IUserRoleInfo } from './User';

interface IUserMentoringRequests
  extends Pick<IUserRoleInfo, 'sentMentoringRequests' | 'receivedMentoringRequests'> {
  isMentee: boolean;
}

export const UserMentoringRequests: React.FC<IUserMentoringRequests> = ({
  sentMentoringRequests,
  receivedMentoringRequests,
  isMentee,
}) => {
  return (
    <>
      {sentMentoringRequests?.length > 0 && (
        <Grid item xs={12}>
          <BrancherDivider marginTop={30} marginBottom={30} />
          <Grid item xs={12}>
            <Box mb={1}>
              <Text variant="sm" fontWeight={600} color="purple" display="inline">
                Sent Mentoring Requests{' '}
              </Text>
              <Text variant="xs" fontWeight={500} display="inline" marginBottom={40}>
                ({sentMentoringRequests?.length ?? 0})
              </Text>
            </Box>
          </Grid>

          <Grid container spacing={2}>
            {sentMentoringRequests?.map((smr) => (
              <Grid
                container
                item
                key={smr.mentoringRequestId}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={4}>
                  <Text variant="sm" fontWeight={500} display="inline" color="purple">
                    Requested:{' '}
                  </Text>
                  <Text variant="sm" fontWeight={500} display="inline">
                    {isMentee ? smr.mentorName : smr.menteeName}
                  </Text>
                </Grid>
                <Grid item xs={3}>
                  <Text variant="sm" fontWeight={500} display="inline" color="purple">
                    Status:{' '}
                  </Text>
                  <Text variant="sm" fontWeight={500} display="inline">
                    {smr.status === EMentoringRequestStatus.SENT
                      ? 'Pending Response'
                      : capitalize(smr.status)}
                  </Text>
                </Grid>
                <Grid item xs={5}>
                  <Text
                    variant="sm"
                    fontWeight={500}
                    display="inline"
                    marginLeft={30}
                    color="purple"
                  >
                    Request Date:{' '}
                  </Text>
                  <Text variant="sm" fontWeight={500} display="inline">
                    {format(new Date(smr.requestDate), 'dd/MM/yyyy')}
                  </Text>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}

      {receivedMentoringRequests?.length > 0 && (
        <Grid item xs={12}>
          <BrancherDivider marginTop={30} marginBottom={30} />
          <Grid item xs={12}>
            <Box mb={1}>
              <Text variant="sm" fontWeight={600} color="purple" display="inline">
                Received Mentoring Requests{' '}
              </Text>
              <Text variant="xs" fontWeight={500} display="inline" marginBottom={20}>
                ({receivedMentoringRequests?.length ?? 0})
              </Text>
            </Box>
          </Grid>

          <Grid container spacing={2}>
            {receivedMentoringRequests?.map((smr) => (
              <Grid container item key={smr.mentoringRequestId}>
                <Grid item xs={4}>
                  <Text variant="sm" fontWeight={500} display="inline" color="purple">
                    Requested:{' '}
                  </Text>
                  <Text variant="sm" fontWeight={500} display="inline">
                    {isMentee ? smr.mentorName : smr.menteeName}
                  </Text>
                </Grid>
                <Grid item xs={3}>
                  <Text variant="sm" fontWeight={500} display="inline" color="purple">
                    Status:{' '}
                  </Text>
                  <Text variant="sm" fontWeight={500} display="inline">
                    {smr.status === EMentoringRequestStatus.SENT
                      ? 'Pending Response'
                      : capitalize(smr.status)}
                  </Text>
                </Grid>
                <Grid item xs={5}>
                  <Text
                    variant="sm"
                    fontWeight={500}
                    display="inline"
                    marginLeft={30}
                    color="purple"
                  >
                    Request Date:{' '}
                  </Text>
                  <Text variant="sm" fontWeight={500} display="inline">
                    {format(new Date(smr.requestDate), 'dd/MM/yyyy')}
                  </Text>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </>
  );
};
