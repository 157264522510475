import { IBrancherThemePalette } from './BrancherTheme';

export const MuiDialog = (theme: IBrancherThemePalette) => ({
  root: {},
  paperWidthSm: {
    maxWidth: '100%',
  },
  paper: {
    border: `1px ${theme.palette.primary.main} solid`,
    borderRadius: 10,
  },
});
