import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { QuestionHeader } from '../FormFieldTypes/QuestionHeader';
import { BrancherDivider } from '../../../Components/General/BrancherDivider';
import { Text } from '../../../Components/General/Text';
import { BrancherList } from '../../../Components/General/BrancherList';
import { PersonalityScaleDescription } from './PersonalityScaleDescription';

export const PersonalityInfo = () => {
  return (
    <>
      <Grid item xs={12} md={10}>
        <QuestionHeader>
          Now we will be asking you questions to understand your behavioural preferences and style.
        </QuestionHeader>
      </Grid>
      <Grid item xs={12} md={10}>
        <Text variant="md" fontWeight={600} marginTop={30}>
          Tips:
        </Text>
        <BrancherList
          listItems={[
            "Answer honestly, even if you don't like the answer.",
            'There are no right or wrong answers.',
            'Describe your behavioural preferences as they are now, not as you wish to be in the future.',
            'Your spontaneous answer is usually the most accurate.',
          ]}
        />
        <BrancherDivider marginTop={30} marginBottom={40} />
      </Grid>
      <PersonalityScaleDescription />
      <Grid item xs={12} md={10}>
        <BrancherDivider marginTop={30} marginBottom={20} />
      </Grid>
    </>
  );
};
