import * as React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './timeline.css';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import AddAlert from '@material-ui/icons/AddAlert';
import DoneOutline from '@material-ui/icons/DoneOutline';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import Flare from '@material-ui/icons/Flare';
import Gavel from '@material-ui/icons/Gavel';
import { add, format, isAfter, parse, sub } from 'date-fns';
import { useSelector } from 'react-redux';
import { ActionOutlinedButton } from '../../Components/InputFields/BrancherButton';
import { Page } from '../../Components/General/Page';
import { Colors } from '../../consts/colors';
import { Text } from '../../Components/General/Text';
import {
  EStructuredTrainingModules,
  ETrainingModules,
  ReadableTrainingModuleTitles,
  StructuredProgramRoleSpecificTrainingModules,
} from '../../store/reducers/DashboardReducer';
import { IStoreTypes } from '../../store/storeTypes';
// @ts-ignore
import domToPdf from 'dom-to-pdf';
import { UpdatableFormConfiguration } from '../../store/reducers/ProgramReducer';

enum ETimelineType {
  CUSTOM = 'custom',
  TRAINING = 'training',
  TRAINING_REMINDER = 'trainingReminder',
  TRAINING_CLOSING = 'trainingClosing',
}

interface ITimelineConfig {
  date: string;
  timelineType: ETimelineType; // custom is for start and end, so the icon is passed in
  description: string;
  title: string;
  background?: string;
  icon?: React.ReactNode;
}

interface ICreateDynamicDates {
  open: string;
  close: string;
  title: string;
  userType: 'mentees' | 'mentors' | 'both mentees and mentors';
  moduleType: 'survey' | 'training';
  module: ETrainingModules;
}

export const Timeline = () => {
  const [forPrint, setForPrint] = React.useState<boolean>(false);
  const theme = useTheme();
  const program = useSelector((state: IStoreTypes) => state.program);
  const applicationCloseDate = useSelector(
    (state: IStoreTypes) =>
      state.program.formConfiguration?.[UpdatableFormConfiguration.APPLICATION_CLOSING_DATE],
  );
  const applicationClosingDateParsed =
    applicationCloseDate &&
    format(
      parse(applicationCloseDate.toString(), "dd/MM/yyyy'T'HH:mm", new Date()),
      'MMMM dd yyyy',
    );
  const launchDate: string = program?.launchedProgram
    ? new Date(program?.launchDate).toString()
    : !!applicationCloseDate
    ? add(new Date(applicationClosingDateParsed), { days: 1 }).toString()
    : new Date().toString();
  const createdDynamicDates: ICreateDynamicDates[] = CreateModulesDates(
    program.programLengthMonths,
    launchDate,
  );
  const CreateCompletedTimeline = sortModules(
    CreateCompleteTime(createdDynamicDates, program.programName, launchDate),
  );

  React.useEffect(() => {
    if (forPrint) {
      generatePdf();
    }
  }, [forPrint]);

  const generatePdf = () => {
    const element = document.getElementById('timeline');

    const options = {
      filename: 'timeline.pdf',
    };

    return domToPdf(element, options, () => {
      setForPrint(false);
    });
  };

  return (
    <>
      <Box bgcolor={Colors.backgroundGrey} pt={2} display="flex" justifyContent="center">
        <ActionOutlinedButton
          loading={forPrint}
          onClick={() => setForPrint(true)}
          startIcon={<PictureAsPdf />}
        >
          Create PDF
        </ActionOutlinedButton>
      </Box>

      <div id="timeline">
        <Page background={Colors.backgroundGrey}>
          <VerticalTimeline lineColor={theme.palette.primary.main} animate={!forPrint}>
            {CreateCompletedTimeline.map((t, index) => (
              <VerticalTimelineElement
                key={index}
                contentStyle={{
                  background:
                    t.timelineType === ETimelineType.CUSTOM
                      ? t.background
                      : t.timelineType === ETimelineType.TRAINING_REMINDER
                      ? Colors.incorrect
                      : t.timelineType === ETimelineType.TRAINING
                      ? Colors.lightRed
                      : Colors.cardSelected,
                  color: Colors.black,
                }}
                dateClassName="date-class-name"
                contentArrowStyle={{ borderRight: `7px solid ${t.background}` }}
                date={t.date}
                iconStyle={{
                  background:
                    t.timelineType === ETimelineType.TRAINING_CLOSING
                      ? Colors.red
                      : theme.palette.primary.main,
                  color: '#fff',
                }}
                icon={
                  t.timelineType === ETimelineType.CUSTOM ? (
                    t.icon
                  ) : t.timelineType === ETimelineType.TRAINING_REMINDER ? (
                    <AddAlert color="secondary" />
                  ) : t.timelineType === ETimelineType.TRAINING ? (
                    <LibraryBooks color="secondary" />
                  ) : (
                    <Gavel color="secondary" />
                  )
                }
              >
                <Text variant="lg" fontWeight={600}>
                  {t.title}
                </Text>
                <Text variant="sm">{t.description}</Text>
              </VerticalTimelineElement>
            ))}
          </VerticalTimeline>
        </Page>
      </div>
    </>
  );
};

function sortModules(modules: ITimelineConfig[]): ITimelineConfig[] {
  return modules.sort((a: any, b: any) => {
    if (!a?.date || !b?.date) {
      return -1;
    } else if (isAfter(new Date(a?.date), new Date(b?.date))) {
      return 1;
    } else if (isAfter(new Date(b?.date), new Date(a?.date))) {
      return -1;
    } else {
      return 0;
    }
  });
}

const CreateCompleteTime = (
  trainingDates: ICreateDynamicDates[],
  programName: string,
  startDate: string,
): ITimelineConfig[] => {
  const TimelineStart: ITimelineConfig = {
    date: format(new Date(startDate), 'MMMM dd yyyy'),
    icon: <Flare color="secondary" />,
    timelineType: ETimelineType.CUSTOM,
    title: `${programName} launches!`,
    description:
      'Mentees and mentors get emails sent out introducing each other, first steps, and can now access the Brancher Mentoring Platform',
    background: Colors.informational,
  };
  const FortnightCheckIn: ITimelineConfig = {
    date: format(add(new Date(startDate), { weeks: 2 }), 'MMMM dd yyyy'),
    icon: <Flare color="secondary" />,
    timelineType: ETimelineType.CUSTOM,
    title: `2 week check-in`,
    description: 'Mentees and mentors get a reminder email to check-in if they have caught up',
    background: Colors.informational,
  };
  const TimelineEnd: ITimelineConfig = {
    date: format(
      add(new Date(trainingDates[trainingDates.length - 1].close), { weeks: 2 }),
      'MMMM dd yyyy',
    ),
    icon: <DoneOutline color="secondary" />,
    timelineType: ETimelineType.CUSTOM,
    title: 'End of mentoring program',
    description: 'See you next program!',
    background: Colors.correct,
  };
  let allTrainingDates: ITimelineConfig[] = [];
  trainingDates.forEach((f) => {
    allTrainingDates.push({
      date: f.open,
      timelineType: ETimelineType.TRAINING,
      title: `Training opens for ${f.title}`,
      description: `An email is sent to ${f.userType} asking them to complete this ${f.moduleType}`,
    });
    allTrainingDates.push({
      date: format(add(new Date(f.open), { weeks: 2 }), 'MMMM dd yyyy'),
      timelineType: ETimelineType.TRAINING_REMINDER,
      title: `Training reminder for ${f.title}`,
      description: `An email reminder is sent to ${f.userType} who haven't already completed this ${f.moduleType}`,
    });
    allTrainingDates.push({
      date: f.close,
      timelineType: ETimelineType.TRAINING_CLOSING,
      title: `Training closes for ${f.title}`,
      description: `The ${f.moduleType} becomes overdue`,
    });
  });
  return sortModules([TimelineStart, FortnightCheckIn, ...allTrainingDates, TimelineEnd]);
};

const defaultTrainingModuleDateMonthConfiguration = {
  [EStructuredTrainingModules.INTRODUCTION_MENTEE]: 0,
  [EStructuredTrainingModules.INTRODUCTION_MENTOR]: 0,
  [EStructuredTrainingModules.MAKE_THE_MOST_MENTEE]: 1,
  [EStructuredTrainingModules.MAKE_THE_MOST_MENTOR]: 1,
  [EStructuredTrainingModules.SURVEY_ONE]: 2,
  [EStructuredTrainingModules.BE_GREAT_MENTEE]: 2,
  [EStructuredTrainingModules.BE_GREAT_MENTOR]: 2,
  [EStructuredTrainingModules.GIVING_RECEIVING_FEEDBACK_MENTEE]: 3,
  [EStructuredTrainingModules.GIVING_RECEIVING_FEEDBACK_MENTOR]: 3,
  [EStructuredTrainingModules.BEYOND_PROGRAM_MENTEE]: 5,
  [EStructuredTrainingModules.BEYOND_PROGRAM_MENTOR]: 5,
  [EStructuredTrainingModules.SURVEY_TWO]: 5,
};

const CreateModulesDates = (programLength: number, launchDate: string): ICreateDynamicDates[] => {
  const allModules: ETrainingModules[] = [
    ...StructuredProgramRoleSpecificTrainingModules.mentee,
    ...StructuredProgramRoleSpecificTrainingModules.mentor,
  ];
  let dateConfig: ICreateDynamicDates[] = [];
  // These dates are to open 2 months before the program ends - hence the program length requirement
  const flexibleDateModules: ETrainingModules[] = [
    EStructuredTrainingModules.BEYOND_PROGRAM_MENTEE,
    EStructuredTrainingModules.BEYOND_PROGRAM_MENTOR,
    EStructuredTrainingModules.SURVEY_TWO,
  ];
  allModules.forEach((module) => {
    if (flexibleDateModules.includes(module)) {
      dateConfig.push({
        open: format(add(new Date(launchDate), { months: programLength - 2 }), 'MMMM dd yyyy'),
        close: format(
          sub(add(new Date(launchDate), { months: programLength - 2 + 1 }), { days: 1 }),
          'MMMM dd yyyy',
        ),
        title: ReadableTrainingModuleTitles[module],
        userType:
          StructuredProgramRoleSpecificTrainingModules.mentee.includes(module) &&
          StructuredProgramRoleSpecificTrainingModules.mentor.includes(module)
            ? 'both mentees and mentors'
            : StructuredProgramRoleSpecificTrainingModules.mentee.includes(module)
            ? 'mentees'
            : 'mentors',
        moduleType:
          module === EStructuredTrainingModules.SURVEY_TWO ||
          module === EStructuredTrainingModules.SURVEY_ONE
            ? 'survey'
            : 'training',
        module,
      });
    } else {
      dateConfig.push({
        open: format(
          add(new Date(launchDate), {
            months: defaultTrainingModuleDateMonthConfiguration[module],
          }),
          'MMMM dd yyyy',
        ),
        close: format(
          sub(
            add(new Date(launchDate), {
              months: defaultTrainingModuleDateMonthConfiguration[module] + 1,
            }),
            { days: 1 },
          ),
          'MMMM dd yyyy',
        ),
        title: ReadableTrainingModuleTitles[module],
        userType:
          StructuredProgramRoleSpecificTrainingModules.mentee.includes(module) &&
          StructuredProgramRoleSpecificTrainingModules.mentor.includes(module)
            ? 'both mentees and mentors'
            : StructuredProgramRoleSpecificTrainingModules.mentee.includes(module)
            ? 'mentees'
            : 'mentors',
        moduleType:
          module === EStructuredTrainingModules.SURVEY_TWO ||
          module === EStructuredTrainingModules.SURVEY_ONE
            ? 'survey'
            : 'training',
        module,
      });
    }
  });
  const allModulesNamed = dateConfig.map((o) => o.module);
  return dateConfig.filter(({ module }, index) => !allModulesNamed.includes(module, index + 1));
};
