import * as React from 'react';
import { Link } from 'react-router-dom';
import { BaseErrorPage } from './BaseErrorPage';
import { BrancherButton } from '../InputFields/BrancherButton';
import { RouteMaster } from './RouteConfig';

export const Error404 = () => (
  <BaseErrorPage
    error={404}
    errorHeading="Page not found."
    errorDescription="Please bear with us while we try to get our wombats back to work."
  >
    <Link to={RouteMaster.login.path}>
      <BrancherButton color="primary">Back</BrancherButton>
    </Link>
  </BaseErrorPage>
);
