import { BrancherAuthRequest } from './utils/BrancherDispatch';
import {
  CLEAN_PROGRAM_DATA,
  IFormConfig,
  SAVE_FORM_CONFIGURATION_DATA,
  SAVE_PROGRAM_DATA,
  UpdatableFormConfiguration,
  UpdatableProgramInfo,
  CLEAN_PROGRAM_STATUS_DATA,
  IAlgorithmConfig,
  IFormConfigDefaultValues,
  IFormQuestionsConfiguration,
  SAVE_APPLICATION_FORM_DATA,
  EApplicationFormSections,
  SAVE_APPLICATION_DEFAULT_FORM_DATA,
  IUpdatableProgramSettings,
  ESupportRequestStatus,
  SAVE_APPLICATION_FORM_DATA_V2,
} from '../reducers/ProgramReducer';
import { EMentoringRequestStatus } from '../reducers/DashboardReducer';
import { IAPIResponseObject } from './actionTypes/apiTypes';
import { IBaseProfileData, IProfileData } from '../../types/ProfileTypes';
import { IGroup, IGroupMember } from '../../Pages/Groups/Groups';
import { IAllProgramUserData } from '../../Pages/ProgramUsers/ProgramUsers';
import { IExtendedGroupData } from '../../Pages/Groups/Group';
import { ProgramPositions } from '../../consts/ProgramPositionOptions';
import { IDraftedPairs, IOldPairs, IPairsInfo } from '../reducers/PairingReducer';
import { ISimpleProgramHealthData } from '../reducers/CompaniesReducer';
import { IUserInfo } from '../reducers/UserInfoReducer';
import { IReportingUserTrainingData } from '../../types/TrainingTypes';

export const SaveProgramData = (programData: ISimpleProgramHealthData) => {
  return {
    type: SAVE_PROGRAM_DATA,
    payload: programData,
  };
};

export const SaveFormConfigurationData = (formConfigData: IFormConfig) => {
  return {
    type: SAVE_FORM_CONFIGURATION_DATA,
    payload: formConfigData,
  };
};

export const CleanProgramStatusData = () => {
  return {
    type: CLEAN_PROGRAM_STATUS_DATA,
  };
};

export const SaveApplicationFormData = (formSection: EApplicationFormSections, data: any) => {
  return {
    type: SAVE_APPLICATION_FORM_DATA,
    payload: { formSection, data },
  };
};

export const SaveApplicationFormDataV2 = (formSection: EApplicationFormSections, data: any) => {
  return {
    type: SAVE_APPLICATION_FORM_DATA_V2,
    payload: { formSection, data },
  };
};

export const SaveApplicationDefaultFormData = (data: IFormConfig['formConfigDefaultValues']) => {
  return {
    type: SAVE_APPLICATION_DEFAULT_FORM_DATA,
    payload: data,
  };
};

export const CleanProgramData = () => {
  return {
    type: CLEAN_PROGRAM_DATA,
  };
};

// This gets the base data of a program
export const UtilGetPrevProgramInformation = (prevProgramId: string, cb: (a: any) => void) => {
  return (dispatch: any, getState: any) => {
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/program',
        params: JSON.stringify({
          programId: prevProgramId,
        }),
      },
      getState(),
    ).then((response: any) => {
      cb(response.data.data);
    });
  };
};

// This retrieves all the data related to a program
export const UtilGetAllProgramData = (cb: (a: any) => void) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/allprogramdata',
        params: JSON.stringify({
          programId,
        }),
      },
      getState(),
    ).then((response: any) => {
      dispatch(SaveProgramData({ ...response.data.data }));
      cb(response.data.data);
    });
  };
};

interface IUtilGetAllProgramUsers extends IAPIResponseObject {
  data: IAllProgramUserData[];
}

// This retrieves all the users attached to a program
export const UtilGetAllProgramUsers = (cb: (a: IUtilGetAllProgramUsers) => void) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/programusers',
        params: JSON.stringify({
          programId,
        }),
      },
      getState(),
    ).then((response: any) => {
      cb(response.data);
    });
  };
};

// This retrieves all the support requests for a program
export const UtilGetAllProgramSupportRequests = (cb: (a: any) => void) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/programsupportrequests',
        params: JSON.stringify({
          programId,
        }),
      },
      getState(),
    ).then((response: any) => {
      cb(response.data.data);
    });
  };
};

// This updates the status of a support request
export const UtilUpdateSupportRequestStatus = (
  supportRequestId: string,
  status: ESupportRequestStatus,
  cb: (a: any) => void,
) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'put',
        url: 'v2/supportrequest',
        data: {
          programId,
          supportRequestId,
          status,
        },
      },
      getState(),
    ).then((response: any) => {
      cb(response.data.data);
    });
  };
};

// This updates the status of a mentoring request
export const UtilUpdateMentoringRequestStatus = (
  mentoringRequestId: string,
  status:
    | EMentoringRequestStatus.DECLINED
    | EMentoringRequestStatus.ACCEPTED
    | EMentoringRequestStatus.CANCELLED,
  cb: (a: any) => void,
) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'put',
        url: 'v2/adminmentoringrequest',
        data: {
          programId,
          mentoringRequestId,
          status,
        },
      },
      getState(),
    ).then((response: any) => {
      cb(response.data.data);
    });
  };
};

// This retrieves all the mentoring requests attached to a program
export const UtilGetAllProgramMentoringRequests = (cb: (a: any) => void) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/programmentoringrequests',
        params: JSON.stringify({
          programId,
        }),
      },
      getState(),
    ).then((response: any) => {
      cb(response.data.data);
    });
  };
};

export const UtilGetProgramFormConfiguration = (cb: (a: any) => void) => {
  return (dispatch: any, getState: any) => {
    const formId = getState().program.formId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/formconfiguration',
        params: JSON.stringify({
          formId,
        }),
      },
      getState(),
    ).then((response: any) => {
      cb(response.data.data);
    });
  };
};

// This updates the given formConfiguration attribute
export const UtilUpdateFormConfigurationAttribute = (
  attribute: UpdatableFormConfiguration,
  attributeValue: any,
  cb: (a?: any) => void,
) => {
  return (dispatch: any, getState: any) => {
    const formId = getState().program.formId;
    BrancherAuthRequest(
      {
        method: 'put',
        url: 'v2/formconfiguration',
        data: {
          formId,
          attribute,
          attributeValue,
        },
      },
      getState(),
    ).then(() => {
      dispatch(SaveFormConfigurationData({ [attribute]: attributeValue }));
      cb();
    });
  };
};

// This updates the given formConfiguration attribute
export const UtilSaveFormConfiguration = (
  formConfig: IFormQuestionsConfiguration,
  algorithmConfig: IAlgorithmConfig,
  formConfigDefaultValues: IFormConfigDefaultValues,
  cb: (a?: any) => void,
) => {
  return (dispatch: any, getState: any) => {
    const formId = getState().program.formId;
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'v2/formconfiguration',
        data: {
          formId,
          formConfig,
          algorithmConfig,
          formConfigDefaultValues,
        },
      },
      getState(),
    ).then(() => {
      dispatch(
        SaveFormConfigurationData({
          [UpdatableFormConfiguration.FORM_CONFIG]: formConfig,
          [UpdatableFormConfiguration.ALGORITHM_CONFIG]: algorithmConfig,
          [UpdatableFormConfiguration.FORM_CONFIG_DEFAULT_VALUES]: formConfigDefaultValues,
        }),
      );
      cb();
    });
  };
};

// This updates the given Program attribute
export const UtilUpdateProgramAttribute = (
  attribute: UpdatableProgramInfo,
  attributeValue: any,
  cb: (a?: any) => void,
) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'put',
        url: 'v2/program',
        data: {
          programId,
          attribute,
          attributeValue,
        },
      },
      getState(),
    ).then(() => {
      dispatch(SaveProgramData({ [attribute]: attributeValue }));
      cb();
    });
  };
};

// This updates the all the program settings
export const UtilUpdateProgramSettings = (
  programInfo: IUpdatableProgramSettings,
  cb: (a?: any) => void,
) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'v2/programsettings',
        data: {
          programId,
          ...programInfo,
        },
      },
      getState(),
    ).then(() => {
      dispatch(SaveProgramData(programInfo));
      cb();
    });
  };
};

// This sends an array of usernames to deactivate
export const UtilUploadUserDeactivationList = (
  userDeactivationList: string[],
  cb: (res?: { message?: string }) => void,
) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'v2/updateuserdeactivations',
        data: {
          programId,
          userDeactivationList,
        },
      },
      getState(),
    ).then((response: any) => {
      cb(response.data);
    });
  };
};

// This sends an array of usernames to approve
export const UtilUploadApplicationApprovalList = (
  userApprovalList: string[],
  sendNotification: boolean,
  cb: (res?: { message?: string }) => void,
) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'v2/updateapplicationapprovals',
        data: {
          programId,
          userApprovalList,
          sendNotification,
        },
      },
      getState(),
    ).then((response: any) => {
      cb(response.data);
    });
  };
};

interface IAllUserInfoResponse extends IUserInfo {
  profileData?: IBaseProfileData;
  completedProfile?: IProfileData['completedProfile'];
  roleId?: IProfileData['roleId'];
  mentoringPartners?: IPairsInfo[];
  previousPartners?: IOldPairs[];
  profileType?: ProgramPositions;
  userGroups?: IGroup[];
  hasFormData?: boolean;
  trainingData?: IReportingUserTrainingData; // list of training modules
}

interface IUtilGetAllUserInfo {
  success: boolean;
  data: IAllUserInfoResponse;
}

// This retrieves all the user data attached to a program
export const UtilGetAllUserInfo = (userId: string, cb: (a: IUtilGetAllUserInfo) => void) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/user',
        params: JSON.stringify({
          userId,
          programId,
        }),
      },
      getState(),
    ).then((response: any) => {
      cb(response.data.data);
    });
  };
};

export const UtilGetUserByRoleId = (roleId: string, cb: (a: IUtilGetAllUserInfo) => void) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/userbyroleid',
        params: JSON.stringify({
          roleId: roleId,
          programId: programId,
        }),
      },
      getState(),
    )
      .then((response: any) => {
        cb(response.data.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

interface IUtilGetProgramCohortProfiles extends IAPIResponseObject {
  data: IProfileData[];
}

export const UtilGetProgramCohortProfiles = (cb: (a: IUtilGetProgramCohortProfiles) => void) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/cohort',
        params: JSON.stringify({
          programId,
          publicProfiles: true,
          getDeactivated: false,
          getActivities: true,
        }),
      },
      getState(),
    )
      .then((response: { data: IUtilGetProgramCohortProfiles }) => {
        cb(response.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

interface IUtilGetProgramGroups extends IAPIResponseObject {
  data: IGroup[];
}

export const UtilGetProgramGroups = (cb: (a: IUtilGetProgramGroups) => void) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/groups',
        params: JSON.stringify({
          programId,
        }),
      },
      getState(),
    )
      .then((response: { data: IUtilGetProgramGroups }) => {
        cb(response.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

interface IUtilGetProgramGroup extends IAPIResponseObject {
  data: IExtendedGroupData;
}

export const UtilGetProgramGroup = (groupId: string, cb: (a: IUtilGetProgramGroup) => void) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/group',
        params: JSON.stringify({
          programId,
          groupId,
        }),
      },
      getState(),
    )
      .then((response: { data: IUtilGetProgramGroup }) => {
        cb(response.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

interface IUtilCreateProgramGroup extends IAPIResponseObject {
  data: IGroup;
}

export const UtilCreateProgramGroup = (
  groupData: Omit<IGroup, 'groupId' | 'programId'>,
  cb: (res?: IUtilCreateProgramGroup) => void,
) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'v2/group',
        data: {
          programId,
          ...groupData,
        },
      },
      getState(),
    ).then((response: any) => {
      cb(response.data);
    });
  };
};

interface IUtilAddUserToGroup extends IAPIResponseObject {
  data: IExtendedGroupData;
}

export const UtilAddUserToGroup = (
  user: IGroupMember,
  userPosition: ProgramPositions,
  groupId: string,
  isGroupOwner: boolean,
  cb: (res: IUtilAddUserToGroup) => void,
) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'put',
        url: 'v2/groupuser',
        data: {
          programId,
          groupId,
          isGroupOwner,
          position: userPosition,
          user,
        },
      },
      getState(),
    ).then((response: any) => {
      cb(response.data);
    });
  };
};

interface IUtilRemoveUserFromGroup extends IAPIResponseObject {
  data: IExtendedGroupData;
}

export const UtilRemoveUserFromGroup = (
  user: IGroupMember,
  userPosition: ProgramPositions,
  groupId: string,
  cb: (res: IUtilRemoveUserFromGroup) => void,
) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'delete',
        url: 'v2/groupuser',
        data: {
          programId,
          groupId,
          position: userPosition,
          user,
        },
      },
      getState(),
    ).then((response: any) => {
      cb(response.data);
    });
  };
};

export interface IDraftedPairWithProfile extends IDraftedPairs {
  partnerProfile?: IBaseProfileData;
}

interface IUtilMatchGroupMentors extends IAPIResponseObject {
  data: IDraftedPairWithProfile[];
}

export const UtilMatchGroupMentors = (
  mentors: { roleId: string }[],
  cb: (res?: IUtilMatchGroupMentors) => void,
) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'v2/matchgroup',
        data: {
          programId,
          mentorsToMatch: mentors,
        },
      },
      getState(),
    ).then((response: any) => {
      cb(response.data);
    });
  };
};

interface IUtilUpdateUserNotes extends IAPIResponseObject {
  data: {
    notes: string;
    notesUpdated: number;
  };
}

export const UtilUpdateUserNotes = (
  username: string,
  notes: string,
  cb: (res?: IUtilUpdateUserNotes) => void,
) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'v2/usernotes',
        data: {
          programId,
          username,
          notes,
        },
      },
      getState(),
    ).then((response: any) => {
      cb(response.data);
    });
  };
};
