import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { FinalisingFormQuestions } from './FinalisingFormConfig';
import { SelectionControl } from '../FormFieldTypes/SelectionControl';
import { TextControl } from '../FormFieldTypes/TextControl';
import { EComponentType } from '../../../store/reducers/ProgramReducer';

interface IValuesForm {
  stepNumber: number;
  isMentee: boolean;
  prevStepNumber: number;
}

export const FinalisingForm = (props: IValuesForm) => {
  const { stepNumber, isMentee, prevStepNumber } = props;
  const q = FinalisingFormQuestions(isMentee);
  return (
    <Grid item container xs={12} md={10}>
      {q.map((s, i) => {
        if (prevStepNumber + i + 1 === stepNumber) {
          const { readableName, notMandatory, componentType, ...other } = s;
          if (componentType === EComponentType.TOGGLE) {
            return (
              <SelectionControl key={i} conditionalTextField={s?.conditionalTextField} {...other} />
            );
          } else if (componentType === EComponentType.TEXT) {
            return <TextControl key={i} {...other} />;
          }
        }
      })}
    </Grid>
  );
};
