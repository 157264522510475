import { Colors } from '../consts/colors';
import { IBrancherThemePalette } from './BrancherTheme';

export const MuiButton = (theme: IBrancherThemePalette) => ({
  root: {
    paddingTop: 13,
    paddingBottom: 13,
    paddingLeft: 30,
    paddingRight: 30,
    borderRadius: 10,
    textTransform: 'none',
    '&$disabled': {
      backgroundColor: Colors.lightGrey,
      color: Colors.white,
      border: 'none',
    },
    lineHeight: 1.2,
    boxShadow:
      '0px 2px 5px -1px rgba(0,0,0,0.07),0px 4px 5px 0px rgba(0,0,0,0.07),0px 1px 10px 0px rgba(0,0,0,0.07)',
    '@media (hover: none)': {
      '&.MuiButton-outlinedPrimary:hover': {
        backgroundColor: `${theme.palette.primary.main} !important`,
      },
      '&.MuiButton-outlinedSecondary:hover': {
        backgroundColor: `${Colors.white} !important`,
      },
    },
  },
  outlinedPrimary: {
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    color: Colors.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: Colors.white,
      borderColor: theme.palette.primary.main,
    },
  },
  outlinedSecondary: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    backgroundColor: Colors.white,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: Colors.white,
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
  outlinedSizeLarge: {
    paddingTop: 13,
    paddingBottom: 13,
    paddingLeft: 30,
    paddingRight: 30,
  },
  sizeSmall: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 15,
    paddingRight: 15,
    '& .MuiButton-label': {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  text: {
    boxShadow: 'none',
  },
  textPrimary: {
    color: theme.palette.primary.main,
    backgroundColor: Colors.white,
    paddingLeft: 0,
    paddingRight: 0,
    fontWeight: 600,
  },
  label: {
    '&$disabled': {
      color: Colors.white,
    },
  },
});
