import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../store/storeTypes';
import { BrancherDispatch, UtilGenerateReport } from '../../store/actions';
import { ActionButton } from '../../Components/InputFields/BrancherButton';
import { EGenerateReport } from '../../store/reducers/ReportReducer';
import { GeneratePersonalityValuesReport } from './GeneratePersonalityValuesReport';

interface IPersonalityValuesReport {
  controlled?: boolean;
  completedCallback?: () => void;
}

export const PersonalityValuesReport: React.FC<IPersonalityValuesReport> = ({
  controlled,
  completedCallback,
}) => {
  const program = useSelector((state: IStoreTypes) => state.program);
  const personalityValuesReportData = useSelector(
    (state: IStoreTypes) => state?.report?.personalityValuesReportData,
  );
  const personalityValuesReportColumns = useSelector(
    (state: IStoreTypes) => state?.report?.personalityValuesReportColumns,
  );
  const [generatingPersonalityValuesReport, setGeneratingPersonalityValuesReport] =
    React.useState<boolean>(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (controlled) {
      generatePersonalityValuesReport();
    }
  }, []);

  const generatePersonalityValuesReport = () => {
    setGeneratingPersonalityValuesReport(true);
    BrancherDispatch(
      dispatch,
      UtilGenerateReport(EGenerateReport.PERSONALITY_VALUES_REPORT, () => {
        setGeneratingPersonalityValuesReport(false);
        if (controlled) {
          completedCallback();
        }
      }),
    );
  };

  return (
    <Grid item xs={12} container alignItems="center" spacing={2}>
      {!controlled && (
        <Grid item xs={6}>
          <ActionButton
            onClick={generatePersonalityValuesReport}
            loading={generatingPersonalityValuesReport}
            size="small"
          >
            Generate personality/values report
          </ActionButton>
        </Grid>
      )}
      {personalityValuesReportData && (
        <Grid item xs={6}>
          <GeneratePersonalityValuesReport
            data={personalityValuesReportData}
            displayData={personalityValuesReportColumns}
            programName={program.programName}
          />
        </Grid>
      )}
    </Grid>
  );
};
