import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import { BrancherGridCard } from '../../Components/General/BrancherGridCard';
import { Text } from '../../Components/General/Text';
import { BrancherDivider } from '../../Components/General/BrancherDivider';
import { IStoreTypes } from '../../store/storeTypes';
import { Subscriptions, UpdatableProgramInfo } from '../../store/reducers/ProgramReducer';
import { SimpleLineChart } from '../Program/Charts/SimpleLineChart';
import { BrancherDispatch, UtilGetDashboardGroupsData } from '../../store/actions';

const useStyles = makeStyles({
  groupsCard: {
    maxWidth: 420,
    width: 420,
    padding: 25,
    height: 'fit-content',
  },
});

interface IGroupsHealth {
  filters: { [x in string]: any };
}

export const GroupsHealth: React.FC<IGroupsHealth> = (props) => {
  const hasGroups = useSelector(
    (state: IStoreTypes) => state.program[UpdatableProgramInfo.SUBSCRIPTION],
  )?.includes(Subscriptions.GROUPS);
  const { filters } = props;
  const [retrievingDashboardData, setRetrievingDashboardData] = React.useState<boolean>(false);
  const groups = useSelector((state: IStoreTypes) => state.dashboard?.groups);
  const styles = useStyles({});
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (hasGroups) {
      setRetrievingDashboardData(true);
      BrancherDispatch(
        dispatch,
        UtilGetDashboardGroupsData(filters, () => {
          setRetrievingDashboardData(false);
        }),
      );
    }
  }, []);

  return hasGroups ? (
    !retrievingDashboardData && groups?.activeGroupsAmount ? (
      <Grid container item>
        <BrancherDivider height={2} marginTop={50} marginBottom={30} />
        <Text variant="lg" marginBottom={30} color="purple" fontWeight={700}>
          Groups
        </Text>
        <Grid container item alignItems="center" justifyContent="space-between">
          <BrancherGridCard
            container
            item
            direction="column"
            spacing={3}
            className={styles.groupsCard}
            md={3}
            alignItems="center"
          >
            <Grid item container justifyContent="center">
              <Text variant="lg" display="inline" color="purple" fontWeight={700}>
                {groups?.activeGroupsAmount}{' '}
              </Text>
              <Text variant="lg" display="inline" align="center" fontWeight={500}>
                Current Groups
              </Text>
            </Grid>
            <Grid item container justifyContent="center">
              <Text variant="lg" display="inline" color="purple" fontWeight={700}>
                {groups?.wrappedUpGroupsAmount}{' '}
              </Text>
              <Text variant="lg" display="inline" align="center" fontWeight={500}>
                Wrapped-up Groups
              </Text>
            </Grid>
            <BrancherDivider marginTop={10} marginBottom={20} height={2} />
            <Grid item>
              <Text variant="lg" align="center" color="purple" fontWeight={700}>
                {groups?.groupMeetingsCreated}{' '}
              </Text>
              <Text variant="lg" align="center" fontWeight={500}>
                Group Meetings Created
              </Text>
            </Grid>
            <Grid item>
              <Text variant="xs" color="placeholderGrey" align="center">
                {groups?.groupPercentageOrganisers}% of groups have organised a meeting
              </Text>
            </Grid>
            <BrancherDivider marginTop={10} height={2} />
            <Grid item>
              <Text variant="lg" align="center" color="purple" fontWeight={700}>
                {groups?.groupMeetingsCompleted}{' '}
              </Text>
              <Text variant="md" align="center" fontWeight={500}>
                Group Meetings Completed
              </Text>
            </Grid>
          </BrancherGridCard>
          <Grid item xs={11} md={8}>
            <SimpleLineChart
              data={groups?.groupMonthlyMeetingAmount ?? []}
              height={500}
              xValueLabel="Date"
              yValueLabel="Amount of meetings"
            />
          </Grid>
        </Grid>
      </Grid>
    ) : (
      <Grid container item xs={12} justifyContent="center" alignItems="center">
        <CircularProgress color="secondary" size={64} />
      </Grid>
    )
  ) : null;
};
