import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import configureStore from './store/configureStore';
import App from './App';
// import { reportWebVitals } from './reportWebVitals';

const { persistor, store } = configureStore();

const StoreProvider = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={null}>
      <App />
    </PersistGate>
  </Provider>
);

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider />
  </React.StrictMode>,
  document.getElementById('root')
);

// reportWebVitals();
