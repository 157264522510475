import * as React from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../consts/colors';
import cx from 'classnames';

const useStyles = makeStyles({
  tile: {
    backgroundColor: Colors.backgroundLightPurple,
    borderRadius: 15,
    padding: (props: IBrancherTile) => props.padding ?? 30,
    '&:hover': {
      cursor: (props: IBrancherTile) => (props.hoverCursor ? 'pointer' : 'initial'),
    },
  },
});

export interface IBrancherTile extends BoxProps {
  hoverCursor?: boolean;
  padding?: number;
}

export const BrancherTile: React.FC<IBrancherTile> = (props) => {
  const { hoverCursor, className, ...other } = props;
  const styles = useStyles({ hoverCursor, padding: other.padding });
  return <Box {...other} className={cx(styles.tile, className)} />;
};
