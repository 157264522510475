import * as React from 'react';
import Add from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../consts/colors';
import { BrancherButton } from '../InputFields/BrancherButton';

const useStyles = makeStyles((theme) => ({
  container: {
    zIndex: 2000,
    marginTop: 4,
    height: 8,
    opacity: 0,
    '&:hover': {
      opacity: 1,
    },
  },
  bar: {
    height: 2,
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
  plusButton: {
    height: 30,
    width: 30,
    left: 10,
    padding: 2,
    borderRadius: '50%',
    backgroundColor: Colors.white,
    borderColor: theme.palette.primary.main,
    '&.MuiButton-root': {
      minWidth: 30,
    },
    '&:hover': {
      backgroundColor: Colors.white,
      borderColor: theme.palette.primary.main,
    },
  },
}));

interface IQuestionCreatorBar {
  addQuestion: () => void;
}

export const QuestionCreatorBar: React.FC<IQuestionCreatorBar> = ({ addQuestion }) => {
  const styles = useStyles();

  return (
    <Grid container className={styles.container} alignItems="center">
      <Box display="flex">
        <BrancherButton className={styles.plusButton} onClick={addQuestion}>
          <Add fontSize="small" />
        </BrancherButton>
      </Box>
      <Grid item xs={10} lg={11} className={styles.bar} />
    </Grid>
  );
};
