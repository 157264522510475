import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Flare from '@material-ui/icons/Flare';
import AutoRenew from '@material-ui/icons/Autorenew';
import LinkOff from '@material-ui/icons/LinkOff';
import Save from '@material-ui/icons/Save';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { useDispatch } from 'react-redux';
import {
  BrancherDispatch,
  UtilGetPairs,
  UtilGetTargetUserPairs,
  UtilSaveIncompleteDraftPairs,
} from '../../store/actions';
import { Page } from '../../Components/General/Page';
import { EPairingTypes, IDraftedPairs, IUnmatched } from '../../store/reducers/PairingReducer';
import { Text } from '../../Components/General/Text';
import { SuggestedPair } from '../Program/ProgramStepThree/SuggestedPair';
import { EDraftingPairTypes } from '../Program/ProgramStepThree/Matchmaking';
import { ProgramPositions } from '../../consts/ProgramPositionOptions';
import { TargetUserPairsDialog } from '../Program/ProgramStepThree/TargetUserPairsDialog';
import { BrancherFullPageLoader } from '../../Components/General/BrancherFullPageLoader';
import { RouteLeavingGuard } from '../../Components/Routing/RouteLeavingGuard';
import {
  getUniqueDraftedMentees,
  getUniqueDraftedMentors,
  getUniqueDraftedUsers
} from '../Program/ProgramStepThree/matchMakingHelpers';
import { ApprovedPair } from '../Program/ProgramStepThree/ApprovedPair';
import { BrancherDivider } from '../../Components/General/BrancherDivider';
import {
  AcceptButton,
  ActionButton,
  ActionNegativeOutlinedButton,
  ActionOutlinedButton, BrancherButton
} from '../../Components/InputFields/BrancherButton';
import {
  mainNavWidth,
  matchmakingMainNavBarHeight,
  matchmakingMobileNavBarHeight,
  mobileMainNavHeight
} from '../../consts/DimensionConsts';
import { Colors } from '../../consts/colors';
import { ConfirmDialog } from '../../Components/General/ConfirmDialog';
import { BrancherCheckbox } from '../../Components/InputFields/BrancherCheckbox';
import { SegmentedControlGroup } from '../../Components/InputFields/BrancherSegmentedControl';
import { BrancherSnackbar } from '../../Components/General/BrancherSnackbar';
import { UserPairingCard } from '../Program/ProgramStepThree/UserPairingCard';
import { PairingReport } from '../Reports/PairingReport';

const useStyles = makeStyles({
  overridePairingCardMargins: {
    marginBottom: 15,
  },
  marginBottom: {
    marginBottom: 150,
  },
  bottomNav: {
    height: (props: { mobile: boolean }) =>
      props.mobile ? matchmakingMobileNavBarHeight : matchmakingMainNavBarHeight,
    background: Colors.backgroundLightPurple,
    bottom: (props: { mobile: boolean }) => (props.mobile ? mobileMainNavHeight : 0),
    top: 'auto',
    display: 'flex',
    alignItems: 'center',
    zIndex: 1000,
    width: (props: { mobile: boolean }) => (props.mobile ? '100%' : `calc(100% - ${mainNavWidth})`),
    boxShadow: `0px -3px 6px ${Colors.cardBorder}`,
  },
});

export enum EMatchingStrategies {
  MAXIMIZE_COMPATIBILITY = 'maximizeCompatibility',
  MAXIMIZE_PAIRING_AMOUNT = 'maximizePairingAmount',
}

export const PairingEngine: React.FC = () => {
  const [pairings, setSuggestedPairings] = React.useState<IDraftedPairs[]>([]);
  const [unpaired, setUnpaired] = React.useState<{ [ProgramPositions.mentee]: IUnmatched[]; [ProgramPositions.mentor]: IUnmatched[] }>({
    mentee: [],
    mentor: [],
  });
  const [gettingPairs, setGettingPairs] = React.useState<boolean>(false);
  const [savingDraftPairs, setSavingDraftPairs] = React.useState<boolean>(false);
  const [targetUser, setTargetUser] = React.useState<string>();
  const [matchingStrategy, setMatchingStrategy] = React.useState<EMatchingStrategies>(EMatchingStrategies.MAXIMIZE_COMPATIBILITY);
  const [targetUserRoleId, setTargetUserRoleId] = React.useState<string>();
  const [targetUserDialog, setTargetUserDialog] = React.useState<boolean>(false);
  const [openFinalisingSnackbar, setOpenFinalisingSnackbar] = React.useState<boolean>(false);
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState<boolean>(false);
  const [hasSavedDraftingData, setHasSavedDraftingData] = React.useState<boolean>(false);
  const [gettingTargetUserPairs, setGettingTargetUserPairs] = React.useState<boolean>(false);
  const [sendPairedEmails, setSendPairedEmails] = React.useState<boolean>(false);
  const [autoMatching, setAutoMatching] = React.useState<boolean>(false);
  const [showAllSuggestedPairings, setShowAllSuggestedPairings] = React.useState<boolean>(false);
  const [showAllDraftPairs, setShowAllDraftPairs] = React.useState<boolean>(false);
  const [targetUserPairs, setTargetUserPairs] = React.useState<IDraftedPairs[]>();
  const [draftingPairType, setDraftingPairType] = React.useState<EDraftingPairTypes>();
  const [draftingActioningPosition, setDraftingActioningPosition] = React.useState<ProgramPositions>();
  const dispatch = useDispatch();
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const styles = useStyles({ mobile });
  // where we keep the incomplete drafted pairs
  const [draftPairs, setDraftPairs] = React.useState<IDraftedPairs[]>([]);
  const [inProgressDraftPairings, setInProgressDraftPairings] = React.useState<IDraftedPairs[]>([]);

  const getPairs = () => {
    setGettingPairs(true);
    BrancherDispatch(
      dispatch,
      UtilGetPairs({ persist: false, matchingStrategy }, (pairData) => {
        setInProgressDraftPairings(pairData.inProgressDraft ?? []);
        setSuggestedPairings(pairData.paired ?? []);
        setUnpaired(pairData?.unpaired ?? { mentee: [], mentor: [] });
        setDraftPairs(pairData?.inProgressDraft ?? []);
        setGettingPairs(false);
      }),
    );
  };

  const saveDraftedPairs = (finalise: boolean = false, emptyDraftPairs: boolean = false, renewPairs: boolean = false) => {
    setSavingDraftPairs(true);
    BrancherDispatch(
      dispatch,
      UtilSaveIncompleteDraftPairs(emptyDraftPairs ? [] : draftPairs, finalise, sendPairedEmails,() => {
        setSavingDraftPairs(false);
        setHasSavedDraftingData(true);
        if(renewPairs) {
          getPairs();
        }
        else if(finalise) {
          setDraftPairs([]);
          setSuggestedPairings([]);
          setOpenFinalisingSnackbar(true);
          setOpenConfirmDialog(false);
        }
      })
    );
  };

  React.useEffect(() => {
    if (pairings?.length === 0) {
      getPairs();
    }
  }, []);

  const autoMatch = () => {
    setAutoMatching(true);
    setHasSavedDraftingData(false);
    let intermediateDraftPairs = draftPairs;
    let intermediateSuggestedPairs = pairings;
    // TODO: This breaks the browser for large pairing sessions - need to improve performance
    pairings.map((pair) => {
      if (
        pair.menteeApprovedPairs !== pair.menteePairAmount &&
        pair.mentorApprovedPairs !== pair.mentorPairAmount
      ) {
        intermediateDraftPairs = [...intermediateDraftPairs, pair].map((p) => {
          let newPairData = p;
          if (p.mentee === pair.mentee) {
            newPairData = {
              ...newPairData,
              pairingType: EPairingTypes.BRANCHER_RECOMMENDED,
              menteeApprovedPairs:
                intermediateDraftPairs.filter((f) => f.mentee === pair.mentee).length + 1,
            };
          }
          if (p.mentor === pair.mentor) {
            newPairData = {
              ...newPairData,
              pairingType: EPairingTypes.BRANCHER_RECOMMENDED,
              mentorApprovedPairs:
                intermediateDraftPairs.filter((f) => f.mentor === pair.mentor).length + 1,
            };
          }
          return newPairData;
        });
        const newPairings = [];
        intermediateSuggestedPairs.forEach((p) => {
          if (!((p.mentee === pair.mentee) && (p.mentor === pair.mentor))) {
            newPairings.push(p);
          }
        });
        intermediateSuggestedPairs = newPairings.map((np) => {
          let newPairData = np;
          if (np.mentee === pair.mentee) {
            newPairData = {
              ...newPairData,
              pairingType: EPairingTypes.BRANCHER_RECOMMENDED,
              menteeApprovedPairs:
                intermediateDraftPairs.filter((f) => f.mentee === pair.mentee).length + 1,
            };
          }
          if (np.mentor === pair.mentor) {
            newPairData = {
              ...newPairData,
              pairingType: EPairingTypes.BRANCHER_RECOMMENDED,
              mentorApprovedPairs:
                intermediateDraftPairs.filter((f) => f.mentor === pair.mentor).length + 1,
            };
          }
          return newPairData;
        });
      }
    });
    setDraftPairs(intermediateDraftPairs);
    setSuggestedPairings(intermediateSuggestedPairs);
    setAutoMatching(false);
  };

  const getTargetUserPossiblePairs = (
    user: string,
    position: ProgramPositions,
    name: string,
    overrideAlgorithm: boolean,
    draftingType?: EDraftingPairTypes,
  ) => {
    setTargetUser(name);
    setTargetUserRoleId(user);
    setGettingTargetUserPairs(true);
    setDraftingPairType(draftingType); // We can later add analytics to this to understand how often they are using certain pairing features
    setDraftingActioningPosition(position); // We can later add analytics to this to understand how often they are using certain pairing features
    BrancherDispatch(
      dispatch,
      UtilGetTargetUserPairs(
        user,
        position,
        overrideAlgorithm,
        false,
        (targetUserMatches: IDraftedPairs[]) => {
          setTargetUserPairs(targetUserMatches);
          setGettingTargetUserPairs(false);
          setTargetUserDialog(true);
        },
      ),
    );
  };

  const closeTargetUserDialog = () => {
    setTargetUserDialog(false);
    setTargetUser('');
    setTargetUserPairs([]);
  };

  const draftPair = (pair: IDraftedPairs, pairingType: EPairingTypes) => {
    if (
      pair.menteeApprovedPairs !== pair.menteePairAmount &&
      pair.mentorApprovedPairs !== pair.mentorPairAmount
    ) {
      setHasSavedDraftingData(false);
      const adjustedDraftPairings = [...draftPairs, pair].map((p) => {
        let newPairData = p;
        if (p.mentee === pair.mentee) {
          newPairData = {
            ...newPairData,
            pairingType,
            menteeApprovedPairs: newPairData.menteeApprovedPairs + 1,
          };
        }
        if (p.mentor === pair.mentor) {
          newPairData = {
            ...newPairData,
            pairingType,
            mentorApprovedPairs: newPairData.mentorApprovedPairs + 1,
          };
        }
        return newPairData;
      });
      setDraftPairs(adjustedDraftPairings);
      const newPairings = [];
      pairings.forEach((p) => {
        if (!(p.mentee === pair.mentee && p.mentor === pair.mentor)) {
          newPairings.push(p);
        }
      });
      const adjustedPairings = newPairings.map((p) => {
        let newPairData = p;
        if (p.mentee === pair.mentee) {
          newPairData = {
            ...newPairData,
            pairingType,
            menteeApprovedPairs: newPairData.menteeApprovedPairs + 1,
          };
        }
        if (p.mentor === pair.mentor) {
          newPairData = {
            ...newPairData,
            pairingType,
            mentorApprovedPairs: newPairData.mentorApprovedPairs + 1,
          };
        }
        return newPairData;
      });
      const filteredMaxedOutPairs = adjustedPairings.filter(
        (p) =>
          p.mentorApprovedPairs !== p.mentorPairAmount &&
          p.menteeApprovedPairs !== p.menteePairAmount,
      );
      setSuggestedPairings(filteredMaxedOutPairs);
      if (targetUser) {
        closeTargetUserDialog();
      }
      // This is really fiddly - in order to update the available positions list
      if (draftingPairType === EDraftingPairTypes.AVAILABLE_POSITIONS) {
        const roleId = draftingActioningPosition === ProgramPositions.mentor ? 'mentorRoleId' : 'menteeRoleId';
        const pairingUserRole =
          draftingActioningPosition === ProgramPositions.mentor ? pair.mentor : pair.mentee;
        const newUnpairedData = unpaired[draftingActioningPosition].filter(
          (user) =>
            !(
              user[roleId] === pairingUserRole &&
              pair[`${draftingActioningPosition}ApprovedPairs`] + 1 ===
              pair[`${draftingActioningPosition}PairAmount`]
            ),
        );

        // Filters out the other user role in the role above removed an unpaired user on the other side
        const mentorIsRoleDraftingPosition = draftingActioningPosition === ProgramPositions.mentor;
        const otherRoleDraftingPosition: ProgramPositions = mentorIsRoleDraftingPosition
          ? ProgramPositions.mentee
          : ProgramPositions.mentor;
        const otherRoleId = mentorIsRoleDraftingPosition ? 'menteeRoleId' : 'mentorRoleId';
        const otherPairingUserRole = mentorIsRoleDraftingPosition ? pair.mentee : pair.mentor;
        const otherUserRoleUnpairedData = unpaired[otherRoleDraftingPosition].filter(
          (user) =>
            !(
              user[otherRoleId] === otherPairingUserRole &&
              pair[`${otherRoleDraftingPosition}ApprovedPairs`] + 1 ===
              pair[`${otherRoleDraftingPosition}PairAmount`]
            ),
        );
        const fullUnpairedData: { unpaired: { [ProgramPositions.mentee]: IUnmatched[]; [ProgramPositions.mentor]: IUnmatched[] } } = {
          // @ts-ignore
          unpaired: {
            [otherRoleDraftingPosition]: otherUserRoleUnpairedData,
            [draftingActioningPosition]: newUnpairedData,
          },
        };
        setUnpaired(fullUnpairedData.unpaired);
      }
    }
  };

  const breakAllPairs = () => {
    setSuggestedPairings([]);
    saveDraftedPairs(false, true, true);
  };

  const breakPair = (pair: IDraftedPairs) => {
    setHasSavedDraftingData(false);
    const newDraftPairings = draftPairs.filter((p) => p !== pair);
    const adjustedDraftPairings = newDraftPairings?.map((p) => {
      let newPairData = p;
      if (p.mentee === pair.mentee) {
        newPairData = { ...newPairData, menteeApprovedPairs: newPairData.menteeApprovedPairs - 1 };
      }
      if (p.mentor === pair.mentor) {
        newPairData = { ...newPairData, mentorApprovedPairs: newPairData.mentorApprovedPairs - 1 };
      }
      return newPairData;
    });
    setDraftPairs(adjustedDraftPairings);
    const adjustedPairings = [...pairings, pair].map((p) => {
      let newPairData = p;
      if (p.mentee === pair.mentee) {
        newPairData = { ...newPairData, menteeApprovedPairs: newPairData.menteeApprovedPairs - 1 };
      }
      if (p.mentor === pair.mentor) {
        newPairData = { ...newPairData, mentorApprovedPairs: newPairData.mentorApprovedPairs - 1 };
      }
      return newPairData;
    });
    setSuggestedPairings(adjustedPairings);
  };

  return (
    <Page loading={gettingPairs} marginBottom={120}>
      <BrancherFullPageLoader loading={gettingTargetUserPairs || autoMatching} />
      <RouteLeavingGuard when={!hasSavedDraftingData} />
      <ConfirmDialog
        setClose={() => setOpenConfirmDialog(false)}
        labelledBy="finalise-user-pairing"
        open={openConfirmDialog}
        title="Finalise user pairing"
        confirmAction={() => saveDraftedPairs(true)}
        confirmButtonText="Yes, finalise"
        denyAction={() => setOpenConfirmDialog(false)}
        denyButtonText="Not yet"
        loading={savingDraftPairs}
      >
        <Grid container justify="center" alignItems="center" direction="column">
          <Grid item>
            <Text variant="md" fontWeight={600} marginTop={50}>
              Are you sure you want to finalise pairing for these {draftPairs.length} pairs?
            </Text>
          </Grid>

          <Grid item>
            <Box display="flex" mb={5} mt={3} justifyContent="center">
              <BrancherCheckbox
                name="sendPairedEmails"
                value={sendPairedEmails}
                updateValue={setSendPairedEmails}
                label="Send pairing introduction email to mentees and mentors"
              />
            </Box>
          </Grid>
        </Grid>
      </ConfirmDialog>

      <BrancherSnackbar
        controlClose={() => setOpenFinalisingSnackbar(false)}
        open={openFinalisingSnackbar}
        message="Pairs have been finalised are currently being sent introduction emails"
      />

      <TargetUserPairsDialog
        setClose={closeTargetUserDialog}
        open={targetUserDialog}
        targetUser={targetUser}
        targetUserPairs={targetUserPairs}
        draftPair={draftPair}
        getUserTargetPairs={() => {
          setTargetUserDialog(false);
          getTargetUserPossiblePairs(
            targetUserRoleId,
            draftingActioningPosition,
            targetUser,
            true,
            draftingPairType,
          );
        }}
      />

      <Grid item container xs={12} justifyContent="center">
        {draftPairs?.length > 0 && (
          <Grid item container xs={10}>
            <Grid item>
              <Text variant="md" color="purple" marginBottom={20} fontWeight={700} display="inline">
                Paired Users
              </Text>
              <Text variant="xs" color="purple" display="inline" marginLeft={8}>
                {getUniqueDraftedUsers(draftPairs)}
              </Text>
            </Grid>
            <Grid item container justifyContent="space-between">
              <Text
                variant="sm"
                color="purple"
                fontWeight={600}
                marginRight={20}
                marginTop={15}
                marginBottom={15}
              >
                Mentee
              </Text>
              <Text variant="sm" color="purple" fontWeight={600} marginTop={15} marginBottom={15}>
                Overall Match Percentage
              </Text>
              <Text
                variant="sm"
                color="purple"
                fontWeight={600}
                marginLeft={20}
                marginTop={15}
                marginBottom={15}
              >
                Mentor
              </Text>
            </Grid>
            <Grid item container spacing={2}>
              {!showAllDraftPairs ? (
                draftPairs?.slice(0, 30)?.map((pair, i) => (
                  <ApprovedPair pair={pair} breakPair={breakPair} key={i} />
                ))
              ) : draftPairs?.map((pair, i) => (
                  <ApprovedPair pair={pair} breakPair={breakPair} key={i} />
                )
              )}
            </Grid>
            {draftPairs?.length > 30 ? (
              <Grid item container justifyContent="center">
                <Box mt={4} display="flex">
                  <BrancherButton size="small" endIcon={!showAllDraftPairs ? <ExpandMore /> : <ExpandLess />} onClick={() => setShowAllDraftPairs(!showAllDraftPairs)}>
                    Show {showAllDraftPairs ? 'Less' : `${draftPairs.length - 30} More`}
                  </BrancherButton>
                </Box>
              </Grid>
            ) : null}
            {(pairings?.length > 0 || unpaired?.mentee?.length > 0 || unpaired?.mentor?.length) ? (
              <Grid item xs={12}>
                <BrancherDivider marginTop={40} marginBottom={40} />
              </Grid>
            ) : null}
          </Grid>
        )}

        <>
          <Grid container item spacing={2} xs={10} alignItems="center" justifyContent="space-between">
            <Grid item xs={12} sm={6} md={4}>
              <SegmentedControlGroup
                value={matchingStrategy}
                updateValue={(s: EMatchingStrategies) => setMatchingStrategy(s)}
                options={[
                  {
                    label: 'Maximize Compatibility',
                    value: EMatchingStrategies.MAXIMIZE_COMPATIBILITY,
                  },
                  {
                    label: 'Maximize Pairing Amount',
                    value: EMatchingStrategies.MAXIMIZE_PAIRING_AMOUNT,
                  }
                ]}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6} md={8} container alignItems="center" justifyContent="center" spacing={4}>
              {pairings?.length > 0 && (
                <Grid item xs={6} md={4}>
                  <ActionButton startIcon={<Flare color="secondary" />} onClick={autoMatch}>
                    Auto-match
                  </ActionButton>
                </Grid>
              )}
              {((unpaired?.[ProgramPositions.mentee]?.length > 0) ||
                (unpaired?.[ProgramPositions.mentor]?.length > 0) ||
                (pairings?.length > 0)) && (
                <Grid item xs={6} md={4}>
                  <ActionButton
                    startIcon={<AutoRenew color="secondary" />}
                    onClick={getPairs}
                    loading={gettingPairs}
                  >
                    Re-run pairing
                  </ActionButton>
                </Grid>
              )}
              {((pairings?.length > 0) || (draftPairs?.length > 0) || (inProgressDraftPairings?.length > 0)) && (
                <Grid item xs={6} md={4}>
                  <PairingReport />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={10}>
            <BrancherDivider marginBottom={40} marginTop={20} />
          </Grid>
        </>

        {pairings?.length > 0 ? (
          <Grid container item xs={10} justifyContent="center" className={styles.marginBottom}>
            <Grid item>
              <Text variant="md" color="purple" marginBottom={20} marginTop={10} fontWeight={700}>
                Suggested Pairs ({pairings?.length} pairs with {getUniqueDraftedUsers(pairings)} unique mentees and mentors)
              </Text>
            </Grid>
            <Grid item container justifyContent="space-between">
              <Text variant="sm" color="purple" fontWeight={600} marginRight={20} marginBottom={10}>
                Mentee ({getUniqueDraftedMentees(pairings)})
              </Text>
              <Text variant="sm" color="purple" fontWeight={600} marginLeft={20} marginBottom={10}>
                Mentor ({getUniqueDraftedMentors(pairings)})
              </Text>
            </Grid>
            <Grid item container spacing={2}>
              {!showAllSuggestedPairings ? (
                pairings?.slice(0, 30)?.map((pair, i) => (
                  <SuggestedPair
                    draftPair={draftPair}
                    pair={pair}
                    key={i}
                    targetUser={getTargetUserPossiblePairs}
                    draftingType={EDraftingPairTypes.SUGGESTED}
                  />
                ))
              ) : pairings?.map((pair, i) => (
                <SuggestedPair
                  draftPair={draftPair}
                  pair={pair}
                  key={i}
                  targetUser={getTargetUserPossiblePairs}
                  draftingType={EDraftingPairTypes.SUGGESTED}
                />
              ))}
              {pairings?.length > 30 ? (
                <Grid item container justifyContent="center">
                  <Box mt={4} display="flex">
                    <BrancherButton size="small" endIcon={!showAllSuggestedPairings ? <ExpandMore /> : <ExpandLess />} onClick={() => setShowAllSuggestedPairings(!showAllSuggestedPairings)}>
                      Show {showAllSuggestedPairings ? 'Less' : `${pairings.length - 30} More`}
                    </BrancherButton>
                  </Box>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        ) : null}
      </Grid>

      {(unpaired?.mentee?.length > 0 || unpaired?.mentor?.length > 0) ? (
        <Grid item xs={12} container justifyContent="center">
          <Grid item xs={10} container justifyContent="center">
            <Grid item>
              <Text variant="md" color="purple" marginBottom={20} fontWeight={700} marginTop={40}>
                Unpaired ({unpaired?.[ProgramPositions.mentee]?.length + unpaired?.[ProgramPositions.mentor]?.length})
              </Text>
            </Grid>
            <Grid item container justifyContent="space-between">
              <Text variant="sm" color="purple" fontWeight={600} marginRight={20} marginBottom={10}>
                Mentee ({unpaired?.mentee?.length})
              </Text>
              <Text variant="sm" color="purple" fontWeight={600} marginLeft={20} marginBottom={10}>
                Mentor ({unpaired?.mentor?.length})
              </Text>
            </Grid>
          </Grid>
          <Grid item container xs={10} spacing={2} justifyContent="space-between">
            <Grid item xs={6}>
              <Grid container direction="column">
                {unpaired?.mentee?.map((mentee, i) => (
                  <UserPairingCard
                    key={i}
                    xs={12}
                    className={styles.overridePairingCardMargins}
                    user={mentee.menteeRoleId}
                    name={`${mentee.firstName} ${mentee.lastName}`}
                    position={ProgramPositions.mentee}
                    targetUser={getTargetUserPossiblePairs}
                    draftingType={EDraftingPairTypes.AVAILABLE_POSITIONS}
                    pairAmount={mentee?.pairAmount}
                    approvedPairs={mentee?.approvedPairs}
                  />
                ))}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="column">
                {unpaired?.mentor?.map((mentor, i) => (
                  <UserPairingCard
                    key={i}
                    xs={12}
                    className={styles.overridePairingCardMargins}
                    user={mentor.mentorRoleId}
                    name={`${mentor.firstName} ${mentor.lastName}`}
                    position={ProgramPositions.mentor}
                    targetUser={getTargetUserPossiblePairs}
                    draftingType={EDraftingPairTypes.AVAILABLE_POSITIONS}
                    pairAmount={mentor?.pairAmount}
                    approvedPairs={mentor?.approvedPairs}
                  />
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container justifyContent="center">
          <Text variant="md" marginBottom={70} marginTop={-60} fontWeight={600} color="purple">
            All mentees and mentors have a suggested pairing 🎉
          </Text>
        </Grid>
      )}
      <Box position="fixed" className={styles.bottomNav}>
        <Grid container justifyContent="center">
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            item
            xs={10}
          >
            <Grid item>
              <ActionNegativeOutlinedButton
                startIcon={<LinkOff color="error" />}
                onClick={breakAllPairs}
                loading={savingDraftPairs}
                disabled={draftPairs.length === 0}
              >
                Start from scratch
              </ActionNegativeOutlinedButton>
            </Grid>
            <Grid item xs={8}>
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item>
                  <ActionOutlinedButton
                    startIcon={<Save />}
                    onClick={() => saveDraftedPairs(false)}
                    loading={savingDraftPairs}
                  >
                    Save as draft
                  </ActionOutlinedButton>
                </Grid>
                <Grid item>
                  <AcceptButton
                    onClick={() => setOpenConfirmDialog(true)}
                  >
                    Finalise pairs (notifies users)
                  </AcceptButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};
