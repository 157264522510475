import { Colors } from '../consts/colors';
import { IBrancherThemePalette } from './BrancherTheme';

export const MuiSvgIcon = (theme: IBrancherThemePalette) => ({
  root: {
    color: theme.palette.primary.main,
  },
  colorPrimary: {
    color: theme.palette.primary.main,
  },
  colorSecondary: {
    color: Colors.white,
  },
  colorAction: {
    color: Colors.white,
  },
  colorError: {
    color: Colors.red,
  },
  colorDisabled: {
    color: Colors.disabledGrey,
  },
});
