import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector } from 'react-redux';
import { IMentoringRequestData } from '../../store/reducers/DashboardReducer';
import { Text } from '../../Components/General/Text';
import { IStoreTypes } from '../../store/storeTypes';
import { Subscriptions } from '../../store/reducers/ProgramReducer';
import { BrancherGridCard } from '../../Components/General/BrancherGridCard';
import { BrancherLinkText } from '../../Components/General/BrancherLinkText';
import { RouteMaster } from '../../Components/Routing';

const useStyles = makeStyles({
  dashboardCard: {
    maxWidth: 400,
    padding: 25,
    minHeight: 'fit-content',
    height: 340,
    marginTop: 30,
  },
});

interface IMentoringRequestsHealth {
  mentoringRequests: IMentoringRequestData;
}

export const MentoringRequestsHealth: React.FC<IMentoringRequestsHealth> = (props) => {
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const subs = useSelector((state: IStoreTypes) => state.program?.subscription);
  const isAdhocPairing = subs?.includes(Subscriptions.ADHOC_PAIRING);
  const { mentoringRequests } = props;
  const styles = useStyles({ mobile });

  return (
    <>
      {isAdhocPairing && (
        <Grid container>
          <BrancherGridCard
            container
            item
            direction="column"
            spacing={3}
            className={styles.dashboardCard}
            xs={11}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item container justifyContent="center">
              <Text variant="lg" fontWeight={500}>
                Mentoring Requests
              </Text>
            </Grid>
            <Grid item container justifyContent="center">
              <Text variant="lg" display="inline" color="purple" fontWeight={700}>
                {mentoringRequests?.requestsSent}{' '}
              </Text>
              <BrancherLinkText
                href={RouteMaster.mentoringRequests.path}
                underline="always"
                variant="lg"
                display="inline"
                fontWeight={600}
              >
                Pending
              </BrancherLinkText>
            </Grid>
            <Grid item container justifyContent="center">
              <Text variant="lg" display="inline" color="purple" fontWeight={700}>
                {mentoringRequests?.requestsAccepted}{' '}
              </Text>
              <Text variant="lg" display="inline" fontWeight={500}>
                Accepted
              </Text>
            </Grid>
            <Grid item container justifyContent="center">
              <Text variant="lg" display="inline" color="purple" fontWeight={700}>
                {mentoringRequests?.requestsDeclined}{' '}
              </Text>
              <Text variant="lg" display="inline" fontWeight={500}>
                Declined
              </Text>
            </Grid>
          </BrancherGridCard>
        </Grid>
      )}
    </>
  );
};
