import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { EComponentType, IToggle } from '../../store/reducers/ProgramReducer';
import { Text } from '../../Components/General/Text';
import { BrancherTextField } from '../../Components/InputFields/BrancherTextField';
import { BrancherDivider } from '../../Components/General/BrancherDivider';
import { BrancherSelect } from '../../Components/InputFields/BrancherSelect';
import { BrancherSwitch } from '../../Components/InputFields/BrancherSwitch';
import { SurveyConfigurationQuestion } from '../../store/reducers/SurveysReducer';
import { QuestionAnswerOptions } from '../Program/ProgramStepOne/QuestionAnswerOptions';
import { SaveButton } from '../../Components/InputFields/BrancherButton';
import { CamelCase } from '../../utils/TextUtils';
import { MakeOptions } from '../ApplicationForm/MakeOptions';
import { Tooltip } from '@material-ui/core';

interface ISurveyBuilderQuestionConfiguration {
  selectedQuestion: string;
  questionConfig: SurveyConfigurationQuestion[];
  saveQuestion: (newConfig: SurveyConfigurationQuestion, prevQuestionName: string) => void;
}

export const SurveyBuilderQuestionConfiguration: React.FC<ISurveyBuilderQuestionConfiguration> = (
  props,
) => {
  const { questionConfig, saveQuestion, selectedQuestion } = props;
  const questionActualConfig = questionConfig.find((a) => a.name === selectedQuestion);
  const [componentType, setComponentType] = React.useState<EComponentType>(
    questionActualConfig?.componentType,
  );
  const [multipleSelectionAmount, setMultipleSelectionAmount] = React.useState<number>(1);
  const [conditionalTextFieldQuestion, setConditionalTextFieldQuestion] = React.useState<string>(
    'textProps' in questionActualConfig ? questionActualConfig.textProps?.label : '',
  );
  const [conditionalTextFieldReportingName, setConditionalTextFieldReportingName] =
    React.useState<string>(
      'textProps' in questionActualConfig ? questionActualConfig.textProps?.reportingName : '',
    );
  const [likertScale, setLikertScale] = React.useState<boolean>(
    'likertScale' in questionActualConfig ? questionActualConfig?.likertScale : false,
  );
  const [multipleSelectionExclusive, setMultipleSelectionExclusive] = React.useState<boolean>();
  const [conditionalTextField, setConditionalTextField] = React.useState<boolean>(
    'conditionalTextField' in questionActualConfig
      ? questionActualConfig?.conditionalTextField
      : false,
  );
  const [conditionalTextFieldValue, setConditionalTextFieldValue] = React.useState<string>(
    'conditionalTextFieldValue' in questionActualConfig
      ? questionActualConfig?.conditionalTextFieldValue
      : '',
  );

  const [nextStepName, setNextStepName] = React.useState<string>(
    'nextStepName' in questionActualConfig ? questionActualConfig?.nextStepName : '',
  );
  const [skipQuestion, setSkipQuestion] = React.useState<boolean>(!!nextStepName ?? false);

  // Branching logic
  const [branchingOptionValue, setBranchingOptionValue] = React.useState<string>(
    'branchingOptionValue' in questionActualConfig
      ? questionActualConfig?.branchingOptionValue
      : '',
  );
  const [branchingStepName, setBranchingStepName] = React.useState<string>(
    'branchingStepName' in questionActualConfig ? questionActualConfig?.branchingStepName : '',
  );
  const [hasBranchingLogic, setHasBranchingLogic] = React.useState<boolean>(
    'hasBranchingLogic' in questionActualConfig ? questionActualConfig?.hasBranchingLogic : false,
  );

  const [reportingName, setReportingName] = React.useState<string>('');
  const [question, setQuestion] = React.useState<string>('');
  const [description, setDescription] = React.useState<string>('');
  const [options, setOptions] = React.useState<IToggle['options']>(
    !!questionActualConfig && 'options' in questionActualConfig
      ? questionActualConfig?.options
      : !!questionActualConfig &&
        'marks' in questionActualConfig &&
        questionActualConfig?.marks?.length > 0
      ? questionActualConfig?.marks?.map((a) => a.label)
      : [],
  );

  React.useEffect(() => {
    setReportingName(questionActualConfig?.readableName ?? '');
    setQuestion(questionActualConfig?.question ?? '');
    setComponentType(questionActualConfig?.componentType ?? EComponentType.TOGGLE);
    setDescription(questionActualConfig?.description ?? '');
    setNextStepName(questionActualConfig?.nextStepName ?? '');
    setSkipQuestion(false);
    if (questionActualConfig?.componentType === EComponentType.SLIDER) {
      setLikertScale(questionActualConfig?.likertScale ?? false);
      setOptions([]);
    }
    if (questionActualConfig?.componentType === EComponentType.TOGGLE) {
      setConditionalTextField(questionActualConfig?.conditionalTextField ?? false);
      setConditionalTextFieldValue(questionActualConfig?.conditionalTextFieldValue ?? '');
      setConditionalTextFieldReportingName(questionActualConfig?.textProps?.readableName ?? '');
      setBranchingOptionValue(questionActualConfig?.branchingOptionValue ?? '');
      setBranchingStepName(questionActualConfig?.branchingStepName ?? '');
      setHasBranchingLogic(questionActualConfig?.hasBranchingLogic ?? false);
      setConditionalTextFieldValue(questionActualConfig?.branchingStepName ?? '');
      setConditionalTextFieldReportingName(questionActualConfig?.textProps?.readableName ?? '');
      setConditionalTextFieldQuestion(questionActualConfig?.textProps?.label ?? '');
      setOptions(questionActualConfig.options ?? []);
      setMultipleSelectionExclusive(
        !questionActualConfig?.exclusive && questionActualConfig?.maximumAmount > 1,
      );
    }
  }, [selectedQuestion]);

  const saveQuestionConfig = () => {
    const actualQuestionUniqueName = CamelCase(reportingName);
    const newCompleteQuestionConfig = (
      componentType === EComponentType.TOGGLE
        ? {
            ...questionActualConfig,
            conditionalTextField,
            conditionalTextFieldValue,
            textProps: {
              label: conditionalTextFieldQuestion,
              readableName: conditionalTextFieldReportingName,
              name: CamelCase(conditionalTextFieldReportingName),
              fullWidth: true,
              componentType: EComponentType.TEXT,
            },
            componentType,
            question,
            readableName: reportingName,
            description,
            name: actualQuestionUniqueName,
            options,
            hasBranchingLogic,
            branchingOptionValue,
            branchingStepName,
            nextStepName,
            exclusive: !(componentType === EComponentType.TOGGLE && multipleSelectionExclusive),
          }
        : componentType === EComponentType.SLIDER
        ? {
            ...questionActualConfig,
            componentType,
            question,
            readableName: reportingName,
            description,
            name: actualQuestionUniqueName,
            likertScale,
            nextStepName,
            ...(options?.length > 0 && { marks: MakeOptions(options as string[]) }),
          }
        : componentType === EComponentType.TEXT && {
            ...questionActualConfig,
            componentType,
            question,
            readableName: reportingName,
            description,
            name: actualQuestionUniqueName,
            fullWidth: true,
            nextStepName,
          }
    ) as SurveyConfigurationQuestion;
    saveQuestion(newCompleteQuestionConfig, actualQuestionUniqueName);
  };

  return (
    <Grid container>
      <Grid item container xs={12}>
        <Text variant="md" fontWeight={600} color="purple" marginTop={20} marginBottom={10}>
          Question Settings
        </Text>
      </Grid>
      <Grid item container xs={12}>
        <BrancherTextField
          value={reportingName}
          fullWidth
          updateValue={setReportingName}
          placeholder="Unique name used for reports"
          label="Reporting name"
        />
      </Grid>
      <Grid item container xs={12}>
        <BrancherTextField
          value={question}
          fullWidth
          updateValue={setQuestion}
          placeholder="What is your..."
          label="Question"
          multiline
          rows={4}
        />
      </Grid>
      <Grid item container xs={12}>
        <BrancherTextField
          value={description}
          fullWidth
          updateValue={setDescription}
          placeholder="Your..."
          label="Description"
          multiline
          rows={3}
        />
      </Grid>

      <Grid item container xs={12}>
        <Text variant="md" fontWeight={600} color="purple" marginTop={20} marginBottom={10}>
          Format
        </Text>
      </Grid>
      <Grid item container xs={12}>
        <BrancherSelect
          value={componentType}
          options={[
            { value: EComponentType.TOGGLE, label: 'Checkbox' },
            { value: EComponentType.TEXT, label: 'Open Text' },
            { value: EComponentType.SLIDER, label: 'Slider' },
          ]}
          fullWidth
          name="componentType"
          updateValue={(a: EComponentType) => setComponentType(a)}
          label="Question type"
        />
      </Grid>

      {componentType === EComponentType.TOGGLE && (
        <>
          <QuestionAnswerOptions options={options} setOptions={setOptions} hideDefaultSelect />

          <BrancherDivider marginTop={40} marginBottom={30} />

          <Grid item container xs={12}>
            <Text variant="md" fontWeight={600} color="purple" marginTop={10} marginBottom={10}>
              Advanced Logic
            </Text>
          </Grid>

          <Grid item container xs={12} justifyContent="space-between" alignItems="center">
            <Text variant="sm" fontWeight={600}>
              Multiple selection
            </Text>
            <BrancherSwitch
              value={multipleSelectionExclusive}
              updateValue={(a: boolean) => {
                setMultipleSelectionExclusive(a);
                if (!a) {
                  setConditionalTextField(false);
                  setConditionalTextFieldValue('');
                  setOptions([]);
                }
              }}
              ariaLabel="multiple-selection"
            />
          </Grid>
          {multipleSelectionExclusive && (
            <Grid item container xs={12}>
              <BrancherTextField
                value={multipleSelectionAmount?.toString()}
                fullWidth
                min={1}
                max={3}
                type="number"
                updateValue={(a: string) => setMultipleSelectionAmount(Number(a))}
                placeholder="Amount of options the user must select"
                label="Selection amount"
              />
            </Grid>
          )}

          <Grid item container xs={12} justifyContent="space-between" alignItems="center">
            <Tooltip title="You can link this question to jump to another question, regardless of the option selected">
              <Text variant="sm" fontWeight={600}>
                Does this question skip to a particular question? (regardless of option selected)
              </Text>
            </Tooltip>
            <BrancherSwitch
              value={skipQuestion}
              updateValue={(a: boolean) => setSkipQuestion(a)}
              ariaLabel="skip-to-question"
            />
            {!!skipQuestion && (
              <Grid item container xs={12} justifyContent="space-between" alignItems="center">
                <Text variant="sm" fontWeight={600}>
                  Which question do you to want to skip to after this question?
                </Text>
                <BrancherSelect
                  value={nextStepName}
                  options={questionConfig.map((q) => ({ label: q.readableName, value: q.name }))}
                  fullWidth
                  updateValue={(a: string) => setNextStepName(a)}
                  name="next-step-name"
                  label="Which question will this question skip to?"
                />
              </Grid>
            )}
          </Grid>

          <Grid item container xs={12} justifyContent="space-between" alignItems="center">
            <Tooltip title="You can link this question to jump to another question, dependant on an option selected">
              <Text variant="sm" fontWeight={600}>
                Does this question link to another question? (with a specific option selected)
              </Text>
            </Tooltip>
            <BrancherSwitch
              value={hasBranchingLogic}
              updateValue={(a: boolean) => {
                setHasBranchingLogic(a);
                if (!a) {
                  setBranchingOptionValue('');
                  setBranchingStepName('');
                }
              }}
              ariaLabel="branching-logic"
            />
          </Grid>
          {hasBranchingLogic && (
            <>
              <Grid item container xs={12} justifyContent="space-between" alignItems="center">
                <Tooltip title="This is the question that the user will skip to">
                  <Text variant="sm" fontWeight={600}>
                    Which question will this question link to?
                  </Text>
                </Tooltip>
                <BrancherSelect
                  value={branchingStepName}
                  options={questionConfig
                    .filter((f) => f.name !== questionActualConfig.name)
                    ?.map((q) => ({ label: q.readableName, value: q.name }))}
                  fullWidth
                  updateValue={(a: string) => setBranchingStepName(a)}
                  name="branching-step-name"
                  label="Which question will this question branch to?"
                />
              </Grid>
              <Grid item container xs={12}>
                <Tooltip title="This is the option that if selected will link the questions">
                  <Text variant="sm" fontWeight={600} marginTop={10}>
                    What option links to this question?
                  </Text>
                </Tooltip>
                <BrancherSelect
                  value={branchingOptionValue}
                  options={MakeOptions(options as string[])}
                  fullWidth
                  updateValue={(a: string) => setBranchingOptionValue(a)}
                  name="branching-option-value"
                  label="The option that will link the questions"
                />
              </Grid>
            </>
          )}

          {!multipleSelectionExclusive && (
            <Grid item container xs={12} justifyContent="space-between" alignItems="center">
              <Tooltip title="A nested text field will appear if a particular option is selected">
                <Text variant="sm" fontWeight={600}>
                  Does this question have a conditional open text field? (dependant on option
                  selected)
                </Text>
              </Tooltip>
              <BrancherSwitch
                value={conditionalTextField}
                updateValue={(a: boolean) => setConditionalTextField(a)}
                ariaLabel="conditional-textfield"
              />
              {conditionalTextField && (
                <Grid item container xs={12}>
                  <Tooltip title="This option opens the text field">
                    <Text variant="sm" fontWeight={600} marginTop={10}>
                      Which option opens the open text?
                    </Text>
                  </Tooltip>
                  <BrancherSelect
                    value={conditionalTextFieldValue}
                    options={MakeOptions(options as string[])}
                    fullWidth
                    name="conditional-textfield-value"
                    updateValue={(a: string) => {
                      setConditionalTextFieldValue(a);
                      setMultipleSelectionAmount(1);
                    }}
                    label="Which option opens the conditional text field?"
                  />

                  <Grid item container xs={12}>
                    <BrancherTextField
                      value={conditionalTextFieldReportingName}
                      fullWidth
                      updateValue={setConditionalTextFieldReportingName}
                      placeholder="Unique name used for reports"
                      label="Conditional Open Text Reporting name"
                    />
                  </Grid>
                  <Grid item container xs={12}>
                    <BrancherTextField
                      value={conditionalTextFieldQuestion}
                      fullWidth
                      updateValue={setConditionalTextFieldQuestion}
                      placeholder="What is your..."
                      label="Conditional Open Text Question"
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
        </>
      )}

      {componentType === EComponentType.SLIDER && (
        <>
          <Grid item container xs={12} justifyContent="space-between" alignItems="center">
            <Text variant="sm" fontWeight={600}>
              Likert Scale (1 to 5)
            </Text>
            <BrancherSwitch
              value={likertScale}
              updateValue={(a: boolean) => {
                setLikertScale(a);
              }}
              ariaLabel="likert-scale"
            />
          </Grid>

          {!likertScale && (
            <QuestionAnswerOptions options={options} setOptions={setOptions} hideDefaultSelect />
          )}
        </>
      )}

      <Grid container justifyContent="flex-end" item>
        <Grid item>
          <Box mt={4} display="flex">
            <SaveButton onClick={saveQuestionConfig}>Save survey question</SaveButton>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
