import { Colors } from '../consts/colors';
import { IBrancherThemePalette } from './BrancherTheme';

export const MuiLinearProgress = (theme: IBrancherThemePalette) => ({
  root: {
    borderRadius: 4,
    height: 8,
    marginLeft: 15,
  },
  colorPrimary: {
    backgroundColor: Colors.lightGrey,
  },
  barColorPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
});
