import * as React from 'react';
import { IValuesFormConfig, ValuesFormQuestions } from './ValuesFormConfig';
import { SliderGroup } from '../FormFieldTypes/SliderGroup';
import { EComponentType } from '../../../store/reducers/ProgramReducer';
import { DraggableSection } from '../../../Components/General/DraggableSection';

interface IValuesForm {
  stepNumber: number;
  prevStepNumber: number;
  valuesQuestions: IValuesFormConfig[];
}

export const ValuesForm: React.FC<IValuesForm> = (props) => {
  const { stepNumber, prevStepNumber, valuesQuestions } = props;
  const valuesStepNumber = stepNumber - prevStepNumber - 1;
  const currQuestion = valuesQuestions[valuesStepNumber];

  return (
    <>
      {currQuestion.componentType === EComponentType.DND ? (
        <DraggableSection
          question={currQuestion.question}
          value={currQuestion.value}
          updateValue={currQuestion.updateValue}
          vertical={true}
        />
      ) : (
        <>
          {stepNumber === prevStepNumber + 1 ? (
            <SliderGroup {...valuesQuestions[0]} />
          ) : stepNumber === prevStepNumber + 2 ? (
            <SliderGroup {...valuesQuestions[1]} />
          ) : null}
        </>
      )}
    </>
  );
};
