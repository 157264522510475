import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SaveButton } from '../../Components/InputFields/BrancherButton';
import {
  ENudgeList,
  nudgeListConfig,
  programConfig,
  subscriptionConfig,
  Subscriptions,
  UpdatableProgramInfo,
} from '../../store/reducers/ProgramReducer';
import { BrancherTextField } from '../../Components/InputFields/BrancherTextField';
import { BrancherCheckbox } from '../../Components/InputFields/BrancherCheckbox';
import { BrancherRadio } from '../../Components/InputFields/Radio/BrancherRadio';
import { IStoreTypes } from '../../store/storeTypes';
import { Text } from '../../Components/General/Text';
import { EBooleanOptions } from '../SuperUser/CreateProgram';
import { AllPositions } from '../../consts/ProgramPositionOptions';
import { DatePicker } from '../../Components/InputFields/DatePicker';
import { add, format, parse } from 'date-fns';
import { BrancherInputLabel } from '../../Components/InputFields/BrancherInputLabel';
import { BrancherDispatch, UtilUpdateProgramSettings } from '../../store/actions';
import { BrancherDivider } from '../../Components/General/BrancherDivider';
import { UserDeactivationUpload } from './UserDeactivationUpload';
import { deepEqual } from '../../utils/equalityComparison';
import { UserApplicationApprovalsUpload } from './UserApplicationApprovalsUpload';
import { CopyProgramSignupLink } from '../Dashboard/CopyProgramSignupLink';
import { CopyAdminSignupLink } from '../Dashboard/CopyAdminSignupLink';
import Box from '@material-ui/core/Box';
import { dateDefaultFormat } from '../../helpers/DateHelpers';

export const Settings: React.FC = () => {
  const programInfo = useSelector((state: IStoreTypes) => state.program);
  const isMoD = programInfo[UpdatableProgramInfo.SUBSCRIPTION]?.includes(
    Subscriptions.ADHOC_PAIRING,
  );
  const isBrancherAdmin = useSelector((state: IStoreTypes) =>
    state.user?.positions?.includes(AllPositions.BRANCHER_ADMIN),
  );
  const [programName, setProgramName] = React.useState<string>(
    programInfo[UpdatableProgramInfo.PROGRAM_NAME],
  );
  const [supportEmail, setSupportEmail] = React.useState<string>(
    programInfo[UpdatableProgramInfo.SUPPORT_EMAIL],
  );
  const [nudgeList, setNudgeList] = React.useState<ENudgeList[]>(
    programInfo[UpdatableProgramInfo.NUDGE_LIST] ?? [],
  );
  const [nextNudgeDate, setNextNudgeDate] = React.useState<Date>(
    programInfo?.[UpdatableProgramInfo.NEXT_NUDGE_DATE]
      ? parse(programInfo[UpdatableProgramInfo.NEXT_NUDGE_DATE], dateDefaultFormat, new Date())
      : new Date(),
  );
  const [subscriptionEndDate, setSubscriptionEndDate] = React.useState<Date>(
    programInfo?.[UpdatableProgramInfo.SUBSCRIPTION_END_DATE]
      ? parse(
          programInfo[UpdatableProgramInfo.SUBSCRIPTION_END_DATE],
          dateDefaultFormat,
          new Date(),
        )
      : new Date(),
  );
  const [maximumMentorAmount, setMaximumMentorAmount] = React.useState<number>(
    programInfo[UpdatableProgramInfo.MAXIMUM_MENTOR_AMT] ?? 1,
  );
  const [maximumMenteeAmount, setMaximumMenteeAmount] = React.useState<number>(
    programInfo[UpdatableProgramInfo.MAXIMUM_MENTEE_AMT] ?? 1,
  );
  const [userMonthlyCost, setUserMonthlyCost] = React.useState<number>(
    programInfo[UpdatableProgramInfo.USER_MONTHLY_COST] ?? 20,
  );
  const [onDemandOpen, setOnDemandOpen] = React.useState<EBooleanOptions>(
    programInfo[UpdatableProgramInfo.ON_DEMAND_OPEN] ? EBooleanOptions.YES : EBooleanOptions.NO,
  );
  const [isActive, setIsActive] = React.useState<EBooleanOptions>(
    programInfo[UpdatableProgramInfo.ACTIVE] ? EBooleanOptions.YES : EBooleanOptions.NO,
  );
  const [subscriptions, setSubscriptions] = React.useState<Subscriptions[]>(
    programInfo[UpdatableProgramInfo.SUBSCRIPTION],
  );
  const [hasPlatformAccess, setHasPlatformAccess] = React.useState<EBooleanOptions>(
    programInfo[UpdatableProgramInfo.HAS_PLATFORM_ACCESS]
      ? EBooleanOptions.YES
      : EBooleanOptions.NO,
  );
  const [isDisableApplicationFormNudge, setDisableApplicationFormNudge] =
    React.useState<EBooleanOptions>(
      programInfo[UpdatableProgramInfo.DISABLE_APPLICATION_FORM_NUDGE]
        ? EBooleanOptions.YES
        : EBooleanOptions.NO,
    );
  const [applicationAlwaysOpen, setApplicationAlwaysOpen] = React.useState<EBooleanOptions>(
    programInfo[UpdatableProgramInfo.APPLICATION_ALWAYS_OPEN]
      ? EBooleanOptions.YES
      : EBooleanOptions.NO,
  );
  const [requiresApplicationApproval, setRequiresApplicationApproval] =
    React.useState<EBooleanOptions>(
      programInfo[UpdatableProgramInfo.REQUIRES_APPLICATION_APPROVAL]
        ? EBooleanOptions.YES
        : EBooleanOptions.NO,
    );
  const [maximumUserCapHard, setMaximumUserCapHard] = React.useState<number>(
    programInfo?.[UpdatableProgramInfo.MAXIMUM_USER_CAP_HARD] ?? 120,
  );
  const [maximumUserCapSoft, setMaximumUserCapSoft] = React.useState<number>(
    programInfo?.[UpdatableProgramInfo.MAXIMUM_USER_CAP_SOFT] ?? 120,
  );
  const [updatingProgram, setUpdatingProgram] = React.useState<boolean>(false);
  const dispatch = useDispatch();

  const addSubscription = (sub: Subscriptions) => {
    setSubscriptions(subscriptions?.concat(sub));
  };

  const removeSubscription = (sub: Subscriptions) => {
    setSubscriptions(subscriptions.filter((s) => s !== sub));
  };

  const addNudge = (nudge: ENudgeList) => {
    setNudgeList(nudgeList?.concat(nudge));
  };

  const removeNudge = (nudge: ENudgeList) => {
    setNudgeList(nudgeList.filter((s) => s !== nudge));
  };

  const programDataHasChanged = (): boolean => {
    const initialProgramState = {
      programName: programInfo[UpdatableProgramInfo.PROGRAM_NAME],
      supportEmail: programInfo[UpdatableProgramInfo.SUPPORT_EMAIL],
      nudgeList: programInfo[UpdatableProgramInfo.NUDGE_LIST] ?? [],
      maximumMentorAmount: programInfo[UpdatableProgramInfo.MAXIMUM_MENTOR_AMT] ?? 1,
      maximumMenteeAmount: programInfo[UpdatableProgramInfo.MAXIMUM_MENTEE_AMT] ?? 1,
      onDemandOpen: programInfo[UpdatableProgramInfo.ON_DEMAND_OPEN]
        ? EBooleanOptions.YES
        : EBooleanOptions.NO,
      subscription: programInfo[UpdatableProgramInfo.SUBSCRIPTION] ?? [],
      active: programInfo[UpdatableProgramInfo.ACTIVE] ? EBooleanOptions.YES : EBooleanOptions.NO,
      disableApplicationFormNudge: programInfo[UpdatableProgramInfo.DISABLE_APPLICATION_FORM_NUDGE]
        ? EBooleanOptions.YES
        : EBooleanOptions.NO,
      hasPlatformAccess: programInfo[UpdatableProgramInfo.HAS_PLATFORM_ACCESS]
        ? EBooleanOptions.YES
        : EBooleanOptions.NO,
      applicationAlwaysOpen: programInfo[UpdatableProgramInfo.APPLICATION_ALWAYS_OPEN]
        ? EBooleanOptions.YES
        : EBooleanOptions.NO,
      requiresApplicantApproval: programInfo[UpdatableProgramInfo.REQUIRES_APPLICATION_APPROVAL]
        ? EBooleanOptions.YES
        : EBooleanOptions.NO,
      nextNudgeDate: programInfo[UpdatableProgramInfo.NEXT_NUDGE_DATE]
        ? format(
            parse(programInfo[UpdatableProgramInfo.NEXT_NUDGE_DATE], dateDefaultFormat, new Date()),
            dateDefaultFormat,
          )
        : format(new Date(), dateDefaultFormat),
      [UpdatableProgramInfo.SUBSCRIPTION_END_DATE]: programInfo[
        UpdatableProgramInfo.SUBSCRIPTION_END_DATE
      ]
        ? format(
            parse(
              programInfo[UpdatableProgramInfo.SUBSCRIPTION_END_DATE],
              dateDefaultFormat,
              new Date(),
            ),
            dateDefaultFormat,
          )
        : format(new Date(), dateDefaultFormat),
      [UpdatableProgramInfo.MAXIMUM_USER_CAP_HARD]:
        programInfo?.[UpdatableProgramInfo.MAXIMUM_USER_CAP_HARD] ?? 120,
      [UpdatableProgramInfo.MAXIMUM_USER_CAP_SOFT]:
        programInfo?.[UpdatableProgramInfo.MAXIMUM_USER_CAP_SOFT] ?? 120,
      [UpdatableProgramInfo.USER_MONTHLY_COST]:
        programInfo?.[UpdatableProgramInfo.USER_MONTHLY_COST] ?? 20,
    };
    const currProgramState = {
      programName,
      supportEmail,
      nudgeList,
      maximumMentorAmount,
      maximumMenteeAmount,
      onDemandOpen,
      subscription: subscriptions,
      active: isActive,
      hasPlatformAccess,
      applicationAlwaysOpen,
      isDisableApplicationFormNudge,
      requiresApplicationApproval,
      nextNudgeDate: format(nextNudgeDate, dateDefaultFormat),
      subscriptionEndDate: format(subscriptionEndDate, dateDefaultFormat),
      userMonthlyCost,
      maximumUserCapHard,
      maximumUserCapSoft,
    };

    return deepEqual(initialProgramState, currProgramState);
  };

  const createProgram = () => {
    setUpdatingProgram(true);
    BrancherDispatch(
      dispatch,
      UtilUpdateProgramSettings(
        {
          programName,
          supportEmail,
          nudgeList,
          maximumMentorAmount,
          maximumMenteeAmount,
          subscription: subscriptions,
          onDemandOpen: onDemandOpen === EBooleanOptions.YES,
          active: isActive === EBooleanOptions.YES,
          disableApplicationFormNudge: isDisableApplicationFormNudge === EBooleanOptions.YES,
          hasPlatformAccess: hasPlatformAccess === EBooleanOptions.YES,
          applicationAlwaysOpen: applicationAlwaysOpen === EBooleanOptions.YES,
          requiresApplicationApproval: requiresApplicationApproval === EBooleanOptions.YES,
          nextNudgeDate: format(nextNudgeDate, dateDefaultFormat),
          subscriptionEndDate: format(subscriptionEndDate, dateDefaultFormat),
          userMonthlyCost,
          maximumUserCapHard,
          maximumUserCapSoft,
        },
        () => {
          setUpdatingProgram(false);
        },
      ),
    );
  };

  const hasAdhocPairing = subscriptions.includes(Subscriptions.ADHOC_PAIRING);

  return (
    <Grid container justifyContent="center">
      <Grid container item xs={11} spacing={2}>
        <Grid item>
          <Text variant="xl" marginTop={30} marginBottom={20} color="purple">
            {programInfo[UpdatableProgramInfo.PROGRAM_NAME]} Settings
          </Text>
        </Grid>
      </Grid>

      <Grid container item xs={11}>
        <Box mb={3}>
          <Grid item xs={12}>
            <CopyProgramSignupLink variant="text" />
          </Grid>
          {isBrancherAdmin && (
            <Grid item xs={12}>
              <CopyAdminSignupLink variant="text" />
            </Grid>
          )}
        </Box>
      </Grid>

      <Grid container item xs={11} justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          <BrancherTextField
            value={programName}
            updateValue={setProgramName}
            placeholder="New program"
            label="Program Name"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <BrancherTextField
            value={supportEmail}
            updateValue={setSupportEmail}
            fullWidth
            type="email"
            placeholder="Program support lead email"
            label="Program support email"
          />
        </Grid>
        <Grid item xs={12}>
          <BrancherRadio
            options={programConfig}
            value={programInfo.programType}
            label="Program type"
            aria-label="program-type"
            disabled
            onChange={() => {}}
          />
        </Grid>
        <Grid item xs={12}>
          <BrancherTextField
            value={maximumMentorAmount}
            updateValue={(a: string) => {
              if (Number(a) <= 5 && Number(a) > 0) {
                setMaximumMentorAmount(Number(a));
              }
            }}
            fullWidth
            max={5}
            min={1}
            type="number"
            placeholder="maximum amount of mentoring partners for mentors"
            label="Mentors' maximum partner amount"
            helperText="Recommended maximum of 5"
          />
          <BrancherTextField
            value={maximumMenteeAmount}
            updateValue={(a: string) => {
              if (Number(a) <= 5 && Number(a) > 0) {
                setMaximumMenteeAmount(Number(a));
              }
            }}
            fullWidth
            max={5}
            min={1}
            type="number"
            placeholder="maximum amount of mentoring partners for mentees"
            label="Mentees' maximum partner amount"
            helperText="Recommended maximum of 5"
          />
        </Grid>
        <Grid container alignItems="center" justifyContent="space-between" item xs={12}>
          <Grid item>
            <BrancherInputLabel variant="sm" fontWeight={700} color="purple" for="date">
              Next nudge date
            </BrancherInputLabel>
            <DatePicker
              value={nextNudgeDate}
              updateValue={setNextNudgeDate}
              minDate={add(new Date(), { days: 1 })}
              id="nudge-date"
              name="next-nudge-date"
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <BrancherRadio
            options={[
              { label: 'No', value: EBooleanOptions.NO },
              { label: 'Yes', value: EBooleanOptions.YES },
            ]}
            value={applicationAlwaysOpen}
            label="Signup form always open"
            aria-label="application-always-open"
            onChange={setApplicationAlwaysOpen}
          />
        </Grid>
        <Grid item xs={12}>
          <BrancherRadio
            options={[
              { label: 'No', value: EBooleanOptions.NO },
              { label: 'Yes', value: EBooleanOptions.YES },
            ]}
            value={isDisableApplicationFormNudge}
            label="Disable application form nudge"
            aria-label="disable-application-form-nudge"
            onChange={setDisableApplicationFormNudge}
          />
        </Grid>
        {hasAdhocPairing && (
          <Grid item xs={12}>
            <BrancherRadio
              options={[
                { label: 'No', value: EBooleanOptions.NO },
                { label: 'Yes', value: EBooleanOptions.YES },
              ]}
              value={requiresApplicationApproval}
              label="Applicants Require Approval"
              aria-label="applicants-require-approval"
              onChange={setRequiresApplicationApproval}
            />
          </Grid>
        )}
        {isMoD && (
          <Grid item xs={12}>
            <BrancherRadio
              options={[
                { label: 'No', value: EBooleanOptions.NO },
                { label: 'Yes', value: EBooleanOptions.YES },
              ]}
              value={onDemandOpen}
              label="User driven matching open"
              aria-label="on-demand-open"
              onChange={setOnDemandOpen}
            />
          </Grid>
        )}

        {isBrancherAdmin && (
          <>
            <Grid item xs={12}>
              <BrancherTextField
                value={maximumUserCapHard}
                updateValue={(cap) => setMaximumUserCapHard(Number(cap))}
                fullWidth
                type="number"
                placeholder="Maximum User Cap"
                label="Maximum User Cap"
                max={10000}
              />
            </Grid>
            <Grid item xs={12}>
              <BrancherTextField
                value={maximumUserCapSoft}
                updateValue={(cap) => setMaximumUserCapSoft(Number(cap))}
                fullWidth
                type="number"
                placeholder="Paid user subscription"
                label="Paid user subscription"
                max={10000}
              />
            </Grid>
            <Grid item xs={12}>
              <BrancherTextField
                value={userMonthlyCost}
                updateValue={(cost) => setUserMonthlyCost(Number(cost))}
                fullWidth
                type="number"
                placeholder="$"
                label="User monthly cost"
              />
            </Grid>
            {!!programInfo?.[UpdatableProgramInfo.BILLING_DATE] && (
              <Grid item xs={12}>
                <Text variant="sm" fontWeight={700} color="purple" marginTop={10}>
                  Next billing date:
                </Text>
                <Text variant="sm" fontWeight={600} marginBottom={10}>
                  {format(
                    parse(
                      programInfo[UpdatableProgramInfo.BILLING_DATE],
                      dateDefaultFormat,
                      new Date(),
                    ),
                    dateDefaultFormat,
                  )}
                </Text>
              </Grid>
            )}
            <Grid container alignItems="center" justifyContent="space-between" item xs={12}>
              <Grid item>
                <BrancherInputLabel variant="sm" fontWeight={700} color="purple" for="date">
                  Subscription end date
                </BrancherInputLabel>
                <DatePicker
                  value={subscriptionEndDate}
                  updateValue={setSubscriptionEndDate}
                  minDate={new Date()}
                  id="subscription-end-date"
                  name="subscription-end-date"
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <BrancherRadio
                options={[
                  { label: 'No', value: EBooleanOptions.NO },
                  { label: 'Yes', value: EBooleanOptions.YES },
                ]}
                value={isActive}
                label="Program is active"
                aria-label="active-program"
                onChange={setIsActive}
              />
            </Grid>
            <Grid item xs={12}>
              <BrancherRadio
                options={[
                  { label: 'No', value: EBooleanOptions.NO },
                  { label: 'Yes', value: EBooleanOptions.YES },
                ]}
                value={hasPlatformAccess}
                label="Has platform access"
                aria-label="has-platform-access"
                onChange={setHasPlatformAccess}
              />
            </Grid>
            <Grid item xs={12} container spacing={1}>
              <Grid item xs={12}>
                <Text variant="sm" fontWeight={600}>
                  Subscriptions
                </Text>
              </Grid>
              {subscriptionConfig.map((sub, i) => (
                <Grid item xs={12} md={6} key={i}>
                  <BrancherCheckbox
                    updateValue={(s) => {
                      if (s) {
                        addSubscription(sub.value);
                      } else {
                        removeSubscription(sub.value);
                      }
                    }}
                    value={subscriptions?.includes(sub.value)}
                    label={sub.label}
                  />
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12} container spacing={1}>
              <Grid item xs={12}>
                <Text variant="sm" fontWeight={600}>
                  Nudges
                </Text>
              </Grid>
              {nudgeListConfig.map((sub, i) => (
                <Grid item xs={12} md={6} key={i}>
                  <BrancherCheckbox
                    updateValue={(s) => {
                      if (s) {
                        addNudge(sub.value);
                      } else {
                        removeNudge(sub.value);
                      }
                    }}
                    value={nudgeList?.includes(sub.value)}
                    label={sub.label}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <SaveButton
            onClick={createProgram}
            loading={updatingProgram}
            disabled={updatingProgram || programDataHasChanged()}
          >
            Update Program Settings
          </SaveButton>
        </Grid>

        {/* CSV upload */}
        <Grid item xs={12}>
          <BrancherDivider marginTop={50} marginBottom={50} />
        </Grid>
        <UserDeactivationUpload />
        {!!programInfo[UpdatableProgramInfo.REQUIRES_APPLICATION_APPROVAL] && (
          <>
            <Grid item xs={12}>
              <BrancherDivider marginTop={50} marginBottom={50} />
            </Grid>
            <UserApplicationApprovalsUpload />
          </>
        )}
      </Grid>
    </Grid>
  );
};
