import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import DragIndicator from '@material-ui/icons/DragIndicator';
import { makeStyles } from '@material-ui/core/styles';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Colors } from '../../consts/colors';
import { Text } from './Text';
import { EDropAnimation, IDraggableColumn } from './DraggableSection';

export const DraggableColumn: React.FC<IDraggableColumn> = (props) => {
  const { items, id, heading, dropAnimation, placeholder, hasCompletedQuestion } = props;

  return (
    <Droppable droppableId={id}>
      {(provided, snapshot) => (
        <Grid container justifyContent="center" item xs={12}>
          <AnimationEmoji
            ref={provided.innerRef}
            isDraggingOver={snapshot.isDraggingOver}
            dropAnimation={dropAnimation}
            heading={heading}
            columnId={id}
          >
            <Grid container item justifyContent="space-around" spacing={2}>
              {items?.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(providedColumn, snapshotColumn) => (
                    <DraggableTile
                      providedColumn={providedColumn}
                      snapshotColumn={snapshotColumn}
                      index={index}
                      columnId={id}
                      itemsInColumn={items.length}
                      hasCompletedQuestion={hasCompletedQuestion}
                    >
                      <Text variant="sm" fontWeight={500} marginLeft={16}>
                        {item.readableName}
                      </Text>
                    </DraggableTile>
                  )}
                </Draggable>
              ))}
              {items?.length === 0 && (
                <Text variant="md" fontWeight={400} color="purple">
                  {placeholder}
                </Text>
              )}
            </Grid>
            {provided.placeholder}
          </AnimationEmoji>
        </Grid>
      )}
    </Droppable>
  );
};

interface IDraggableTile {
  providedColumn: {
    innerRef: React.RefObject<HTMLDivElement>;
    draggableProps: any;
    dragHandleProps: any;
  };
  snapshotColumn: any;
  itemsInColumn: number;
  index: number;
  columnId: string;
  hasCompletedQuestion: boolean;
}

const useStyles = makeStyles({
  defaultCard: {
    minHeight: 56,
    background: Colors.backgroundDarkerGrey,
    userSelect: 'none',
    display: 'flex',
    alignItems: 'center',
    width: 'inherit',
    marginTop: (props: { hasBeenChosen: boolean; hasCompletedQuestion: boolean }) =>
      props.hasBeenChosen ? 0 : 20,
    justifyContent: 'flex-start',
    padding: `16px 20px`,
    borderRadius: 15,
    border: (props: { hasBeenChosen: boolean; hasCompletedQuestion: boolean }) =>
      props.hasBeenChosen
        ? `2px solid ${props.hasCompletedQuestion ? Colors.green : Colors.purple}`
        : 'inherit',
    boxShadow: (props: { hasBeenChosen: boolean; hasCompletedQuestion: boolean }) =>
      props.hasBeenChosen ? 'none' : `0px 3px 6px ${Colors.cardBorder}`,
  },
  icon: {
    color: (props: { hasBeenChosen: boolean; hasCompletedQuestion: boolean }) =>
      props.hasCompletedQuestion ? Colors.green : Colors.purple,
  },
});

const DraggableTile: React.FC<IDraggableTile> = (props) => {
  const { providedColumn, snapshotColumn, children, columnId, hasCompletedQuestion } = props;
  const isOptionsColumn = columnId === 'options';
  const styles = useStyles({
    hasBeenChosen: !isOptionsColumn,
    hasCompletedQuestion: hasCompletedQuestion && !isOptionsColumn,
  });

  const getItemStyle = (snapshot: any, draggableStyle: any) => {
    if (!snapshot.isDropAnimating) {
      return draggableStyle;
    }
    const { curve } = snapshot.dropAnimation;

    return {
      ...draggableStyle,
      borderRadius: 15,
      userSelect: 'none',
      alignItems: 'center',
      minWidth: 'inherit',
      height: 56,
      justifyContent: 'flex-start',
      display: 'flex',
      boxShadow: `0px 3px 6px ${Colors.cardBorder}`,
      cursor: snapshot.isDragging ? 'move' : 'pointer',
      transition: `all ${curve} .01s`, // This causes it to pop to the front of the card if we increase duration
    };
  };

  return (
    <Grid
      item
      container
      ref={providedColumn.innerRef}
      {...providedColumn.draggableProps}
      {...providedColumn.dragHandleProps}
      style={getItemStyle(snapshotColumn, providedColumn.draggableProps.style)}
      className={styles.defaultCard}
      xs={11}
    >
      <DragIndicator className={styles.icon} />
      {children}
    </Grid>
  );
};

const useEmojiStyles = makeStyles({
  listStyle: {
    minHeight: 56,
    background: Colors.white,
    userSelect: 'none',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'flex-start',
    padding: `16px 20px`,
    borderRadius: 15,
    border: (props: { isDraggingOver: boolean }) =>
      props.isDraggingOver ? `4px solid ${Colors.purple}` : `2px solid ${Colors.purple}`,
  },
  optionsColumn: {
    background: Colors.white,
    userSelect: 'none',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: 10,
    marginTop: 10,
    justifyContent: 'flex-start',
  },
});

interface IAnimationEmoji {
  isDraggingOver: boolean;
  dropAnimation: EDropAnimation;
  heading: string;
  columnId: string;
  children: React.ReactNode;
}

const AnimationEmoji = React.forwardRef(
  (props: IAnimationEmoji, ref: React.RefObject<HTMLDivElement> = null) => {
    const { isDraggingOver, dropAnimation, heading, children, columnId } = props;
    const hasItems = children[0].props.children[0].length > 0;
    const styles = useEmojiStyles({ isDraggingOver });
    const variant = 'sm';

    const emoji =
      dropAnimation === EDropAnimation.LOVE ? (
        <Text variant={variant} display="inline">
          &#128525;
        </Text>
      ) : dropAnimation === EDropAnimation.THINKING ? (
        <Text variant={variant} display="inline">
          &#129300;
        </Text>
      ) : dropAnimation === EDropAnimation.NEUTRAL ? (
        <Text variant={variant} display="inline">
          &#128528;
        </Text>
      ) : dropAnimation === EDropAnimation.DISLIKE ? (
        <Text variant={variant} display="inline">
          &#128533;
        </Text>
      ) : (
        ''
      );

    return (
      <>
        {heading && (
          <Box mt={2} mb={2}>
            <Text variant={variant} fontWeight={400} color="black" display="inline">
              Drag & drop{' '}
            </Text>
            <Text variant={variant} fontWeight={600} color="purple" display="inline">
              {heading}
            </Text>
            <Text variant={variant} fontWeight={400} color="black" display="inline">
              {' '}
              value to you in the box below {emoji}
            </Text>
          </Box>
        )}
        <Grid
          container
          item
          ref={ref}
          xs={12}
          className={
            !!heading && !hasItems ? styles.listStyle : !heading ? styles.optionsColumn : ''
          }
          alignItems="center"
          justifyContent="center"
        >
          {children}
        </Grid>
      </>
    );
  },
);
