import { IBrancherThemePalette } from './BrancherTheme';
import { Colors } from '../consts/colors';

export const MuiBadge = (theme: IBrancherThemePalette) => ({
  badge: {
    minHeight: 40,
    minWidth: 40,
    borderRadius: '50%',
    paddingBottom: 4,
  },
  colorPrimary: {
    backgroundColor: Colors.white,
    color: theme.palette.primary.main,
  },
});
