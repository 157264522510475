import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/icons/Link';
import LinkOff from '@material-ui/icons/LinkOff';
import { EPairingTypes, IDraftedPairs } from '../../../store/reducers/PairingReducer';
import { ProgramPositions } from '../../../consts/ProgramPositionOptions';
import { UserPairingCard } from './UserPairingCard';
import { EDraftingPairTypes } from './Matchmaking';
import { CircularProgress, Tooltip } from '@material-ui/core';

export interface ISuggestedPair {
  pair: IDraftedPairs;
  draftPair: (pair: IDraftedPairs, pairingType: EPairingTypes) => void;
  draftingType?: EDraftingPairTypes;
  targetUser?: (
    user: string,
    position: ProgramPositions,
    name: string,
    overrideAlgorithm: boolean,
    draftingType?: EDraftingPairTypes,
  ) => void;
  draftingPair?: boolean;
}

export const SuggestedPair: React.FC<ISuggestedPair> = ({
  pair,
  draftPair,
  targetUser,
  draftingType,
  draftingPair,
}) => {
  const {
    mentorName,
    menteeName,
    mentorPairAmount,
    menteePairAmount,
    menteeApprovedPairs,
    mentorApprovedPairs,
    mentor,
    mentee,
  } = pair;
  const [hoveringLink, setHoverLink] = React.useState<boolean>(false);

  const closeLinkIcon = () => {
    setHoverLink(false);
  };

  const openLinkIcon = () => {
    setHoverLink(true);
  };

  return (
    <Grid container item justifyContent="space-between" alignItems="center">
      <UserPairingCard
        user={mentee}
        name={menteeName}
        position={ProgramPositions.mentee}
        targetUser={targetUser}
        pairAmount={menteePairAmount}
        approvedPairs={menteeApprovedPairs}
        draftingType={draftingType}
      />
      {!!draftPair && (
        <Grid item container justifyContent="center" xs={2}>
          {draftingPair ? (
            <CircularProgress size={64} color="secondary" />
          ) : (
            <Tooltip title={`Pair ${menteeName} and ${mentorName}`}>
              <IconButton
                onMouseEnter={openLinkIcon}
                onMouseLeave={closeLinkIcon}
                onFocus={openLinkIcon}
                onBlur={closeLinkIcon}
                disabled={draftingPair}
                onClick={() => draftPair(pair, EPairingTypes.BRANCHER_RECOMMENDED)}
              >
                {hoveringLink ? <Link /> : <LinkOff color="disabled" />}
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      )}
      <UserPairingCard
        user={mentor}
        name={mentorName}
        position={ProgramPositions.mentor}
        targetUser={targetUser}
        pairAmount={mentorPairAmount}
        approvedPairs={mentorApprovedPairs}
        draftingType={draftingType}
      />
    </Grid>
  );
};
