import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import { BrancherGridCard } from '../../Components/General/BrancherGridCard';
import { GetIllustrationLink } from '../../helpers/LinkHelpers';
import {
  IModuleCompletions,
  ReadableTrainingModuleTitles,
} from '../../store/reducers/DashboardReducer';
import { Text } from '../../Components/General/Text';
import { Colors } from '../../consts/colors';
import { BrancherDispatch, UtilGetTrainingModuleData } from '../../store/actions';
import { TrainingModuleInfo } from './TrainingModuleInfo';
import { IGetTrainingModuleData } from '../../store/actions/DashboardActions';
import { IStoreTypes } from '../../store/storeTypes';
import { Subscriptions } from '../../store/reducers/ProgramReducer';

interface ITrainingHealthCardStyles {
  mobile: boolean;
  phablet: boolean;
  active: boolean;
  overdue: boolean;
  showStats: boolean;
}

const useStyles = makeStyles({
  image: {
    width: (props: ITrainingHealthCardStyles) => (props.mobile ? 40 : props.phablet ? 60 : 80),
    height: (props: ITrainingHealthCardStyles) => (props.mobile ? 40 : props.phablet ? 60 : 80),
    margin: (props: ITrainingHealthCardStyles) =>
      props.mobile ? 10 : props.phablet ? 20 : `30px 25px`,
  },
  trainingCard: {
    marginBottom: 20,
    padding: 10,
    border: (props: ITrainingHealthCardStyles) =>
      props.active
        ? `3px solid ${Colors.green}`
        : props.overdue
        ? `1px solid ${Colors.red}`
        : `1px solid ${Colors.lightPurple}`,
  },
  statsBorder: {
    marginRight: 15,
    borderRight: (props: ITrainingHealthCardStyles) =>
      props.showStats && `1px solid ${Colors.iconTextGrey}`,
  },
});

interface ITrainingHealthCard extends IModuleCompletions {}

export const TrainingHealthCard: React.FC<ITrainingHealthCard> = (props) => {
  const isAdhocPairing = useSelector((state: IStoreTypes) => state.program?.subscription)?.includes(
    Subscriptions.ADHOC_PAIRING,
  );
  const {
    image,
    active,
    overdue,
    module,
    completions,
    showStats,
    competence,
    confidence,
    satisfaction,
  } = props;
  const [gettingTrainingModuleData, setGettingTrainingModuleData] = React.useState<boolean>(false);
  const [openTrainingInfo, setOpenTrainingInfo] = React.useState<boolean>(false);
  const [trainingModuleInfo, setTrainingModuleInfo] = React.useState<IGetTrainingModuleData>();
  const dispatch = useDispatch();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const phablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const styles = useStyles({ mobile, phablet, active, overdue, showStats });

  const getTrainingModuleData = () => {
    if ((active || overdue) && !gettingTrainingModuleData) {
      setGettingTrainingModuleData(true);
      BrancherDispatch(
        dispatch,
        UtilGetTrainingModuleData(module, (res) => {
          setTrainingModuleInfo(res);
          setGettingTrainingModuleData(false);
          setOpenTrainingInfo(true);
        }),
      );
    }
  };

  const controlClose = () => {
    setOpenTrainingInfo(false);
  };

  return (
    <>
      <TrainingModuleInfo
        open={openTrainingInfo}
        setClose={controlClose}
        moduleReadableName={ReadableTrainingModuleTitles[module]}
        module={module}
        moduleDates={trainingModuleInfo?.trainingModuleDates}
        moduleData={trainingModuleInfo?.trainingModuleData}
        moduleColumns={trainingModuleInfo?.trainingModuleColumns}
        customModuleColumns={trainingModuleInfo?.customModuleColumns}
        surveyOneNotMetColumns={trainingModuleInfo?.surveyOneNotMetModuleColumns}
        surveyOneNotMetModuleData={trainingModuleInfo?.surveyOneNotMetModuleData}
      />
      <BrancherGridCard
        className={styles.trainingCard}
        hoverCursor={active || overdue}
        container
        alignItems="center"
        justifyContent="center"
        onClick={getTrainingModuleData}
        spacing={2}
      >
        {gettingTrainingModuleData ? (
          <CircularProgress color="secondary" size={80} />
        ) : (
          <>
            <Grid item xs={1} md={2}>
              <img
                src={GetIllustrationLink(image)}
                alt={`${ReadableTrainingModuleTitles[module]} Illustration`}
                className={styles.image}
              />
            </Grid>
            <Grid item xs={2}>
              <Text variant="sm" marginBottom={10} marginTop={10} fontWeight={600}>
                {ReadableTrainingModuleTitles[module]}
              </Text>
            </Grid>
            <Grid item xs={2}>
              <Grid
                container
                direction="column"
                spacing={2}
                alignItems="center"
                className={showStats ? styles.statsBorder : ''}
              >
                <Grid item>
                  <Text variant="md" marginBottom={10} fontWeight={700} color="purple">
                    {completions}%
                  </Text>
                </Grid>
                <Text variant="xs" fontWeight={500} wordBreak>
                  Completion
                </Text>
              </Grid>
            </Grid>
            {showStats && (
              <>
                <Grid
                  item
                  xs={2}
                  container
                  direction="column"
                  spacing={2}
                  alignItems="center"
                  className={!isAdhocPairing ? styles.statsBorder : ''}
                >
                  <Grid item>
                    <Text variant="md" marginBottom={10} fontWeight={700} color="purple">
                      {satisfaction}%
                    </Text>
                  </Grid>
                  <Text variant="xs" fontWeight={500} wordBreak>
                    Advocacy
                  </Text>
                </Grid>
                {!isAdhocPairing && (
                  <>
                    <Grid
                      item
                      xs={2}
                      container
                      direction="column"
                      spacing={2}
                      alignItems="center"
                      className={styles.statsBorder}
                    >
                      <Grid item>
                        <Text variant="md" marginBottom={10} fontWeight={700} color="purple">
                          {confidence}%
                        </Text>
                      </Grid>
                      <Text variant="xs" fontWeight={500} wordBreak>
                        Confidence Increase
                      </Text>
                    </Grid>
                    <Grid item xs={2} container direction="column" spacing={2} alignItems="center">
                      <Grid item>
                        <Text variant="md" marginBottom={10} fontWeight={700} color="purple">
                          {competence}%
                        </Text>
                      </Grid>
                      <Text variant="xs" marginLeft={15} fontWeight={500} wordBreak>
                        Competence Increase
                      </Text>
                    </Grid>
                  </>
                )}
              </>
            )}
          </>
        )}
      </BrancherGridCard>
    </>
  );
};
