import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../../store/storeTypes';
import { ISegment } from '../FormFieldTypes/SegmentedControl';
import { makePrettyQuestionNumber } from '../Values/ValuesFormConfig';
import { PersonalityScaleDescription } from './PersonalityScaleDescription';
import {
  EApplicationFormSections,
  UpdatableFormConfiguration,
} from '../../../store/reducers/ProgramReducer';
import { SaveApplicationFormData } from '../../../store/actions/ProgramActions';
import { EFormStepperSections } from '../FormStepper';

export interface IPersonalityFormConfig {
  name: string;
  question: string;
  value: string;
  options: ISegment[];
  title?: string;
  updateValue?: (a: string | string[] | number | number[]) => void;
  stage?: string;
  description?: React.ReactNode;
  section?: EFormStepperSections;
}

export const IsPersonalityV2 = (): boolean => {
  const config = useSelector(
    (state: IStoreTypes) =>
      state.program.formConfiguration?.[UpdatableFormConfiguration.FORM_CONFIG].personality,
  );
  return !!config?.version && config?.version === 'v2';
};

export const PersonalityFormIndex = (): string[] => {
  const personalityValues = useSelector(
    (state: IStoreTypes) =>
      state.program?.formConfiguration?.[UpdatableFormConfiguration.FORM_CONFIG_DEFAULT_VALUES]
        ?.personality,
  );
  return personalityValues.map((a) => a.key);
};

export function PersonalityFormQuestions(): IPersonalityFormConfig[] {
  const personalityValues = useSelector(
    (state: IStoreTypes) => state.program?.applicationForm?.personality,
  );
  const config = useSelector(
    (state: IStoreTypes) =>
      state.program?.formConfiguration?.[UpdatableFormConfiguration.FORM_CONFIG]?.personality,
  );
  const dispatch = useDispatch();
  const isPersonalityV2 = IsPersonalityV2();

  return isPersonalityV2
    ? config.options.map((f: any) => {
        const personalityOptions = [
          {
            value: 0,
            title: 'Very inaccurate',
          },
          {
            value: 1,
            title: 'Moderately inaccurate',
          },
          {
            value: 2,
            title: 'Neither',
          },
          {
            value: 3,
            title: 'Moderately accurate',
          },
          {
            value: 4,
            title: 'Very accurate',
          },
        ];
        return {
          title: f.title,
          question: '',
          description: 'Select how accurately the following statement describes you',
          options: personalityOptions,
          customOptions: true,
          section: EFormStepperSections.PERSONALITY,
          name: f.name,
          value: personalityValues[f.name],
          updateValue: (a: any) =>
            dispatch(
              SaveApplicationFormData(EApplicationFormSections.PERSONALITY, { [f.name]: a }),
            ),
        };
      })
    : config.map((f: any, parentIndex) => {
        return {
          question: 'Behavioural Style Questions',
          description: <PersonalityScaleDescription />,
          stage: `${parentIndex + 1}/4`,
          section: f.section,
          name: '',
          value: '',
          options: f.options.map((o, i) => {
            return {
              ...o,
              valueLength: 7,
              questionNumber: makePrettyQuestionNumber(`${parentIndex * 6 + i + 1}`),
              value: personalityValues[o.name],
              updateValue: (a: any) =>
                dispatch(
                  SaveApplicationFormData(EApplicationFormSections.PERSONALITY, { [o.name]: a }),
                ),
            };
          }),
        };
      });
}
