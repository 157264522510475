import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { ISharedNotesHealthData } from '../../store/reducers/DashboardReducer';
import { BrancherGridCard } from '../../Components/General/BrancherGridCard';
import { Text } from '../../Components/General/Text';

const useStyles = makeStyles({
  dashboardCard: {
    maxWidth: 400,
    padding: 25,
    minHeight: 'fit-content',
    height: 340,
    marginTop: 30,
  },
});

interface ISharedNotes {
  sharedNotes: ISharedNotesHealthData;
}

export const SharedNotesHealth: React.FC<ISharedNotes> = (props) => {
  const { sharedNotes } = props;
  const styles = useStyles({});

  const completed = sharedNotes?.all;
  const percentage = sharedNotes?.allPercentage;

  return (
    <Grid container>
      <BrancherGridCard
        container
        item
        direction="column"
        spacing={3}
        className={styles.dashboardCard}
        xs={11}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item container justifyContent="center">
          <Text variant="lg" display="inline" color="purple" fontWeight={700}>
            {completed}{' '}
          </Text>
          <Text variant="lg" display="inline" fontWeight={500}>
            Shared Notes
          </Text>
        </Grid>
        <Grid item>
          <Text variant="xs" color="placeholderGrey" align="center">
            {percentage ?? '0'}% of pairs have shared notes
          </Text>
        </Grid>
      </BrancherGridCard>
    </Grid>
  );
};
