import jwtDecode from 'jwt-decode';
import { IDecodedUserTokenAttributes } from '../store/reducers/UserInfoReducer';

export const decodeUserToken = (idToken: string): IDecodedUserTokenAttributes => {
  const decodedToken: any = jwtDecode(idToken);
  return {
    id: decodedToken?.sub,
    email: decodedToken?.email,
    username: decodedToken?.['cognito:username'],
    phoneNumber: decodedToken?.phone_number,
    tokenExp: decodedToken?.exp,
    IDToken: idToken,
  };
};
