import * as React from 'react';
import Workbook from 'react-excel-workbook';
import { BrancherButton } from '../../Components/InputFields/BrancherButton';

export const GenerateTrainingModuleReport = ({ data, displayData, programName, module }) => (
  <Workbook
    filename={`${programName}_${module}_report.xlsx`}
    element={
      <BrancherButton color="primary" variant="text">
        Download training report!
      </BrancherButton>
    }
  >
    <Workbook.Sheet data={data} name="Training Module Report">
      {displayData.map((d, l) => (
        <Workbook.Column label={d.readableName} value={d.attributeName} key={l} />
      ))}
    </Workbook.Sheet>
  </Workbook>
);
