import * as React from 'react';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export interface IBrancherRadioBaseProps extends RadioProps {
  label: string;
  checked?: boolean;
}

export const BrancherRadioBase = (props: IBrancherRadioBaseProps) => {
  const { value, label, disabled } = props;
  return <FormControlLabel value={value} disabled={disabled} control={<Radio />} label={label} />;
};
