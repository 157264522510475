import * as React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { ScaleText } from '../../../Components/General/ScaleText';
import { QuestionDescription } from '../FormFieldTypes/QuestionDescription';

export const PersonalityScaleDescription = () => (
  <>
    <Grid item xs={12} md={10}>
      <QuestionDescription>
        Please rate the extent to which each of the following statements accurately describes you.
        Using the 7 point scale below where:
      </QuestionDescription>
      <Box marginBottom={2} />
    </Grid>
    <Grid item xs={12} md={10}>
      <Grid container>
        <Grid container direction="column" item xs={12}>
          <ScaleText scale={7} description={<strong>strongly agree</strong>} />
          <ScaleText scale={1} description={<strong>strongly disagree</strong>} />
        </Grid>
      </Grid>
    </Grid>
  </>
);
