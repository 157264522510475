import * as React from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Redirect } from 'react-router';
import { format } from 'date-fns';
import { BrancherDispatch, UtilRemovePair } from '../../store/actions';
import { RouteMaster } from '../../Components/Routing';
import { ConfirmDialog } from '../../Components/General/ConfirmDialog';
import { Text } from '../../Components/General/Text';
import { ActionNegativeOutlinedButton } from '../../Components/InputFields/BrancherButton';

interface IPairInfo {
  pairingStartDate: number;
  relationshipLength: string;
  menteeRoleId: string;
  mentorRoleId: string;
}

export const PairInfo: React.FC<IPairInfo> = ({
  pairingStartDate,
  relationshipLength,
  menteeRoleId,
  mentorRoleId,
}) => {
  const [removingPair, setRemovingPair] = React.useState<boolean>(false);
  const [redirectToPairs, setRedirectToPairs] = React.useState<boolean>(false);
  const [confirmRemovePairDialog, setConfirmRemovePairDialog] = React.useState<boolean>(false);
  const dispatch = useDispatch();

  const removePair = () => {
    setRemovingPair(true);
    BrancherDispatch(
      dispatch,
      UtilRemovePair(menteeRoleId, mentorRoleId, () => {
        setConfirmRemovePairDialog(false);
        setRemovingPair(false);
        setRedirectToPairs(true);
      }),
    );
  };

  return (
    <Grid item>
      {redirectToPairs && <Redirect to={RouteMaster.pairs.path} />}
      <ConfirmDialog
        confirmAction={removePair}
        denyAction={() => setConfirmRemovePairDialog(false)}
        setClose={() => setConfirmRemovePairDialog(false)}
        open={confirmRemovePairDialog}
        denyButtonText="Don't remove pair"
        confirmButtonText="Remove pair"
        title="Are you sure you want to remove this pair?"
        labelledBy="remove-pair-dialog"
      />
      <Grid item container alignItems="center" spacing={2}>
        {!!pairingStartDate && (
          <Text variant="xs" fontWeight={500}>
            Paired: {format(new Date(pairingStartDate), 'do LLL yyyy')} ({relationshipLength})
          </Text>
        )}
        <Box display="flex" ml={3}>
          <ActionNegativeOutlinedButton
            size="small"
            onClick={() => setConfirmRemovePairDialog(true)}
            loading={removingPair}
          >
            Unpair
          </ActionNegativeOutlinedButton>
        </Box>
      </Grid>
    </Grid>
  );
};
