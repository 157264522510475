import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../Components/General/Text';
import { IPairingReasons } from '../../store/reducers/PairingReducer';
import { GetIllustrationLink } from '../../helpers/LinkHelpers';

interface IPairReasons extends IPairingReasons {}

export const PairReasons: React.FC<IPairReasons> = (props) => {
  const { personality, custom, values } = props;

  // const createPairingReasonsContent = (): string => {
  //   let personalityValuesContent = [];
  //   if (personality) {
  //     personalityValuesContent.push('personality');
  //   }
  //   if (values) {
  //     personalityValuesContent.push('values');
  //   }
  //   return [...createCustomPairingContent(), ...personalityValuesContent].join(', ');
  // };

  const createCustomPairingContent = (): string[] => {
    let content = [];
    if (custom) {
      custom.forEach((reason) => {
        content.push(reason.readableName.toLowerCase());
      });
    }
    return content;
  };

  const createPersonalityPairingContent = (): string => {
    let content = [];
    if (personality) {
      personality.forEach((reason) => {
        content.push(reason.attributeName.toLowerCase());
      });
    }
    return readableJoin(content);
  };

  const createValuesPairingContent = (): string => {
    let content = [];
    if (values) {
      values.forEach((reason) => {
        content.push(reason.attributeName.toLowerCase());
      });
    }
    return readableJoin(content);
  };

  const readableJoin = (contents: string[]): string => {
    const commaContents = contents.slice(0, contents.length - 1);
    return commaContents.join(', ').concat(` and ${contents[contents.length - 1]}`);
  };

  return (
    <Grid container item xs={12}>
      {/*<Grid container alignItems="center">*/}
      {/*  <Grid item xs={12} container direction="column" spacing={1}>*/}
      {/*    <Grid item container alignItems="center">*/}
      {/*      <Text variant="sm" color="secondaryGrey" fontWeight={500}>*/}
      {/*        We think they're great for each other because of compatible{' '}*/}
      {/*        {createPairingReasonsContent()} and more!*/}
      {/*      </Text>*/}
      {/*    </Grid>*/}
      {/*  </Grid>*/}
      {/*</Grid>*/}
      {/*<Grid item xs={12}>*/}
      {/*  <Text variant="sm" fontWeight={700} marginBottom={30} marginTop={20}>*/}
      {/*    The specifics:*/}
      {/*  </Text>*/}
      {/*</Grid>*/}
      <Grid item container spacing={2} xs={12}>
        {custom && (
          <Grid item container xs={12} spacing={2}>
            <Grid item>
              <img
                src={`${GetIllustrationLink('developRocket.png')}`}
                alt="develop-rocket"
                width={50}
                height={50}
              />
            </Grid>
            <Grid item md={10}>
              <Text variant="sm" color="secondaryGrey" display="inline">
                They have a compatible:{' '}
              </Text>
              <Text variant="sm" color="purple" display="inline" fontWeight={600}>
                {readableJoin(createCustomPairingContent())}.
              </Text>
            </Grid>
          </Grid>
        )}
        {personality && (
          <Grid item container xs={12} spacing={2}>
            <Grid item>
              <img
                src={`${GetIllustrationLink('explore.png')}`}
                alt="explore"
                width={50}
                height={50}
              />
            </Grid>
            <Grid item md={10}>
              <Text variant="sm" color="secondaryGrey" display="inline">
                They have similar personality attributes:{' '}
              </Text>
              <Text variant="sm" color="purple" display="inline" fontWeight={600}>
                {createPersonalityPairingContent()}.
              </Text>
            </Grid>
          </Grid>
        )}
        {values && (
          <Grid item container xs={12} spacing={2}>
            <Grid item>
              <img
                src={`${GetIllustrationLink('pairingReasons.png')}`}
                alt="pairing-reasons"
                width={50}
                height={50}
              />
            </Grid>
            <Grid item md={10}>
              <Text variant="sm" color="secondaryGrey" display="inline">
                They have similar views when considering the values:{' '}
              </Text>
              <Text variant="sm" color="purple" display="inline" fontWeight={600}>
                {createValuesPairingContent()}.
              </Text>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
