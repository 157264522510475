import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch } from 'react-redux';
import { BrancherDispatch, UtilGenerateMasterPairingSatisfactionReport } from '../../store/actions';
import { ActionButton } from '../../Components/InputFields/BrancherButton';
import { IReportColumn } from '../../store/reducers/ReportReducer';
import { GenerateMasterPairingSatisfactionReport } from './GenerateMasterPairingSatisfactionReport';

export const MasterPairingSatisfactionReport: React.FC = () => {
  const [pairingMasterSatisfactionReportData, setPairingMasterSatisfactionReportData] =
    React.useState<any[]>(null);
  const [pairingMasterSatisfactionReportColumns, setPairingMasterSatisfactionReportColumns] =
    React.useState<IReportColumn[]>(null);
  const [generatingMasterPairingSatisfactionReport, setGeneratingMasterPairingSatisfactionReport] =
    React.useState<boolean>(false);
  const dispatch = useDispatch();

  const generateMasterPairingSatisfactionReport = () => {
    setGeneratingMasterPairingSatisfactionReport(true);
    BrancherDispatch(
      dispatch,
      UtilGenerateMasterPairingSatisfactionReport((res) => {
        setPairingMasterSatisfactionReportData(res.pairingSatisfactionReportData);
        setPairingMasterSatisfactionReportColumns(res.pairingSatisfactionReportColumns);
        setGeneratingMasterPairingSatisfactionReport(false);
      }),
    );
  };

  return (
    <Grid item xs={12} container alignItems="center" spacing={2}>
      <Grid item xs={6}>
        <ActionButton
          onClick={generateMasterPairingSatisfactionReport}
          loading={generatingMasterPairingSatisfactionReport}
          size="small"
        >
          Generate master pairing satisfaction report
        </ActionButton>
      </Grid>
      {!generatingMasterPairingSatisfactionReport &&
        pairingMasterSatisfactionReportData?.length > 0 && (
          <Grid item xs={6}>
            <GenerateMasterPairingSatisfactionReport
              data={pairingMasterSatisfactionReportData}
              displayData={pairingMasterSatisfactionReportColumns}
            />
          </Grid>
        )}
    </Grid>
  );
};
