import * as React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Colors } from '../../consts/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: Colors.white,
    },
  }),
);

export const BrancherFullPageLoader = (props: { loading: boolean }) => {
  const styles = useStyles();
  return (
    <Backdrop open={props.loading} className={styles.backdrop}>
      <CircularProgress color="inherit" size={64} />
    </Backdrop>
  );
};
