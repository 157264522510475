import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { ActionButton } from '../../Components/InputFields/BrancherButton';
import { GenerateAllTrainingReport } from './GenerateAllTrainingReport';
import { IStoreTypes } from '../../store/storeTypes';
import { BrancherDispatch, UtilGenerateReport } from '../../store/actions';
import { EGenerateReport } from '../../store/reducers/ReportReducer';

interface ITrainingReport {
  controlled?: boolean;
  completedCallback?: () => void;
}

export const TrainingReport: React.FC<ITrainingReport> = ({ controlled, completedCallback }) => {
  const programName = useSelector((state: IStoreTypes) => state.program.programName);
  const allTrainingMenteeReportData = useSelector(
    (state: IStoreTypes) => state?.report?.allTrainingMenteeReportData,
  );
  const allTrainingMentorReportData = useSelector(
    (state: IStoreTypes) => state?.report?.allTrainingMentorReportData,
  );
  const allTrainingMenteeReportColumns = useSelector(
    (state: IStoreTypes) => state?.report?.allTrainingMenteeReportColumns,
  );
  const allTrainingMentorReportColumns = useSelector(
    (state: IStoreTypes) => state?.report?.allTrainingMentorReportColumns,
  );
  const [generatingTrainingReport, setGeneratingTrainingReport] = React.useState<boolean>(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (controlled) {
      generalAllTraining();
    }
  }, []);

  const generalAllTraining = () => {
    setGeneratingTrainingReport(true);
    BrancherDispatch(
      dispatch,
      UtilGenerateReport(EGenerateReport.ALL_TRAINING_REPORT, () => {
        setGeneratingTrainingReport(false);
        if (controlled) {
          completedCallback();
        }
      }),
    );
  };

  return (
    <Grid item xs={12} container alignItems="center" spacing={2}>
      {!controlled && (
        <ActionButton onClick={generalAllTraining} loading={generatingTrainingReport} size="small">
          Generate training report
        </ActionButton>
      )}
      {allTrainingMentorReportData && (
        <Grid item xs={5}>
          <GenerateAllTrainingReport
            menteeData={allTrainingMenteeReportData}
            menteeDisplayData={allTrainingMenteeReportColumns}
            mentorData={allTrainingMentorReportData}
            mentorDisplayData={allTrainingMentorReportColumns}
            programName={programName}
          />
        </Grid>
      )}
    </Grid>
  );
};
