import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  LineChart,
  Line,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  YAxis,
  XAxis,
} from 'recharts';
import { CustomizedLabelB } from './SimpleLineChart';
import { Colors } from '../../../consts/colors';
import { BrancherCheckbox } from '../../../Components/InputFields/BrancherCheckbox';
import {
  IMonthlyActivity,
  IProgressActivityGraphData,
} from '../../../store/reducers/DashboardReducer';
import { EChosenProfile } from '../../Dashboard/Dashboard';

const useStyles = makeStyles({
  marginTop: {
    marginTop: 5,
  },
});

const getFilteredAmount = (activity: IMonthlyActivity[] = [], chosenProfile: EChosenProfile) => {
  if (chosenProfile === EChosenProfile.ALL) {
    return activity.map((a) => ({ name: a.name, value: a.allAmount }));
  } else if (chosenProfile === EChosenProfile.MENTEE) {
    return activity.map((a) => ({ name: a.name, value: a.menteeAmount }));
  } else if (chosenProfile === EChosenProfile.MENTOR) {
    return activity.map((a) => ({ name: a.name, value: a.mentorAmount }));
  }
};

interface IProgressActivityLineChart {
  activityGraphData: IProgressActivityGraphData;
  chosenProfile: EChosenProfile;
}

export const ProgressActivityLineChart: React.FC<IProgressActivityLineChart> = (props) => {
  const { activityGraphData, chosenProfile } = props;
  const totalLogins = getFilteredAmount(activityGraphData?.loginData, chosenProfile);
  const createdGoals = getFilteredAmount(
    activityGraphData?.goalGraphData?.createdGoalsMonthlyActivity,
    chosenProfile,
  );
  const updatedGoals = getFilteredAmount(
    activityGraphData?.goalGraphData?.updatedGoalsMonthlyActivity,
    chosenProfile,
  );
  const completedGoals = getFilteredAmount(
    activityGraphData?.goalGraphData?.completedGoalsMonthlyActivity,
    chosenProfile,
  );
  const privateNotes = getFilteredAmount(activityGraphData?.privateNotesGraphData, chosenProfile);
  const sharedNotes = getFilteredAmount(activityGraphData?.sharedNotesGraphData, chosenProfile);
  const hasLogins = totalLogins.filter((a) => a.value > 0)?.length > 0;
  const hasCreatedGoals = createdGoals.filter((a) => a.value > 0)?.length > 0;
  const hasUpdatedGoals = updatedGoals.filter((a) => a.value > 0)?.length > 0;
  const hasCompletedGoals = completedGoals.filter((a) => a.value > 0)?.length > 0;
  const hasPrivateNotes = privateNotes.filter((a) => a.value > 0)?.length > 0;
  const hasSharedNotes = sharedNotes.filter((a) => a.value > 0)?.length > 0;
  const [showUserLogins, setShowUserLogins] = React.useState<boolean>(false);
  const [showCreatedGoals, setShowCreatedGoals] = React.useState<boolean>(hasCreatedGoals);
  const [showUpdatedGoals, setShowUpdatedGoals] = React.useState<boolean>(hasUpdatedGoals);
  const [showCompletedGoals, setShowCompletedGoals] = React.useState<boolean>(hasCompletedGoals);
  const [showPrivateNotes, setShowPrivateNotes] = React.useState<boolean>(hasPrivateNotes);
  const [showSharedNotes, setShowSharedNotes] = React.useState<boolean>(hasSharedNotes);
  const styles = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const chartRef = React.useRef(null);

  const yValueLabel = 'Amount of user activities';
  const privateNotesLabel = 'Private Notes';
  const sharedNotesLabel = 'Shared Notes';
  const userLoginsLabel = 'User Logins';
  const createdGoalsLabel = 'Created Goals';
  const updatedGoalsLabel = 'Updated Goals';
  const completedGoalsLabel = 'Completed Goals';

  const modifiedDataLabel = [];
  createdGoals?.forEach((cg) => {
    const dayActivityIndex = modifiedDataLabel.findIndex((m) => m.name === cg.name);
    if (dayActivityIndex >= 0) {
      modifiedDataLabel[dayActivityIndex][createdGoalsLabel] = cg.value;
    } else {
      modifiedDataLabel.push({ name: cg.name, [createdGoalsLabel]: cg.value });
    }
  });
  updatedGoals?.forEach((cg) => {
    const dayActivityIndex = modifiedDataLabel.findIndex((m) => m.name === cg.name);
    if (dayActivityIndex >= 0) {
      modifiedDataLabel[dayActivityIndex][updatedGoalsLabel] = cg.value;
    } else {
      modifiedDataLabel.push({ name: cg.name, [updatedGoalsLabel]: cg.value });
    }
  });
  completedGoals?.forEach((cg) => {
    const dayActivityIndex = modifiedDataLabel.findIndex((m) => m.name === cg.name);
    if (dayActivityIndex >= 0) {
      modifiedDataLabel[dayActivityIndex][completedGoalsLabel] = cg.value;
    } else {
      modifiedDataLabel.push({ name: cg.name, [completedGoalsLabel]: cg.value });
    }
  });
  privateNotes?.forEach((cg) => {
    const dayActivityIndex = modifiedDataLabel.findIndex((m) => m.name === cg.name);
    if (dayActivityIndex >= 0) {
      modifiedDataLabel[dayActivityIndex][privateNotesLabel] = cg.value;
    } else {
      modifiedDataLabel.push({ name: cg.name, [privateNotesLabel]: cg.value });
    }
  });
  sharedNotes?.forEach((cg) => {
    const dayActivityIndex = modifiedDataLabel.findIndex((m) => m.name === cg.name);
    if (dayActivityIndex >= 0) {
      modifiedDataLabel[dayActivityIndex][sharedNotesLabel] = cg.value;
    } else {
      modifiedDataLabel.push({ [sharedNotesLabel]: cg.value });
    }
  });
  totalLogins?.forEach((cg) => {
    const dayActivityIndex = modifiedDataLabel.findIndex((m) => m.name === cg.name);
    if (dayActivityIndex >= 0) {
      modifiedDataLabel[dayActivityIndex][userLoginsLabel] = cg.value;
    } else {
      modifiedDataLabel.push({ [userLoginsLabel]: cg.value });
    }
  });

  return (
    <div ref={chartRef}>
      <ResponsiveContainer width="100%" height={mobile ? 350 : 400}>
        <LineChart
          width={chartRef?.current?.clientWidth ?? 320}
          height={mobile ? 350 : 400}
          data={modifiedDataLabel}
          margin={{
            right: 15,
            left: -15,
            top: 5,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis allowDecimals={false} label={<CustomizedLabelB label={yValueLabel} />} />
          <Tooltip />
          <Legend wrapperStyle={{ bottom: -5 }} />
          {showUserLogins && (
            <Line
              dataKey={userLoginsLabel}
              stroke={Colors.darkPurple}
              type="monotone"
              activeDot={{ r: 5 }}
            />
          )}
          {showCreatedGoals && (
            <Line
              dataKey={createdGoalsLabel}
              stroke={Colors.secondaryGrey}
              type="monotone"
              activeDot={{ r: 5 }}
            />
          )}
          {showUpdatedGoals && (
            <Line
              dataKey={updatedGoalsLabel}
              stroke={Colors.green}
              type="monotone"
              activeDot={{ r: 5 }}
            />
          )}
          {showCompletedGoals && (
            <Line
              dataKey={completedGoalsLabel}
              stroke={Colors.red}
              type="monotone"
              activeDot={{ r: 5 }}
            />
          )}
          {showPrivateNotes && (
            <Line
              dataKey={privateNotesLabel}
              stroke={theme.palette.primary.main}
              type="monotone"
              activeDot={{ r: 5 }}
            />
          )}
          {showSharedNotes && (
            <Line
              dataKey={sharedNotesLabel}
              stroke={Colors.oceanBlue}
              type="monotone"
              activeDot={{ r: 5 }}
            />
          )}
        </LineChart>
      </ResponsiveContainer>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        className={styles.marginTop}
      >
        {hasCreatedGoals && (
          <Grid item xs={4} md={3} lg={2}>
            <BrancherCheckbox
              name="showCreatedGoals"
              value={showCreatedGoals}
              updateValue={setShowCreatedGoals}
              label="Created Goals"
            />
          </Grid>
        )}
        {hasUpdatedGoals && (
          <Grid item xs={4} md={3} lg={2}>
            <BrancherCheckbox
              name="showUpdatedGoals"
              value={showUpdatedGoals}
              updateValue={setShowUpdatedGoals}
              label="Updated Goals"
            />
          </Grid>
        )}
        {hasCompletedGoals && (
          <Grid item xs={4} md={3} lg={2}>
            <BrancherCheckbox
              name="showCompletedGoals"
              value={showCompletedGoals}
              updateValue={setShowCompletedGoals}
              label="Completed Goals"
            />
          </Grid>
        )}
        {hasPrivateNotes && (
          <Grid item xs={4} md={3} lg={2}>
            <BrancherCheckbox
              name="showPrivateNotes"
              value={showPrivateNotes}
              updateValue={setShowPrivateNotes}
              label="Private Notes"
            />
          </Grid>
        )}
        {hasSharedNotes && (
          <Grid item xs={4} md={3} lg={2}>
            <BrancherCheckbox
              name="showSharedNotes"
              value={showSharedNotes}
              updateValue={setShowSharedNotes}
              label="Shared Notes"
            />
          </Grid>
        )}
        {hasLogins && (
          <Grid item xs={4} md={3} lg={2}>
            <BrancherCheckbox
              name="showUserLogins"
              value={showUserLogins}
              updateValue={setShowUserLogins}
              label="User Logins"
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};
