import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { NextButton, PrevButton } from '../../Components/InputFields/BrancherButton';
import { Text } from '../../Components/General/Text';
import { GeneralForm } from './General/GeneralForm';
import { GeneralFormIndex, GeneralFormQuestions as GeneralQs } from './General/GeneralFormConfig';
import { IStoreTypes } from '../../store/storeTypes';
import { ValuesForm } from './Values/ValuesForm';
import { ValuesInfo } from './Values/ValuesInfo';
import { PersonalityInfo } from './Personality/PersonalityInfo';
import { PersonalityForm } from './Personality/PersonalityForm';
import { IsValuesV2, ValuesFormIndex, ValuesFormQuestions } from './Values/ValuesFormConfig';
import {
  IsPersonalityV2,
  PersonalityFormIndex,
  PersonalityFormQuestions,
} from './Personality/PersonalityFormConfig';
import { FinalisingForm } from './Finalising/FinalisingForm';
import {
  FinalisingFormIndex,
  FinalisingFormQuestions as FinalisingQs,
} from './Finalising/FinalisingFormConfig';
import { ProgramPositions } from '../../consts/ProgramPositionOptions';
import { QualifyingForm } from './QualifyingForm/QualifyingForm';
import { Colors } from '../../consts/colors';
import { FormPaneHeight, FormStepper } from './FormStepper';
import { EComponentType } from '../../store/reducers/ProgramReducer';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles({
  parentContainer: {
    marginBottom: FormPaneHeight,
  },
  form: {
    height: '-webkit-fill-available',
    marginTop: 10,
    padding: (props: { mobile: boolean }) => (props.mobile ? `10px 10px 30px` : `10px 40px 30px`),
    borderRadius: 15,
    width: '100%',
    background: Colors.white,
    boxShadow: `0px 3px 6px ${Colors.darkGrey}`,
  },
  marginTop: {
    marginTop: 10,
  },
  fixedActionPane: {
    position: 'absolute',
    bottom: 0,
    height: FormPaneHeight,
    backgroundColor: Colors.backgroundLightPurple,
    width: '100vw',
    zIndex: 100,
    boxShadow: `inset 0px 2px 2px -2px ${Colors.darkGrey};`,
  },
  stepperPane: {
    marginBottom: 50,
    marginLeft: 10,
    position: 'absolute',
    top: 0,
    height: 80,
    backgroundColor: Colors.backgroundLightPurple,
    width: '100vw',
    minWidth: '100%',
    zIndex: 100,
    boxShadow: `0 2px 2px -2px ${Colors.darkGrey};`,
  },
});

interface IApplicationForm {
  closeDialog: () => void;
}

export const ApplicationForm: React.FC<IApplicationForm> = ({ closeDialog }) => {
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const generalFormValue = useSelector(
    (state: IStoreTypes) => state.program?.applicationForm?.general,
  );
  const valuesFormValue = useSelector(
    (state: IStoreTypes) => state.program?.applicationForm?.values,
  );
  const personalityFormValue = useSelector(
    (state: IStoreTypes) => state.program?.applicationForm?.personality,
  );
  const position = useSelector(
    (state: IStoreTypes) => state.program?.applicationForm?.qualifying?.position,
  );
  const finalisingFormValue = useSelector(
    (state: IStoreTypes) => state.program?.applicationForm?.finalising,
  );
  const isMentee = position === ProgramPositions.mentee;
  const [finished, setFinished] = React.useState(false);
  const [step, setStep] = React.useState(1);
  const styles = useStyles({ mobile });

  // Scrolls the user to the top of the page after changing step
  React.useEffect(() => {
    document.getElementById('topofform').scrollIntoView();
  }, [step]);

  const actualGeneralQs = GeneralQs(isMentee);
  const actualFinalisingQs = FinalisingQs(isMentee);
  const GeneralFormIndexes = GeneralFormIndex(isMentee);
  const ValuesFormIndexes = ValuesFormIndex();
  const actualValuesQs = ValuesFormQuestions();
  const isValuesV2 = IsValuesV2();
  const isPersonalityV2 = IsPersonalityV2();
  const PersonalityFormIndexes = PersonalityFormIndex();
  const actualPersonalityQs = PersonalityFormQuestions();
  const FinalisingFormQuestions = FinalisingFormIndex();
  const GeneralLength = GeneralFormIndexes.length;
  const ValuesLength = isValuesV2 ? actualValuesQs.length : ValuesFormQuestions.length / 5;
  const PersonalityLength = isPersonalityV2
    ? actualPersonalityQs.length
    : PersonalityFormIndexes.length / 6;
  const GeneralValueLength = GeneralLength + ValuesLength;
  const GeneralValuePersonalityLength = GeneralValueLength + PersonalityLength;
  const stepAmt = GeneralValuePersonalityLength + FinalisingFormQuestions.length;

  const hasPersonality = PersonalityFormIndexes.length > 0;
  const hasFinalising = FinalisingFormQuestions.length > 0;

  const hasDefinedValue = (val: any): boolean => {
    return Boolean(val) || val === '0' || val === 0;
  };

  // Checks whether the required fields have a value
  const validateValue = (): boolean => {
    let hasValue = false;
    if (step <= GeneralLength) {
      const currQuestion = actualGeneralQs[step - 1];
      const notMandatory = currQuestion?.notMandatory === true;
      const currentValue = generalFormValue[GeneralFormIndexes[step - 1]];
      const actualValue = generalFormValue?.[currQuestion.name];
      if (notMandatory) {
        hasValue = true;
      } else {
        hasValue = currentValue;
      }
      // For when they need to fill out a certain amount of fields
      if (currQuestion?.componentType === EComponentType.DND) {
        hasValue =
          !!actualValue &&
          actualValue
            ?.filter((c) => c.id !== currQuestion.optionsColumn)
            .reduce((acc, f) => f?.items?.length + acc, 0) > 1;
      } else if (
        currQuestion?.componentType === EComponentType.TOGGLE &&
        !currQuestion?.exclusive
      ) {
        if (currQuestion?.minimumAmount) {
          hasValue = currentValue?.length >= currQuestion.minimumAmount;
        } else {
          hasValue = currentValue?.length === currQuestion.maximumAmount;
        }
      } // For array lengths
      else if (currentValue && typeof currentValue === 'object') {
        if (currentValue.length < 1) {
          hasValue = false;
        }
      }
    } // For pages without questions
    else if (step === GeneralLength + 0.5 || step === GeneralValueLength + 0.5) {
      hasValue = true;
    } else if (!isValuesV2) {
      if (step === GeneralLength + 1) {
        const valuesNames = ValuesFormIndexes.slice(0, 5);
        const v: boolean[] = valuesNames.map((a) => hasDefinedValue(valuesFormValue[a]));
        hasValue = v.reduce((a, b) => a && b);
      } else if (step === GeneralLength + 2) {
        const valuesNames = ValuesFormIndexes.slice(5, 10);
        const v: boolean[] = valuesNames.map((a) => hasDefinedValue(valuesFormValue[a]));
        hasValue = v.reduce((a, b) => a && b);
      } else if (step === GeneralLength + 3 && ValuesLength / 5 > 2) {
        const valuesNames = ValuesFormIndexes.slice(10, 15);
        const v: boolean[] = valuesNames.map((a) => hasDefinedValue(valuesFormValue[a]));
        hasValue = v.reduce((a, b) => a && b);
      } else if (step === GeneralLength + 4 && ValuesLength / 5 > 3) {
        const valuesNames = ValuesFormIndexes.slice(15, 20);
        const v: boolean[] = valuesNames.map((a) => hasDefinedValue(valuesFormValue[a]));
        hasValue = v.reduce((a, b) => a && b);
      } else if (step === GeneralValueLength + 1 && hasPersonality) {
        const personalityNames = PersonalityFormIndexes.slice(0, 6);
        const v: boolean[] = personalityNames.map((a) => hasDefinedValue(personalityFormValue[a]));
        hasValue = v.reduce((a, b) => a && b);
      } else if (step === GeneralValueLength + 2 && hasPersonality) {
        const personalityNames = PersonalityFormIndexes.slice(6, 12);
        const v: boolean[] = personalityNames.map((a) => hasDefinedValue(personalityFormValue[a]));
        hasValue = v.reduce((a, b) => a && b);
      } else if (step === GeneralValueLength + 3 && hasPersonality) {
        const personalityNames = PersonalityFormIndexes.slice(12, 18);
        const v: boolean[] = personalityNames.map((a) => hasDefinedValue(personalityFormValue[a]));
        hasValue = v.reduce((a, b) => a && b);
      } else if (step === GeneralValueLength + 4 && hasPersonality) {
        const personalityNames = PersonalityFormIndexes.slice(18, 24);
        const v: boolean[] = personalityNames.map((a) => hasDefinedValue(personalityFormValue[a]));
        hasValue = v.reduce((a, b) => a && b);
      } else if (step === GeneralValuePersonalityLength && hasPersonality) {
        const personalityNames = PersonalityFormIndexes.slice(18, 24);
        const v: boolean[] = personalityNames.map((a) => hasDefinedValue(personalityFormValue[a]));
        hasValue = v.reduce((a, b) => a && b);
      } else if (step > GeneralValuePersonalityLength && hasPersonality && hasFinalising) {
        const currQuestion = actualFinalisingQs[FinalisingFormQuestions.length - 1];
        const notMandatory = currQuestion?.notMandatory === true;
        const currentValue =
          finalisingFormValue[FinalisingFormQuestions[FinalisingFormQuestions.length - 1]];
        const actualValue = finalisingFormValue?.[currQuestion.name];
        if (notMandatory) {
          hasValue = true;
        } else {
          hasValue = currentValue;
        }
        // For when they need to fill out a certain amount of fields
        if (currQuestion?.componentType === EComponentType.DND) {
          hasValue =
            !!actualValue &&
            actualValue
              ?.filter((c) => c.id !== currQuestion.optionsColumn)
              .reduce((acc, f) => f?.items?.length + acc, 0) > 1;
        } else if (
          currQuestion?.componentType === EComponentType.TOGGLE &&
          !currQuestion?.exclusive
        ) {
          if (currQuestion?.minimumAmount) {
            hasValue = currentValue?.length >= currQuestion.minimumAmount;
          } else {
            hasValue = currentValue?.length === currQuestion.maximumAmount;
          }
        } // For array lengths
        else if (currentValue && typeof currentValue === 'object') {
          if (currentValue.length < 1) {
            hasValue = false;
          }
        }
      }
    } else if (isValuesV2 && (isPersonalityV2 || !hasPersonality)) {
      // just here for readability
      if (step <= GeneralValueLength) {
        const valuesStep = step - GeneralLength;
        const currentValue = valuesFormValue?.[valuesStep - 1];
        hasValue = currentValue?.most?.length > 0 && currentValue?.least?.length > 0;
        if (hasValue && (step <= GeneralValueLength)) {
          setStep(step + 1);
        }
      } else if (
        hasPersonality &&
        step > GeneralValueLength &&
        step <= GeneralValuePersonalityLength
      ) {
        const personalityStep = step - GeneralValueLength;
        const currQuestion = actualPersonalityQs[personalityStep - 1];
        const currentValue = personalityFormValue?.[currQuestion.name];
        hasValue = hasDefinedValue(currentValue);
        if (hasValue && (step <= GeneralValuePersonalityLength)) {
          setStep(step + 1);
        }
      } else if (step > GeneralValuePersonalityLength && step <= stepAmt && hasFinalising) {
        const sectionStep = stepAmt - GeneralValuePersonalityLength - 1;
        const currQuestion = actualFinalisingQs[sectionStep];
        const notMandatory = currQuestion?.notMandatory === true;
        const currentValue = finalisingFormValue[sectionStep];
        const actualValue = finalisingFormValue?.[currQuestion.name];
        if (notMandatory) {
          hasValue = true;
        } else {
          hasValue = !!currentValue;
        }
        // For when they need to fill out a certain amount of fields
        if (currQuestion?.componentType === EComponentType.DND) {
          hasValue =
            !!actualValue &&
            actualValue
              ?.filter((c) => c.id !== currQuestion.optionsColumn)
              .reduce((acc, f) => f?.items?.length + acc, 0) > 1;
        } else if (
          currQuestion?.componentType === EComponentType.TOGGLE &&
          !currQuestion?.exclusive
        ) {
          if (currQuestion?.minimumAmount) {
            hasValue = currentValue?.length >= currQuestion.minimumAmount;
          } else {
            hasValue = currentValue?.length === currQuestion.maximumAmount;
          }
        } // For array lengths
        else if (currentValue && typeof currentValue === 'object') {
          if (currentValue.length < 1) {
            hasValue = false;
          }
        }
      }
    }
    return hasValue;
  };

  const controlPrevSteps = () => {
    if (finished) {
      setFinished(false);
      setStep(step - 1);
    } // This is just to put in the info screens
    else if (
      step === GeneralLength + 1 ||
      step === GeneralLength + 0.5 ||
      step === GeneralValueLength + 0.5 ||
      step === GeneralValueLength + 1
    ) {
      setStep(step - 0.5);
    } else {
      setStep(step - 1);
    }
  };

  const controlNextSteps = () => {
    if (step === stepAmt) {
      setFinished(true);
    } // This is just to put in the info screens
    else if (
      step === GeneralLength ||
      step === GeneralLength + 0.5 ||
      step === GeneralValueLength ||
      step === GeneralValueLength + 0.5
    ) {
      setStep(step + 0.5);
    } else {
      setStep(step + 1);
    }
  };

  return (
    <Grid container alignItems="center" justifyContent="center" className={styles.parentContainer}>
      <div id="topofform" />
      {!position ? (
        <QualifyingForm />
      ) : (
        <>
          <FormStepper
            step={step - 1}
            generalQuestions={actualGeneralQs}
            finalisingQuestions={actualFinalisingQs}
            valuesQuestions={actualValuesQs}
            personalityQuestions={actualPersonalityQs}
            saveFormData={closeDialog}
          />
          <Grid container className={styles.marginTop} />

          <Grid container item xs={11} md={9} className={styles.form} justify="center">
            {step <= GeneralLength ? (
              <GeneralForm stepNumber={step} generalQuestions={actualGeneralQs} />
            ) : step === GeneralLength + 0.5 ? (
              <ValuesInfo />
            ) : step <= GeneralValueLength ? (
              <ValuesForm
                stepNumber={step}
                prevStepNumber={GeneralLength}
                valuesQuestions={actualValuesQs}
              />
            ) : step === GeneralValueLength + 0.5 && hasPersonality ? (
              <PersonalityInfo />
            ) : step <= GeneralValuePersonalityLength && hasPersonality && !finished ? (
              <PersonalityForm
                stepNumber={step}
                prevStepNumber={GeneralValueLength}
                personalityQuestions={actualPersonalityQs}
              />
            ) : step <= stepAmt && !finished && hasFinalising ? (
              <FinalisingForm
                stepNumber={step}
                isMentee={isMentee}
                prevStepNumber={GeneralValuePersonalityLength}
              />
            ) : (
              finished && (
                <Text variant="lg" color="purple" fontWeight={600} marginTop={30}>
                  Close this form to restart
                </Text>
              )
            )}
          </Grid>
          {!finished && (
            <Grid container item xs={12} justifyContent="center" className={styles.fixedActionPane}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                item
                xs={11}
                md={9}
              >
                <Grid item xs={5} md={3}>
                  <PrevButton
                    onClick={controlPrevSteps}
                    fontSize={20}
                    disabled={!(step > 1 && !finished)}
                    fullWidth
                    color="secondary"
                  >
                    Previous
                  </PrevButton>
                </Grid>
                <Grid item xs={5} md={3}>
                  <NextButton fullWidth disabled={!validateValue()} onClick={controlNextSteps}>
                    {finished ? 'Submit' : 'Next'}
                  </NextButton>
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};
