import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { IActiveUsersData } from '../../store/reducers/DashboardReducer';
import { Text } from '../../Components/General/Text';
import { BrancherGridCard } from '../../Components/General/BrancherGridCard';
import { SegmentedControlGroup } from '../../Components/InputFields/BrancherSegmentedControl';
import { EChosenProfile, ReportingProfileOptions } from './Dashboard';

const useStyles = makeStyles({
  dashboardCard: {
    maxWidth: 400,
    padding: 25,
    minHeight: 'fit-content',
    height: 320,
  },
});

interface IActiveUsers {
  activeUsers: IActiveUsersData;
}

export const ActiveUsersHealth: React.FC<IActiveUsers> = (props) => {
  const [chosenProfile, setChosenProfile] = React.useState<EChosenProfile>(EChosenProfile.ALL);
  const { activeUsers } = props;
  const styles = useStyles({});

  let percentage = 0;
  let totalAmount = 0;
  let activeAmount = 0;
  if (chosenProfile === EChosenProfile.ALL) {
    activeAmount = activeUsers?.totalMonthlyActiveAllAmount;
    percentage = activeUsers?.allPercentage;
    totalAmount = activeUsers?.totalAllAmount;
  } else if (chosenProfile === EChosenProfile.MENTEE) {
    activeAmount = activeUsers?.totalMonthlyActiveMenteeAmount;
    percentage = activeUsers?.menteePercentage;
    totalAmount = activeUsers?.totalMenteeAmount;
  } else if (chosenProfile === EChosenProfile.MENTOR) {
    activeAmount = activeUsers?.totalMonthlyActiveMentorAmount;
    percentage = activeUsers?.mentorPercentage;
    totalAmount = activeUsers?.totalMentorAmount;
  }

  return (
    <Grid container item>
      <Grid item xs={11}>
        <SegmentedControlGroup
          value={chosenProfile}
          options={ReportingProfileOptions}
          updateValue={(v: EChosenProfile) => setChosenProfile(v)}
        />
      </Grid>
      <Grid container item alignItems="center" justifyContent="space-between">
        <BrancherGridCard
          container
          item
          direction="column"
          spacing={3}
          xs={11}
          className={styles.dashboardCard}
          alignItems="center"
          justifyContent="space-around"
        >
          <Grid item container alignItems="center" justifyContent="center">
            <Text variant="lg" display="inline" color="purple" fontWeight={700}>
              {activeAmount}{' '}
            </Text>
            <Text variant="lg" display="inline" color="tertiaryGrey" fontWeight={600}>
              (out of {totalAmount})
            </Text>
          </Grid>
          <Grid item>
            <Text variant="lg" fontWeight={500} align="center">
              Active Users
            </Text>
          </Grid>
          <Grid item container alignItems="center" justifyContent="center">
            <Text variant="xs" color="placeholderGrey" align="center">
              {percentage ?? '0'}% of{' '}
              {chosenProfile === EChosenProfile.ALL
                ? 'users'
                : chosenProfile === EChosenProfile.MENTEE
                ? 'mentees'
                : 'mentors'}{' '}
              have logged on in the last 30 days
            </Text>
          </Grid>
        </BrancherGridCard>
      </Grid>
    </Grid>
  );
};
