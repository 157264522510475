import * as React from 'react';
import Workbook from 'react-excel-workbook';
import { BrancherButton } from '../../Components/InputFields/BrancherButton';

interface IGeneratePairingSatisfactionReport {
  data: any;
  displayData: any;
  programName: string;
}

export const GeneratePairingSatisfactionReport: React.FC<IGeneratePairingSatisfactionReport> = ({
  data,
  displayData,
  programName,
}) => (
  <Workbook
    filename={`${programName}+pairing+satisfaction_report.xlsx`}
    element={
      <BrancherButton color="primary" variant="text" size="small">
        Download pairing satisfaction report!
      </BrancherButton>
    }
  >
    <Workbook.Sheet data={data} name="Pairing-Satisfaction">
      {displayData.map((d, l) => (
        <Workbook.Column label={d.readableName} value={d.attributeName} key={l} />
      ))}
    </Workbook.Sheet>
  </Workbook>
);
