import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { IPersonalityFormConfig, IsPersonalityV2 } from './PersonalityFormConfig';
import { Segment, SegmentedControl } from '../FormFieldTypes/SegmentedControl';
import { Text } from '../../../Components/General/Text';

interface IPersonalityForm {
  stepNumber: number;
  prevStepNumber: number;
  personalityQuestions: IPersonalityFormConfig[];
}

export const PersonalityForm = (props: IPersonalityForm) => {
  const { stepNumber, prevStepNumber, personalityQuestions } = props;
  const personalityStepNumber = stepNumber - prevStepNumber - 1;

  const isPersonalityV2 = IsPersonalityV2();

  return isPersonalityV2 ? (
    <>
      {personalityQuestions[personalityStepNumber]?.description && (
        <Grid item container justifyContent="center">
          <Text variant="sm" fontWeight={500} marginBottom={20} marginTop={10}>
            {personalityQuestions[personalityStepNumber]?.description}
          </Text>
        </Grid>
      )}
      <Segment {...personalityQuestions[personalityStepNumber]} fullWidth />
    </>
  ) : (
    <>
      {stepNumber === prevStepNumber + 1 ? (
        <SegmentedControl {...personalityQuestions[0]} fullWidth />
      ) : stepNumber === prevStepNumber + 2 ? (
        <SegmentedControl {...personalityQuestions[1]} fullWidth />
      ) : stepNumber === prevStepNumber + 3 ? (
        <SegmentedControl {...personalityQuestions[2]} fullWidth />
      ) : stepNumber === prevStepNumber + 4 ? (
        <SegmentedControl {...personalityQuestions[3]} fullWidth />
      ) : null}
    </>
  );
};
