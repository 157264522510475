import * as React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch } from 'react-redux';
import { Text } from '../../Components/General/Text';
import { BrancherButton } from '../../Components/InputFields/BrancherButton';
import { UtilSSOLogin } from '../../store/actions';
import { IUtilLoginResponse } from '../../store/actions/UserInfoActions';
import { cognitoClientId, loginRedirectUri } from '../../consts/cognito';
import { GetSharedResourceLink } from '../../helpers/LinkHelpers';
import { SSOTypes } from '../SignUp/SSOSignUp';

interface ISSOSignIn {
  controlSSOUser: (res: IUtilLoginResponse) => void;
  type: SSOTypes;
}

export const SSOSignIn: React.FC<ISSOSignIn> = ({ controlSSOUser, type }) => {
  const [gettingToken, setGettingToken] = React.useState<boolean>(false);
  const params = new URLSearchParams(document.location.search.substring(1));
  const code = params.get('code');
  const dispatch = useDispatch();
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  React.useEffect(() => {
    if (code && !gettingToken) {
      loginSSO();
    }
  }, [code]);

  const loginSSO = () => {
    setGettingToken(true);
    dispatch(
      UtilSSOLogin(code, (a) => {
        setGettingToken(false);
        controlSSOUser(a);
      }),
    );
  };

  const epLink: string = `https://brancher.auth.ap-southeast-2.amazoncognito.com/authorize?response_type=code&client_id=${cognitoClientId}&redirect_uri=${loginRedirectUri}&identity_provider=Google&scope: aws.cognito.signin.user.admin email openid phone profile`;

  const icon: string = 'googleIcon.png';
  const ssoTypeName = 'Google';

  return (
    <>
      <BrancherButton onClick={() => window.location.assign(epLink)}>
        <img height={mobile ? 20 : 30} alt="SSO-sign-in-logo" src={GetSharedResourceLink(icon)} />
        <Text variant="xs" color="tertiaryGrey" marginLeft={15} fontWeight={600}>
          Sign in with {ssoTypeName}
        </Text>
      </BrancherButton>
    </>
  );
};
