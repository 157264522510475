import * as React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { GetIllustrationLink, GetLinkBase } from '../../helpers/LinkHelpers';

interface IProfilePicture {
  src?: string;
  alt?: string;
}

interface IProfilePictureStyles {
  mobile: boolean;
  tablet: boolean;
}

const useStyles = makeStyles({
  profilePic: {
    maxWidth: (props: IProfilePictureStyles) => (props.mobile ? 40 : props.tablet ? 55 : 70),
    minWidth: (props: IProfilePictureStyles) => (props.mobile ? 40 : props.tablet ? 55 : 70),
    maxHeight: (props: IProfilePictureStyles) => (props.mobile ? 40 : props.tablet ? 55 : 70),
    minHeight: (props: IProfilePictureStyles) => (props.mobile ? 40 : props.tablet ? 55 : 70),
    borderRadius: '50%',
  },
});

export const ProfilePicture: React.FC<IProfilePicture> = ({ src, alt = 'profile-avatar' }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const styles = useStyles({ mobile, tablet });
  return (
    <img
      src={src ? `${GetLinkBase()}${src}` : GetIllustrationLink('noAvatar.png')}
      className={styles.profilePic}
      alt={alt}
    />
  );
};
