import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import { BrancherGridCard } from '../../Components/General/BrancherGridCard';
import { Text } from '../../Components/General/Text';
import { BrancherDivider } from '../../Components/General/BrancherDivider';
import { SimpleLineChart } from '../Program/Charts/SimpleLineChart';
import { IStoreTypes } from '../../store/storeTypes';
import { BrancherDispatch, UtilGetDashboardMeetingData } from '../../store/actions';

const useStyles = makeStyles({
  meetingCard: {
    maxWidth: 420,
    width: 420,
    padding: 25,
    height: 600,
    minHeight: 'fit-content',
  },
  marginTop: {
    marginTop: (props: { phablet: boolean }) => (props.phablet ? 50 : 0),
  },
});

interface IMeetingHealth {
  filters: { [x in string]: any };
}

export const MeetingHealth: React.FC<IMeetingHealth> = (props) => {
  const { filters } = props;
  const phablet = useMediaQuery(useTheme().breakpoints.down('md'));
  const [retrievingDashboardData, setRetrievingDashboardData] = React.useState<boolean>(false);
  const meetings = useSelector((state: IStoreTypes) => state.dashboard?.meetings);
  const styles = useStyles({ phablet });
  const dispatch = useDispatch();

  React.useEffect(() => {
    setRetrievingDashboardData(true);
    BrancherDispatch(
      dispatch,
      UtilGetDashboardMeetingData(filters, () => {
        setRetrievingDashboardData(false);
      }),
    );
  }, []);

  return !retrievingDashboardData && meetings?.meetingsCreated !== null ? (
    <Grid container item>
      <Text variant="lg" marginTop={20} marginBottom={30} color="purple" fontWeight={700}>
        Meetings
      </Text>
      <Grid container item alignItems="center" justifyContent="space-between">
        <BrancherGridCard
          container
          item
          direction="column"
          className={styles.meetingCard}
          xs={6}
          lg={2}
          alignItems="center"
          justifyContent="space-around"
        >
          <Grid item>
            <Text variant="lg" align="center" color="purple" fontWeight={700}>
              {meetings?.meetingsCreated}{' '}
            </Text>
            <Text variant="lg" fontWeight={500} align="center">
              1:1 Meetings Created
            </Text>
          </Grid>
          <Grid item>
            <Text variant="xs" color="placeholderGrey" align="center">
              {meetings?.meetingsCurrentRelationshipPercentage ?? '0'}% of current pairs have
              organised a meeting
            </Text>
            {/*<Text variant="xs" color="placeholderGrey" align="center">*/}
            {/*  {meetings?.percentageOrganisers}% of users have organised a meeting*/}
            {/*</Text>*/}
          </Grid>
          <BrancherDivider marginTop={10} height={2} />
          <Grid item>
            <Text variant="lg" align="center" color="purple" fontWeight={700}>
              {meetings?.meetingsCompleted}{' '}
            </Text>
            <Text variant="md" align="center" fontWeight={500}>
              1:1 Meetings Completed
            </Text>
            <Text variant="xs" color="placeholderGrey" align="center" marginTop={10}>
              {meetings?.meetingAllUsersParticipatedPercentage ?? '0'}% of users from previous and
              current pairs have participated in a meeting
            </Text>
          </Grid>
        </BrancherGridCard>

        <BrancherGridCard
          container
          item
          direction="column"
          spacing={1}
          className={styles.meetingCard}
          xs={6}
          lg={3}
          alignItems="center"
          justifyContent="space-around"
        >
          <Grid item>
            <Text variant="lg" align="center" color="purple" fontWeight={700}>
              {meetings?.currentMeetingTalkingPoints}{' '}
            </Text>
            <Text variant="lg" fontWeight={500} align="center">
              Active Meeting Talking Points
            </Text>
          </Grid>
          <Grid item>
            <Text variant="lg" align="center" color="purple" fontWeight={700}>
              {meetings?.completedTalkingPoints}{' '}
            </Text>
            <Text variant="lg" fontWeight={500} align="center">
              Completed Meeting Talking Points
            </Text>
          </Grid>
          <BrancherDivider height={2} />
          <Grid item>
            <Text variant="lg" align="center" color="purple" fontWeight={700}>
              {meetings?.currentMeetingActions}{' '}
            </Text>
            <Text variant="lg" fontWeight={500} align="center">
              Active Meeting Actions
            </Text>
          </Grid>
          <Grid item>
            <Text variant="lg" align="center" color="purple" fontWeight={700}>
              {meetings?.completedMeetingActions}{' '}
            </Text>
            <Text variant="lg" fontWeight={500} align="center">
              Completed Meeting Actions
            </Text>
          </Grid>
        </BrancherGridCard>
        <Grid item xs={12} lg={6} className={phablet ? styles.marginTop : ''}>
          <SimpleLineChart
            data={meetings?.monthlyMeetingAmount ?? []}
            height={600}
            xValueLabel="Amount"
            yValueLabel="Amount of meetings"
          />
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Grid container item xs={12} justifyContent="center" alignItems="center">
      <CircularProgress color="secondary" size={64} />
    </Grid>
  );
};
