import { format } from 'date-fns';

export const dateDefaultFormat = 'dd/MM/yyyy';
export const dateTimeFormat = 'dd/MM/yyyy hh:mm a';
export const dateTimeExtendedFormat = "dd/MM/yyyy'T'HH:mm";

export const formatDateTime = (date?: Date | string): string => {
  if (date) {
    return format(new Date(date), dateTimeExtendedFormat);
  } else {
    return format(new Date(), dateTimeExtendedFormat);
  }
};
