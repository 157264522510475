import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { SaveButton } from '../../Components/InputFields/BrancherButton';
import { Text } from '../../Components/General/Text';
import { BrancherFileUpload } from '../../Components/InputFields/BrancherFileUpload';
import { UtilUploadUserDeactivationList, BrancherDispatch } from '../../store/actions/';
import { validateEmail } from '../../utils/validators/validateEmail';
import { BrancherLinkText } from '../../Components/General/BrancherLinkText';
import { GetSharedResourceLink } from '../../helpers/LinkHelpers';
import { BrancherList } from '../../Components/General/BrancherList';

const useStyles = makeStyles({
  popover: {
    '& .MuiPopover-paper': {
      padding: 12,
      borderRadius: 20,
    },
  },
  infoButton: {
    display: 'inline',
  },
});

export const UserDeactivationUpload: React.FC = () => {
  const [CSVList, setCSVList] = React.useState<string[]>([]);
  const [invalidUsernames, setInvalidUsernames] = React.useState<string[]>([]);
  const [deactivationErrorMessage, setDeactivationErrorMessage] = React.useState<string>('');
  const [updatingProgram, setUpdatingProgram] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const styles = useStyles();
  const [popoverAnchor, setPopoverAnchor] = React.useState(null);

  const cleanUsernames = (username: string): string => {
    return username.replace('\r\n', '');
  };

  const controlSavingCSVList = (usernameList: string) => {
    let validatedUsernames: string[] = [];
    let invalidNames: string[] = [];
    const initialNameList = Array.from(new Set(usernameList.split(',')));
    initialNameList
      ?.map((m) => cleanUsernames(m))
      ?.forEach((f) => {
        if (validateEmail(f)) {
          validatedUsernames.push(f);
        } else {
          invalidNames.push(f);
        }
      });
    setDeactivationErrorMessage('');
    setInvalidUsernames(invalidNames);
    setCSVList(validatedUsernames);
  };

  const uploadCSVList = () => {
    setUpdatingProgram(true);
    BrancherDispatch(
      dispatch,
      UtilUploadUserDeactivationList(CSVList, (res) => {
        setUpdatingProgram(false);
        setCSVList([]);
        setInvalidUsernames([]);
        if (!!res?.message) {
          setDeactivationErrorMessage(res.message);
        }
      }),
    );
  };

  const controlValidator = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!Boolean(popoverAnchor)) {
      setPopoverAnchor(event.currentTarget);
      event.currentTarget.focus();
    }
    event.stopPropagation();
    event.preventDefault();
  };

  const closeValidator = () => {
    if (Boolean(popoverAnchor)) {
      setPopoverAnchor(null);
    }
  };
  const open = Boolean(popoverAnchor);

  return (
    <Grid container justifyContent="center" spacing={4} onClick={closeValidator}>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Text variant="sm" color="purple" fontWeight={600} marginBottom={10}>
            Upload user deactivation CSV file (usernames/emails)
          </Text>
          <Text variant="xs" fontWeight={400} marginBottom={10} display="inline">
            This is a list of (comma separated) emails of users you want deactivated
          </Text>
          <IconButton className={styles.infoButton} onClick={controlValidator}>
            <InfoOutlined />
          </IconButton>
          <Popover
            open={open}
            className={styles.popover}
            id="active-users-more-info"
            anchorEl={popoverAnchor}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Grid container>
              <Grid item>
                <BrancherList
                  listItems={[
                    'Email addresses must be in a single row format (e.g. cell 1A, 1B, 1C)',
                    'No commas or semi-colons after the emails',
                    'Tip: in excel, you can select a paste option to "transpose" the emails from a single column into a single row',
                  ]}
                />
              </Grid>
            </Grid>
          </Popover>
          <BrancherLinkText
            marginBottom={30}
            variant="sm"
            download
            href={GetSharedResourceLink('user_deactivation_list.csv')}
          >
            Example deactivation CSV file
          </BrancherLinkText>
          <Box marginBottom={3}>
            <BrancherFileUpload
              isCSV
              value={CSVList.join(',')}
              name="csv-upload-list"
              updateValue={(c: string) => controlSavingCSVList(c)}
            />
          </Box>
          {CSVList?.length > 0 && (
            <Text variant="sm" fontWeight={500}>
              {CSVList.length} users will be deactivated.
            </Text>
          )}
          {invalidUsernames?.length > 0 && (
            <Text variant="sm" marginTop={15} color="red" fontWeight={500}>
              {invalidUsernames.join(', ')} are not valid usernames.
            </Text>
          )}
        </Grid>
      </Grid>
      {!!deactivationErrorMessage && (
        <Grid item xs={12}>
          <Text variant="sm" fontWeight={600} marginBottom={30}>
            {deactivationErrorMessage}
          </Text>
        </Grid>
      )}
      <Grid item xs={12}>
        <SaveButton
          onClick={uploadCSVList}
          loading={updatingProgram}
          disabled={updatingProgram || !(CSVList?.length > 0)}
        >
          Upload user deactivation list
        </SaveButton>
      </Grid>
    </Grid>
  );
};
