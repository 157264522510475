import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Create from '@material-ui/icons/Create';
import { useDispatch } from 'react-redux';
import { BrancherDispatch, UtilCreateCompany } from '../../store/actions';
import { BrancherTextField } from '../../Components/InputFields/BrancherTextField';
import { ActionButton } from '../../Components/InputFields/BrancherButton';
import { Text } from '../../Components/General/Text';
import { BrancherSwitch } from '../../Components/InputFields/BrancherSwitch';
import { BrancherFileUpload } from '../../Components/InputFields/BrancherFileUpload';

export const CreateCompany: React.FC = () => {
  const [companyName, setCompanyName] = React.useState<string>();
  const [creatingCompany, setCreatingCompany] = React.useState<boolean>(false);
  const [googleSSO, setGoogleSSO] = React.useState<boolean>(false);
  const [samlSignOn, setSamlSignOn] = React.useState<boolean>(false);
  const [passwordAuth, setPasswordAuth] = React.useState<boolean>(true);
  const [whiteLabel, setWhiteLabel] = React.useState<boolean>(false);
  const [logo, setLogo] = React.useState<string>('');
  const [domainEmail, setDomainEmail] = React.useState<string>('');
  const [mfaEnabled, setMfaEnabled] = React.useState<boolean>(false);
  const dispatch = useDispatch();

  const createCompany = () => {
    setCreatingCompany(true);
    BrancherDispatch(
      dispatch,
      UtilCreateCompany(
        companyName,
        googleSSO,
        samlSignOn,
        passwordAuth,
        whiteLabel,
        logo,
        mfaEnabled,
        domainEmail,
        () => {
          setCreatingCompany(false);
        },
      ),
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={600}>
          Create New Company
        </Text>
      </Grid>
      <Grid item xs={12}>
        <BrancherTextField
          value={companyName}
          updateValue={setCompanyName}
          placeholder="New company"
          label="Company Name"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <BrancherTextField
          value={domainEmail}
          updateValue={setDomainEmail}
          placeholder="company.com"
          label="Domain Email"
          fullWidth
          helperText="Don't put the '@' symbol, only the registered domain name"
        />
      </Grid>
      <Grid item container xs={12} justifyContent="space-between" alignItems="center">
        <Text variant="sm" fontWeight={600}>
          MFA Enabled?
        </Text>
        <BrancherSwitch value={mfaEnabled} updateValue={setMfaEnabled} ariaLabel="mfaEnabled" />
      </Grid>
      <Grid item container xs={12} justifyContent="space-between" alignItems="center">
        <Text variant="sm" fontWeight={600}>
          Google sign on?
        </Text>
        <BrancherSwitch value={googleSSO} updateValue={setGoogleSSO} ariaLabel="googleSSO" />
      </Grid>
      <Grid item container xs={12} justifyContent="space-between" alignItems="center">
        <Text variant="sm" fontWeight={600}>
          SAML sign on?
        </Text>
        <BrancherSwitch value={samlSignOn} updateValue={setSamlSignOn} ariaLabel="samlSignOn" />
      </Grid>
      <Grid item container xs={12} justifyContent="space-between" alignItems="center">
        <Text variant="sm" fontWeight={600}>
          Password Authentication?
        </Text>
        <BrancherSwitch
          value={passwordAuth}
          updateValue={setPasswordAuth}
          ariaLabel="passwordAuth"
        />
      </Grid>
      <Grid item container xs={12} justifyContent="space-between" alignItems="center">
        <Text variant="sm" fontWeight={600}>
          White labelled?
        </Text>
        <BrancherSwitch value={whiteLabel} updateValue={setWhiteLabel} ariaLabel="whiteLabel" />
      </Grid>
      {whiteLabel && (
        <Grid item container xs={12} justifyContent="space-between" alignItems="center">
          <Text variant="sm" fontWeight={600}>
            Logo
          </Text>
          <BrancherFileUpload value={logo} name="logo" updateValue={setLogo} ratio={1.2} />
        </Grid>
      )}
      <Grid item xs={12}>
        <ActionButton
          startIcon={<Create color="secondary" />}
          loading={creatingCompany}
          onClick={createCompany}
          disabled={!companyName}
        >
          Create Company
        </ActionButton>
      </Grid>
    </Grid>
  );
};
