import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import Popover from '@material-ui/core/Popover';
import { useSelector } from 'react-redux';
import { BrancherGridCard } from '../../Components/General/BrancherGridCard';
import { Text } from '../../Components/General/Text';
import { IStoreTypes } from '../../store/storeTypes';
import { RouteMaster } from '../../Components/Routing';
import { EMeetingFrequencyHealth } from '../../store/actions/PairingActions';
import { BrancherLinkText } from '../../Components/General/BrancherLinkText';
import { Tooltip } from '@material-ui/core';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
  dashboardCard: {
    minWidth: 420,
    padding: 25,
    minHeight: 220,
    height: 'fit-content',
  },
  popover: {
    '& .MuiPopover-paper': {
      padding: 12,
      borderRadius: 20,
    },
  },
  infoSubscriptionButton: {
    display: 'inline',
  },
});

export const MeetingFrequencyHealth: React.FC = () => {
  const styles = useStyles();
  const frequency = useSelector(
    (state: IStoreTypes) => state.dashboard?.meetingSatisfactionFrequencyHealth?.frequency,
  );
  const [popoverAnchor, setPopoverAnchor] = React.useState(null);

  const controlValidator = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!Boolean(popoverAnchor)) {
      setPopoverAnchor(event.currentTarget);
      event.currentTarget.focus();
    }
    event.stopPropagation();
    event.preventDefault();
  };

  const closeValidator = () => {
    if (Boolean(popoverAnchor)) {
      setPopoverAnchor(null);
    }
  };
  const open = Boolean(popoverAnchor);

  return !!frequency ? (
    <BrancherGridCard
      container
      item
      md={5}
      className={styles.dashboardCard}
      alignItems="center"
      justifyContent="center"
      onClick={closeValidator}
    >
      <Grid item xs={12} container justifyContent="center" alignItems="center">
        <Text variant="lg" display="inline" color="purple" fontWeight={700}>
          Meeting Frequency
        </Text>
        <IconButton className={styles.infoSubscriptionButton} onClick={controlValidator}>
          <InfoOutlined />
        </IconButton>
      </Grid>
      <Grid container item spacing={5} alignItems="center" justifyContent="center">
        <Popover
          open={open}
          className={styles.popover}
          id="meeting-frequency-more-info"
          anchorEl={popoverAnchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Grid container spacing={2} direction="column">
            <Grid item>
              <Text variant="sm" display="inline" fontWeight={600}>
                "On Track"{' '}
              </Text>
              <Text variant="sm" display="inline" fontWeight={500}>
                means the pair has met in the last 60 days
              </Text>
            </Grid>
            <Grid item>
              <Text variant="sm" display="inline" fontWeight={600}>
                "To Watch"{' '}
              </Text>
              <Text variant="sm" display="inline" fontWeight={500}>
                means that the pair hasn't met in the last 60 days
              </Text>
            </Grid>
            <Grid item>
              <Text variant="sm" display="inline" fontWeight={600}>
                "Too Early to Tell"{' '}
              </Text>
              <Text variant="sm" display="inline" fontWeight={500}>
                means the pair has not met yet
              </Text>
            </Grid>
          </Grid>
        </Popover>
        <Grid item>
          <Text variant="md" display="inline" marginLeft={10} fontWeight={500}>
            On Track:{' '}
          </Text>
          <Tooltip title="See all 'On Track' pairs">
            <Box display="inline-flex">
              <BrancherLinkText
                href={`${RouteMaster.pairs.path}?mf=${EMeetingFrequencyHealth.ON_TRACK}`}
                underline="always"
                variant="lg"
                display="inline"
                align="center"
                fontWeight={600}
                marginLeft={10}
              >
                {frequency?.onTrack}
              </BrancherLinkText>
            </Box>
          </Tooltip>
        </Grid>
        <Grid item>
          <Text variant="md" display="inline" marginLeft={10} fontWeight={500}>
            To Watch:{' '}
          </Text>
          <Tooltip title="See all 'To Watch' pairs">
            <Box display="inline-flex">
              <BrancherLinkText
                href={`${RouteMaster.pairs.path}?mf=${EMeetingFrequencyHealth.TO_WATCH}`}
                underline="always"
                variant="lg"
                display="inline"
                align="center"
                fontWeight={600}
                marginLeft={10}
              >
                {frequency?.toWatch}
              </BrancherLinkText>
            </Box>
          </Tooltip>
        </Grid>
        <Grid item>
          <Text variant="md" display="inline" marginLeft={10} fontWeight={500}>
            Too Early to Tell:{' '}
          </Text>
          <Tooltip title="See all 'Too Early to Tell' pairs">
            <Box display="inline-flex">
              <BrancherLinkText
                href={`${RouteMaster.pairs.path}?mf=${EMeetingFrequencyHealth.TOO_EARLY}`}
                underline="always"
                variant="lg"
                display="inline"
                align="center"
                fontWeight={600}
                marginLeft={10}
              >
                {frequency?.tooEarly}
              </BrancherLinkText>
            </Box>
          </Tooltip>
        </Grid>
      </Grid>
    </BrancherGridCard>
  ) : (
    <CircularProgress color="secondary" size={64} />
  );
};
