import * as React from 'react';
import { IText, Text } from './Text';

interface IEmojiRate extends Omit<IText, 'children'> {
  rating: string | number;
  showRawRating?: boolean;
}

export const EmojiRate: React.FC<IEmojiRate> = ({ rating, showRawRating = true, ...other }) => {
  const emoji =
    rating === '-' ? (
      <Text {...other} display="inline" marginLeft={5}>
        {rating}
      </Text>
    ) : rating <= 1 ? (
      <Text {...other} display="inline" marginLeft={5}>
        &#128543;
      </Text>
    ) : rating <= 2 ? (
      <Text {...other} display="inline" marginLeft={5}>
        &#128533;
      </Text>
    ) : rating <= 3 ? (
      <Text {...other} display="inline" marginLeft={5}>
        &#128528;
      </Text>
    ) : rating <= 4 ? (
      <Text {...other} display="inline" marginLeft={5}>
        &#128512;
      </Text>
    ) : (
      <Text {...other} display="inline" marginLeft={5}>
        &#128525;
      </Text>
    );

  return (
    <>
      {rating !== '-' && showRawRating && (
        <Text {...other} display="inline">
          {rating}
        </Text>
      )}
      {emoji}
    </>
  );
};
