import { BrancherAuthRequest } from './utils/BrancherDispatch';
import {
  SAVE_DRAFTED_PAIRS,
  SAVE_PAIRING_DATA,
  IDraftedPairs,
  SAVE_SUGGESTED_PAIRS,
  CLEAN_PAIRING_DATA,
  IPairingInfo,
  IUnmatched,
} from '../reducers/PairingReducer';
import { ProgramPositions } from '../../consts/ProgramPositionOptions';
import { SaveProgramData } from './ProgramActions';
import { UpdatableProgramInfo } from '../reducers/ProgramReducer';
import { SaveDashboardData } from './DashboardActions';
import { SaveReportData } from './ReportActions';
import { IBaseProfileData, IProfileData } from '../../types/ProfileTypes';
import { IAPIResponseObject } from './actionTypes/apiTypes';
import { IMeetingAction, IMeetingAgenda, IMeetingData } from '../../types/MeetingTypes';
import { EMatchingStrategies } from '../../Pages/Matching/PairingEngine';

export const SavePairingData = (pairingData: IPairingInfo) => {
  return {
    type: SAVE_PAIRING_DATA,
    payload: pairingData,
  };
};

export const SaveDrafting = (draftedPairs: IDraftedPairs[]) => {
  return {
    type: SAVE_DRAFTED_PAIRS,
    payload: draftedPairs,
  };
};

export const SaveSuggestedPairs = (suggestedPairs: IDraftedPairs[]) => {
  return {
    type: SAVE_SUGGESTED_PAIRS,
    payload: suggestedPairs,
  };
};

export const CleanPairingData = () => {
  return {
    type: CLEAN_PAIRING_DATA,
  };
};

interface IPairsFullResponse {
  pairingReportData: any;
  pairingReportColumns: any;
  suggestedPairsReportData: any;
  suggestedPairsReportColumns: any;
  unpairedReportData: any;
  unpairedReportColumns: any;
  paired: IDraftedPairs[];
  draftPairs: IDraftedPairs[];
  inProgressDraft?: IDraftedPairs[];
  unpaired?: { mentee: IUnmatched[]; mentor: IUnmatched[] };
  targetUserMatches?: IDraftedPairs[];
}

interface IUtilGetPairsResponse {
  data: {
    success: boolean;
    data: IPairsFullResponse;
  };
}

// This runs the algorithm and returns all the pairs
export const UtilGetPairs = (matchConfig: { persist: boolean, matchingStrategy?: EMatchingStrategies }, cb: (a?: IPairsFullResponse) => void) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/pairusers',
        params: JSON.stringify({
          programId,
          adminBulkPairing: !matchConfig.persist,
          matchingStrategy: matchConfig?.matchingStrategy,
        }),
      },
      getState(),
    )
      .then((response: IUtilGetPairsResponse) => {
        const {
          pairingReportData,
          pairingReportColumns,
          suggestedPairsReportData,
          suggestedPairsReportColumns,
          unpairedReportData,
          unpairedReportColumns,
          ...other
        } = response.data.data;
        if (matchConfig.persist) {
          dispatch(
            SaveReportData({
              pairingReportData,
              pairingReportColumns,
              suggestedPairsReportData,
              suggestedPairsReportColumns,
              unpairedReportData,
              unpairedReportColumns,
            }),
          );
          dispatch(SavePairingData(other));
        }
        cb(response.data.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

// This saves the pairs and then runs the algorithm and just returns the pairs for the given `targetUser`
export const UtilGetTargetUserPairs = (
  targetUser: string,
  targetUserPosition: ProgramPositions,
  overrideAlgorithm: boolean,
  saveDrafts: boolean,
  cb: (a: any) => void,
) => {
  return (dispatch: any) => {
    if (saveDrafts) {
      dispatch(
        UtilSaveDraftedPairs(() => {
          dispatch(UtilGetUserPairs(targetUser, targetUserPosition, overrideAlgorithm, false, 20, cb));
        }),
      );
    } else {
      dispatch(UtilGetUserPairs(targetUser, targetUserPosition, overrideAlgorithm, true, 20, cb));
    }
  };
};

// This runs the algorithm and just returns the pairs for the given `targetUser`
export const UtilGetUserPairs = (
  targetUser: string,
  targetUserPosition: ProgramPositions,
  overrideAlgorithm: boolean,
  adminBulkPairing: boolean = false,
  filterTopAmount: number,
  cb: (a: any) => void,
) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/pairusers',
        params: JSON.stringify({
          programId,
          targetUser,
          targetUserPosition,
          overrideAlgorithm,
          adminBulkPairing,
          filterTopAmount,
        }),
      },
      getState(),
    )
      .then((response: any) => {
        cb(response.data.data?.targetUserMatches);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

// This approves the matched users
export const UtilCompletePairing = (
  sendPairedEmails: boolean,
  sendUnpairedEmails: boolean,
  cb: (a: any) => void,
) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'v2/completepairing',
        data: {
          programId,
          sendPairedEmails,
          sendUnpairedEmails,
        },
      },
      getState(),
    )
      .then((response: any) => {
        dispatch(SaveDashboardData({ congrats: true }));
        dispatch(SaveProgramData({ [UpdatableProgramInfo.LAUNCHED_PROGRAM]: true }));
        cb(response);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

// This updates the drafted pairs
export const UtilSaveDraftedPairs = (cb: (a?: any) => void) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    const basicDraftPairs = getState().pairing.draftPairs;
    const unpaired = getState().pairing.unpaired;
    const draftPairs = basicDraftPairs.map((p) => ({ ...p, approved: true }));
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'v2/pairusers',
        data: {
          programId,
          draftPairs,
          unpaired,
        },
      },
      getState(),
    )
      .then(() => {
        dispatch(SaveDrafting(draftPairs));
        cb();
      })
      .catch((error) => {
        cb(error);
      });
  };
};

// This saves a list of draft pairs and also finalises them
export const UtilSaveIncompleteDraftPairs = (incompleteDraftPairs: IDraftedPairs[], finalise: boolean = false, sendPairedEmails: boolean = false, cb: (a?: any) => void) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'v2/matchdrafting',
        data: {
          programId,
          incompleteDraftPairs,
          finalise,
          sendPairedEmails,
        },
      },
      getState(),
    )
      .then(() => {
        cb();
      })
      .catch((error) => {
        cb(error);
      });
  };
};

// This removes a pair post program launch
export const UtilRemovePair = (
  menteeRoleId: string,
  mentorRoleId: string,
  cb: (a?: any) => void,
) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'v2/removepair',
        data: {
          programId,
          menteeRoleId,
          mentorRoleId,
        },
      },
      getState(),
    )
      .then(() => {
        cb();
      })
      .catch((error) => {
        cb(error);
      });
  };
};

// This adds a pair post program launch
export const UtilAddPair = (pair: IDraftedPairs, cb: (a?: any) => void) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'v2/addpair',
        data: {
          programId,
          pair,
        },
      },
      getState(),
    )
      .then(() => {
        cb();
      })
      .catch((error) => {
        cb(error);
      });
  };
};

export enum EMeetingFrequencyHealth {
  ON_TRACK = 'onTrack',
  TO_WATCH = 'toWatch',
  TOO_EARLY = 'tooEarly',
}

export interface IAllProgramPairsData
  extends Pick<
    IProgramPair,
    'averageMeetingRating' | 'averageMenteeRating' | 'averageMentorRating'
  > {
  id: string; // {menteeRoleId}:{mentorRoleId}
  mentee: string;
  mentor: string;
  menteeName: string;
  mentorName: string;
  percentageMatch: number;
  brancherRecommended: string;
  relationshipLength: string;
  menteeAccessibilityDetails: string;
  mentorAccessibilityDetails: string;
  totalMeetings: string;
  allMeetingsAmount: string;
  nextMeetingDate: string;
  lastMeetingDate: string;
  mentee_pairAmount: number;
  mentor_pairAmount: number;
  meetingFrequencyHealth: EMeetingFrequencyHealth;
}

interface IUtilGetProgramCurrentPairs extends IAPIResponseObject {
  data: IAllProgramPairsData[];
}

// This gets all the active pairs for the program
export const UtilGetProgramCurrentPairs = (cb: (a: IUtilGetProgramCurrentPairs) => void) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/pairs',
        params: JSON.stringify({
          programId,
        }),
      },
      getState(),
    )
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

export interface IPairRoleProfileData
  extends Pick<IProfileData, 'completedProfile' | 'activityScore' | 'roleId'> {
  profileData: IBaseProfileData;
}

export interface IProgramPair extends IDraftedPairs {
  menteeProfile: IPairRoleProfileData;
  mentorProfile: IPairRoleProfileData;
  relationshipLength: string;
  totalMeetings: number;
  allMeetingsAmount: number;
  nextMeetingDate: string;
  lastMeetingDate: string;
  sharedNotesAmount: number;
  completedMentoringAgreement: boolean;
  averageMeetingRating?: string;
  averageMenteeRating?: string;
  averageMentorRating?: string;
  totalTalkingPointsCompleted?: number;
  totalActionsCompleted?: number;
  meetingDetails?: IMeetingData[];
  allCompletedTalkingPoints?: IMeetingAgenda[];
  allCompletedActions?: IMeetingAction[];
}

interface IUtilGetPairs extends IAPIResponseObject {
  data: IProgramPair;
}

// This gets a specific pair
export const UtilGetPair = (
  menteeRoleId: string,
  mentorRoleId: string,
  cb: (a: IUtilGetPairs) => void,
) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/pair',
        params: JSON.stringify({
          programId,
          menteeRoleId,
          mentorRoleId,
        }),
      },
      getState(),
    )
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};
