import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector } from 'react-redux';
import {
  IActiveUsersData,
  IApplicationApprovalHealthData,
} from '../../store/reducers/DashboardReducer';
import { Text } from '../../Components/General/Text';
import { IStoreTypes } from '../../store/storeTypes';
import { Subscriptions, UpdatableProgramInfo } from '../../store/reducers/ProgramReducer';
import { BrancherGridCard } from '../../Components/General/BrancherGridCard';
import { addPlural } from '../../utils/TextUtils';
import { RouteMaster } from '../../Components/Routing';
import { BrancherButton } from '../../Components/InputFields/BrancherButton';

const useStyles = makeStyles({
  gridCard: {
    maxHeight: (props: { desktop: boolean }) => (props.desktop ? 500 : 600),
    height: (props: { desktop: boolean }) => (props.desktop ? 500 : 600),
    padding: 25,
    maxWidth: 420,
  },
});

interface IAdminActions {
  activeUsers: IActiveUsersData;
  applicationApproval?: IApplicationApprovalHealthData;
}

export const AdminActions: React.FC<IAdminActions> = (props) => {
  const desktop = useMediaQuery(useTheme().breakpoints.up('xl'));
  const subs = useSelector((state: IStoreTypes) => state.program?.subscription);
  const requiresApplicantApproval = useSelector(
    (state: IStoreTypes) => state.program?.[UpdatableProgramInfo.REQUIRES_APPLICATION_APPROVAL],
  );
  const isAdhocPairing = subs?.includes(Subscriptions.ADHOC_PAIRING);
  const { activeUsers, applicationApproval } = props;
  const mentoringRequests = activeUsers?.mentoringRequests;
  const styles = useStyles({ desktop });

  const hasPendingMentoringRequests = mentoringRequests?.requestsSent > 0;
  const hasApplicationsToReview = applicationApproval?.needsReviewAmount > 0;
  const hasActions = hasPendingMentoringRequests || hasApplicationsToReview;

  return (
    <Grid container item>
      <Grid container item>
        <BrancherGridCard
          container
          item
          spacing={3}
          className={styles.gridCard}
          alignItems="center"
          justifyContent="space-around"
        >
          <Grid item container justifyContent="center">
            <Text variant="lg" color="purple" marginTop={-20} fontWeight={700}>
              Actions
            </Text>
          </Grid>
          {hasActions ? (
            <>
              {requiresApplicantApproval && hasApplicationsToReview && (
                <Grid item container justifyContent="center" alignItems="center">
                  <BrancherButton
                    size="small"
                    color="secondary"
                    onClick={() =>
                      window.location.assign(`${RouteMaster.programUsers.path}?sp=true`)
                    }
                  >
                    {applicationApproval?.needsReviewAmount?.toString()} Application
                    {addPlural(applicationApproval?.needsReviewAmount)} to review
                  </BrancherButton>
                </Grid>
              )}
              {isAdhocPairing && hasPendingMentoringRequests && (
                <Grid item container justifyContent="center">
                  <BrancherButton
                    size="small"
                    color="secondary"
                    onClick={() => window.location.assign(RouteMaster.mentoringRequests.path)}
                  >
                    {mentoringRequests?.requestsSent} Pending Mentoring Requests
                  </BrancherButton>
                </Grid>
              )}
            </>
          ) : (
            <Grid item container justifyContent="center">
              <Text variant="lg" align="center" color="purple" fontWeight={700}>
                You have nothing to action.
              </Text>
            </Grid>
          )}
        </BrancherGridCard>
      </Grid>
    </Grid>
  );
};
