import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../store/storeTypes';
import {
  BrancherDispatch,
  CleanPairingData,
  CleanProgramStatusData,
  UtilGetAllProgramData,
} from '../../store/actions';
import { Page } from '../../Components/General/Page';
import { ProgramWizard } from './ProgramWizard';
import { TrackingFormSubmissions } from './ProgramStepTwo/TrackingFormSubmissions';
import { FormConfigurationLanding } from './ProgramStepOne/FormConfigurationLanding';
import { LaunchProgram } from './ProgramStepFour/LaunchProgram';
import { MatchmakingLanding } from './ProgramStepThree/MatchmakingLanding';
import { Subscriptions } from '../../store/reducers/ProgramReducer';

export const Program: React.FC = () => {
  const program = useSelector((state: IStoreTypes) => state.program);
  const [retrievingAllProgramData, setRetrievingAllProgramData] = React.useState<boolean>(true);
  const [step, setStep] = React.useState<number>();
  const dispatch = useDispatch();
  const hasAdhocPairing = program.subscription.includes(Subscriptions.ADHOC_PAIRING);

  // TODO: How do we want to manage this date?
  const controlStep = (): number => {
    // || !isAfter(parse(program?.formConfiguration?.[UpdatableFormConfiguration.APPLICATION_CLOSING_DATE], dateTimeExtendedFormat, new Date()), new Date());
    const currStep = program?.matchingComplete
      ? 4
      : !program.active && program?.menteeAmount > 0
      ? 3
      : program.active
      ? 2
      : !program.active
      ? 1
      : 1;
    // Because by default matchingComplete and launchedProgram is set to true with mentoring on demand
    if (hasAdhocPairing && step > 1) {
      return 1;
    } else {
      return currStep;
    }
  };

  React.useEffect(() => {
    // TODO: Get rid of this? What side effects are there?
    if (
      program.programIdData !== program.programId ||
      program?.menteeAmount === null ||
      program?.menteeAmount === undefined ||
      step === 1
    ) {
      dispatch(CleanPairingData());
      dispatch(CleanProgramStatusData());
      setRetrievingAllProgramData(true);
      BrancherDispatch(
        dispatch,
        UtilGetAllProgramData(() => {
          setRetrievingAllProgramData(false);
        }),
      );
    } else {
      setRetrievingAllProgramData(false);
    }
  }, []);

  React.useEffect(() => {
    if (
      !retrievingAllProgramData &&
      program.programIdData === program.programId &&
      !!program?.formConfiguration
    ) {
      setStep(controlStep());
    }
  }, [program?.active, program?.matchingComplete, program?.menteeAmount, retrievingAllProgramData]);

  return (
    <Page loading={retrievingAllProgramData}>
      <Grid container justifyContent="center">
        <Grid item xs={11} md={10} lg={9} container justifyContent="center">
          <Grid item xs={12}>
            <ProgramWizard step={step} hasAdhocPairing={hasAdhocPairing} />
          </Grid>
          <Grid item xs={12}>
            {step === 1 || hasAdhocPairing ? (
              <FormConfigurationLanding />
            ) : step === 2 ? (
              <TrackingFormSubmissions />
            ) : step === 3 ? (
              <MatchmakingLanding />
            ) : step === 4 ? (
              <LaunchProgram />
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};
