import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import { BrancherDispatch, UtilSetUserDeactivation } from '../../store/actions';
import {
  ActionNegativeOutlinedButton,
  ActionOutlinedButton,
} from '../../Components/InputFields/BrancherButton';
import { BrancherSnackbar } from '../../Components/General/BrancherSnackbar';
import { IStoreTypes } from '../../store/storeTypes';

interface IUserDeactivation {
  userId: string;
  name: string;
  deactivated: boolean;
  actionActivationCallback?: (active?: boolean) => void;
  disableDeactivation?: boolean;
  tableView?: boolean;
}

export const UserDeactivation: React.FC<IUserDeactivation> = (props) => {
  const { deactivated, userId, actionActivationCallback, disableDeactivation, tableView, name } =
    props;
  const programStyle = useSelector((state: IStoreTypes) => state.program.roleLabels.programStyle);
  const [settingUserDeactivation, setSettingUserDeactivation] = React.useState<boolean>(false);
  const [openDeactivationMessage, setOpenDeactivationMessage] = React.useState<boolean>(false);
  const [deactivationMessage, setDeactivationMessage] = React.useState<string>('');
  const dispatch = useDispatch();

  const controlUserDeactivation = (deactivate: boolean) => {
    setSettingUserDeactivation(true);
    BrancherDispatch(
      dispatch,
      UtilSetUserDeactivation(userId, deactivate, () => {
        actionActivationCallback(deactivate);
        setSettingUserDeactivation(false);
        if (deactivate) {
          const message = `${name}'s account is now inactive and they can't login`;
          setDeactivationMessage(message);
        } else {
          const message = `${name}'s account is now active and they can login`;
          setDeactivationMessage(message);
        }
        setOpenDeactivationMessage(true);
      }),
    );
  };

  return (
    <>
      <BrancherSnackbar
        controlClose={setOpenDeactivationMessage}
        open={openDeactivationMessage}
        message={deactivationMessage}
      />
      {tableView ? (
        <Grid container>
          {deactivated ? (
            <Grid item xs={3}>
              <Tooltip title={`Reactivate ${name}`}>
                <Box>
                  <ActionOutlinedButton
                    onClick={() => controlUserDeactivation(false)}
                    disabled={settingUserDeactivation}
                    size="small"
                  >
                    Reactivate
                  </ActionOutlinedButton>
                </Box>
              </Tooltip>
            </Grid>
          ) : (
            <Grid item xs={3}>
              <Tooltip title={`Deactivate ${name}`}>
                <Box>
                  <ActionNegativeOutlinedButton
                    onClick={() => controlUserDeactivation(true)}
                    disabled={settingUserDeactivation}
                    size="small"
                  >
                    Deactivate
                  </ActionNegativeOutlinedButton>
                </Box>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid container justifyContent="flex-end">
          {deactivated ? (
            <ActionOutlinedButton
              onClick={() => controlUserDeactivation(false)}
              loading={settingUserDeactivation}
            >
              Reactivate
            </ActionOutlinedButton>
          ) : (
            <Tooltip
              title={
                disableDeactivation
                  ? `${name} can't be deactivated until all of their ${programStyle} partners are unpaired`
                  : `Deactivate ${name}`
              }
            >
              <Box>
                <ActionNegativeOutlinedButton
                  onClick={() => controlUserDeactivation(true)}
                  disabled={disableDeactivation}
                  loading={settingUserDeactivation}
                >
                  Deactivate
                </ActionNegativeOutlinedButton>
              </Box>
            </Tooltip>
          )}
        </Grid>
      )}
    </>
  );
};
