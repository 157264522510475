import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { Text } from '../../Components/General/Text';
import { Colors } from '../../consts/colors';

interface IProgramWizard {
  step: number;
  hasAdhocPairing?: boolean;
}

const useStyles = makeStyles({
  wizard: {
    boxShadow: `0px 5px 15px ${Colors.cardBorder}`,
    borderRadius: `15px 15px 0px 0px`,
    maxHeight: 120,
    height: 120,
    marginTop: 40,
    marginBottom: 40,
  },
  selectedStep: {
    maxHeight: 120,
    height: 120,
    backgroundColor: Colors.cardSelected,
    borderBottom: `2px solid ${Colors.purple}`,
  },
  lastStep: {
    borderRadius: '0px 15px 0px 0px',
  },
  firstStep: {
    borderRadius: (props: { hasAdhocPairing: boolean }) =>
      props?.hasAdhocPairing ? '15px 15px 0px 0px' : '15px 0px 0px 0px',
  },
});

export const ProgramWizard: React.FC<IProgramWizard> = (props) => {
  const { step, hasAdhocPairing = false } = props;
  const styles = useStyles({ hasAdhocPairing });

  return (
    <Grid container className={styles.wizard} alignItems="center">
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        xs={hasAdhocPairing ? 12 : 3}
        className={step === 1 ? cx(styles.selectedStep, styles.firstStep) : ''}
      >
        <WizardStep step={1} selected={step === 1} alreadyCompleted={step > 1}>
          Form Configuration
        </WizardStep>
      </Grid>

      {!hasAdhocPairing && (
        <>
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            xs={hasAdhocPairing ? 12 : 3}
            className={step === 2 ? styles.selectedStep : ''}
          >
            <WizardStep step={2} selected={step === 2} alreadyCompleted={step > 2}>
              Track Form Submissions
            </WizardStep>
          </Grid>

          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            xs={3}
            className={step === 3 ? styles.selectedStep : ''}
          >
            <WizardStep step={3} selected={step === 3} alreadyCompleted={step > 3}>
              Matchmaking
            </WizardStep>
          </Grid>

          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            xs={3}
            className={step === 4 ? cx(styles.selectedStep, styles.lastStep) : ''}
          >
            <WizardStep step={4} selected={step === 4} alreadyCompleted={step > 4}>
              Launch Program
            </WizardStep>
          </Grid>
        </>
      )}
    </Grid>
  );
};

interface IWizardStep {
  step: number;
  selected: boolean;
  alreadyCompleted: boolean;
}

const WizardStep: React.FC<IWizardStep> = (props) => {
  const { step, selected, alreadyCompleted, children } = props;
  return (
    <Grid item xs={11}>
      <Text
        variant="sm"
        fontWeight={700}
        marginLeft={10}
        color={selected ? 'purple' : alreadyCompleted ? 'secondaryGrey' : 'disabledGrey'}
      >
        {step}. {children}
      </Text>
      <Grid container justifyContent="flex-end">
        <Text
          variant="xs"
          color={selected ? 'purple' : alreadyCompleted ? 'green' : 'disabledGrey'}
          fontWeight={600}
          marginTop={8}
          marginRight={10}
        >
          {selected ? 'In Progress' : alreadyCompleted ? 'Completed' : 'Pending'}
        </Text>
      </Grid>
    </Grid>
  );
};
