import * as React from 'react';
import Workbook from 'react-excel-workbook';
import { BrancherButton } from '../../Components/InputFields/BrancherButton';

interface IGenerateMasterPairingSatisfactionReport {
  data: any;
  displayData: any;
}

export const GenerateMasterPairingSatisfactionReport: React.FC<IGenerateMasterPairingSatisfactionReport> =
  ({ data, displayData }) => (
    <Workbook
      filename={`Master pairing satisfaction report.xlsx`}
      element={
        <BrancherButton color="primary" variant="text" size="small">
          Download master pairing satisfaction report!
        </BrancherButton>
      }
    >
      <Workbook.Sheet data={data} name="Master-Pairing-Satisfaction">
        {displayData.map((d, l) => (
          <Workbook.Column label={d.readableName} value={d.attributeName} key={l} />
        ))}
      </Workbook.Sheet>
    </Workbook>
  );
