import { SaveUserInfo } from '../UserInfoActions';
import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { IStoreTypes } from '../../storeTypes';
import { apiKey } from '../configs/keyConfs';

export const BrancherDispatch = (preDispatch, apiCall) => {
  preDispatch((dispatch, getState) => {
    const expired = getState().user.tokenExp < new Date().getTime() / 1000;
    if (expired) {
      dispatch(SaveUserInfo({ expiredToken: true, redirected: true }));
    } else {
      dispatch(apiCall);
    }
  });
};

export const BrancherAuthRequest = (
  axiosData: AxiosRequestConfig,
  state: IStoreTypes,
): AxiosPromise => {
  const location = window.location.hostname.split('.')[0];
  const urlBase =
    location === 'localhost' ? 'http://localhost:3000' : 'https://api.brancher.com.au';
  const token = state.user.IDToken;
  return axios({
    ...axiosData,
    url: `${urlBase}/${axiosData.url}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const BrancherAPIKeyRequest = (axiosData: AxiosRequestConfig): AxiosPromise => {
  const location = window.location.hostname.split('.')[0];
  const urlBase =
    location === 'localhost' ? 'http://localhost:3000' : 'https://api.brancher.com.au';
  return axios({
    ...axiosData,
    url: `${urlBase}/${axiosData.url}`,
    headers: {
      'X-Api-Key': apiKey,
      'Content-Type': 'application/json',
    },
  });
};

export const isLocalEnvironment = (): boolean => {
  return window.location.href.indexOf('localhost:8080') !== -1;
};
