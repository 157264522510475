import { BrancherAuthRequest } from './utils/BrancherDispatch';
import {
  ESurveyStatus,
  EUserActions,
  ISurveyConfiguration,
  ISurveysInfo,
  SAVE_ALL_SURVEYS_DATA,
  SurveyConfigurationQuestion,
} from '../reducers/SurveysReducer';
import { IReportColumn } from '../reducers/ReportReducer';
import { ProgramPositions } from '../../consts/ProgramPositionOptions';
import { IAPIResponseObject } from './actionTypes/apiTypes';

export const SaveSurveysData = (surveyData: ISurveysInfo['surveys']) => {
  return {
    type: SAVE_ALL_SURVEYS_DATA,
    payload: surveyData,
  };
};

// This creates a new survey
export const UtilCreateSurvey = (
  surveyTitle: string,
  surveyDescription: string,
  duplicateSurveyId: string,
  surveyDueDate: string,
  surveyType: EUserActions,
  surveySegments: ProgramPositions[],
  cb: (a: any) => void,
) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'v2/surveyconfiguration',
        data: {
          programId,
          duplicateSurveyId,
          surveyTitle,
          surveyDescription,
          surveyDueDate,
          surveyType,
          surveySegments,
        },
      },
      getState(),
    ).then((response: { data: { data: ISurveyConfiguration[] } }) => {
      dispatch(SaveSurveysData([...getState().surveys.surveys, ...response.data.data]));
      cb(response.data.data);
    });
  };
};

// This deletes an un-launched survey
export const UtilDeleteSurvey = (surveyId: string, cb: (a: any) => void) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'delete',
        url: 'v2/survey',
        data: {
          surveyId,
          programId,
        },
      },
      getState(),
    ).then((response: { data: { data: ISurveyConfiguration[] } }) => {
      dispatch(SaveSurveysData(getState().surveys.surveys.filter((s) => s.surveyId !== surveyId)));
      cb(response.data.data);
    });
  };
};

// This sends the survey to user segments and updates the survey status to ACTIVE
export const UtilSendSurveyToSegments = (
  segments: ProgramPositions[],
  surveyId: string,
  emailUsers: boolean,
  cb: (a: any) => void,
) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'v2/sendsurvey',
        data: {
          surveyId,
          programId,
          segments,
          emailUsers,
        },
      },
      getState(),
    ).then((response: any) => {
      const newSurveyList = getState().surveys.surveys.map((s) => {
        if (s.surveyId === surveyId) {
          return { ...s, surveyStatus: ESurveyStatus.ACTIVE };
        }
        return s;
      });
      dispatch(SaveSurveysData(newSurveyList));
      cb(response.data.data);
    });
  };
};

// This sends the survey to all users who haven't completed it
export const UtilSendSurveyReminder = (
  surveyId: string,
  finalReminder: boolean,
  cb: (a: any) => void,
) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'v2/sendsurveyreminder',
        data: {
          surveyId,
          programId,
          finalReminder,
        },
      },
      getState(),
    ).then((response: any) => {
      const newSurveyList = getState().surveys.surveys.map((s) => {
        if (s.surveyId === surveyId) {
          return { ...s, surveyStatus: ESurveyStatus.ACTIVE };
        }
        return s;
      });
      dispatch(SaveSurveysData(newSurveyList));
      cb(response.data.data);
    });
  };
};

// This gets all the surveys from the selected program
export const UtilGetAllProgramSurveys = (cb: (a: any) => void) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/programsurveys',
        params: JSON.stringify({
          programId,
        }),
      },
      getState(),
    ).then((response: { data: { data: ISurveyConfiguration[] } }) => {
      dispatch(SaveSurveysData(response.data.data));
      cb(response.data.data);
    });
  };
};

// This gets the survey report given the surveyId
export const UtilGetSurveyReport = (
  surveyId: string,
  cb: (a: {
    surveyReportColumns: IReportColumn[];
    surveyReportData: any[];
    surveyNoResponsesColumns: IReportColumn[];
    surveyNoResponsesData: any[];
  }) => void,
) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/surveyreport',
        params: JSON.stringify({
          surveyId,
          programId,
        }),
      },
      getState(),
    ).then(
      (response: {
        data: {
          data: {
            surveyReportColumns: IReportColumn[];
            surveyReportData: any[];
            surveyNoResponsesColumns: IReportColumn[];
            surveyNoResponsesData: any[];
          };
        };
      }) => {
        cb(response.data.data);
      },
    );
  };
};

interface IGetSurveyConfiguration extends IAPIResponseObject {
  data: {
    configuration: any[];
  };
}

export const UtilGetSurveyConfiguration = (
  surveyId: string,
  cb: (a: IGetSurveyConfiguration) => void,
) => {
  return (dispatch: any, getState: any) => {
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/surveyconfiguration',
        params: JSON.stringify({
          surveyId,
        }),
      },
      getState(),
    )
      .then((response: any) => {
        cb(response.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

export const UtilUpdateSurveyDate = (
  surveyId: string,
  dates: {
    surveyInitialReminderDate: string;
    surveyFinalReminderDate: string;
    surveyDueDate: string;
  },
  cb: (a: any) => void,
) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'put',
        url: 'v2/surveydates',
        data: {
          surveyId,
          programId,
          ...dates,
        },
      },
      getState(),
    ).then((response: any) => {
      const newSurveyList = getState().surveys.surveys.map((s) => {
        if (s.surveyId === surveyId) {
          return { ...s, ...dates };
        }
        return s;
      });
      dispatch(SaveSurveysData(newSurveyList));
      cb(response.data.data);
    });
  };
};

export const UtilUpdateSurveyConfiguration = (
  surveyId: string,
  configuration: SurveyConfigurationQuestion[],
  cb: (a: any) => void,
) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'put',
        url: 'v2/surveyconfiguration',
        data: {
          surveyId,
          programId,
          configuration,
        },
      },
      getState(),
    ).then((response: any) => {
      const newSurveyList = getState().surveys.surveys.map((s) => {
        if (s.surveyId === surveyId) {
          return { ...s, configuration };
        }
        return s;
      });
      dispatch(SaveSurveysData(newSurveyList));
      cb(response.data.data);
    });
  };
};

export const UtilUpdateSurveyTitle = (
  surveyId: string,
  surveyTitle: string,
  surveyDescription: string,
  cb: (a: any) => void,
) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'put',
        url: 'v2/surveytitle',
        data: {
          surveyId,
          programId,
          surveyTitle,
          surveyDescription,
        },
      },
      getState(),
    ).then((response: any) => {
      const newSurveyList = getState().surveys.surveys.map((s) => {
        if (s.surveyId === surveyId) {
          return { ...s, surveyTitle, surveyDescription };
        } else {
          return s;
        }
      });
      dispatch(SaveSurveysData(newSurveyList));
      cb(response.data.data);
    });
  };
};

export const UtilUpdateSurveyStatus = (
  surveyId: string,
  surveyStatus: ESurveyStatus,
  cb: (a: any) => void,
) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().program.programId;
    BrancherAuthRequest(
      {
        method: 'put',
        url: 'v2/surveystatus',
        data: {
          surveyId,
          programId,
          surveyStatus,
        },
      },
      getState(),
    ).then((response: any) => {
      const newSurveyList = getState().surveys.surveys.map((s) => {
        if (s.surveyId === surveyId) {
          return { ...s, surveyStatus };
        }
        return s;
      });
      dispatch(SaveSurveysData(newSurveyList));
      cb(response.data.data);
    });
  };
};
