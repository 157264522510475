import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { Page } from '../../Components/General/Page';
import { BrancherDispatch, UtilGetDashboardTrainingData } from '../../store/actions';
import { IStoreTypes } from '../../store/storeTypes';
import { Text } from '../../Components/General/Text';
import { TrainingCompletionHealth } from './TrainingCompletionHealth';

export const TrainingDashboard = () => {
  const [gettingProgramTrainingHealth, setGettingProgramTrainingHealth] =
    React.useState<boolean>(false);
  const trainingCompletions = useSelector(
    (state: IStoreTypes) => state.dashboard?.trainingCompletions,
  );
  const totalTrainingCompletions = useSelector(
    (state: IStoreTypes) => state.dashboard?.totalTrainingCompletions,
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    setGettingProgramTrainingHealth(true);
    BrancherDispatch(
      dispatch,
      UtilGetDashboardTrainingData(() => {
        setGettingProgramTrainingHealth(false);
      }),
    );
  }, []);

  return (
    <Page loading={gettingProgramTrainingHealth}>
      <Grid container item xs={12} justifyContent="center">
        <Grid item xs={11}>
          <Text variant="lg" marginBottom={40} marginTop={40} color="purple" fontWeight={700}>
            Training
          </Text>
        </Grid>
        <Grid item xs={11} container justifyContent="center" spacing={2}>
          <TrainingCompletionHealth
            trainingCompletions={trainingCompletions}
            totalTrainingCompletions={totalTrainingCompletions}
          />
        </Grid>
      </Grid>
    </Page>
  );
};
