import { BrancherPlatformType, ProgramPositions } from '../consts/ProgramPositionOptions';
import { ETrainingModules } from '../store/reducers/DashboardReducer';

export enum EProfileAttributes {
  IMAGE = 'image',
  PHONE_NUMBER = 'phoneNumber',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  JOB_TITLE = 'jobTitle',
  ORGANISATION = 'organisation',
  LOCATION = 'location',
  CONTACT_EMAIL = 'contactEmail',
  LINKEDIN = 'linkedIn',
  CAREER_STORY = 'careerStory',
  PREFERRED_MEETING_TIMES = 'preferredMeetingTimes',
  PUBLIC_PROFILE = 'publicProfile',
  PUBLIC_PROFILE_RAW = 'publicProfileRaw',
  MAXIMUM_PARTNER_AMOUNT = 'maximumPartnerAmount',
}

export enum EProgressActivities {
  HAS_COMPLETED_PROFILE = 'hasCompletedProfile',
  HAS_UPLOADED_PROFILE_PIC = 'hasUploadedProfilePic',
  HAS_UPDATED_PROFILE = 'hasUpdatedProfile',
  HAS_CREATED_GOAL = 'hasCreatedGoal',
  HAS_UPDATED_GOAL = 'hasUpdatedGoal',
  HAS_COMPLETED_GOAL = 'hasCompletedGoal',
  HAS_PARTICIPATED_IN_MEETING = 'hasParticipatedInMeeting',
  HAS_CREATED_MEETING = 'hasCreatedMeeting',
  HAS_COMPLETED_TRAINING = 'hasCompletedTraining',
  HAS_CREATED_PRIVATE_NOTE = 'hasCreatedPrivateNote',
  HAS_CREATED_SHARED_NOTE = 'hasCreatedSharedNote',
  HAS_EXPLORED_COHORT = 'hasExploredCohort',
  HAS_CONNECTED_CALENDAR = 'hasConnectedCalendar',
  HAS_LOGGED_IN = 'hasLoggedIn',
  // partner oriented
  HAS_MENTORING_PARTNER = 'hasMentoringPartner',
  HAS_COMPLETED_MENTORING_AGREEMENT = 'hasCompletedMentoringAgreement',
  HAS_MET_MENTORING_PARTNER = 'hasMetMentoringPartner',
  HAS_NEXT_MEETING_SCHEDULED = 'hasNextMeetingScheduled',
  HAS_COMPLETED_SURVEY = 'hasCompletedSurvey',
  HAS_WRAPPED_UP_RELATIONSHIP = 'hasWrappedUpRelationship',
}

enum ECalendars {
  AZURE = 'azure',
  GOOGLE = 'google',
  BRANCHER = 'brancher',
}

export interface IRelatedActivityAttributes {
  partnerRoleId?: string;
  meetingId?: string;
  meetingStart?: number;
  goalId?: string;
  trainingModule?: ETrainingModules;
  calendarType?: ECalendars;
  platform?: BrancherPlatformType;
}

export interface IProgressActivity extends IRelatedActivityAttributes {
  completedTime: number; // epoch time
  programId?: string;
}

export interface IBaseProfileData {
  [EProfileAttributes.IMAGE]?: string;
  [EProfileAttributes.PHONE_NUMBER]?: string;
  [EProfileAttributes.FIRST_NAME]?: string;
  [EProfileAttributes.LAST_NAME]?: string;
  [EProfileAttributes.JOB_TITLE]?: string;
  [EProfileAttributes.ORGANISATION]?: string;
  [EProfileAttributes.LOCATION]?: string;
  [EProfileAttributes.CONTACT_EMAIL]?: string;
  [EProfileAttributes.LINKEDIN]?: string;
  [EProfileAttributes.CAREER_STORY]?: string;
  [EProfileAttributes.PREFERRED_MEETING_TIMES]?: string;
  [EProfileAttributes.PUBLIC_PROFILE]?: boolean;
  [EProfileAttributes.PUBLIC_PROFILE_RAW]?: boolean;
  [EProfileAttributes.MAXIMUM_PARTNER_AMOUNT]?: { [programId: string]: number };
}

export interface IProfileData extends IBaseProfileData {
  completedProfile?: boolean;
  roleId?: string;
  newProfilePic?: string;
  profileType?: ProgramPositions;
  activityScore?: number;
  progressActivity?: { [attr in EProgressActivities]?: IProgressActivity[] };
}
