import { Colors } from '../consts/colors';
import { IBrancherThemePalette } from './BrancherTheme';

export const MuiCheckbox = (theme: IBrancherThemePalette) => ({
  root: {
    color: Colors.lightGrey,
    '& .Mui-checked': {
      '& .MuiSvgIcon-root': {
        color: theme.palette.primary.main,
      },
    },
  },
});
