import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Check from '@material-ui/icons/Check';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import LinearScale from '@material-ui/icons/LinearScale';
import List from '@material-ui/icons/List';
import { makeStyles } from '@material-ui/core/styles';
import { BrancherTile, IBrancherTile } from './BrancherTile';
import { EComponentType, IGeneralQuestionsConfig } from '../../store/reducers/ProgramReducer';
import { Text } from './Text';
import { ActionNegativeTextButton } from '../InputFields/BrancherButton';
import { Colors } from '../../consts/colors';

const useStyles = makeStyles((theme) => ({
  tile: {
    border: (props: { selected: boolean }) =>
      props.selected ? `2px solid ${theme.palette.primary.main}` : 'none',
    width: '100%',
  },
  popover: {
    '& .MuiPopover-paper': {
      border: `2px solid ${theme.palette.primary.main}`,
      marginTop: 20,
      borderRadius: 5,
      padding: 5,
      height: 'fit-content',
      width: 'fit-content',
    },
  },
}));

export interface IQuestionTile extends IBrancherTile {
  questionIndex: number;
  question?: IGeneralQuestionsConfig;
  deleteQuestion?: () => void;
  selected?: boolean;
  hoverCursor?: boolean;
  brancherOwned?: boolean;
}

export const QuestionTile: React.FC<IQuestionTile> = (props) => {
  const {
    hoverCursor,
    children,
    question,
    selected,
    questionIndex,
    deleteQuestion,
    brancherOwned,
    ...other
  } = props;
  const [open, setOpen] = React.useState<boolean>(false);
  const [validatorAnchor, setValidatorAnchor] = React.useState(null);
  const styles = useStyles({ selected });

  let visibleTo: string[] = [];
  if (question.isMentor) {
    visibleTo.push('Mentors');
  }
  if (question.isMentee) {
    visibleTo.push('Mentees');
  }

  const controlValidator = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setOpen(true);
    if (!Boolean(validatorAnchor)) {
      setValidatorAnchor(event.currentTarget);
      event.currentTarget.focus();
    }
    event.stopPropagation();
    event.preventDefault();
  };

  const closeValidator = () => {
    if (Boolean(validatorAnchor)) {
      setOpen(false);
      setValidatorAnchor(null);
    }
  };

  return (
    <BrancherTile
      hoverCursor={brancherOwned ? hoverCursor : false}
      className={styles.tile}
      onClick={closeValidator}
      {...other}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={2} container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Text variant="sm" color="purple" fontWeight={600}>
              {questionIndex + 1}
            </Text>
          </Grid>
          <Grid item>
            <Box
              p="5px 10px"
              width="fit-content"
              borderRadius={5}
              bgcolor={Colors.purple}
              display="flex"
              alignItems="center"
            >
              {question.componentType === EComponentType.TOGGLE ? (
                <Check color="secondary" fontSize="small" />
              ) : question.componentType === EComponentType.TEXT ? (
                <Edit color="secondary" fontSize="small" />
              ) : question.componentType === EComponentType.SELECTION ? (
                <List color="secondary" fontSize="small" />
              ) : (
                question.componentType === EComponentType.SLIDER && (
                  <LinearScale color="secondary" fontSize="small" />
                )
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={9} container>
          <Grid item xs={10}>
            <Text variant="sm" fontWeight={700}>
              {question.question?.mentee ?? question.question?.mentor ?? question.question}
            </Text>
            {brancherOwned && (
              <Text variant="xxs" fontWeight={500} marginTop={10} color="secondaryGrey">
                This question is mandatory and cannot be changed.
              </Text>
            )}
          </Grid>
          {!brancherOwned && (
            <Grid item xs={2} container justifyContent="flex-end">
              <Box mt={-1}>
                <IconButton size="small" onClick={controlValidator}>
                  <Delete color="error" />
                </IconButton>
                <Popover
                  id="tile-actions"
                  open={open}
                  anchorEl={validatorAnchor}
                  disableScrollLock={true}
                  anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  className={styles.popover}
                  disableEnforceFocus
                  disableAutoFocus
                >
                  <Grid container justifyContent="center">
                    <ActionNegativeTextButton size="small" onClick={deleteQuestion}>
                      Delete Question
                    </ActionNegativeTextButton>
                  </Grid>
                </Popover>
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
      {visibleTo?.length > 0 && (
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Text variant="xs" color="iconTextGrey" marginTop={15}>
              {visibleTo.join(', ')}
            </Text>
          </Grid>
        </Grid>
      )}
    </BrancherTile>
  );
};
