import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CompletedProfilesHealth } from './CompletedProfilesHealth';
import { MentoringAgreementHealth } from './MentoringAgreementHealth';
import { GoalsHealth } from './GoalsHealth';
import { SharedNotesHealth } from './SharedNotesHealth';
import { PrivateNotesHealth } from './PrivateNotesHealth';
import { IStoreTypes } from '../../store/storeTypes';
import { BrancherDispatch, UtilGetDashboardProgressActivitiesData } from '../../store/actions';
import { MentoringRequestsHealth } from './MentoringRequestsHealth';
import { Text } from '../../Components/General/Text';

interface IProgressActivitiesHealth {
  filters: { [x in string]: any };
}

export const ProgressActivitiesHealth: React.FC<IProgressActivitiesHealth> = ({ filters }) => {
  const [retrievingDashboardData, setRetrievingDashboardData] = React.useState<boolean>(false);
  const progressActivities = useSelector(
    (state: IStoreTypes) => state.dashboard?.progressActivities,
  );
  const completedProfiles = useSelector((state: IStoreTypes) => state.dashboard?.completedProfiles);
  const mentoringRequests = useSelector(
    (state: IStoreTypes) => state.dashboard?.activeUsers?.mentoringRequests,
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    setRetrievingDashboardData(true);
    BrancherDispatch(
      dispatch,
      UtilGetDashboardProgressActivitiesData(filters, () => {
        setRetrievingDashboardData(false);
      }),
    );
  }, []);

  return !retrievingDashboardData && progressActivities?.goals ? (
    <>
      <Grid item container xs={12}>
        <Text variant="lg" color="purple" fontWeight={700}>
          All Previous Activity
        </Text>
      </Grid>
      <Grid item xs={6} md={4}>
        <CompletedProfilesHealth completedProfiles={completedProfiles} />
      </Grid>
      <Grid item xs={6} md={4}>
        <GoalsHealth goals={progressActivities?.goals} />
      </Grid>
      <Grid item xs={6} md={4}>
        <PrivateNotesHealth privateNotes={progressActivities?.privateNotes} />
      </Grid>
      {mentoringRequests && (
        <Grid item xs={6} md={4}>
          <MentoringRequestsHealth mentoringRequests={mentoringRequests} />
        </Grid>
      )}
      <Grid item xs={6} md={4}>
        <MentoringAgreementHealth
          mentoringAgreement={{
            completedMentoringAgreements:
              progressActivities?.sharedNotes?.completedMentoringAgreements,
            completedMentoringAgreementsPercentage:
              progressActivities?.sharedNotes?.completedMentoringAgreementsPercentage,
          }}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <SharedNotesHealth
          sharedNotes={{
            all: progressActivities?.sharedNotes?.all,
            allPercentage: progressActivities?.sharedNotes?.allPercentage,
          }}
        />
      </Grid>
    </>
  ) : (
    <Grid container item xs={12} justifyContent="center" alignItems="center">
      <CircularProgress color="secondary" size={64} />
    </Grid>
  );
};
