import { ToggleOptions } from '../../Components/InputFields/ToggleButton/BrancherToggleButton.types';

export const MakeOptions = (opts: string[]): ToggleOptions[] => {
  return opts.map((o, i) => {
    return {
      value: `${i}`,
      label: o,
    };
  });
};
