import * as React from 'react';
import { Home } from '../../Pages/Home/Home';
import { SplashScreen } from '../../Pages/SplashScreen/SplashScreen';
import { SignUp } from '../../Pages/SignUp/SignUp';
import { Login } from '../../Pages/Login/Login';
import { ForgotPassword } from '../../Pages/ForgotPassword/ForgotPassword';
import { ConfirmRegistration } from '../../Pages/ConfirmRegistration/ConfirmRegistration';
import { Program } from '../../Pages/Program/Program';
import { VerifyForgotPassword } from '../../Pages/ForgotPassword/VerifyForgotPassword';
import { Dashboard } from '../../Pages/Dashboard/Dashboard';
import { SuperUser } from '../../Pages/SuperUser/SuperUser';
import { ProgramUsers } from '../../Pages/ProgramUsers/ProgramUsers';
import { User } from '../../Pages/User/User';
import { Timeline } from '../../Pages/Timeline/Timeline';
import { Settings } from '../../Pages/Settings/Settings';
import { SupportRequests } from '../../Pages/SupportRequests/SupportRequests';
import { MentoringRequests } from '../../Pages/MentoringRequests/MentoringRequests';
import { Surveys } from '../../Pages/Surveys/Surveys';
import { Survey } from '../../Pages/Surveys/Survey';
import { TrainingDashboard } from '../../Pages/Dashboard/TrainingDashboard';
import { Matching } from '../../Pages/Matching/Matching';
import { Leaderboard } from '../../Pages/Leaderboard/Leaderboard';
import { Groups } from '../../Pages/Groups/Groups';
import { Group } from '../../Pages/Groups/Group';
import { Pairs } from '../../Pages/Pairs/Pairs';
import { Pair } from '../../Pages/Pair/Pair';
import { SuperUserDashboard } from '../../Pages/SuperUser/SuperUserDashboard';
import { Resources } from '../../Pages/Resources/Resources';

export interface IRouteWithSubRoutesProps {
  path: string;
  component: React.ComponentType<any>;
  name: string;
  title?: string;
  paramsPath?: string;
  routes?: string;
  public?: boolean;
  exact?: boolean;
}

export const GetRoute = (routeName: string) => {
  return routeConfig.find((r) => r.name === routeName);
};

export const routeConfig: IRouteWithSubRoutesProps[] = [
  {
    path: '/',
    component: SplashScreen,
    exact: true,
    name: 'splash',
  },
  {
    path: '/login',
    component: Login,
    exact: true,
    name: 'login',
  },
  {
    path: '/signup',
    component: SignUp,
    exact: false,
    name: 'signup',
  },
  {
    path: '/forgotPassword',
    component: ForgotPassword,
    exact: true,
    name: 'forgotPassword',
  },
  {
    path: '/verifyForgotPassword',
    component: VerifyForgotPassword,
    exact: true,
    name: 'verifyForgotPassword',
  },
  {
    path: '/confirmRegistration',
    component: ConfirmRegistration,
    exact: true,
    name: 'confirmRegistration',
  },
  {
    path: '/home',
    component: Home,
    exact: true,
    name: 'home',
  },
  {
    path: '/programUsers',
    component: ProgramUsers,
    exact: true,
    name: 'programUsers',
    title: 'Program users - Brancher',
  },
  {
    path: '/program',
    component: Program,
    exact: true,
    name: 'program',
    title: 'Program - Brancher',
  },
  {
    path: '/dashboard',
    component: Dashboard,
    exact: true,
    name: 'dashboard',
    title: 'Dashboard - Brancher',
  },
  {
    path: '/leaderboard',
    component: Leaderboard,
    exact: true,
    name: 'leaderboard',
    title: 'Leaderboard - Brancher',
  },
  {
    path: '/groups',
    component: Groups,
    exact: true,
    name: 'groups',
    title: 'Groups - Brancher',
  },
  {
    path: '/group',
    component: Group,
    exact: true,
    name: 'group',
    title: 'Group - Brancher',
  },
  {
    path: '/trainingDashboard',
    component: TrainingDashboard,
    exact: true,
    name: 'trainingDashboard',
    title: 'Training Dashboard - Brancher',
  },
  {
    path: '/superUserDashboard',
    component: SuperUserDashboard,
    exact: true,
    name: 'superUserDashboard',
    title: 'Super User Dashboard - Brancher',
  },
  {
    path: '/superUser',
    component: SuperUser,
    exact: true,
    name: 'superUser',
    title: 'Super User - Brancher',
  },
  {
    path: '/user',
    component: User,
    exact: true,
    name: 'user',
    title: 'View User - Brancher',
  },
  {
    path: '/pairs',
    component: Pairs,
    exact: true,
    name: 'pairs',
    title: 'View Pairs - Brancher',
  },
  {
    path: '/pair',
    component: Pair,
    exact: true,
    name: 'pair',
    title: 'View Pair - Brancher',
  },
  {
    path: '/matching',
    component: Matching,
    exact: true,
    name: 'matching',
    title: 'Pair Users - Brancher',
  },
  {
    path: '/timeline',
    component: Timeline,
    exact: true,
    name: 'timeline',
    title: 'View timeline - Brancher',
  },
  {
    path: '/settings',
    component: Settings,
    exact: true,
    name: 'settings',
    title: 'Program Settings - Brancher',
  },
  {
    path: '/supportRequests',
    component: SupportRequests,
    exact: true,
    name: 'supportRequests',
    title: 'Support Requests - Brancher',
  },
  {
    path: '/mentoringRequests',
    component: MentoringRequests,
    exact: true,
    name: 'mentoringRequests',
    title: 'Mentoring Requests - Brancher',
  },
  {
    path: '/surveys',
    component: Surveys,
    exact: true,
    name: 'surveys',
    title: 'Surveys - Brancher',
  },
  {
    path: '/survey',
    component: Survey,
    exact: true,
    name: 'survey',
    title: 'Survey - Brancher',
  },
  {
    path: '/resources',
    component: Resources,
    exact: true,
    name: 'resources',
    title: 'Resources - Brancher',
  },
];

const getRoute = (routeName: string): IRouteWithSubRoutesProps =>
  routeConfig.find((r) => r.name === routeName);

export const RouteMaster = {
  home: getRoute('home'),
  login: getRoute('login'),
  signup: getRoute('signup'),
  settings: getRoute('settings'),
  supportRequests: getRoute('supportRequests'),
  matching: getRoute('matching'),
  pairs: getRoute('pairs'),
  pair: getRoute('pair'),
  groups: getRoute('groups'),
  group: getRoute('group'),
  mentoringRequests: getRoute('mentoringRequests'),
  forgotPassword: getRoute('forgotPassword'),
  verifyForgotPassword: getRoute('verifyForgotPassword'),
  confirmRegistration: getRoute('confirmRegistration'),
  splash: getRoute('splash'),
  program: getRoute('program'),
  programUsers: getRoute('programUsers'),
  dashboard: getRoute('dashboard'),
  leaderboard: getRoute('leaderboard'),
  trainingDashboard: getRoute('trainingDashboard'),
  superUser: getRoute('superUser'),
  superUserDashboard: getRoute('superUserDashboard'),
  user: getRoute('user'),
  timeline: getRoute('timeline'),
  surveys: getRoute('surveys'),
  survey: getRoute('survey'),
  resources: getRoute('resources'),
};
