import { IReducerType } from './ReducerType';
import { AllPositions } from '../../consts/ProgramPositionOptions';
import { PlatformTypes } from '../../types/PlatformTypes';
import { IValidationRules } from '../../utils/validators/validatePassword';
import { Subscriptions } from './ProgramReducer';

export const SAVE_USER_INFO = 'SAVE_USER_INFO';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_REDIRECT = 'USER_REDIRECT';

export const UserInfoReducer = (state = initialState, action: IReducerType) => {
  switch (action.type) {
    case SAVE_USER_INFO:
      return { ...state, ...action.payload };
    case USER_LOGOUT:
      return { ...state, loggedIn: false, tokenExp: 0 };
    case USER_REDIRECT:
      return { ...state, redirected: action.payload };
    default:
      return state;
  }
};

export interface IDecodedUserTokenAttributes {
  id?: string;
  email?: string;
  username?: string;
  phoneNumber?: string;
  tokenExp?: number;
  IDToken?: string;
}

export interface IUserGroups {
  roleId: string;
  programId: string;
  groupId: string;
}

export enum EApplicationStatus {
  APPROVED = 'Approved',
  PENDING = 'Pending',
  DECLINED = 'Declined',
  NA = 'NA',
}

export interface ICompanyInfo {
  companyId?: string;
  companyName?: string;
  programIds?: string[];
  googleSignOn?: boolean;
  samlSignOn?: boolean;
  passwordSignOn?: boolean;
  whiteLabel?: boolean;
  customLogo?: string;
  domainEmail?: string[];
}

export type ISubscriptions =
  | Subscriptions.TRAINING
  | Subscriptions.FORM
  | Subscriptions.PROFILE
  | Subscriptions.PRIVATE_NOTES
  | Subscriptions.SHARED_NOTES
  | Subscriptions.MEETINGS
  | Subscriptions.ADHOC_PAIRING
  | Subscriptions.COHORT;

export interface IUserInfo extends IDecodedUserTokenAttributes, ICompanyInfo {
  accessToken?: string;
  expiredToken?: boolean;
  samlSignOn?: boolean;
  forgotPasswordEmail?: string;
  firstName?: string;
  lastName?: string;
  dob?: string;
  menteeRoleId?: string;
  mentorRoleId?: string;
  peerRoleId?: string;
  loggedIn?: boolean;
  redirected?: boolean;
  formConfig?: object;
  notes?: string;
  notesUpdated?: number;
  selectedCompanyId?: string; // Only for BRANCHER_ADMIN
  positions?: AllPositions[];
  userId?: string;
  agreeToPolicies?: boolean;
  loggedInPlatform?: PlatformTypes;
  lastLoggedIn?: number;
  deactivated?: boolean;
  isSSO?: boolean;
  mentees?: string[];
  mentors?: string[];
  peers?: string[];
  subscription?: Subscriptions[];
  passwordPolicy?: IValidationRules;
}

const initialState: IUserInfo = {
  IDToken: '',
  expiredToken: false,
  tokenExp: 0,
  firstName: '',
  lastName: '',
  email: '',
  username: '',
  id: '',
  loggedIn: false,
  companyId: '',
  redirected: false,
};
