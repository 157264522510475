import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { BrancherDialog, IBrancherDialog } from './BrancherDialog';
import { AcceptButton, NotYetButton } from '../InputFields/BrancherButton';

interface IConfirmDialog extends IBrancherDialog {
  confirmAction: () => void;
  denyAction: () => void;
  denyButtonText?: string;
  confirmButtonText?: string;
  disableActions?: boolean;
  loading?: boolean;
}

export const ConfirmDialog: React.FC<IConfirmDialog> = (props) => {
  const {
    setClose,
    open,
    title,
    confirmAction,
    denyAction,
    labelledBy,
    disableActions,
    children,
    denyButtonText = 'No',
    confirmButtonText = 'Yes',
    loading = false,
  } = props;
  return (
    <BrancherDialog
      open={open}
      setClose={setClose}
      title={title}
      labelledBy={labelledBy}
      fitLargeScreen
    >
      <Grid container justifyContent="space-around">
        {children}
        <Grid container justifyContent="space-between" spacing={1}>
          <Grid item>
            <NotYetButton onClick={denyAction} disabled={disableActions} loading={loading}>
              {denyButtonText}
            </NotYetButton>
          </Grid>
          <Grid item>
            <AcceptButton onClick={confirmAction} disabled={disableActions} loading={loading}>
              {confirmButtonText}
            </AcceptButton>
          </Grid>
        </Grid>
      </Grid>
    </BrancherDialog>
  );
};
