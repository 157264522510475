import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { IMentoringAgreementsHealthData } from '../../store/reducers/DashboardReducer';
import { BrancherGridCard } from '../../Components/General/BrancherGridCard';
import { Text } from '../../Components/General/Text';

const useStyles = makeStyles({
  dashboardCard: {
    maxWidth: 400,
    padding: 25,
    minHeight: 'fit-content',
    height: 340,
    marginTop: 30,
  },
});

interface IMentoringAgreementHealth {
  mentoringAgreement: IMentoringAgreementsHealthData;
}

export const MentoringAgreementHealth: React.FC<IMentoringAgreementHealth> = (props) => {
  const { mentoringAgreement } = props;
  const styles = useStyles({});

  const completedMentoringAgreements = mentoringAgreement?.completedMentoringAgreements;
  const completedMentoringAgreementsPercentage =
    mentoringAgreement?.completedMentoringAgreementsPercentage;

  return (
    <Grid container>
      <BrancherGridCard
        container
        item
        direction="column"
        spacing={3}
        className={styles.dashboardCard}
        xs={11}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item container justifyContent="center">
          <Text variant="lg" align="center" color="purple" fontWeight={700}>
            {completedMentoringAgreements}{' '}
          </Text>
          <Text variant="md" align="center" fontWeight={500}>
            Completed Mentoring Agreements
          </Text>
        </Grid>
        <Grid item>
          <Text variant="xs" color="placeholderGrey" align="center">
            {completedMentoringAgreementsPercentage ?? '0'}% of pairs have completed their mentoring
            agreement
          </Text>
        </Grid>
      </BrancherGridCard>
    </Grid>
  );
};
