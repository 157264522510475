export const MuiStepper = () => ({
  root: {
    paddingLeft: 0,
    '& .MuiStepConnector-vertical': {
      padding: 0,
    },
    '& .MuiStepConnector-lineVertical': {
      border: 'none',
      backgroundImage: 'linear-gradient(black 33%, rgba(255,255,255,0) 0%)',
      backgroundPosition: 'left',
      backgroundSize: '1px 5px',
      backgroundRepeat: 'repeat-y',
    },
    '& .MuiStepContent-last': {
      border: 'none',
      backgroundImage: 'none',
    },
  },
});
