import { Colors } from '../consts/colors';

export const MuiFormHelperText = () => ({
  root: {
    textAlign: 'right',
    fontSize: 18,
    fontWeight: 700,
    '&$error': {
      color: Colors.red,
    },
  },
});
