import * as React from 'react';
import Workbook from 'react-excel-workbook';
import { BrancherButton } from '../../Components/InputFields/BrancherButton';
import { IReportColumn } from '../../store/reducers/ReportReducer';

interface IGenerateUserReport {
  data: any;
  displayData: IReportColumn[];
  programName: string;
}

export const GenerateUserReport: React.FC<IGenerateUserReport> = ({
  data,
  displayData,
  programName,
}) => (
  <Workbook
    filename={`${programName}_users_report.xlsx`}
    element={
      <BrancherButton color="primary" variant="text" size="small" id="download-users-report" />
    }
  >
    <Workbook.Sheet data={data} name="Users Report">
      {displayData.map((d, l) => (
        <Workbook.Column label={d.readableName} value={d.attributeName} key={l} />
      ))}
    </Workbook.Sheet>
  </Workbook>
);
