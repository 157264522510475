import * as React from 'react';
import FileCopy from '@material-ui/icons/FileCopy';
import { useSelector } from 'react-redux';
import { BrancherSnackbar } from '../../Components/General/BrancherSnackbar';
import { GetAdminLinkBase } from '../../helpers/LinkHelpers';
import { IStoreTypes } from '../../store/storeTypes';
import {
  ActionOutlinedButton,
  ActionTextButton,
  IBrancherButton,
} from '../../Components/InputFields/BrancherButton';

export const CopyAdminSignupLink: React.FC<IBrancherButton> = ({ variant }) => {
  const companyId = useSelector((state: IStoreTypes) => state.program.companyId);
  const [snackbarOpen, setSnackbar] = React.useState<boolean>(false);

  const signupUrl = `${GetAdminLinkBase()}signup?cId=${companyId}`;

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(signupUrl);
    setSnackbar(true);
  };

  const ButtonStyle = variant === 'text' ? ActionTextButton : ActionOutlinedButton;

  return (
    <>
      <BrancherSnackbar
        controlClose={setSnackbar}
        open={snackbarOpen}
        message="Signup link copied!"
      />
      <ButtonStyle size="small" onClick={copyToClipboard} startIcon={<FileCopy />}>
        Copy Admin signup link
      </ButtonStyle>
    </>
  );
};
