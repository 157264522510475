import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { NegativeButton } from '../InputFields/BrancherButton';
import { Text } from './Text';
import { mainNavWidth } from '../../consts/DimensionConsts';

interface IDialogStyles
  extends Pick<
    IBrancherDialog,
    'fitLargeScreen' | 'width' | 'contentWidth' | 'position' | 'noOverflow' | 'fullHeight'
  > {}

const useStyles = makeStyles({
  closeIcon: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  noOverflow: {
    '&&.MuiDialogContent-root': {
      overflowY: (props: IDialogStyles) => (props.noOverflow ? 'hidden' : 'auto'),
    },
  },
  paperWidthSm: {
    width: (props: IDialogStyles) =>
      props?.fitLargeScreen ? `calc(100% - ${mainNavWidth})` : props?.width ?? '100%',
    minHeight: (props: IDialogStyles) => (props?.fullHeight ? '100dvh' : 'inherit'),
    maxWidth: (props: IDialogStyles) => (props.contentWidth ? 800 : '100%'),
    position: (props: IDialogStyles) =>
      props?.position === 'bottom' || props?.position === 'top' || props.fitLargeScreen
        ? 'absolute'
        : 'relative',
    bottom: (props: IDialogStyles) => (props?.position === 'bottom' ? 10 : 'inherit'),
    top: (props: IDialogStyles) => (props?.position === 'top' ? 10 : 'inherit'),
    right: (props: IDialogStyles) => (props.fitLargeScreen ? 0 : 'inherit'),
  },
  paper: {
    marginRight: (props: IDialogStyles) => (props.fitLargeScreen ? 0 : 'inherit'),
  },
  scrollPaper: {
    justifyContent: (props: IDialogStyles) => (props.fitLargeScreen ? 'flex-end' : 'center'),
  },
});

export interface IBrancherDialog extends DialogProps {
  setClose: () => void;
  labelledBy: string;
  noOverflow?: boolean;
  contentWidth?: boolean;
  fullHeight?: boolean;
  fitLargeScreen?: boolean;
  width?: number | string;
  describedBy?: string;
  position?: 'bottom' | 'top';
}

export const BrancherDialog = (props: IBrancherDialog) => {
  const {
    setClose,
    open,
    children,
    title,
    labelledBy,
    noOverflow,
    contentWidth,
    describedBy,
    width,
    fullHeight,
    position,
    fitLargeScreen,
  } = props;
  const mobile = useMediaQuery(useTheme().breakpoints.down('xs'));
  const styles = useStyles({
    noOverflow,
    contentWidth,
    width,
    position,
    fitLargeScreen: fitLargeScreen && !mobile,
    fullHeight,
  });

  return (
    <Dialog
      open={open}
      onClose={setClose}
      fullScreen={mobile}
      aria-labelledby={labelledBy}
      aria-describedby={describedBy}
      classes={{
        paperWidthSm: styles.paperWidthSm,
        paper: styles.paper,
        scrollPaper: styles.scrollPaper,
      }}
    >
      <Box right={24} position="absolute" marginTop={2} className={styles.closeIcon}>
        <NegativeButton variant="text" onClick={setClose} aria-label="Close">
          Close
        </NegativeButton>
      </Box>
      {title && (
        <Grid container justifyContent="center">
          <Grid item xs={11}>
            <Text variant="md" id={labelledBy} fontWeight={600} marginTop={65} align="center">
              {title}
            </Text>
          </Grid>
        </Grid>
      )}
      <DialogContent className={styles.noOverflow} id={describedBy ?? ''}>
        {children}
      </DialogContent>
    </Dialog>
  );
};
