import { IReducerType } from './ReducerType';
import { ProgramPositions } from '../../consts/ProgramPositionOptions';
import { IDraftedPairs } from './PairingReducer';
import { IMeetingSatisfactionFrequencyHealth } from '../actions/DashboardActions';
import { IReportColumn } from './ReportReducer';

export const SAVE_DASHBOARD_DATA = 'SAVE_DASHBOARD_DATA';
export const SAVE_BASE_DASHBOARD_DATA = 'SAVE_BASE_DASHBOARD_DATA';
export const SAVE_DASHBOARD_TRAINING_DATA = 'SAVE_DASHBOARD_TRAINING_DATA';
export const CLEAN_DASHBOARD_DATA = 'CLEAN_DASHBOARD_DATA';

export const DashboardReducer = (state = initialState, action: IReducerType) => {
  switch (action.type) {
    case SAVE_DASHBOARD_DATA:
      return { ...state, ...action.payload };
    case SAVE_BASE_DASHBOARD_DATA:
      return { ...state, ...action.payload };
    case SAVE_DASHBOARD_TRAINING_DATA:
      return { ...state, ...action.payload };
    case CLEAN_DASHBOARD_DATA:
      return initialState;
    default:
      return state;
  }
};

export enum EStructuredTrainingModules {
  WELCOME = 'welcome',
  INTRODUCTION_MENTEE = 'introductionMentee',
  INTRODUCTION_MENTOR = 'introductionMentor',
  MAKE_THE_MOST_MENTEE = 'makeTheMostMentee',
  MAKE_THE_MOST_MENTOR = 'makeTheMostMentor',
  BE_GREAT_MENTEE = 'beGreatMentee',
  BE_GREAT_MENTOR = 'beGreatMentor',
  GIVING_RECEIVING_FEEDBACK_MENTEE = 'givingReceivingFeedbackMentee',
  GIVING_RECEIVING_FEEDBACK_MENTOR = 'givingReceivingFeedbackMentor',
  BEYOND_PROGRAM_MENTEE = 'beyondProgramMentee',
  BEYOND_PROGRAM_MENTOR = 'beyondProgramMentor',
  SURVEY_ONE = 'surveyOne',
  SURVEY_TWO = 'surveyTwo',
}

export enum EMoDTrainingModules {
  MOD_BE_GREAT_MENTEE = 'modBeGreatMentee',
  MOD_BE_GREAT_MENTOR = 'modBeGreatMentor',
  MOD_GOAL_SETTING_MENTEE = 'modGoalSettingMentee',
  MOD_GOAL_SETTING_MENTOR = 'modGoalSettingMentor',
  MOD_POWER_OF_REFLECTION_MENTEE = 'modPowerOfReflectionMentee',
  MOD_POWER_OF_REFLECTION_MENTOR = 'modPowerOfReflectionMentor',
  MOD_SITUATIONAL_MENTORSHIP_MENTOR = 'modSituationalMentorshipMentor',
  MOD_GROWTH_COACHING_FRAMEWORK_MENTOR = 'modGrowthCoachingFrameworkMentor',
}

export type ETrainingModules =
  | EStructuredTrainingModules
  | EMoDTrainingModules
  | EEasyEnglishTrainingModules;

export enum EEasyEnglishTrainingModules {
  EASY_ENGLISH_MENTEE = 'easyEnglishMentee',
  EASY_ENGLISH_MENTOR = 'easyEnglishMentor',
  EASY_ENGLISH_CC = 'easyEnglishCC',
}

export const StructuredProgramRoleSpecificTrainingModules: {
  [ProgramPositions.mentee]: ETrainingModules[];
  [ProgramPositions.mentor]: ETrainingModules[];
} = {
  mentee: [
    EStructuredTrainingModules.INTRODUCTION_MENTEE,
    EStructuredTrainingModules.MAKE_THE_MOST_MENTEE,
    EStructuredTrainingModules.SURVEY_ONE,
    EStructuredTrainingModules.BE_GREAT_MENTEE,
    EStructuredTrainingModules.GIVING_RECEIVING_FEEDBACK_MENTEE,
    EStructuredTrainingModules.BEYOND_PROGRAM_MENTEE,
    EStructuredTrainingModules.SURVEY_TWO,
  ],
  mentor: [
    EStructuredTrainingModules.INTRODUCTION_MENTOR,
    EStructuredTrainingModules.MAKE_THE_MOST_MENTOR,
    EStructuredTrainingModules.SURVEY_ONE,
    EStructuredTrainingModules.BE_GREAT_MENTOR,
    EStructuredTrainingModules.GIVING_RECEIVING_FEEDBACK_MENTOR,
    EStructuredTrainingModules.BEYOND_PROGRAM_MENTOR,
    EStructuredTrainingModules.SURVEY_TWO,
  ],
};

export const RoleSpecificTrainingModules: {
  [ProgramPositions.mentee]: ETrainingModules[];
  [ProgramPositions.mentor]: ETrainingModules[];
} = {
  mentee: [
    EStructuredTrainingModules.INTRODUCTION_MENTEE,
    EStructuredTrainingModules.MAKE_THE_MOST_MENTEE,
    EMoDTrainingModules.MOD_BE_GREAT_MENTEE,
    EMoDTrainingModules.MOD_GOAL_SETTING_MENTEE,
    EMoDTrainingModules.MOD_POWER_OF_REFLECTION_MENTEE,
    EEasyEnglishTrainingModules.EASY_ENGLISH_MENTEE,
    EEasyEnglishTrainingModules.EASY_ENGLISH_CC,
    EStructuredTrainingModules.SURVEY_ONE,
    EStructuredTrainingModules.BE_GREAT_MENTEE,
    EStructuredTrainingModules.GIVING_RECEIVING_FEEDBACK_MENTEE,
    EStructuredTrainingModules.BEYOND_PROGRAM_MENTEE,
    EStructuredTrainingModules.SURVEY_TWO,
  ],
  mentor: [
    EStructuredTrainingModules.INTRODUCTION_MENTOR,
    EStructuredTrainingModules.MAKE_THE_MOST_MENTOR,
    EMoDTrainingModules.MOD_BE_GREAT_MENTOR,
    EMoDTrainingModules.MOD_GOAL_SETTING_MENTOR,
    EMoDTrainingModules.MOD_POWER_OF_REFLECTION_MENTOR,
    EMoDTrainingModules.MOD_SITUATIONAL_MENTORSHIP_MENTOR,
    EMoDTrainingModules.MOD_GROWTH_COACHING_FRAMEWORK_MENTOR,
    EEasyEnglishTrainingModules.EASY_ENGLISH_MENTOR,
    EEasyEnglishTrainingModules.EASY_ENGLISH_CC,
    EStructuredTrainingModules.SURVEY_ONE,
    EStructuredTrainingModules.BE_GREAT_MENTOR,
    EStructuredTrainingModules.GIVING_RECEIVING_FEEDBACK_MENTOR,
    EStructuredTrainingModules.BEYOND_PROGRAM_MENTOR,
    EStructuredTrainingModules.SURVEY_TWO,
  ],
};

export const ReadableTrainingModuleTitles = {
  [EMoDTrainingModules.MOD_BE_GREAT_MENTEE]: 'Be a great mentee',
  [EMoDTrainingModules.MOD_BE_GREAT_MENTOR]: 'Be a great mentor',
  [EMoDTrainingModules.MOD_GOAL_SETTING_MENTEE]: 'Goal setting (mentee)',
  [EMoDTrainingModules.MOD_GOAL_SETTING_MENTOR]: 'Goal setting (mentor)',
  [EMoDTrainingModules.MOD_POWER_OF_REFLECTION_MENTEE]: 'The power of reflection (mentee)',
  [EMoDTrainingModules.MOD_POWER_OF_REFLECTION_MENTOR]: 'The power of reflection (mentor)',
  [EMoDTrainingModules.MOD_SITUATIONAL_MENTORSHIP_MENTOR]: 'Situational mentorship',
  [EMoDTrainingModules.MOD_GROWTH_COACHING_FRAMEWORK_MENTOR]: 'Growth coaching framework',
  [EEasyEnglishTrainingModules.EASY_ENGLISH_MENTEE]: 'Easy English - The Mentee Guide',
  [EEasyEnglishTrainingModules.EASY_ENGLISH_MENTOR]: 'Easy English - The Mentor Guide',
  [EEasyEnglishTrainingModules.EASY_ENGLISH_CC]: 'Easy English - Code of Conduct',
  [EStructuredTrainingModules.WELCOME]: 'Welcome',
  [EStructuredTrainingModules.INTRODUCTION_MENTEE]: 'Introduction to mentoring (mentee)',
  [EStructuredTrainingModules.INTRODUCTION_MENTOR]: 'Introduction to mentoring (mentor)',
  [EStructuredTrainingModules.MAKE_THE_MOST_MENTEE]: 'Make the most of mentoring (mentee)',
  [EStructuredTrainingModules.MAKE_THE_MOST_MENTOR]: 'Make the most of mentoring (mentor)',
  [EStructuredTrainingModules.BE_GREAT_MENTEE]: 'Be a great mentee',
  [EStructuredTrainingModules.BE_GREAT_MENTOR]: 'Be a great mentor',
  [EStructuredTrainingModules.GIVING_RECEIVING_FEEDBACK_MENTEE]: 'Effective feedback (mentee)',
  [EStructuredTrainingModules.GIVING_RECEIVING_FEEDBACK_MENTOR]: 'Effective feedback (mentor)',
  [EStructuredTrainingModules.BEYOND_PROGRAM_MENTEE]: 'Wrap up your relationship (mentee)',
  [EStructuredTrainingModules.BEYOND_PROGRAM_MENTOR]: 'Wrap up your relationship (mentor)',
  [EStructuredTrainingModules.SURVEY_ONE]: 'Check-in survey',
  [EStructuredTrainingModules.SURVEY_TWO]: 'Finishing-up survey',
};

export interface IAllTrainingModuleStats {
  completions: number;
  satisfaction?: number | string;
  confidence?: number | string;
  competence?: number | string;
}

export interface IModuleCompletions extends IAllTrainingModuleStats {
  module: ETrainingModules;
  image?: string;
  isMenteeModule?: boolean;
  isMentorModule?: boolean;
  isMenteeMentorModule?: boolean;
  active?: boolean;
  overdue?: boolean;
  showStats?: boolean;
}

export interface IBaseHealthData {
  mentee: number;
  mentor: number;
  all: number;
  menteePercentage: number;
  mentorPercentage: number;
  allPercentage: number;
  totalMenteeAmount?: number;
  totalMentorAmount?: number;
  totalAllAmount?: number;
}

export interface IGoalHealthData extends IBaseHealthData {
  allGoalsCreated: number;
  menteeGoalsCreated: number;
  mentorGoalsCreated: number;
}

export interface IMentoringAgreementsHealthData {
  completedMentoringAgreements: number;
  completedMentoringAgreementsPercentage: number;
}

export type ISharedNotesHealthData = Pick<IBaseHealthData, 'all' | 'allPercentage'>;

export interface ISharedHealthData extends ISharedNotesHealthData, IMentoringAgreementsHealthData {}

export interface ITrainingModule {
  competenceBaseline: number;
  competenceFinal: number;
  confidenceBaseline: number;
  confidenceFinal: number;
  rating: number;
  correctAmt?: number;
  incorrectAmt?: number;
  feedback?: string;
}

export interface IActiveUsersData {
  uniqueActiveUsers: number;
  uniqueUsersAmount: number;
  uniqueActiveUsersPercentage: number;
  totalMenteeAmount: number;
  submittedMenteeAmount: number;
  totalMentorAmount: number;
  submittedMentorAmount: number;
  totalAllAmount: number;
  totalMonthlyActiveMenteeAmount: number;
  totalMonthlyActiveMentorAmount: number;
  totalMonthlyActiveAllAmount: number;
  menteePercentage: number;
  mentorPercentage: number;
  allPercentage: number;
  activePairs?: number;
  wrappedUpPairs?: number;
  signedUpUserAmount?: number;
  userCap?: number;
  userPaidCapLeft?: number;
  unpairedMenteeAmount?: number;
  availableMentorCapacity?: number;
  mentoringRequests?: IMentoringRequestData;
}

export interface IMeetingHealthData {
  meetingsCreated?: number;
  meetingsCurrentRelationshipPercentage?: number;
  meetingAllUsersParticipatedPercentage?: number; // all past and future pairs, amount of users that have attended one meeting
  meetingsCompleted?: number;
  percentageOrganisers?: number;
  monthlyMeetingAmount?: IMonthlyActivity[];
  currentMeetingTalkingPoints?: number;
  completedTalkingPoints?: number;
  currentMeetingActions?: number;
  completedMeetingActions?: number;
}

export interface IMonthlyActivity {
  name: string; // date
  menteeAmount: number;
  mentorAmount: number;
  allAmount: number;
}

export interface ISharedNotesGraphData {
  sharedNotesGraphData?: IMonthlyActivity[];
}

export interface IUserLoginGraphData {
  loginData?: IUserLoginHealth[];
}

export interface IPrivateNotesGraphData {
  privateNotesGraphData?: IMonthlyActivity[];
}

interface IGoalsGraphData {
  createdGoalsMonthlyActivity?: IMonthlyActivity[];
  updatedGoalsMonthlyActivity?: IMonthlyActivity[];
  completedGoalsMonthlyActivity?: IMonthlyActivity[];
}

export interface IGoalGraphData {
  goalGraphData?: IGoalsGraphData;
}

export interface IProgressActivityGraphData
  extends IGoalGraphData,
    IPrivateNotesGraphData,
    ISharedNotesGraphData,
    IUserLoginGraphData {}

export interface IGroupsHealthData {
  activeGroupsAmount?: number;
  wrappedUpGroupsAmount?: number;
  groupMeetingsCreated?: number;
  groupMeetingsCompleted?: number;
  groupPercentageOrganisers?: number;
  groupMonthlyMeetingAmount?: Array<{ name: string; value: number }>;
}

export interface IUserLoginHealth extends IMonthlyActivity {}

export interface IProgressActivityHealthData {
  goals?: IGoalHealthData;
  privateNotes?: IBaseHealthData;
  sharedNotes?: ISharedHealthData;
  activityGraphData?: IProgressActivityGraphData;
}

export interface ITrainingSurveyData {
  [EStructuredTrainingModules.SURVEY_ONE]: any;
  [EStructuredTrainingModules.SURVEY_TWO]: any;
}

export enum EMentoringRequestStatus {
  SENT = 'sent',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
  CANCELLED = 'cancelled',
}

export interface IMentoringRequest {
  mentoringRequestId: string;
  programId: string;
  menteeRoleId: string;
  menteeName: string;
  mentorRoleId: string;
  mentorName: string;
  pairingData: IDraftedPairs;
  requestDate: number;
  status: EMentoringRequestStatus;
  requestInitiator: ProgramPositions;
  requestMessage?: string;
}

export type IProgramIndexedMentoringRequest = Pick<
  IMentoringRequest,
  | 'menteeName'
  | 'mentorName'
  | 'status'
  | 'menteeRoleId'
  | 'mentorRoleId'
  | 'requestInitiator'
  | 'requestDate'
  | 'mentoringRequestId'
>;

export type IUserRoleIndexedMentoringRequest = IProgramIndexedMentoringRequest &
  Pick<IMentoringRequest, 'programId'>;

export interface IMentoringRequestData {
  requestsSent: number;
  requestsAccepted: number;
  requestsDeclined: number;
}

export interface IDashboardTrainingInfo {
  trainingCompletions?: IModuleCompletions[];
  totalTrainingCompletions?: IAllTrainingModuleStats;
}

export interface IApplicationApprovalHealthData {
  needsReviewAmount?: number;
  menteeNeedsReviewAmount?: number;
  mentorNeedsReviewAmount?: number;
}

export interface IMatchSatisfactionData {
  mentee: number;
  mentor: number;
  all: number;
}

export enum EBillingType {
  PER_USER = 'PER_USER',
  PER_PAIR = 'PER_PAIR',
}

export interface IBillingInfo {
  billingPeriodId: string; // uid
  programId: string; // GSI
  billingStartDate: string;
  billingEndDate: string;
  additionalPayableAmount: string; // toPrecision(2)
  currentSubscriptionAmount: number;
  activeUserAmount: number;
  userSubscriptionCost: number;
  additionalUserSubscription: number;
  reportSnapshot: { userReportData: any[]; userReportColumns: IReportColumn[]; pairAmount: number }; // user report JSON blobs
  billingType: EBillingType;
  hasBeenBilled: boolean;
}

interface IBaseProgramDashboardHealth {
  programId: string;
  programName: string;
  programCloseDate: string;
  activeUserPercentage: number;
  pairingSatisfactionRating: number;
  meetingSatisfactionRating: number;
  engagementPercentage: number;
  averageRelationshipLength: number;
  userTrainingModulesCompleted: number;
  signedUpUserAmount: number;
  subscriptionAmount: number;
  admins: string[]; // emails
  billing: IBillingInfo[];
}

export interface IGlobalDashboardHealth
  extends Omit<IBaseProgramDashboardHealth, 'programName' | 'programCloseDate' | 'billing'> {
  programs: IBaseProgramDashboardHealth[];
  activeUserPercentage: number;
  pairingSatisfactionRating: number;
  meetingSatisfactionRating: number;
  engagementPercentage: number;
  averageRelationshipLength: number;
  userTrainingModulesCompleted: number;
  signedUpUserAmount: number;
  subscriptionAmount: number;
}

export interface IBaseDashboardInfo {
  completedProfiles?: IBaseHealthData;
  activeUsers?: IActiveUsersData;
  applicationApproval?: IApplicationApprovalHealthData;
  groups?: IGroupsHealthData;
  meetings?: IMeetingHealthData;
  progressActivities?: IProgressActivityHealthData;
  meetingSatisfactionFrequencyHealth?: IMeetingSatisfactionFrequencyHealth;
  globalDashboardHealth?: IGlobalDashboardHealth;
}

export interface IDashboardInfo extends IDashboardTrainingInfo, IBaseDashboardInfo {
  congrats?: boolean;
}

const initialState: IDashboardInfo = {};
