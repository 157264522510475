import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { SelectionControl } from '../FormFieldTypes/SelectionControl';
import { IGeneralFormConfig } from './GeneralFormConfig';
import { TextControl } from '../FormFieldTypes/TextControl';
import { EComponentType } from '../../../store/reducers/ProgramReducer';

interface IGeneralForm {
  stepNumber: number;
  generalQuestions: IGeneralFormConfig[];
}

export const GeneralForm = (props: IGeneralForm) => {
  const { stepNumber, generalQuestions } = props;

  return (
    <Grid item container xs={12}>
      {generalQuestions.map((s, i) => {
        if (i + 1 === stepNumber) {
          const {
            readableName,
            componentType,
            conditionalTextField,
            conditionalTextFieldValue,
            isMenteeNotFilterable,
            isMentor,
            isMentorNotFilterable,
            isMentee: f,
            ...other
          } = s;
          if (componentType === EComponentType.TOGGLE) {
            return (
              <SelectionControl
                key={i}
                conditionalTextField={conditionalTextField}
                conditionalTextFieldValue={conditionalTextFieldValue}
                {...other}
              />
            );
          } else if (componentType === EComponentType.TEXT) {
            return <TextControl key={i} {...other} />;
          }
        }
      })}
    </Grid>
  );
};
