import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../../store/storeTypes';
import { ValueTypes } from '../General/GeneralFormConfig';
import { ISelectionControl } from '../FormFieldTypes/SelectionControl';
import { ITextControl } from '../FormFieldTypes/TextControl';
import { MakeOptions } from '../MakeOptions';
import {
  EApplicationFormSections,
  EComponentType,
  UpdatableFormConfiguration,
} from '../../../store/reducers/ProgramReducer';
import { SaveApplicationFormData } from '../../../store/actions/ProgramActions';
import { EFormStepperSections } from '../FormStepper';
import { IDraggableSection } from '../../../Components/General/DraggableSection';

export interface IFinalisingFormConfig extends ISelectionControl, ITextControl, IDraggableSection {
  componentType: EComponentType;
  value: any;
  updateValue: (a: ValueTypes) => void;
  readableName: string;
  notMandatory?: boolean;
  section?: EFormStepperSections;
}

export const FinalisingFormIndex = (): string[] => {
  const finalisingValues = useSelector(
    (state: IStoreTypes) =>
      state.program?.formConfiguration?.[UpdatableFormConfiguration.FORM_CONFIG_DEFAULT_VALUES]
        ?.finalising,
  );
  return finalisingValues.map((a) => a?.key);
};

export function FinalisingFormQuestions(isMentee: boolean): IFinalisingFormConfig[] {
  const finalisingValues = useSelector(
    (state: IStoreTypes) => state.program?.applicationForm?.finalising,
  );
  const config = useSelector(
    (state: IStoreTypes) =>
      state.program?.formConfiguration?.[UpdatableFormConfiguration.FORM_CONFIG]?.finalising,
  );
  const dispatch = useDispatch();
  return config.map((f: any) => {
    let field = {
      ...f,
      value: finalisingValues?.[f.name],
      updateValue: (a: any) =>
        dispatch(SaveApplicationFormData(EApplicationFormSections.FINALISING, { [f.name]: a })),
      question:
        isMentee && f.question.mentee
          ? f.question.mentee
          : !isMentee && f.question.mentor
          ? f.question.mentor
          : f.question,
    };
    // For conditional text field with accessibility
    // @ts-ignore
    if (f.conditionalTextField && finalisingValues?.[f.name] === '1') {
      field.textProps = {
        ...field.textProps,
        value: finalisingValues?.[field?.textProps?.name],
        updateValue: (a: any) =>
          dispatch(
            SaveApplicationFormData(EApplicationFormSections.FINALISING, {
              [field.textProps.name]: a,
            }),
          ),
      };
    } else {
      field.conditionalTextField = false;
    }
    // Most complex logic to pay attention to - stateTerritory and state transformations
    if (f.resetsOtherField) {
      field.updateValue = (a: any) => {
        dispatch(
          SaveApplicationFormData(EApplicationFormSections.FINALISING, { [f.resetField]: '' }),
        );
        dispatch(SaveApplicationFormData(EApplicationFormSections.FINALISING, { [f.name]: a }));
      };
    }
    // Most complex logic to pay attention to - stateTerritory and state transformations
    if (field.options) {
      const opts =
        f.conditionalOptions && finalisingValues[f.conditionalOptionsField]
          ? // @ts-ignore
            f.options?.[finalisingValues?.[f.conditionalOptionsField]]
          : f.options.mentee && isMentee
          ? f.options.mentee
          : f.options.mentor && !isMentee
          ? f.options.mentor
          : f.options;
      field.options = MakeOptions(opts);
    }
    return field;
  });
}
