import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../consts/colors';

const useStyles = makeStyles((theme) => ({
  fill: {
    fill: (props: { selected: boolean }) =>
      !props.selected ? Colors.white : theme.palette.primary.main,
  },
}));

interface ITrainingIcon {
  selected: boolean;
}

export const TrainingIcon: React.FC<ITrainingIcon> = ({ selected }) => {
  const styles = useStyles({ selected });
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 30">
      <g id="expanded">
        <g id="Training">
          <g id="直线_41" data-name="直线 41">
            <path
              className={styles.fill}
              d="M37.55,10.35v0a.73.73,0,0,0-.38-.64L37,9.64A.73.73,0,0,1,37.55,10.35Z"
            />
          </g>
          <g id="路径_13" data-name="路径 13">
            <path
              className={styles.fill}
              d="M37.55,23.74V10.39a.73.73,0,0,0-.38-.64L37,9.64h0L18.86.08a.73.73,0,0,0-.66,0L.39,9.25a.72.72,0,0,0,0,1.28l6.79,3.59v6.71a.71.71,0,0,0,.38.62l11.07,6.14a.7.7,0,0,0,.35.09.79.79,0,0,0,.35-.09L30.1,21.76a.75.75,0,0,0,.38-.64V14.47l5.63-2.9V23.74a3.17,3.17,0,1,0,1.44,0Zm-8.52-3L19,26.13,8.63,20.4V14.88l10,5.31a.69.69,0,0,0,.34.09.76.76,0,0,0,.33-.08l9.68-5Zm7.8,7.86a1.72,1.72,0,0,1-.39-3.4,1.67,1.67,0,0,1,.39,0,1.58,1.58,0,0,1,.38,0,1.72,1.72,0,0,1-.38,3.4Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
