import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../store/storeTypes';
import { BrancherDispatch, UtilGenerateReport } from '../../store/actions';
import { ActionButton } from '../../Components/InputFields/BrancherButton';
import { EGenerateReport } from '../../store/reducers/ReportReducer';
import { GeneratePairingSatisfactionReport } from './GeneratePairingSatisfactionReport';

interface IPairingSatisfactionReport {
  controlled?: boolean;
  completedCallback?: () => void;
}

export const PairingSatisfactionReport: React.FC<IPairingSatisfactionReport> = ({
  controlled,
  completedCallback,
}) => {
  const program = useSelector((state: IStoreTypes) => state.program);
  const pairingSatisfactionReportData = useSelector(
    (state: IStoreTypes) => state?.report?.pairingSatisfactionReportData,
  );
  const pairingSatisfactionReportColumns = useSelector(
    (state: IStoreTypes) => state?.report?.pairingSatisfactionReportColumns,
  );
  const [generatingPairingSatisfactionReport, setGeneratingPairingSatisfactionReport] =
    React.useState<boolean>(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (controlled) {
      generatePairingSatisfactionReport();
    }
  }, []);

  const generatePairingSatisfactionReport = () => {
    setGeneratingPairingSatisfactionReport(true);
    BrancherDispatch(
      dispatch,
      UtilGenerateReport(EGenerateReport.PAIRING_SATISFACTION_REPORT, () => {
        setGeneratingPairingSatisfactionReport(false);
        if (controlled) {
          completedCallback();
        }
      }),
    );
  };

  return (
    <Grid item xs={12} container alignItems="center" spacing={2}>
      {!controlled && (
        <Grid item xs={6}>
          <ActionButton
            onClick={generatePairingSatisfactionReport}
            loading={generatingPairingSatisfactionReport}
            size="small"
          >
            Generate pairing satisfaction report
          </ActionButton>
        </Grid>
      )}
      {pairingSatisfactionReportData && (
        <Grid item xs={6}>
          <GeneratePairingSatisfactionReport
            data={pairingSatisfactionReportData}
            displayData={pairingSatisfactionReportColumns}
            programName={program.programName}
          />
        </Grid>
      )}
    </Grid>
  );
};
