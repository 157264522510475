import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../../store/storeTypes';
import { ITextControl } from '../FormFieldTypes/TextControl';
import { ISelectionControl } from '../FormFieldTypes/SelectionControl';
import { MakeOptions } from '../MakeOptions';
import {
  EApplicationFormSections,
  EComponentType,
  UpdatableFormConfiguration,
} from '../../../store/reducers/ProgramReducer';
import { SaveApplicationFormData } from '../../../store/actions/ProgramActions';
import { EFormStepperSections } from '../FormStepper';
import { IDraggableColumn, IDraggableSection } from '../../../Components/General/DraggableSection';

export type ValueTypes = string[] | string | number | number[] | boolean | IDraggableColumn[];

export interface IGeneralFormConfig
  extends Omit<ITextControl, 'question'>,
    ISelectionControl,
    IDraggableSection {
  value: any;
  updateValue: (a: ValueTypes) => void;
  componentType: string;
  notMandatory?: boolean;
  section?: EFormStepperSections;

  isMenteeNotFilterable: boolean;
  isMentor: boolean;
  isMentorNotFilterable: boolean;
  isMentee: boolean;
}

export const GeneralFormIndex = (isMentee: boolean): string[] => {
  const generalValues = useSelector(
    (state: IStoreTypes) =>
      state.program?.formConfiguration?.[UpdatableFormConfiguration.FORM_CONFIG_DEFAULT_VALUES]
        ?.general,
  );
  const menteeFields = generalValues.mentee.map((a) => a.key);
  const mentorFields = generalValues.mentor.map((a) => a.key);
  return !isMentee ? mentorFields : menteeFields;
};

export function GeneralFormQuestions(isMentee: boolean): IGeneralFormConfig[] {
  const generalValues = useSelector(
    (state: IStoreTypes) => state.program?.applicationForm?.general,
  );
  const config = useSelector(
    (state: IStoreTypes) =>
      state.program?.formConfiguration?.[UpdatableFormConfiguration.FORM_CONFIG]?.general,
  );
  const dispatch = useDispatch();
  return config
    .map((f: any) => {
      if ((f.isMentor && !isMentee) || (f.isMentee && isMentee)) {
        let field = {
          ...f,
          value:
            f.componentType === EComponentType.DND && !generalValues?.[f.name]
              ? f.value
              : generalValues?.[f.name],
          updateValue: (a: any) =>
            dispatch(SaveApplicationFormData(EApplicationFormSections.GENERAL, { [f.name]: a })),
          question:
            isMentee && f.question.mentee
              ? f.question.mentee
              : !isMentee && f.question.mentor
              ? f.question.mentor
              : f.question,
        };
        if (field?.minimumAmount) {
          const hasDifferentAmountLogicPerPosition: boolean =
            typeof field?.minimumAmount !== 'number';
          if (hasDifferentAmountLogicPerPosition) {
            if (isMentee) {
              field.minimumAmount = field.minimumAmount.mentee;
            } else {
              field.minimumAmount = field.minimumAmount.mentor;
            }
          }
        }
        if (field?.maximumAmount) {
          const hasDifferentAmountLogicPerPosition: boolean =
            typeof field?.maximumAmount !== 'number';
          if (hasDifferentAmountLogicPerPosition) {
            if (isMentee) {
              field.maximumAmount = field.maximumAmount.mentee;
            } else {
              field.maximumAmount = field.maximumAmount.mentor;
            }
          }
        }
        if (field?.resetsOtherField) {
          field.updateValue = (a: any) => {
            dispatch(
              SaveApplicationFormData(EApplicationFormSections.GENERAL, { [f.resetField]: '' }),
            );
            dispatch(SaveApplicationFormData(EApplicationFormSections.GENERAL, { [f.name]: a }));
          };
        }
        if (field?.options) {
          const opts = f?.conditionalOptions
            ? f.options?.[generalValues?.[f.conditionalOptionsField]] ?? []
            : f.options.mentee && isMentee
            ? f.options.mentee
            : f.options.mentor && !isMentee
            ? f.options.mentor
            : f.options;
          const secondaryOpts =
            f?.conditionalOptions && f?.conditionalOptionsSecondaryField
              ? opts[generalValues?.[f.conditionalOptionsSecondaryField]] ?? []
              : opts;
          field.options = MakeOptions(secondaryOpts);
        }
        return field;
      }
    })
    .filter((a) => a !== undefined);
}
