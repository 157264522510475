import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { BrancherGridCard } from '../../Components/General/BrancherGridCard';
import { IAllTrainingModuleStats } from '../../store/reducers/DashboardReducer';
import { Text } from '../../Components/General/Text';
import { Colors } from '../../consts/colors';
import { useSelector } from 'react-redux';
import { IStoreTypes } from '../../store/storeTypes';
import { Subscriptions } from '../../store/reducers/ProgramReducer';

interface ITrainingHealthCardStyles {
  mobile: boolean;
  phablet: boolean;
}

const useStyles = makeStyles({
  image: {
    width: (props: ITrainingHealthCardStyles) => (props.mobile ? 40 : props.phablet ? 60 : 80),
    height: (props: ITrainingHealthCardStyles) => (props.mobile ? 40 : props.phablet ? 60 : 80),
    margin: (props: ITrainingHealthCardStyles) =>
      props.mobile ? 10 : props.phablet ? 20 : `30px 25px`,
  },
  trainingCard: {
    marginBottom: 20,
    padding: 30,
    border: `1px solid ${Colors.lightPurple}`,
    backgroundColor: Colors.backgroundLightPurple,
  },
  statsBorder: {
    marginRight: 15,
    borderRight: `1px solid ${Colors.iconTextGrey}`,
  },
});

interface ITrainingHealthOverallCard extends IAllTrainingModuleStats {
  cardTitle: string;
}

export const TrainingHealthOverallCard: React.FC<ITrainingHealthOverallCard> = (props) => {
  const isAdhocPairing = useSelector((state: IStoreTypes) => state.program?.subscription)?.includes(
    Subscriptions.ADHOC_PAIRING,
  );
  const { completions, competence, confidence, satisfaction, cardTitle } = props;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const phablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const styles = useStyles({ mobile, phablet });

  return (
    <BrancherGridCard
      className={styles.trainingCard}
      container
      alignItems="center"
      justifyContent="center"
      spacing={2}
    >
      <Grid item xs={3}>
        <Text variant="md" marginBottom={10} fontWeight={700} color="purple" align="center">
          {cardTitle}
        </Text>
      </Grid>
      <Grid item xs={2}>
        <Grid
          container
          direction="column"
          spacing={1}
          alignItems="center"
          className={styles.statsBorder}
        >
          <Grid item>
            <Text variant="md" marginBottom={10} fontWeight={700} color="purple">
              {completions}%
            </Text>
          </Grid>
          <Text variant="xs" fontWeight={500} wordBreak align="center">
            Overall Completion
          </Text>
        </Grid>
      </Grid>
      <Grid
        item
        xs={2}
        container
        direction="column"
        spacing={1}
        alignItems="center"
        className={!isAdhocPairing ? styles.statsBorder : ''}
      >
        <Grid item>
          <Text variant="md" marginBottom={10} fontWeight={700} color="purple">
            {satisfaction}%
          </Text>
        </Grid>
        <Text variant="xs" fontWeight={500} wordBreak align="center">
          Overall Advocacy
        </Text>
      </Grid>
      {!isAdhocPairing && (
        <>
          <Grid
            item
            xs={2}
            container
            direction="column"
            spacing={1}
            alignItems="center"
            className={styles.statsBorder}
          >
            <Grid item>
              <Text variant="md" marginBottom={10} fontWeight={700} color="purple">
                {confidence}%
              </Text>
            </Grid>
            <Text variant="xs" fontWeight={500} wordBreak align="center">
              Overall Confidence Increase
            </Text>
          </Grid>
          <Grid item xs={2} container direction="column" spacing={1} alignItems="center">
            <Grid item>
              <Text variant="md" marginBottom={10} fontWeight={700} color="purple">
                {competence}%
              </Text>
            </Grid>
            <Text variant="xs" marginLeft={15} fontWeight={500} wordBreak align="center">
              Overall Competence Increase
            </Text>
          </Grid>
        </>
      )}
    </BrancherGridCard>
  );
};
