import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { BrancherDivider } from '../../../Components/General/BrancherDivider';
import { QuestionHeader } from './QuestionHeader';
import { QuestionDescription } from './QuestionDescription';
import { Colors } from '../../../consts/colors';
import { MobileFontSizes } from '../../../consts/FontSizes';
import { Text } from '../../../Components/General/Text';

export interface ISegment {
  value: string | string[] | number | number[];
  name?: string;
  title?: string;
  updateValue?: (a: string | string[] | number | number[]) => void;
  valueLength?: number;
  questionNumber?: string;
  fullWidth?: boolean;
}

interface ISegmentedControl {
  question: React.ReactNode;
  fullWidth?: boolean;
  options?: ISegment[];
  stage?: string;
  customOptions?: boolean;
  description?: React.ReactNode;
}

interface ISegmentedControlStyles extends ISegmentedControlGroup {
  mobile?: boolean;
}

const useStyles = makeStyles({
  tbroot: {
    backgroundColor: Colors.white,
    '&$selected': {
      backgroundColor: Colors.purple,
      color: Colors.white,
      '&:hover': {
        backgroundColor: Colors.purple,
        color: Colors.white,
      },
    },
  },
  tbselected: {
    '& .MuiToggleButton-label': {
      color: `${Colors.white} !important`,
    },
    '&.MuiToggleButton-root': {
      backgroundColor: `${Colors.purple} !important`,
    },
  },
  tblabel: {
    color: Colors.black,
    fontSize: (props: ISegmentedControlStyles) =>
      props?.mobile ? MobileFontSizes.xxxs : MobileFontSizes.lg,
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'none',
  },
  tggrouped: {
    width: (props: ISegmentedControlStyles) => props.fullWidth && `calc(100%/${props.valueLength})`,
    padding: (props: ISegmentedControlStyles) => props.mobile && 10,
    '&:not(:last-child)': {
      borderTop: `2px solid ${Colors.purple} !important`,
      borderBottom: `2px solid ${Colors.purple} !important`,
      borderLeft: `none`,
      borderRight: `none`,
    },
    '&:not(:first-child)': {
      borderTop: `2px solid ${Colors.purple} !important`,
      borderBottom: `2px solid ${Colors.purple} !important`,
      borderRight: `2px solid ${Colors.purple} !important`,
      borderLeft: `none`,
    },
    '&:last-child': {
      borderTopRightRadius: '15px !important',
      borderBottomRightRadius: '15px !important',
      borderTop: `2px solid ${Colors.purple} !important`,
      borderBottom: `2px solid ${Colors.purple} !important`,
      borderRight: `2px solid ${Colors.purple} !important`,
      borderLeft: `none`,
    },
    '&:first-child': {
      borderTopLeftRadius: '15px !important',
      borderBottomLeftRadius: '15px !important',
      border: `2px solid ${Colors.purple} !important`,
    },
  },
  tggroupedRoot: {
    width: (props: ISegmentedControlStyles) => props.fullWidth && '100%',
  },
  segmentedControlBase: {
    backgroundColor: Colors.backgroundDarkGrey,
    padding: 40,
  },
});

export const SegmentedControl = (props: ISegmentedControl) => {
  const { question, description, stage, options, fullWidth } = props;
  return (
    <>
      <Grid item xs={12} md={10}>
        <QuestionHeader stage={stage}>{question}</QuestionHeader>
      </Grid>
      <Grid item xs={12} md={10}>
        {typeof description === 'string' ? (
          <QuestionDescription>{description}</QuestionDescription>
        ) : (
          description
        )}
      </Grid>
      <Grid item xs={12} md={10}>
        <BrancherDivider marginBottom={20} marginTop={10} />
      </Grid>
      <Grid item xs={12} md={10}>
        {options.map((o, i) => (
          <Segment key={i} fullWidth={fullWidth} {...o} />
        ))}
      </Grid>
    </>
  );
};

interface IExtendedSegment extends ISegment, Pick<ISegmentedControl, 'customOptions' | 'options'> {}

export const Segment = (props: IExtendedSegment) => {
  const { title, valueLength, value, updateValue, fullWidth, customOptions, options } = props;
  const styles = useStyles(props);

  return (
    <Grid container className={styles.segmentedControlBase} justify="center">
      {title && (
        <Grid item container justify="center">
          <Text variant="md" fontWeight={600} marginBottom={20}>
            {title}
          </Text>
        </Grid>
      )}
      <Grid item container justify="center" sm={10}>
        <SegmentedControlGroup
          value={value}
          valueLength={valueLength}
          updateValue={updateValue}
          fullWidth={fullWidth}
          options={options}
          customOptions={customOptions}
        />
      </Grid>
    </Grid>
  );
};

interface ISegmentedControlGroup
  extends Pick<ISegment, 'valueLength' | 'value' | 'updateValue'>,
    Pick<ISegmentedControl, 'options' | 'customOptions'> {
  fullWidth?: boolean;
  startFromZero?: boolean;
}

export const SegmentedControlGroup = (props: ISegmentedControlGroup) => {
  const styles = useStyles(props);
  const { valueLength, value, updateValue, startFromZero, customOptions, options } = props;
  const handleChange = (e, newValue) => {
    updateValue(newValue);
  };

  return (
    <ToggleButtonGroup
      classes={{ grouped: styles.tggrouped, root: styles.tggroupedRoot }}
      exclusive
      value={value}
      onChange={handleChange}
    >
      {customOptions
        ? options.map((o, i) => (
            <ToggleButton
              classes={{
                root: styles.tbroot,
                label: styles.tblabel,
                selected: styles.tbselected,
              }}
              key={i}
              value={o.value}
            >
              {o.title}
            </ToggleButton>
          ))
        : [...Array(valueLength).keys()].map((v, i) => (
            <ToggleButton
              classes={{
                root: styles.tbroot,
                label: styles.tblabel,
                selected: styles.tbselected,
              }}
              key={i}
              value={startFromZero ? v : v++}
            >
              {startFromZero ? v : v++}
            </ToggleButton>
          ))}
    </ToggleButtonGroup>
  );
};
