import * as React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import capitalize from '@material-ui/core/utils/capitalize';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import { Cancel } from '@material-ui/icons';
import Send from '@material-ui/icons/Send';
import Flare from '@material-ui/icons/Flare';
import Assessment from '@material-ui/icons/Assessment';
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';
import Build from '@material-ui/icons/Build';
import Delete from '@material-ui/icons/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { format, isPast, parse } from 'date-fns';
import { Text } from '../../Components/General/Text';
import {
  BrancherDispatch,
  UtilDeleteSurvey,
  UtilGetSurveyReport,
  UtilSendSurveyToSegments,
  UtilUpdateSurveyConfiguration,
  UtilUpdateSurveyDate,
  UtilUpdateSurveyStatus,
  UtilUpdateSurveyTitle,
} from '../../store/actions';
import { IStoreTypes } from '../../store/storeTypes';
import { ProgramPositions } from '../../consts/ProgramPositionOptions';
import { BrancherCheckbox } from '../../Components/InputFields/BrancherCheckbox';
import {
  ActionButton,
  ActionNegativeOutlinedButton,
  ActionOutlinedButton,
  BackButton,
  SaveButton,
} from '../../Components/InputFields/BrancherButton';
import { Page } from '../../Components/General/Page';
import {
  ESurveyStatus,
  EUserActions,
  SurveyConfigurationQuestion,
  UpdatableSurveyConfiguration,
} from '../../store/reducers/SurveysReducer';
import { IReportColumn } from '../../store/reducers/ReportReducer';
import { GenerateSurveyReport } from '../Reports/GenerateSurveyReport';
import { BrancherDialog } from '../../Components/General/BrancherDialog';
import { CustomSurvey } from './CustomSurvey';
import { BrancherRadio } from '../../Components/InputFields/Radio/BrancherRadio';
import { EBooleanOptions } from '../SuperUser/CreateProgram';
import { BrancherInputLabel } from '../../Components/InputFields/BrancherInputLabel';
import { DatePicker } from '../../Components/InputFields/DatePicker';
import { dateDefaultFormat } from '../../helpers/DateHelpers';
import { BrancherDivider } from '../../Components/General/BrancherDivider';
import { SurveyBuilderEngine } from './SurveyBuilderEngine';
import { ConfirmDialog } from '../../Components/General/ConfirmDialog';
import { RouteMaster } from '../../Components/Routing';
import { BrancherTextField } from '../../Components/InputFields/BrancherTextField';

export const Survey: React.FC = () => {
  const surveyId = new URLSearchParams(useLocation().search).get('s');
  const locationHistory = useHistory();
  const dispatch = useDispatch();
  const survey = useSelector((state: IStoreTypes) => state.surveys.surveys)?.find(
    (f) => f.surveyId === surveyId,
  );
  const [intermediaryTitle, setIntermediaryTitle] = React.useState<string>(
    survey?.[UpdatableSurveyConfiguration.SURVEY_TITLE] ?? '',
  );
  const [intermediaryDescription, setIntermediaryDescription] = React.useState<string>(
    survey?.[UpdatableSurveyConfiguration.SURVEY_DESCRIPTION] ?? '',
  );
  const [segments, setSegments] = React.useState<ProgramPositions[]>([]);
  const [updatingSurvey, setUpdatingSurvey] = React.useState<boolean>(false);
  const [sendingSurvey, setSendingSurvey] = React.useState<boolean>(false);
  const [updatingSurveyStatus, setUpdatingSurveyStatus] = React.useState<boolean>(false);
  const [generatingReport, setGeneratingReport] = React.useState<boolean>(false);
  const [emailUsers, setEmailUsers] = React.useState<EBooleanOptions>(EBooleanOptions.NO);
  const [reportColumns, setReportColumns] = React.useState<IReportColumn[]>(null);

  // For minimum date
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const [surveyInitialReminderDate, setSurveyInitialReminderDate] = React.useState<Date>(
    survey?.surveyInitialReminderDate
      ? parse(survey?.surveyInitialReminderDate, dateDefaultFormat, new Date())
      : tomorrow,
  );
  const [surveyFinalReminderDate, setSurveyFinalReminderDate] = React.useState<Date>(
    survey?.surveyFinalReminderDate
      ? parse(survey?.surveyFinalReminderDate, dateDefaultFormat, new Date())
      : tomorrow,
  );
  const [surveyDueDate, setSurveyDueDate] = React.useState<Date>(
    survey?.surveyDueDate ? parse(survey?.surveyDueDate, dateDefaultFormat, new Date()) : tomorrow,
  );
  const [reportData, setReportData] = React.useState<any[]>(null);
  const [reportMissingResponseColumns, setMissingResponseReportColumns] =
    React.useState<IReportColumn[]>(null);
  const [reportMissingResponseData, setMissingResponseReportData] = React.useState<any[]>(null);
  const [openSurveyDialog, setOpenSurveyDialog] = React.useState<boolean>(false);
  const [openSurveyBuilderDialog, setOpenSurveyBuilderDialog] = React.useState<boolean>(false);
  const [confirmDeleteDialog, setConfirmDeleteDialog] = React.useState<boolean>(false);
  const [deletingSurvey, setDeletingSurvey] = React.useState<boolean>(false);
  const [openEditSurveyTitleDescription, setOpenEditSurveyTitleDescription] =
    React.useState<boolean>(false);
  const [updatingTitle, setUpdatingTitle] = React.useState<boolean>(false);

  const generateSurveyReport = () => {
    setGeneratingReport(true);
    BrancherDispatch(
      dispatch,
      UtilGetSurveyReport(surveyId, (res) => {
        setReportColumns(res.surveyReportColumns);
        setReportData(res.surveyReportData);
        setMissingResponseReportColumns(res?.surveyNoResponsesColumns);
        setMissingResponseReportData(res?.surveyNoResponsesData);
        setGeneratingReport(false);
      }),
    );
  };

  const controlPositionSegments = (userPosition: ProgramPositions, selectedPosition: boolean) => {
    if (segments.includes(userPosition) && !selectedPosition) {
      setSegments(segments.filter((s) => s !== userPosition));
    } else if (!segments.includes(userPosition) && selectedPosition) {
      setSegments([...segments, userPosition]);
    }
  };

  const sendSurvey = () => {
    setSendingSurvey(true);
    BrancherDispatch(
      dispatch,
      UtilSendSurveyToSegments(segments, surveyId, emailUsers === EBooleanOptions.YES, () => {
        setSendingSurvey(false);
      }),
    );
  };

  const updateTitle = () => {
    setUpdatingTitle(true);
    BrancherDispatch(
      dispatch,
      UtilUpdateSurveyTitle(surveyId, intermediaryTitle, intermediaryDescription, () => {
        setUpdatingTitle(false);
      }),
    );
  };

  const deleteSurvey = () => {
    setDeletingSurvey(true);
    BrancherDispatch(
      dispatch,
      UtilDeleteSurvey(surveyId, () => {
        window.location.assign(`${RouteMaster.surveys.path}`);
      }),
    );
  };

  const saveSurveyConfiguration = (configuration: SurveyConfigurationQuestion[]) => {
    setUpdatingSurvey(true);
    BrancherDispatch(
      dispatch,
      UtilUpdateSurveyConfiguration(surveyId, configuration, () => {
        setUpdatingSurvey(false);
        setOpenSurveyBuilderDialog(false);
      }),
    );
  };

  const updateReminderDates = () => {
    setUpdatingSurvey(true);
    BrancherDispatch(
      dispatch,
      UtilUpdateSurveyDate(
        surveyId,
        {
          surveyInitialReminderDate: format(surveyInitialReminderDate, 'dd/MM/yyyy'),
          surveyFinalReminderDate: format(surveyFinalReminderDate, 'dd/MM/yyyy'),
          surveyDueDate: format(surveyDueDate, 'dd/MM/yyyy'),
        },
        () => {
          setUpdatingSurvey(false);
        },
      ),
    );
  };

  const updateSurveyStatus = () => {
    const newSurveyStatus = surveyStatusIsClosed ? ESurveyStatus.ACTIVE : ESurveyStatus.CLOSED;
    setUpdatingSurveyStatus(true);
    BrancherDispatch(
      dispatch,
      UtilUpdateSurveyStatus(surveyId, newSurveyStatus, () => {
        setUpdatingSurveyStatus(false);
      }),
    );
  };

  const isActive = survey?.surveyStatus === ESurveyStatus.ACTIVE;
  const isClosed = survey?.surveyStatus === ESurveyStatus.CLOSED;
  const isMenteeWrapUp = survey?.surveyType === EUserActions.POST_WRAP_UP_MENTEE_SURVEY;
  const isMentorWrapUp = survey?.surveyType === EUserActions.POST_WRAP_UP_MENTOR_SURVEY;
  const isBrancherOwned = !survey?.updatable;
  const hasSentInitialReminder = isPast(
    parse(survey?.surveyInitialReminderDate, dateDefaultFormat, new Date()),
  );
  const hasSentFinalReminder = isPast(
    parse(survey?.surveyFinalReminderDate, dateDefaultFormat, new Date()),
  );
  const surveyStatusIsClosed = survey?.surveyStatus === ESurveyStatus.CLOSED;
  const hasClosed =
    surveyStatusIsClosed || isPast(parse(survey?.surveyDueDate, dateDefaultFormat, new Date()));

  const surveySegmentTitle = isMenteeWrapUp
    ? 'Mentees'
    : isMentorWrapUp
    ? 'Mentors'
    : survey?.surveySegments?.length > 0
    ? `${capitalize(survey?.surveySegments?.join(' and '))}s`
    : 'All Users';

  return (
    <Page loading={deletingSurvey || !survey}>
      <Grid container justifyContent="center">
        <Grid item xs={11}>
          <Box mt={5} mb={5}>
            <BackButton onClick={() => locationHistory.goBack()} />
          </Box>
        </Grid>
        <Grid container item xs={11}>
          <Grid item container justifyContent="space-between" alignItems="center" spacing={1}>
            <Grid item xs={11} md={11}>
              <Grid item>
                <Text variant="xl" color="purple">
                  Title: {survey?.surveyTitle} (For {surveySegmentTitle})
                </Text>
                <Grid item container alignItems="center">
                  <Text variant="md" color="purple" display="inline">
                    Description: {survey?.[UpdatableSurveyConfiguration.SURVEY_DESCRIPTION]}
                  </Text>
                  <IconButton onClick={() => setOpenEditSurveyTitleDescription(true)}>
                    <Edit />
                  </IconButton>
                </Grid>
              </Grid>
              <Text variant="sm" color="purple" marginTop={-1} display="inline">
                (status: {survey?.surveyStatus ?? ESurveyStatus.ACTIVE})
              </Text>
              {!!survey?.[UpdatableSurveyConfiguration.SURVEY_DATE_SENT] && (
                <Text variant="sm" color="purple" marginTop={-1} marginLeft={20} display="inline">
                  (launched: {survey?.[UpdatableSurveyConfiguration.SURVEY_DATE_SENT]})
                </Text>
              )}
            </Grid>
            {!hasClosed && (
              <Grid item xs={12}>
                <BrancherDivider marginTop={30} marginBottom={30} />
              </Grid>
            )}
            {survey?.updatable && (
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                item
                xs={12}
                spacing={4}
              >
                {!hasSentInitialReminder && !hasClosed && (
                  <Grid item xs={12} sm={6} md={3}>
                    <BrancherInputLabel variant="sm" fontWeight={700} color="purple" for="date">
                      Initial reminder date
                    </BrancherInputLabel>
                    <DatePicker
                      value={surveyInitialReminderDate}
                      updateValue={setSurveyInitialReminderDate}
                      minDate={tomorrow}
                      id="initial-reminder-date"
                      name="initial-reminder-date"
                      helperText={`${surveySegmentTitle} will receive an initial reminder if they haven't already completed the survey.`}
                    />
                  </Grid>
                )}
                {!hasSentFinalReminder && !hasClosed && (
                  <Grid item xs={12} sm={6} md={3}>
                    <BrancherInputLabel variant="sm" fontWeight={700} color="purple" for="date">
                      Final reminder date
                    </BrancherInputLabel>
                    <DatePicker
                      value={surveyFinalReminderDate}
                      updateValue={setSurveyFinalReminderDate}
                      minDate={tomorrow}
                      id="final-reminder-date"
                      name="final-reminder-date"
                      helperText={`${surveySegmentTitle} will receive a final reminder if they haven't already completed the survey.`}
                    />
                  </Grid>
                )}
                {!hasClosed && (
                  <>
                    <Grid item xs={12} sm={6} md={3}>
                      <BrancherInputLabel variant="sm" fontWeight={700} color="purple" for="date">
                        Due date
                      </BrancherInputLabel>
                      <DatePicker
                        value={surveyDueDate}
                        updateValue={setSurveyDueDate}
                        minDate={tomorrow}
                        id="due-date"
                        name="due-date"
                        helperText={`${surveySegmentTitle} will be notified on their dashboard when this is overdue.`}
                      />
                    </Grid>
                    <Grid item xs={6} sm={2}>
                      <ActionOutlinedButton onClick={updateReminderDates} loading={updatingSurvey}>
                        Update dates
                      </ActionOutlinedButton>
                    </Grid>
                  </>
                )}
              </Grid>
            )}
            <Grid item xs={12}>
              <BrancherDivider marginTop={30} marginBottom={30} />
            </Grid>
            <Grid item xs={11} container alignItems="center" spacing={2}>
              <Grid container item spacing={4}>
                {survey?.updatable && !isActive && !surveyStatusIsClosed && (
                  <Grid item>
                    <ActionButton
                      startIcon={<Build color="secondary" />}
                      onClick={() => setOpenSurveyBuilderDialog(true)}
                    >
                      Survey Builder
                    </ActionButton>
                  </Grid>
                )}
                <Grid item>
                  <ActionButton
                    startIcon={<Flare color="secondary" />}
                    onClick={() => setOpenSurveyDialog(true)}
                  >
                    Preview
                  </ActionButton>
                </Grid>
                {survey?.surveyStatus !== ESurveyStatus.DRAFT && !isBrancherOwned && (
                  <Grid item>
                    <ActionButton
                      startIcon={
                        surveyStatusIsClosed ? (
                          <LockOpen color="secondary" />
                        ) : (
                          <Lock color="secondary" />
                        )
                      }
                      loading={updatingSurveyStatus}
                      onClick={() => updateSurveyStatus()}
                    >
                      {surveyStatusIsClosed ? 'Open' : 'Close'} survey
                    </ActionButton>
                  </Grid>
                )}
                <Grid item>
                  <ActionButton
                    onClick={generateSurveyReport}
                    loading={generatingReport}
                    startIcon={<Assessment color="secondary" />}
                  >
                    Generate report
                  </ActionButton>
                  {!!reportData && !!reportMissingResponseData && (
                    <GenerateSurveyReport
                      data={reportData}
                      displayData={reportColumns}
                      missingResponseColumns={reportMissingResponseColumns}
                      missingResponseData={reportMissingResponseData}
                      surveyName={survey?.surveyTitle}
                    />
                  )}
                </Grid>
                {survey?.updatable && !isActive && !surveyStatusIsClosed && (
                  <>
                    <Grid item>
                      <ActionNegativeOutlinedButton
                        startIcon={<Delete color="error" />}
                        onClick={() => setConfirmDeleteDialog(true)}
                        loading={deletingSurvey}
                      >
                        Delete Survey
                      </ActionNegativeOutlinedButton>
                    </Grid>
                    <ConfirmDialog
                      confirmAction={deleteSurvey}
                      denyAction={() => setConfirmDeleteDialog(false)}
                      setClose={() => setConfirmDeleteDialog(false)}
                      open={confirmDeleteDialog}
                      denyButtonText="No, do not delete"
                      confirmButtonText="Yes, delete"
                      title="Are you sure you want to delete this survey?"
                      labelledBy="delete-survey-confirmation"
                    />
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <BrancherDivider marginTop={30} marginBottom={30} />
          </Grid>
          <BrancherDialog
            setClose={() => setOpenEditSurveyTitleDescription(false)}
            title="Update survey title and description"
            labelledBy={surveyId}
            open={openEditSurveyTitleDescription}
            fitLargeScreen
          >
            <Grid container justifyContent="center" spacing={4}>
              <Grid item xs={10}>
                <BrancherTextField
                  fullWidth
                  value={intermediaryTitle}
                  updateValue={setIntermediaryTitle}
                  label="Title"
                />
              </Grid>
              <Grid item xs={10}>
                <BrancherTextField
                  fullWidth
                  value={intermediaryDescription}
                  updateValue={setIntermediaryDescription}
                  label="Description"
                />
              </Grid>
            </Grid>
            <Box mt={4}>
              <Grid container justifyContent="center">
                <Grid container justifyContent="space-between" item xs={10}>
                  <ActionNegativeOutlinedButton
                    onClick={() => setOpenEditSurveyTitleDescription(false)}
                    loading={updatingTitle}
                    startIcon={<Cancel color="error" />}
                  >
                    Cancel
                  </ActionNegativeOutlinedButton>
                  <SaveButton onClick={updateTitle} loading={updatingTitle}>
                    Update
                  </SaveButton>
                </Grid>
              </Grid>
            </Box>
          </BrancherDialog>
          <BrancherDialog
            setClose={() => setOpenSurveyDialog(false)}
            title={survey?.surveyTitle}
            labelledBy={surveyId}
            open={openSurveyDialog}
            fitLargeScreen
          >
            <CustomSurvey surveyId={surveyId} closeDialog={() => setOpenSurveyDialog(false)} />
          </BrancherDialog>
          <BrancherDialog
            setClose={() => setOpenSurveyBuilderDialog(false)}
            title={`Build your survey - ${survey?.surveyTitle}`}
            labelledBy={surveyId}
            open={openSurveyBuilderDialog}
          >
            <SurveyBuilderEngine
              configuration={survey?.configuration}
              surveyIntro={survey?.[UpdatableSurveyConfiguration.SURVEY_INTRO] ?? ''}
              saveConfiguration={saveSurveyConfiguration}
              savingForm={updatingSurvey}
            />
          </BrancherDialog>
          {survey?.updatable && !isActive && !surveyStatusIsClosed ? (
            <Box display="flex" mb={5} width="100%" flexDirection="column">
              <Grid item>
                <Text variant="md" marginBottom={20} fontWeight={600}>
                  Ready to open and send the survey?
                </Text>
              </Grid>
              <Grid container spacing={4} item>
                <Grid item container spacing={2}>
                  {survey?.surveySegments?.includes(ProgramPositions.mentee) && (
                    <Grid item xs={12} sm={10} md={9}>
                      <BrancherCheckbox
                        updateValue={(v: boolean) =>
                          controlPositionSegments(ProgramPositions.mentee, v)
                        }
                        value={segments.includes(ProgramPositions.mentee)}
                        label={
                          <Text variant="xs" fontWeight={600}>
                            Send this survey to mentees
                          </Text>
                        }
                      />
                    </Grid>
                  )}
                  {survey?.surveySegments?.includes(ProgramPositions.mentor) && (
                    <Grid item xs={12} sm={10} md={9}>
                      <BrancherCheckbox
                        updateValue={(v: boolean) =>
                          controlPositionSegments(ProgramPositions.mentor, v)
                        }
                        value={segments.includes(ProgramPositions.mentor)}
                        label={
                          <Text variant="xs" fontWeight={600}>
                            Send this survey to mentors
                          </Text>
                        }
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid item>
                  <BrancherRadio
                    aria-label="email-users"
                    value={emailUsers}
                    onChange={(a: EBooleanOptions) => setEmailUsers(a)}
                    label="Do you want to send a survey notification email to users?"
                    options={[
                      { label: 'No', value: EBooleanOptions.NO },
                      { label: 'Yes', value: EBooleanOptions.YES },
                    ]}
                  />
                </Grid>
              </Grid>

              <Box display="flex" marginTop={3}>
                <ActionButton
                  onClick={sendSurvey}
                  disabled={sendingSurvey || segments?.length === 0}
                  loading={sendingSurvey}
                  startIcon={<Send color="secondary" />}
                >
                  Send & Open Survey
                </ActionButton>
              </Box>
            </Box>
          ) : (
            <Grid container item xs={11}>
              <Grid item>
                <Text variant="sm" marginTop={30}>
                  {isActive
                    ? "This survey can't be modified as it's open"
                    : isClosed
                    ? "This survey can't be modified as it's closed"
                    : survey?.updatable
                    ? "This survey can't be updated as it's always open"
                    : ''}
                  .
                </Text>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Page>
  );
};
