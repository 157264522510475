export const Colors: { [IColors: string]: string } = {
  cardGrey: '#FAFAFA', // TODO: Check this one - legacy
  darkPurple: '#2A0134',
  purple: '#5000FF',
  lightPurple: '#A880FF',
  backgroundLightPurple: '#F9F9FC',
  backgroundDarkPurple: '#E1E7F8',
  backgroundDarkerPurple: '#E4F6FF',
  backgroundLightBlue: '#AEE9F2',
  backgroundDarkBlue: '#53EFFC',
  backgroundDarkerBlue: '#33CCFF',
  oceanBlue: '#0077FF',
  lightGrey: '#D1D1D1',
  disabledGrey: '#DEDFE2',
  darkGrey: '#888888',
  tertiaryGrey: '#607177',
  secondaryGrey: '#3C3C3E',
  placeholderGrey: '#8B8B8B',
  iconTextGrey: '#9A9DA7',
  backgroundGrey: '#F2F2F2',
  backgroundDarkGrey: '#F6F6F6',
  backgroundDarkerGrey: '#F6F6F6',
  cardBorder: '#D8DCE9',
  cardSelected: '#DBF3FF',
  informational: '#FFFEDB',
  red: '#FF0000',
  lightRed: '#FFCCCC',
  green: '#06A846',
  CTABanner: '#DBFAFF',
  incorrect: '#FFECEC',
  correct: '#DBFFED',
  white: '#FFF',
  black: '#000',
  transparent: 'transparent',
};

export type IColors =
  | 'purple'
  | 'lightPurple'
  | 'backgroundLightPurple'
  | 'backgroundDarkPurple'
  | 'backgroundDarkerPurple'
  | 'backgroundLightBlue'
  | 'backgroundDarkBlue'
  | 'backgroundDarkerBlue'
  | 'lightGrey'
  | 'disabledGrey'
  | 'darkGrey'
  | 'cardGrey'
  | 'tertiaryGrey'
  | 'secondaryGrey'
  | 'placeholderGrey'
  | 'iconTextGrey'
  | 'backgroundGrey'
  | 'cardBorder'
  | 'red'
  | 'lightRed'
  | 'green'
  | 'CTABanner'
  | 'incorrect'
  | 'correct'
  | 'transparent'
  | 'white'
  | 'black';
