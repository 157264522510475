import * as React from 'react';
import Workbook from 'react-excel-workbook';
import { BrancherButton } from '../../Components/InputFields/BrancherButton';

export const GenerateSurveyReport = ({
  data,
  displayData,
  missingResponseData,
  missingResponseColumns,
  surveyName,
}) => (
  <Workbook
    filename={`${surveyName.slice(0, 8)}..._survey_report.xlsx`}
    element={
      <BrancherButton color="primary" variant="text" size="small">
        Download survey report!
      </BrancherButton>
    }
  >
    <Workbook.Sheet data={data} name={surveyName.slice(0, 20)}>
      {displayData.map((d, l) => (
        <Workbook.Column label={d.readableName} value={d.attributeName} key={l} />
      ))}
    </Workbook.Sheet>
    <Workbook.Sheet data={missingResponseData} name="Missing responses">
      {missingResponseColumns.map((d, l) => (
        <Workbook.Column label={d.readableName} value={d.attributeName} key={l} />
      ))}
    </Workbook.Sheet>
  </Workbook>
);
