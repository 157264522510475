import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreTypes } from '../../store/storeTypes';
import { BrancherDispatch, UtilGenerateReport } from '../../store/actions';
import { ActionButton } from '../../Components/InputFields/BrancherButton';
import { EGenerateReport } from '../../store/reducers/ReportReducer';
import { GenerateProfileReport } from './GenerateProfileReport';

interface IReportReport {
  controlled?: boolean;
  completedCallback?: () => void;
}

export const ProfileReport: React.FC<IReportReport> = ({ controlled, completedCallback }) => {
  const profileReportData = useSelector((state: IStoreTypes) => state?.report?.profileReportData);
  const profileReportColumns = useSelector(
    (state: IStoreTypes) => state?.report?.profileReportColumns,
  );
  const [generatingProfileReport, setGeneratingReportReport] = React.useState<boolean>(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (controlled) {
      generateProfileReport();
    }
  }, []);

  const generateProfileReport = () => {
    setGeneratingReportReport(true);
    BrancherDispatch(
      dispatch,
      UtilGenerateReport(EGenerateReport.PROFILE_REPORT, () => {
        setGeneratingReportReport(false);
        if (controlled) {
          completedCallback();
        }
      }),
    );
  };

  return (
    <Grid item xs={12} container alignItems="center" spacing={2}>
      {!controlled && (
        <Grid item xs={6}>
          <ActionButton
            onClick={generateProfileReport}
            loading={generatingProfileReport}
            size="small"
          >
            Generate profile report
          </ActionButton>
        </Grid>
      )}
      {profileReportData && (
        <Grid item xs={6}>
          <GenerateProfileReport data={profileReportData} displayData={profileReportColumns} />
        </Grid>
      )}
    </Grid>
  );
};
