import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RouteMaster } from '../../Components/Routing';
import { BrancherThickDivider } from '../../Components/General/BrancherDivider';
import { NextButton, PrevButton } from '../../Components/InputFields/BrancherButton';
import { BrancherTextField } from '../../Components/InputFields/BrancherTextField';
import { Text } from '../../Components/General/Text';
import { IStoreTypes } from '../../store/storeTypes';
import { UtilConfirmRegistration } from '../../store/actions';

export const ConfirmRegistration = () => {
  const username = useSelector((state: IStoreTypes) => state.user.username);
  const [confirmed, hasConfirmed] = React.useState(false);
  const [waiting, updateWaiting] = React.useState(false);
  const [verification, setVerification] = React.useState('');
  const [fieldErrors, setFieldErrors] = React.useState([]);
  const [apiError, setApiError] = useState('');
  const dispatch = useDispatch();

  const confirmRegistration = () => {
    const errors = validateConfirmRegistrationFields();
    if (errors.length === 0) {
      if (fieldErrors.length > 0) {
        setFieldErrors([]);
      }
      updateWaiting(true);
      dispatch(
        UtilConfirmRegistration(username, verification, (response) => {
          if (response.success) {
            hasConfirmed(true);
          } else {
            updateWaiting(false);
            setApiError(response.message);
          }
        }),
      );
    } else {
      setFieldErrors(errors);
    }
  };

  const validateConfirmRegistrationFields = (): string[] => {
    const errors: string[] = [];
    if (verification === '') {
      errors.push('verification');
    }
    return errors;
  };

  return (
    <Grid container direction="row" alignItems="center" justifyContent="center">
      <Grid item xs={10} md={8} lg={6}>
        <Grid container direction="column" justifyContent="center">
          <Link to={RouteMaster.signup.path}>
            <PrevButton color="primary" variant="text">
              Back
            </PrevButton>
          </Link>
          <BrancherThickDivider marginTop={30} marginBottom={10} />
          <Text variant="xl" marginBottom={15}>
            Verify email
          </Text>
          <Text variant="sm" marginBottom={20}>
            Please check your email for the verification code.
          </Text>
          {apiError && (
            <Text variant="xs" color="red" marginTop={15} marginBottom={15}>
              {apiError}
            </Text>
          )}
          <Grid container item direction="row" xs={12} justifyContent="space-between">
            <Grid item xs={12}>
              <BrancherTextField
                fullWidth
                name="verification"
                value={verification}
                updateValue={setVerification}
                placeholder="Enter verification code"
                label="Verification Code"
                error={fieldErrors.includes('verification')}
                helpText={
                  fieldErrors.includes('verification') &&
                  'Please enter the verification code you received!'
                }
              />
            </Grid>
          </Grid>
          <Box marginTop={4} alignSelf="center">
            <NextButton onClick={confirmRegistration}>
              {waiting && !confirmed ? (
                <CircularProgress size="small" />
              ) : confirmed ? (
                <Redirect to={RouteMaster.login.path} />
              ) : (
                'Submit'
              )}
            </NextButton>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
