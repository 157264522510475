import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/icons/Link';
import { useDispatch, useSelector } from 'react-redux';
import { UtilGetPairs, UtilUpdateProgramAttribute, BrancherDispatch } from '../../../store/actions';
import { IStoreTypes } from '../../../store/storeTypes';
import {
  ActionButton,
  ActionOutlinedButton,
  BackButton,
  ForwardButton,
} from '../../../Components/InputFields/BrancherButton';
import { Matchmaking } from './Matchmaking';
import { BrancherFullPageLoader } from '../../../Components/General/BrancherFullPageLoader';
import { BrancherGridCard } from '../../../Components/General/BrancherGridCard';
import { Text } from '../../../Components/General/Text';
import { UpdatableProgramInfo } from '../../../store/reducers/ProgramReducer';
import { getUniqueDraftedUsers } from './matchMakingHelpers';
import { BrancherDivider } from '../../../Components/General/BrancherDivider';
import { EMatchingStrategies } from '../../Matching/PairingEngine';

const useStyles = makeStyles({
  margins: {
    marginTop: 60,
  },
});

export const MatchmakingLanding: React.FC = () => {
  const program = useSelector((state: IStoreTypes) => state?.program);
  const draftPairs = useSelector((state: IStoreTypes) => state?.pairing?.draftPairs);
  const pairings = useSelector((state: IStoreTypes) => state?.pairing?.paired);
  const [matchingStrategy, setMatchingStrategy] = React.useState<EMatchingStrategies>(EMatchingStrategies.MAXIMIZE_COMPATIBILITY);
  const [isMatching, setIsMatching] = React.useState<boolean>(false);
  const [gettingPairs, setGettingPairs] = React.useState<boolean>(false);
  const [updatingProgramStatus, setUpdatingProgramStatus] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const styles = useStyles();

  React.useEffect(() => {
    if (!pairings?.length && !program.active) {
      setGettingPairs(true);
      BrancherDispatch(
        dispatch,
        UtilGetPairs({ persist: true, matchingStrategy }, () => {
          setGettingPairs(false);
        }),
      );
    }
  }, []);

  const updateProgramStatus = (previous: boolean) => {
    setUpdatingProgramStatus(true);
    BrancherDispatch(
      dispatch,
      UtilUpdateProgramAttribute(
        previous ? UpdatableProgramInfo.ACTIVE : UpdatableProgramInfo.MATCHING_COMPLETE,
        true,
        () => {
          setUpdatingProgramStatus(false);
        },
      ),
    );
  };

  return (
    <>
      <BrancherFullPageLoader loading={gettingPairs} />
      {!isMatching ? (
        <>
          <Grid container justifyContent="center" alignItems="center" className={styles.margins}>
            <BrancherGridCard
              item
              xs={10}
              md={5}
              container
              direction="column"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Text variant="md" fontWeight={600} color="purple" display="inline">
                  {getUniqueDraftedUsers(draftPairs)}
                </Text>
                <Text variant="md" fontWeight={600} color="placeholderGrey" display="inline">
                  {' '}
                  (out of {program.totalSubmittedUsers})
                </Text>
              </Grid>
              <Grid item>
                <Text variant="md" fontWeight={500}>
                  Users have been matched
                </Text>
              </Grid>
              <Grid item>
                {draftPairs?.length > 0 ? (
                  <ActionOutlinedButton
                    startIcon={<Link />}
                    aria-label="continue-matchmaking"
                    onClick={() => setIsMatching(true)}
                  >
                    Edit matchmaking
                  </ActionOutlinedButton>
                ) : (
                  <ActionButton
                    startIcon={<Link color="secondary" />}
                    aria-label="start-matchmaking"
                    onClick={() => setIsMatching(true)}
                  >
                    Start matchmaking
                  </ActionButton>
                )}
              </Grid>
            </BrancherGridCard>
          </Grid>
          <BrancherDivider marginTop={120} marginBottom={60} />
          <Grid container justifyContent="space-between">
            <Grid item>
              <BackButton
                disabled={updatingProgramStatus}
                onClick={() => updateProgramStatus(true)}
              >
                Previous step
              </BackButton>
            </Grid>
            {draftPairs?.length > 0 && (
              <Grid item>
                <ForwardButton
                  disabled={updatingProgramStatus}
                  onClick={() => updateProgramStatus(false)}
                >
                  Next step
                </ForwardButton>
              </Grid>
            )}
          </Grid>
        </>
      ) : (
        <Matchmaking showActionsNav setIsMatching={() => setIsMatching(false)} />
      )}
    </>
  );
};
