import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { DragDropContext } from 'react-beautiful-dnd';
import { SurveyConfigurationQuestion } from '../../store/reducers/SurveysReducer';
import { BrancherTile } from '../../Components/General/BrancherTile';
import { Text } from '../../Components/General/Text';
import { QuestionCreatorBar } from '../../Components/General/QuestionCreatorBar';
import { DraggableQuestions } from './DraggableQuestions';
import { NonDraggableQuestions } from './SurveyBuilderEngine';

interface ISurveyBuilderQuestionList {
  questions: SurveyConfigurationQuestion[];
  setSelectedQuestion: (questionName: string) => void;
  selectedQuestion: string;
  addQuestion: (index: number) => void;
  deleteQuestion: (questionName: string) => void;
  saveConfiguration: (configuration: SurveyConfigurationQuestion[]) => void;
  introduction?: string;
}

interface IDroppableSource {
  index: number;
  droppableId: 'questions';
}

export const SurveyBuilderQuestionList: React.FC<ISurveyBuilderQuestionList> = (props) => {
  const {
    questions,
    setSelectedQuestion,
    addQuestion,
    selectedQuestion,
    deleteQuestion,
    saveConfiguration,
    introduction = 'Please complete this survey...',
  } = props;

  const controlSettingItems = (items: SurveyConfigurationQuestion[]) => {
    saveConfiguration(items);
  };

  const reorder = (
    list: SurveyConfigurationQuestion[],
    startIndex: number,
    endIndex: number,
  ): SurveyConfigurationQuestion[] => {
    const questionFromIndex = list[startIndex];
    if (!NonDraggableQuestions.includes(questionFromIndex.name)) {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    }
  };

  // The main orchestrator for moving cards around
  const onDragEnd = (result: { source: IDroppableSource; destination: IDroppableSource }) => {
    const { source, destination } = result;
    if (!!destination) {
      const items = reorder(questions, source.index, destination.index);
      controlSettingItems(items);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} container spacing={3}>
        <Grid item xs={12}>
          <BrancherTile mb={-3}>
            <Text variant="sm" fontWeight={600} align="center">
              {introduction}
            </Text>
          </BrancherTile>
        </Grid>
        <Grid item xs={12}>
          <QuestionCreatorBar addQuestion={() => addQuestion(0)} />
        </Grid>

        <DragDropContext onDragEnd={onDragEnd}>
          <DraggableQuestions
            questions={questions}
            setSelectedQuestion={setSelectedQuestion}
            deleteQuestion={deleteQuestion}
            addQuestion={addQuestion}
            selectedQuestion={selectedQuestion}
          />
        </DragDropContext>

        <Grid item xs={12}>
          <BrancherTile>
            <Text variant="sm" fontWeight={500}>
              Thank you for completing this survey!
            </Text>
          </BrancherTile>
        </Grid>
      </Grid>
    </Grid>
  );
};
