import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { Page } from '../../Components/General/Page';
import { BrancherDispatch, UtilGetPair } from '../../store/actions';
import { BackButton } from '../../Components/InputFields/BrancherButton';
import { IProgramPair } from '../../store/actions/PairingActions';
import { ProgramPositions } from '../../consts/ProgramPositionOptions';
import { Colors } from '../../consts/colors';
import { PairMeetingDetails } from './PairMeetingDetails';
import { PairInfo } from './PairInfo';
import { PairRoleProfile } from './PairRoleProfile';
import { PairReasons } from '../User/PairReasons';
import { Text } from '../../Components/General/Text';
import { PairSharedNotesAgreement } from './PairSharedNotesAgreement';

const useStyles = makeStyles({
  profileImage: {
    marginRight: 15,
  },
  marginTop: {
    marginTop: 30,
  },
  marginLeft: {
    marginLeft: (props: { phablet?: boolean; mobile?: boolean; desktop?: boolean }) =>
      props.phablet ? 50 : 0,
  },
  bottomBorder: {
    paddingBottom: `30px !important`,
    marginBottom: 15,
    borderBottom: (props: { phablet?: boolean; mobile?: boolean; desktop?: boolean }) =>
      props.desktop ? `1px solid ${Colors.cardBorder}` : 0,
    borderRight: (props: { phablet?: boolean; mobile?: boolean; desktop?: boolean }) =>
      props.phablet && !props.mobile ? `1px solid ${Colors.cardBorder}` : 0,
  },
});

export const Pair: React.FC = () => {
  const [gettingPairData, setGettingPairData] = React.useState<boolean>(false);
  const [hasPairData, setHasPairData] = React.useState<boolean>(false);
  const menteeRoleId = new URLSearchParams(useLocation().search).get(ProgramPositions.mentee);
  const mentorRoleId = new URLSearchParams(useLocation().search).get(ProgramPositions.mentor);
  const locationHistory = useHistory();
  const [pairData, setPairData] = React.useState<IProgramPair>();
  const hasPairIds = menteeRoleId && mentorRoleId;
  const dispatch = useDispatch();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const phablet = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const styles = useStyles({ phablet, mobile, desktop });

  React.useEffect(() => {
    if (!pairData) {
      if (hasPairIds) {
        getPairData();
      }
    }
  }, []);

  const getPairData = () => {
    setGettingPairData(true);
    BrancherDispatch(
      dispatch,
      UtilGetPair(menteeRoleId, mentorRoleId, (pair) => {
        setPairData(pair.data);
        setGettingPairData(false);
        setHasPairData(true);
      }),
    );
  };

  return (
    <Page loading={!pairData || !hasPairData || gettingPairData}>
      <Grid container>
        <Grid container justifyContent="center">
          <Grid item xs={10}>
            <Box mt={5} mb={5}>
              <BackButton onClick={locationHistory.goBack} />
            </Box>
          </Grid>
          <Grid container item xs={11} md={10} alignItems="center" spacing={4}>
            <Grid container justifyContent="flex-end" item xs={12} alignItems="center">
              <PairInfo
                pairingStartDate={pairData?.pairingStartDate}
                relationshipLength={pairData?.relationshipLength}
                menteeRoleId={menteeRoleId}
                mentorRoleId={mentorRoleId}
              />
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              item
              xs={12}
              sm={6}
              md={12}
              alignItems="center"
              spacing={phablet ? 4 : 0}
              className={styles.bottomBorder}
            >
              <PairRoleProfile
                profileData={pairData?.menteeProfile?.profileData}
                completedProfile={pairData?.menteeProfile?.completedProfile}
                roleId={pairData?.menteeProfile?.roleId}
                activityScore={pairData?.menteeProfile?.activityScore}
                position={ProgramPositions.mentee}
                justifyContent="flex-start"
              />
              <PairRoleProfile
                profileData={pairData?.mentorProfile?.profileData}
                completedProfile={pairData?.mentorProfile?.completedProfile}
                roleId={pairData?.mentorProfile?.roleId}
                activityScore={pairData?.mentorProfile?.activityScore}
                position={ProgramPositions.mentor}
                justifyContent={phablet ? 'flex-start' : 'flex-end'}
              />
            </Grid>
            <Grid container item xs={12}>
              <Grid item>
                <Text variant="md" color="purple" fontWeight={600} marginBottom={30}>
                  We know they're a good match because:
                </Text>
              </Grid>
              <PairReasons {...pairData?.pairingReasons} />
            </Grid>
            <Grid container item xs={12}>
              <Grid item>
                <Text variant="md" color="purple" fontWeight={600} marginBottom={20}>
                  Meetings
                </Text>
              </Grid>
              <PairMeetingDetails {...pairData} />
              <Grid item>
                <Text variant="md" color="purple" fontWeight={600} marginBottom={20}>
                  Other insights
                </Text>
              </Grid>
              <PairSharedNotesAgreement
                sharedNotesAmount={pairData?.sharedNotesAmount}
                completedMentoringAgreement={pairData?.completedMentoringAgreement}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};
