export enum ProgramPositions {
  mentee = 'mentee',
  mentor = 'mentor',
  peer = 'peer',
}

export const ProgramPositionOptions = [
  { value: ProgramPositions.mentee, label: 'Mentee' },
  { value: ProgramPositions.mentor, label: 'Mentor' },
];

export const ExtendedProgramPositionOptions = [
  ...ProgramPositionOptions,
  { value: ProgramPositions.peer, label: 'Peer' },
];

export enum ProgramPairPositions {
  mentees = 'mentees',
  mentors = 'mentors',
  peers = 'peers',
}

export const ProgramPairOptions = [
  { value: ProgramPairPositions.mentees, label: 'Mentees' },
  { value: ProgramPairPositions.mentors, label: 'Mentors' },
  { value: ProgramPairPositions.peers, label: 'Peers' },
];

export enum SuperUserPositions {
  BRANCHER_ADMIN = 'brancherAdmin',
  ADMIN = 'admin',
  DEMO = 'demo',
}

export enum AllPositions {
  mentee = 'mentee',
  mentor = 'mentor',
  peer = 'peer',
  BRANCHER_ADMIN = 'brancherAdmin',
  ADMIN = 'admin',
  DEMO = 'demo',
}

export enum BrancherPlatformType {
  mentoring = 'mentoring',
  peer = 'peer',
  admin = 'admin',
  local = 'local',
}
