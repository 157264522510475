import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { IGoalHealthData } from '../../store/reducers/DashboardReducer';
import { EChosenProfile, ReportingProfileOptions } from './Dashboard';
import { BrancherGridCard } from '../../Components/General/BrancherGridCard';
import { Text } from '../../Components/General/Text';
import { SegmentedControlGroup } from '../../Components/InputFields/BrancherSegmentedControl';
import { BrancherDivider } from '../../Components/General/BrancherDivider';

const useStyles = makeStyles({
  dashboardCard: {
    maxWidth: 400,
    padding: 25,
    minHeight: 'fit-content',
    height: 340,
  },
});

interface IGoals {
  goals: IGoalHealthData;
}

export const GoalsHealth: React.FC<IGoals> = (props) => {
  const [chosenProfile, setChosenProfile] = React.useState<EChosenProfile>(EChosenProfile.ALL);
  const { goals } = props;
  const styles = useStyles({});

  let completed = 0;
  let goalsCreated = 0;
  let percentage = 0;
  if (chosenProfile === EChosenProfile.ALL) {
    completed = goals?.all;
    percentage = goals?.allPercentage;
    goalsCreated = goals?.allGoalsCreated;
  } else if (chosenProfile === EChosenProfile.MENTEE) {
    completed = goals?.mentee;
    percentage = goals?.menteePercentage;
    goalsCreated = goals?.menteeGoalsCreated;
  } else if (chosenProfile === EChosenProfile.MENTOR) {
    completed = goals?.mentor;
    percentage = goals?.mentorPercentage;
    goalsCreated = goals?.mentorGoalsCreated;
  }

  return (
    <Grid container>
      <Grid item xs={11}>
        <SegmentedControlGroup
          value={chosenProfile}
          updateValue={(v: EChosenProfile) => setChosenProfile(v)}
          options={ReportingProfileOptions}
        />
      </Grid>
      <BrancherGridCard
        container
        item
        direction="column"
        spacing={3}
        className={styles.dashboardCard}
        xs={11}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item container justifyContent="center">
          <Text variant="lg" display="inline" color="purple" fontWeight={700}>
            {completed}{' '}
          </Text>
          <Text variant="lg" display="inline" fontWeight={500} align="center">
            Goals Achieved
          </Text>
        </Grid>
        <BrancherDivider marginTop={10} height={2} />
        <Grid item container justifyContent="center">
          <Text variant="lg" display="inline" color="purple" fontWeight={700}>
            {goalsCreated}{' '}
          </Text>
          <Text variant="lg" display="inline" fontWeight={500}>
            Goals Created
          </Text>
        </Grid>
        <Grid item>
          <Text variant="xs" color="placeholderGrey" align="center">
            {percentage ?? '0'}% of{' '}
            {chosenProfile === EChosenProfile.ALL
              ? 'users'
              : chosenProfile === EChosenProfile.MENTOR
              ? 'mentors'
              : 'mentees'}{' '}
            have created goals
          </Text>
        </Grid>
      </BrancherGridCard>
    </Grid>
  );
};
