import React, { ReactElement } from 'react';
import Box from '@material-ui/core/Box';
import Accordion, { AccordionProps } from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Text } from './Text';

interface IBrancherAccordion extends Omit<AccordionProps, 'children'> {
  panels: Array<{
    title: string;
    name: string;
    content: ReactElement;
  }>;
}

export const BrancherAccordion: React.FC<IBrancherAccordion> = (props) => {
  const { panels, ...other } = props;
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box width="100%">
      {panels?.map((panel, panelIndex) => (
        <Accordion
          {...other}
          key={panelIndex}
          expanded={expanded === panel.name}
          onChange={handleChange(panel.name)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${panel.name}-content`}
            id={`${panel.name}-header`}
          >
            <Text variant="sm" fontWeight={600} color="purple">
              {panel.title}
            </Text>
          </AccordionSummary>
          <AccordionDetails>{panel.content}</AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};
