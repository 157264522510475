import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import { useDispatch, useSelector } from 'react-redux';
import domToPdf from 'dom-to-pdf';
import { format } from 'date-fns';
import { Page } from '../../Components/General/Page';
import { Text } from '../../Components/General/Text';
import { IStoreTypes } from '../../store/storeTypes';
import { BrancherDispatch, UtilGetGlobalDashboardData } from '../../store/actions';
import { AllPositions } from '../../consts/ProgramPositionOptions';
import { ActionOutlinedButton } from '../../Components/InputFields/BrancherButton';
import { BrancherDivider } from '../../Components/General/BrancherDivider';
import { RouteMaster } from '../../Components/Routing';
import { DashboardCard } from '../Dashboard/DashboardCard';
import { EmojiRate } from '../../Components/General/EmojiRate';
import { BillingTable } from './BillingTable';

export enum EChosenProfile {
  ALL = 'all',
  MENTEE = 'MENTEE',
  MENTOR = 'MENTOR',
}

const useStyles = makeStyles({
  dashboardContainer: {
    marginBottom: 50,
  },
});

export const SuperUserDashboard = () => {
  const [forPrint, setForPrint] = React.useState<boolean>(false);
  const [retrievingDashboardData, setRetrievingDashboardData] = React.useState<boolean>(false);
  const dashboardData = useSelector((state: IStoreTypes) => state.dashboard?.globalDashboardHealth);
  const isBrancherAdmin = useSelector((state: IStoreTypes) => state.user?.positions)?.includes(
    AllPositions.BRANCHER_ADMIN,
  );
  const dispatch = useDispatch();
  const styles = useStyles();

  React.useEffect(() => {
    if (forPrint) {
      generatePdf();
    }
  }, [forPrint]);

  const generatePdf = () => {
    const element = document.getElementById('dashboard');
    const captureDate = format(new Date(), 'dd/MM/yyyy');

    const options = {
      filename: `${captureDate}-global-dashboard.pdf`,
    };

    return domToPdf(element, options, () => {
      setForPrint(false);
    });
  };

  const setRetrievingInitialDashboardData = () => {
    setRetrievingDashboardData(true);
    BrancherDispatch(
      dispatch,
      UtilGetGlobalDashboardData(() => {
        setRetrievingDashboardData(false);
      }),
    );
  };

  React.useEffect(() => {
    if (!isBrancherAdmin) {
      window.location.assign(RouteMaster.home.path);
    } else {
      setRetrievingInitialDashboardData();
    }
  }, []);

  return (
    <Page loading={retrievingDashboardData || !dashboardData}>
      <Grid container justifyContent="center" className={styles.dashboardContainer}>
        <Grid container item xs={11} alignItems="center" spacing={2}>
          <Grid item container justifyContent="space-between">
            <Grid item>
              <Grid item>
                <Text variant="xl" marginTop={30} color="purple" marginBottom={50}>
                  Global Dashboard
                </Text>
              </Grid>
            </Grid>
            <Box marginTop={4}>
              <ActionOutlinedButton
                size="small"
                loading={forPrint}
                onClick={() => setForPrint(true)}
                startIcon={<PictureAsPdf />}
              >
                Export as PDF
              </ActionOutlinedButton>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={11} container id="dashboard" spacing={2}>
          <Grid item container justifyContent="space-between" spacing={4}>
            <Grid item xs={6} md={4}>
              <DashboardCard title="Average Active User" height={300} width="100%">
                <Grid item container alignItems="center" justifyContent="center">
                  <Text variant="xl" display="inline" color="purple" fontWeight={700}>
                    {dashboardData?.activeUserPercentage}%
                  </Text>
                </Grid>
              </DashboardCard>
            </Grid>
            <Grid item xs={6} md={4}>
              <DashboardCard title="Total Signed-up users" height={300} width="100%">
                <Grid item container alignItems="center" justifyContent="center">
                  <Text variant="xl" display="inline" color="purple" fontWeight={700}>
                    {dashboardData?.signedUpUserAmount}
                  </Text>
                </Grid>
              </DashboardCard>
            </Grid>
            <Grid item xs={6} md={4}>
              <DashboardCard title="Average Relationship Length" height={300} width="100%">
                <Grid item container alignItems="center" justifyContent="center">
                  <Text variant="xl" display="inline" color="purple" fontWeight={700}>
                    {dashboardData?.averageRelationshipLength} days
                  </Text>
                </Grid>
              </DashboardCard>
            </Grid>
            <Grid item xs={6} md={4}>
              <DashboardCard title="Average Engagement" height={300} width="100%">
                <Grid item container alignItems="center" justifyContent="center">
                  <Text variant="xl" display="inline" color="purple" fontWeight={700}>
                    {dashboardData?.engagementPercentage}%
                  </Text>
                </Grid>
              </DashboardCard>
            </Grid>
            <Grid item xs={6} md={4}>
              <DashboardCard title="Average Meeting Satisfaction Rating" height={300} width="100%">
                <Grid item container alignItems="center" justifyContent="center">
                  <Text variant="xl" display="inline" color="purple" fontWeight={700}>
                    {dashboardData?.meetingSatisfactionRating}
                    <EmojiRate
                      rating={dashboardData?.meetingSatisfactionRating}
                      variant="xl"
                      showRawRating={false}
                    />
                  </Text>
                </Grid>
              </DashboardCard>
            </Grid>
            <Grid item xs={6} md={4}>
              <DashboardCard title="Average Pairing Satisfaction Rating" height={300} width="100%">
                <Grid item container alignItems="center" justifyContent="center">
                  <Text variant="xl" display="inline" color="purple" fontWeight={700}>
                    {dashboardData?.pairingSatisfactionRating} (TBD)
                    <EmojiRate
                      rating={dashboardData?.pairingSatisfactionRating}
                      variant="xl"
                      showRawRating={false}
                    />
                  </Text>
                </Grid>
              </DashboardCard>
            </Grid>
            <Grid item xs={6} md={4}>
              <DashboardCard title="Total Subscription Amount" height={300} width="100%">
                <Grid item container alignItems="center" justifyContent="center">
                  <Text variant="xl" display="inline" color="purple" fontWeight={700}>
                    {dashboardData?.subscriptionAmount}
                  </Text>
                </Grid>
              </DashboardCard>
            </Grid>
            <Grid item xs={6} md={4}>
              <DashboardCard title="Total Training Modules Completed" height={300} width="100%">
                <Grid item container alignItems="center" justifyContent="center">
                  <Text variant="xl" display="inline" color="purple" fontWeight={700}>
                    {dashboardData?.userTrainingModulesCompleted}
                  </Text>
                </Grid>
              </DashboardCard>
            </Grid>
          </Grid>

          <BrancherDivider height={2} marginTop={50} marginBottom={30} />

          <Grid container xs={12} spacing={4} alignItems="center" justifyContent="center">
            {dashboardData?.programs?.map((program) => (
              <>
                <DashboardCard
                  title={program.programName}
                  height={700}
                  width="100%"
                  key={program.programId}
                >
                  <Text variant="md">
                    Admins: {program.admins?.length > 0 ? program.admins?.join(', ') : '-'}
                  </Text>
                  <Text variant="md">Close Date: {program.programCloseDate ?? '-'}</Text>
                  <Text variant="md">User Amount: {program.signedUpUserAmount ?? '-'}</Text>
                  <Text variant="md">Subscription Amount: {program.subscriptionAmount ?? '-'}</Text>
                  <Text variant="md">Active Users: {program.activeUserPercentage ?? '-'}%</Text>
                  <Text variant="md">Engagement: {program.engagementPercentage ?? '-'}%</Text>
                  <Text variant="md">
                    Average Relationship Length: {program.averageRelationshipLength ?? '-'} days
                  </Text>
                  <Text variant="md" display="inline">
                    Meeting Satisfaction:{' '}
                    <EmojiRate
                      rating={
                        program.meetingSatisfactionRating > 0
                          ? program.meetingSatisfactionRating
                          : '-'
                      }
                      variant="md"
                    />
                  </Text>
                  <Text variant="md">
                    Pairing Satisfaction:{' '}
                    <EmojiRate
                      rating={
                        program.pairingSatisfactionRating > 0
                          ? program.pairingSatisfactionRating
                          : '-'
                      }
                      variant="md"
                    />
                  </Text>
                  <Text variant="md">
                    Training Modules Completed: {program.userTrainingModulesCompleted ?? '-'}
                  </Text>

                  <Grid container item justifyContent="center">
                    <Text variant="md" fontWeight={600}>
                      Billing Periods
                    </Text>
                    {program.billing?.length > 0 ? (
                      <Grid container>
                        <BillingTable billings={program.billing} />
                      </Grid>
                    ) : (
                      <Grid item container justifyContent="center" xs={12}>
                        <Text variant="sm">
                          No billing cycles are available for this program yet.
                        </Text>
                      </Grid>
                    )}
                  </Grid>
                </DashboardCard>
                <BrancherDivider height={2} marginTop={30} marginBottom={30} />
              </>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};
