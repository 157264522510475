import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Add from '@material-ui/icons/Add';
import Flare from '@material-ui/icons/Flare';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch } from 'react-redux';
import {
  BrancherDispatch,
  UtilAddUserToGroup,
  UtilGetAllProgramUsers,
  UtilMatchGroupMentors,
} from '../../store/actions';
import { BrancherDialog } from '../../Components/General/BrancherDialog';
import {
  ActionOutlinedButton,
  BrancherButton,
  SaveButton,
} from '../../Components/InputFields/BrancherButton';
import {
  BrancherAutoComplete,
  IBrancherAutoCompleteOption,
} from '../../Components/InputFields/BrancherAutoComplete';
import { IGroupMember } from './Groups';
import { IExtendedGroupData } from './Group';
import { ProgramPositions } from '../../consts/ProgramPositionOptions';

const useStyles = makeStyles({
  ctas: {
    marginTop: 60,
    marginBottom: 10,
  },
});

interface IAddMenteesToGroup {
  groupId: string;
  currentMentees: IGroupMember[];
  updateGroupData: (groupData: IExtendedGroupData) => void;
  currentMentors?: IGroupMember[];
}

export const AddMenteeToGroup: React.FC<IAddMenteesToGroup> = ({
  updateGroupData,
  groupId,
  currentMentees = [],
  currentMentors = [],
}) => {
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [editingGroup, setEditingGroup] = React.useState<boolean>(false);
  const [openEditingGroup, setOpenEditingGroup] = React.useState<boolean>(false);
  const [gettingUsers, setGettingUsers] = React.useState<boolean>(false);
  const [gettingIdealMentees, setGettingIdealMentees] = React.useState<boolean>(false);
  const [retrievedListOfIdealMentees, setRetrievedListOfIdealMentees] =
    React.useState<boolean>(false);
  const [menteeList, setMenteeList] = React.useState<IBrancherAutoCompleteOption[]>([]);
  const [mentee, setMentee] = React.useState<IBrancherAutoCompleteOption>(null);
  const dispatch = useDispatch();
  const styles = useStyles();

  React.useEffect(() => {
    setGettingUsers(true);
    setRetrievedListOfIdealMentees(false);
    BrancherDispatch(
      dispatch,
      UtilGetAllProgramUsers((userData) => {
        if (userData.success) {
          let allMentees = [];
          userData.data.forEach((user) => {
            if (user.hasMenteePosition) {
              if (!currentMentees.find((cm) => cm.roleId === user.menteeRoleId)) {
                allMentees.push({
                  value: user.menteeRoleId,
                  label: `${user.firstName} ${user.lastName}`,
                });
              }
            }
          });
          setMenteeList(allMentees);
        }
        setGettingUsers(false);
      }),
    );
  }, [currentMentees]);

  const convertToGroupMember = (): IGroupMember => {
    return { roleId: mentee.value, name: mentee.label, joined: new Date().getTime() };
  };

  const editGroup = () => {
    setEditingGroup(true);
    BrancherDispatch(
      dispatch,
      UtilAddUserToGroup(
        convertToGroupMember(),
        ProgramPositions.mentee,
        groupId,
        false,
        (groupRes) => {
          setEditingGroup(false);
          if (groupRes.success) {
            updateGroupData(groupRes.data);
          }
          setMentee(null);
          setMenteeList([]);
          setOpenEditingGroup(false);
        },
      ),
    );
  };

  const getIdealMentees = () => {
    setGettingIdealMentees(true);
    BrancherDispatch(
      dispatch,
      UtilMatchGroupMentors(currentMentors, (idealMentees) => {
        setGettingIdealMentees(false);
        setMentee(null);
        setMenteeList(idealMentees.data?.map((g) => ({ value: g.mentee, label: g.menteeName })));
        setRetrievedListOfIdealMentees(true);
      }),
    );
  };

  return (
    <>
      <BrancherDialog
        keepMounted={false}
        contentWidth
        open={openEditingGroup}
        setClose={() => setOpenEditingGroup(false)}
        labelledBy="edit-group"
        title="Add mentee to group"
        fullScreen={mobile}
      >
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={10}>
            <BrancherAutoComplete
              helpText={retrievedListOfIdealMentees ? 'The list of mentees has been updated' : ''}
              fullWidth
              value={mentee}
              updateValue={(a: IBrancherAutoCompleteOption) => setMentee(a)}
              label="Add a mentee"
              name="mentee"
              options={menteeList}
            />
          </Grid>
          <Grid item container justifyContent="space-between" className={styles.ctas} xs={10}>
            {currentMentors?.length > 0 && (
              <ActionOutlinedButton
                startIcon={<Flare />}
                onClick={getIdealMentees}
                loading={gettingIdealMentees}
              >
                Get ideal mentees
              </ActionOutlinedButton>
            )}
            <SaveButton onClick={editGroup} loading={editingGroup}>
              Save
            </SaveButton>
          </Grid>
        </Grid>
      </BrancherDialog>
      <Grid item xs={12}>
        <BrancherButton
          startIcon={<Add color="secondary" />}
          size="small"
          color="primary"
          onClick={() => setOpenEditingGroup(true)}
          disabled={gettingUsers}
          id="add-mentee-to-group"
        >
          Add mentee to group
        </BrancherButton>
      </Grid>
    </>
  );
};
