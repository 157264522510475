import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { DataGrid, GridColDef } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { Page } from '../../Components/General/Page';
import {
  BrancherDispatch,
  UtilGetAllProgramSupportRequests,
  UtilUpdateSupportRequestStatus,
} from '../../store/actions';
import { UserProfileLink } from '../ProgramUsers/ProgramUsers';
import { ESupportRequestStatus, ISupportRequestInfo } from '../../store/reducers/ProgramReducer';
import { BrancherLinkText } from '../../Components/General/BrancherLinkText';
import { Text } from '../../Components/General/Text';
import { BrancherSelect } from '../../Components/InputFields/BrancherSelect';
import { format, fromUnixTime } from 'date-fns';

interface IAllProgramSupportRequestData extends Omit<ISupportRequestInfo, 'supportOption'> {
  supportOption: string;
  requesterName: string;
  email: string;
}

const useStyles = makeStyles({
  dataGrid: {
    width: '100%',
    minWidth: '100%',
    minHeight: '100vh',
    // height: '100%',
    '& .MuiDataGrid-renderingZone': {
      maxHeight: 'none !important',
    },
    '& .MuiDataGrid-cell': {
      lineHeight: 'unset !important',
      maxHeight: 'fit-content !important',
      minHeight: 'fit-content !important',
      whiteSpace: 'normal',
      paddingTop: 8,
      paddingBottom: 8,
      '& .MuiPaper-root': {
        marginTop: -150,
      },
      '& .MuiPopover-root': {
        zIndex: 300,
        minHeight: '320px !important',
      },
    },
    '& .MuiDataGrid-row': {
      // overflowY: 'scroll',
      // minHeight: '80px !important',
      maxHeight: '100% !important',
    },
  },
});

export const SupportRequests = () => {
  const [gettingProgramSupportRequests, setGettingProgramSupportRequests] =
    React.useState<boolean>(false);
  const [programSupportRequests, setProgramSupportRequests] = React.useState<
    IAllProgramSupportRequestData[]
  >([]);
  const dispatch = useDispatch();
  const styles = useStyles();

  const EReadableStatus = {
    [ESupportRequestStatus.OPEN]: 'Open',
    [ESupportRequestStatus.IN_PROGRESS]: 'In progress',
    [ESupportRequestStatus.CLOSED]: 'Closed',
  };

  const requestStatusOptions: Array<{ label: string; value: ESupportRequestStatus }> = [
    {
      value: ESupportRequestStatus.OPEN,
      label: EReadableStatus[ESupportRequestStatus.OPEN],
    },
    {
      value: ESupportRequestStatus.IN_PROGRESS,
      label: EReadableStatus[ESupportRequestStatus.IN_PROGRESS],
    },
    {
      value: ESupportRequestStatus.CLOSED,
      label: EReadableStatus[ESupportRequestStatus.CLOSED],
    },
  ];

  const columns: GridColDef[] = [
    {
      field: 'email',
      headerName: 'Email',
      flex: 150,
      sortable: true,
      renderCell: (grid) => (
        <BrancherLinkText
          variant="xxs"
          target="_blank"
          href={`mailto:${grid.value}?subject=Support request`}
        >
          {grid.value}
        </BrancherLinkText>
      ),
    },
    {
      field: 'requesterName',
      headerName: 'Name',
      width: 120,
      sortable: true,
      renderCell: (grid) => <Text variant="xxs">{grid.value}</Text>,
    },
    {
      field: 'supportOption',
      headerName: 'Support reason',
      width: 150,
      sortable: true,
      renderCell: (grid) => <Text variant="xxs">{grid.value}</Text>,
    },
    {
      field: 'requestText',
      headerName: 'Request message',
      flex: 400,
      sortable: false,
      renderCell: (grid) => <Text variant="xxs">{grid.value}</Text>,
    },
    {
      field: 'requestDate',
      headerName: 'Request date',
      flex: 150,
      sortable: false,
      renderCell: (grid) => (
        <Text variant="xxs">
          {grid.value ? format(fromUnixTime(Number(grid.value) / 1000), 'dd/MM/yyyy') : ''}
        </Text>
      ),
    },
    {
      field: 'status',
      headerName: 'Request status',
      width: 250,
      sortable: true,
      renderCell: (grid) =>
        grid.value !== ESupportRequestStatus.CLOSED ? (
          <BrancherSelect
            value={grid.value as string}
            options={requestStatusOptions}
            fullWidth
            name="request-status"
            updateValue={(a: ESupportRequestStatus) =>
              updateSupportRequestStatus(grid.id.toString(), a)
            }
          />
        ) : (
          <Text variant="xs" color="red" fontWeight={600}>
            Closed
          </Text>
        ),
    },
    {
      field: 'requesterUserId',
      headerName: 'Actions',
      width: 150,
      renderCell: (grid) => (
        <UserProfileLink userId={String(grid.value)}>View user profile</UserProfileLink>
      ),
    },
  ];

  React.useEffect(() => {
    setGettingProgramSupportRequests(true);
    BrancherDispatch(
      dispatch,
      UtilGetAllProgramSupportRequests((supportRequests) => {
        setGettingProgramSupportRequests(false);
        setProgramSupportRequests(supportRequests);
      }),
    );
  }, []);

  const updateSupportRequestStatus = (requestId: string, status: ESupportRequestStatus) => {
    BrancherDispatch(
      dispatch,
      UtilUpdateSupportRequestStatus(requestId, status, () => {
        const newSupportRequests = programSupportRequests.map((s) => {
          if (s.supportRequestId === requestId) {
            return { ...s, status };
          } else {
            return s;
          }
        });
        setProgramSupportRequests(newSupportRequests);
      }),
    );
  };

  const modifiedSupportRequestData = programSupportRequests?.map((u) => ({
    ...u,
    id: u.supportRequestId,
  }));

  return (
    <Page loading={gettingProgramSupportRequests}>
      <Grid container justifyContent="center">
        <Grid container item xs={12}>
          <Box className={styles.dataGrid}>
            {programSupportRequests?.length > 0 ? (
              <DataGrid
                rows={modifiedSupportRequestData}
                columns={columns}
                pageSize={7}
                disableSelectionOnClick
              />
            ) : (
              <Grid container item alignItems="center" justifyContent="center">
                <Text variant="md" fontWeight={500} color="purple" marginTop={150}>
                  There are no "Open" or "In progress" support requests.
                </Text>
              </Grid>
            )}
          </Box>
        </Grid>
      </Grid>
    </Page>
  );
};
