import * as React from 'react';
import Box from '@material-ui/core/Box';
import DragIndicator from '@material-ui/icons/DragIndicator';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { SurveyConfigurationQuestion } from '../../store/reducers/SurveysReducer';
import { QuestionTile } from '../../Components/General/QuestionTile';
import { QuestionCreatorBar } from '../../Components/General/QuestionCreatorBar';
import { Colors } from '../../consts/colors';
import { BrancherOwnedQuestions, NonDraggableQuestions } from './SurveyBuilderEngine';

interface IDraggableQuestion {
  questions: SurveyConfigurationQuestion[];
  setSelectedQuestion: (a: SurveyConfigurationQuestion['name']) => void;
  deleteQuestion: (a: SurveyConfigurationQuestion['name']) => void;
  addQuestion: (index: number) => void;
  selectedQuestion: string;
}

export const DraggableQuestions: React.FC<IDraggableQuestion> = (props) => {
  const { questions, setSelectedQuestion, selectedQuestion, deleteQuestion, addQuestion } = props;

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    minHeight: 'fit-content',
    borderRadius: 15,
    userSelect: 'none',
    margin: `12px 0 12px 0`,
    display: 'flex',
    cursor: isDragging ? 'move' : 'pointer',
    // boxShadow: `0px 3px 8px ${Colors.cardBorder}`,
    background: isDragging ? Colors.backgroundLightPurple : Colors.white,
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver: boolean) => ({
    // background: isDraggingOver ? Colors.lightGrey : Colors.backgroundDarkerPurple,
    padding: 12,
    borderRadius: 15,
  });

  return (
    <Droppable droppableId="questions">
      {(provided, snapshot) => (
        <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
          {questions?.map((item, index) => (
            <Draggable draggableId={item.name} index={index} key={item.name}>
              {(providedColumn, snapshotColumn) => {
                const draggableProps = providedColumn.draggableProps;
                const dragHandleProps = providedColumn.dragHandleProps;
                return (
                  <div
                    onClick={() => setSelectedQuestion(item.name)}
                    ref={providedColumn.innerRef}
                    {...draggableProps}
                    {...dragHandleProps}
                    style={getItemStyle(
                      snapshotColumn.isDragging,
                      providedColumn.draggableProps.style,
                    )}
                  >
                    <Box width="100%" mb={3}>
                      <QuestionTile
                        padding={15}
                        hoverCursor
                        question={item}
                        selected={item.name === selectedQuestion}
                        questionIndex={index}
                        brancherOwned={item.mandatory || BrancherOwnedQuestions.includes(item.name)}
                        deleteQuestion={() => deleteQuestion(item.name)}
                      />
                      <QuestionCreatorBar addQuestion={() => addQuestion(index + 1)} />
                    </Box>
                    {!NonDraggableQuestions.includes(item.name) && (
                      <DragIndicator color="primary" />
                    )}
                  </div>
                );
              }}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
