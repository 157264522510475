import * as React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { IBillingInfo } from '../../store/reducers/DashboardReducer';
import { BrancherButton } from '../../Components/InputFields/BrancherButton';
import { EBooleanOptions } from './CreateProgram';
import { BrancherDispatch, UtilSetHasBeenBilled } from '../../store/actions';

interface IBillingTable {
  billings: IBillingInfo[];
}

export const BillingTable: React.FC<IBillingTable> = ({ billings }) => {
  const [settingHasBeenBilled, setSettingHasBeenBilled] = React.useState<boolean>(false);
  const dispatch = useDispatch();

  const setHasBeenBilled = (billingPeriodId: string, programId: string, hasBeenBilled: boolean) => {
    setSettingHasBeenBilled(true);
    BrancherDispatch(
      dispatch,
      UtilSetHasBeenBilled(billingPeriodId, programId, hasBeenBilled, (res) => {
        setSettingHasBeenBilled(false);
      }),
    );
  };

  return (
    <Table aria-label="billing table">
      <TableHead>
        <TableRow>
          <TableCell align="center">Cycle Start Date</TableCell>
          <TableCell align="center">Cycle End Date</TableCell>
          <TableCell align="center">Active User Amount</TableCell>
          <TableCell align="center">Subscription Amount</TableCell>
          <TableCell align="center">Extra Subscription Amount</TableCell>
          <TableCell align="center">Payable Amount</TableCell>
          <TableCell align="center">Action</TableCell>
          <TableCell align="center">Has been billed</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {billings.map((billing) => (
          <TableRow key={billing.billingPeriodId}>
            <TableCell component="th" scope="row">
              {billing.billingStartDate}
            </TableCell>
            <TableCell align="center">{billing.billingEndDate}</TableCell>
            <TableCell align="center">{billing.activeUserAmount}</TableCell>
            <TableCell align="center">{billing.currentSubscriptionAmount}</TableCell>
            <TableCell align="center">
              {billing.additionalUserSubscription < 0 ? 0 : billing.additionalUserSubscription}
            </TableCell>
            <TableCell align="center">
              ${billing.additionalPayableAmount ? Number(billing.additionalPayableAmount) : '0'}
            </TableCell>
            <TableCell align="center">
              <BrancherButton
                disabled={billing.hasBeenBilled || settingHasBeenBilled}
                size="small"
                onClick={() =>
                  setHasBeenBilled(
                    billing.billingPeriodId,
                    billing.programId,
                    !billing.hasBeenBilled,
                  )
                }
              >
                Mark as billed
              </BrancherButton>
            </TableCell>
            <TableCell align="center">
              {billing.hasBeenBilled ? EBooleanOptions.YES : EBooleanOptions.NO}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
