import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Text } from '../../Components/General/Text';
import { BrancherGridCard } from '../../Components/General/BrancherGridCard';

interface IDashboardCardUseStyles extends Pick<IDashboardCard, 'height' | 'width'> {
  desktop: boolean;
}

const useStyles = makeStyles({
  dashboardCard: {
    maxHeight: (props: IDashboardCardUseStyles) =>
      props.height ? 'fit-content' : props.desktop ? 500 : 600,
    height: (props: IDashboardCardUseStyles) =>
      props.height ? props.height : props.desktop ? 500 : 600,
    maxWidth: (props: IDashboardCardUseStyles) => props.width ?? 420,
    padding: 25,
  },
});

interface IDashboardCard {
  title: string;
  height?: number | string;
  width?: number | string;
}

export const DashboardCard: React.FC<IDashboardCard> = ({ children, title, height, width }) => {
  const desktop = useMediaQuery(useTheme().breakpoints.up('xl'));
  const styles = useStyles({ desktop, height, width });

  return (
    <BrancherGridCard
      container
      item
      direction="column"
      spacing={3}
      className={styles.dashboardCard}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item container justifyContent="center">
        <Text variant="lg" color="purple" fontWeight={700}>
          {title}
        </Text>
      </Grid>
      {children}
    </BrancherGridCard>
  );
};
