import * as React from 'react';
import Workbook from 'react-excel-workbook';
import { ActionOutlinedButton } from '../../Components/InputFields/BrancherButton';

export const GenerateAllUserInfoReport = ({
  baseData,
  baseDataColumns,
  menteeData,
  menteeDataColumns,
  mentorData,
  mentorDataColumns,
}) => (
  <Workbook
    filename={`${baseData[0]?.name}_user_info_report.xlsx`}
    element={
      <ActionOutlinedButton size="small">
        Download {baseData[0]?.name}'s user report!
      </ActionOutlinedButton>
    }
  >
    <Workbook.Sheet data={baseData} name="Base User">
      {baseDataColumns.map((d, l) => (
        <Workbook.Column label={d.readableName} value={d.attributeName} key={l} />
      ))}
    </Workbook.Sheet>
    <Workbook.Sheet data={menteeData} name="Mentee Report">
      {menteeDataColumns?.map((d, l) => (
        <Workbook.Column label={d.readableName} value={d.attributeName} key={l} />
      ))}
    </Workbook.Sheet>
    <Workbook.Sheet data={mentorData} name="Mentor Report">
      {mentorDataColumns?.map((d, l) => (
        <Workbook.Column label={d.readableName} value={d.attributeName} key={l} />
      ))}
    </Workbook.Sheet>
  </Workbook>
);
